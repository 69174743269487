import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
// import 'react-select/scss/select.scss';
import "../../styles/select-field.scss";

/**
 * SelectField React Component
 * @extends {module:React.PureComponent}
 * @returns {JSX.Element} HTML
 */
function SelectField({
  className = "",
  label = "",
  disabled = false,
  onChange,
  value,
  options,
  placeholder = "",
  ...props
}) {
  return (
    <div className={`select-field-container ${className}`}>
      {Boolean(label) && <div className="select-field-label">{label}</div>}
      <Select
        disabled={disabled}
        options={options}
        value={value}
        onChange={onChange}
        clearable={false}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
}

SelectField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape(),
      ]),
    }),
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape(),
  ]),
  placeholder: PropTypes.string,
};

export default SelectField;
