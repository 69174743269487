import axios from 'axios';
import { getApiUrl } from 'helpers';

export const updateBasicConfigsNew = async (dibsId) => {
    const url = getApiUrl('/widget/get-basic-config');

    let data;
    await axios
        .post(url, { dibsStudioId: dibsId })
        .then((response) => {
            data = response.data;
            console.log('basic config info is', data);
        })
        .catch((error) => {
            console.log('update basic configs new error is', error);
        });
    return data;
};
