import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import io from 'socket.io-client';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    Container,
    CssBaseline,
    Toolbar,
    useMediaQuery,
} from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import HideAppBar from './Header/HideAppBarOnScroll';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { logout } from 'firebaseConfig';
import FullPageLoader from 'ui-component/loaders/FullPageLoader';

// assets
import { IconChevronRight } from '@tabler/icons-react';
import StudioEmployeeAccount from 'actions';
import { addStudioData } from 'store/slices/dibsstudio';
import { getEvents } from 'store/slices/calendar';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, mtu }) => ({
        ...theme.typography.mainContent,
        minHeight: '100vh', // Ensure it takes the full height of the viewport
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: '50px', // here is where there have been issues with some of the views - being too low
        ...(!open && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.shorter,
            }),
            [theme.breakpoints.up('md')]: {
                marginLeft: -(drawerWidth - 5),
                width: `calc(100% - ${drawerWidth}px)`,
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: '10px',
                width: `calc(100% - ${drawerWidth}px)`,
                padding: '16px',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px',
                width: `calc(100% - ${drawerWidth}px)`,
                padding: '16px',
                marginRight: '10px',
            },
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter,
            }),
            marginLeft: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: `calc(100% - ${drawerWidth}px)`,
            [theme.breakpoints.down('md')]: {
                marginLeft: '10px',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px',
            },
        }),
    })
);

// ==============================|| MAIN LAYOUT - THIS ONE IS BEING USED ||============================== //

const MainLayout = ({ children }) => {
    const theme = useTheme();
    const location = useLocation();

    const navigate = useNavigate();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    // const user = firebase.auth().currentUser;
    // TODO - come back to this auth
    // console.log(`user from firebase is -> ${JSON.stringify(user)}`);
    // const { config } = useSelector((state) => state.dibsstudio);
    const { studioEmployeeId } = useSelector(
        (state) => state.studioemployeeuser
    );

    const { dibsId } = useParams();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();
    const [marginToUse, setMarginToUse] = useState('40px');

    React.useEffect(() => {
        const loggingout = async () => {
            await logout().then(() => {
                navigate(`/login/${dibsId}`);
            });
        };
        if (studioEmployeeId === 0) {
            try {
                loggingout();
            } catch (err) {
                console.error(`error on main layout - logging out -> ${err}`);
            }
        }
    }, [dibsId, navigate, studioEmployeeId]);
    useEffect(() => {
        // Use the environment variable directly within your component
        const socket = io(process.env.REACT_APP_SOCKET_URL);

        socket.on('appointmentUpdated', (appointment) => {
            dispatch(getEvents(dibsId, 'tz - not used'));
        });

        return () => {
            socket.off('appointmentUpdated');
            socket.close();
        };
    }, [dibsId, dispatch]);

    useEffect(() => {
        if (location.pathname.includes('/frontdesk')) {
            setMarginToUse('80px');
        } else {
            setMarginToUse('40px');
        }
    }, [location]);

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // if (config.id === 0) {
        //     // change this to user.email
        //     StudioEmployeeAccount('ops+218@ondibs.com').then((result) => {
        //         dispatch(addStudioData(result.data));
        //     });
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd, dispatch]);

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    );
    return (
        <Box sx={{ display: 'flex' }} id="main-layout-new-box">
            <FullPageLoader />
            <CssBaseline />
            {/* header */}
            <HideAppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                drawerOpenStatus={drawerOpen}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: drawerOpen
                        ? theme.transitions.create('width')
                        : 'none',
                }}
            >
                {header}
            </HideAppBar>
            {/* drawer */}
            <Sidebar />

            {/* main content */}

            <Main
                theme={theme}
                open={drawerOpen}
                id="main-holding-container"
                mtu={marginToUse}
            >
                {/* breadcrumb */}
                {container && (
                    <Container maxWidth="lg" id="container-sub-main">
                        <Breadcrumbs
                            separator={IconChevronRight}
                            navigation={navigation}
                            icon
                            title
                            rightAlign
                        />
                        {children}
                    </Container>
                )}
                {!container && (
                    <>
                        {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                        {children}
                    </>
                )}
            </Main>
        </Box>
    );
};

export default MainLayout;
