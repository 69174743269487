import { useDispatch } from 'store';

// material-ui
import { Button, Grid, Stack, TextField, capitalize } from '@mui/material';

import { NumericFormat } from 'react-number-format';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

import createNewDibsUser from 'actions/users/createNewDibsUser.js';
import createNewShopifyUser from 'actions/shopify/createNewShopifyUser';
import recordShopifyStatus from 'actions/shopify/recordShopifyStatus';
import { useSelector } from 'store';

import { capitalizeFirstLetter } from 'helpers/general';
import setAlert from 'actions/status/setAlert';
import translate from 'helpers/translate';

const validationSchema = yup.object({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),
    firstName: yup.string().required('First name is required'),
});

const myStyle = {
    minWidth: '300px',
    width: '98%',
    boxShadow: 'none',
    padding: '10px',
};

// ==============================|| FORM VALIDATION - NEW CLIENT ACCOUNT  ||============================== //

const NewClientAccountForm = () => {
    const dispatch = useDispatch();
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const { studioEmployeeId } = useSelector(
        (state) => state.studioemployeeuser
    );

    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            phone2: '',
        },
        validationSchema,
        onSubmit: async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
        ) => {
            try {
                const thisemail = values.email;
                const newfirstname = capitalizeFirstLetter(values.firstName);
                const valuestosend = {
                    firstname: newfirstname,
                    lastname: capitalizeFirstLetter(values.lastName),
                    birthday: null,
                    email: thisemail.toLowerCase(),
                    phone: values.phone2,
                    studioEmployeeId,
                    dibsId: dibsStudioId,
                    countryAbbr: 'US',
                };
                const newuser = await createNewDibsUser(valuestosend);

                const { userid, msg } = newuser;
                if (
                    msg ===
                    'A user w/ this email address already exists. Try resetting your password instead.'
                ) {
                    setAlert({
                        msg: `A user with this email address already exists. Please use a different email address or look up the client's account above.`,
                        warning: true,
                        seconds: 7,
                    });
                    return;
                } else if (
                    msg ===
                    'Success! Your account was created. Note: Your phone number is already associated with another account so it could not be added to this account. Contact us if you need help.'
                ) {
                    setAlert({
                        msg: `The account was created for ${newfirstname}. Please note: The phone number that you provided is already associated with another account (${newuser.firstname} ${newuser.lastname}), so it could not be added to this account. If you would like to add it to this account, please remove it from ${newuser.firstname}'s account first. If you need help, please contact support.`,
                        warning: true,
                        seconds: 20,
                    });
                    return;
                }

                if (dibsStudioId === '226') {
                    const newpwd = `Dibs!1234${Math.floor(Math.random() * 100000)}`;
                    const thisdata = {
                        dibsStudioId,
                        email: thisemail,
                        phone: values.phone2,
                        newpwd,
                        countryOrigin: 'US',
                    };
                    if (dibsStudioId === '260') {
                        thisdata.countryOrigin = 'Italy';
                    }
                    const newShopifyUser = await createNewShopifyUser(
                        thisdata,
                        userid
                    );
                    if (newShopifyUser) {
                        const { createdNewCustomer } = newShopifyUser;
                        if (createdNewCustomer) {
                            await recordShopifyStatus(newShopifyUser, userid);
                        }
                    } else {
                        setAlert({
                            msg: `There was an error creating a client account for ${newfirstname}. Please refresh your screen and then try again. If the problem persists, please contact support.`,
                            error: true,
                            seconds: 7,
                        });
                    }
                }
                setAlert({
                    msg: `Success! A new client account has been created for ${newfirstname}.`,
                    success: true,
                    seconds: 7,
                });
                resetForm();
            } catch (error) {
                setSubmitting(false);
                setErrors({ submit: error.message });
                setStatus({ success: false });
                setAlert({
                    msg: `There was an error creating a client account. Please refresh your screen and then try again. If the problem persists, please contact support.`,
                    error: true,
                    seconds: 7,
                });
            }
        },
    });
    const firstnameT = translate({
        id: 'firstname',
        msg: 'First Name',
    });
    const lastnamT = translate({
        id: 'lastname',
        msg: 'Last Name',
    });
    const phoneT = translate({
        id: 'phone',
        msg: 'Phone #',
    });
    const verify = translate({
        id: 'verifysubmit',
        msg: 'Verify & Submit',
    });

    return (
        <form
            style={myStyle}
            className="form-profile"
            onSubmit={formik.handleSubmit}
        >
            <Grid item xs={12} md={10} lg={7} border={0}>
                <Grid container spacing={3}>
                    <Grid item xs={11} md={5} id="first-name-textfield">
                        <TextField
                            fullWidth
                            id="firstName"
                            size="small"
                            name="firstName"
                            label={firstnameT}
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.firstName &&
                                Boolean(formik.errors.firstName)
                            }
                            helperText={
                                formik.touched.firstName &&
                                formik.errors.firstName
                            }
                        />
                    </Grid>
                    <Grid item xs={11} md={5} id="lastName-textfield">
                        <TextField
                            fullWidth
                            id="lastName"
                            size="small"
                            name="lastName"
                            label={lastnamT}
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={11}
                        md={7}
                        sx={{ marginTop: '24px' }}
                        id="email-textfield"
                    >
                        <TextField
                            fullWidth
                            id="email"
                            size="small"
                            name="email"
                            label="Email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={8}
                        md={4}
                        sx={{ marginTop: '24px' }}
                        id="grid-holding-numeric-format-phone"
                    >
                        <Grid
                            item
                            sx={{ marginTop: '3px' }}
                            id="numberic-format-phone"
                        >
                            <NumericFormat
                                id="phone2"
                                size="small"
                                label={phoneT}
                                format="+1 (###) ###-####"
                                mask="_"
                                fullWidth
                                value={formik.values.phone2}
                                customInput={TextField}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.phone2 &&
                                    Boolean(formik.errors.phone2)
                                }
                                helperText={
                                    formik.touched.phone2 &&
                                    formik.errors.phone2
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-start">
                            <AnimateButton>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disableElevation
                                >
                                    {verify}
                                </Button>
                            </AnimateButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default NewClientAccountForm;
