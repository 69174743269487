import moment from "moment";
import axios from "axios";
import { getApiUrl } from "helpers";
import { LOGIN, LOGOUT, REFRESH_USER } from "../constants/UserConstants";
import { setCurrentDate } from "./FeedActions";

/**
 * login logs user in
 * @param {Object} user data from the user logging in
 * @returns {Object} action on the state
 */
export function loginUser(user) {
  return { type: LOGIN, user };
}

/**
 * @returns {Object} action
 */
export function logoutUser() {
  return {
    type: LOGOUT,
  };
}

/**
 * @param {Object} user to login
 * @returns {function} thunk
 */
export function login(user) {
  return function innerLogin(dispatch) {
    dispatch(loginUser(user));
    dispatch(setCurrentDate(moment().tz(user.studio.mainTZ).startOf("day")));
  };
}

/**
 * logout logs user out
 * @returns {Object} action on the state
 */
export function logout() {
  return function logoutInner() {
    axios.post("/studios/api/logout").done(() => {
      window.location = "window.location.origin";
    });
  };
}

/**
 * refreshUser in the store after update
 * @param {Object} user new data
 * @returns {Object} action on the state
 */
export function refreshUser(user) {
  return { type: REFRESH_USER, user };
}
