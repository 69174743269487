/**
 * From most upvoted answer: https://stackoverflow.com/questions/31757852/how-can-i-detect-internet-explorer-ie-and-microsoft-edge-using-javascript
 * @returns {boolean} true if user is using Edge or IE
 */
export default function isIEorEdge() {
  if (/MSIE 10/i.test(navigator.userAgent)) {
    // This is internet explorer 10
    return true;
  }

  if (
    /MSIE 9/i.test(navigator.userAgent) ||
    /rv:11.0/i.test(navigator.userAgent)
  ) {
    // This is internet explorer 9 or 11
    return true;
  }

  if (/Edge\/\d./i.test(navigator.userAgent)) {
    // This is Microsoft Edge
    return true;
  }

  return false;
}
