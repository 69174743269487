export const FIREBASE_CONFIG = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBAS_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBAS_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBAS_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBAS_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard';
const config = {
    fontFamily: `'Roboto', 'Nunito Sans', sans-serif`,
    borderRadius: 4,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false,
};

export default config;
