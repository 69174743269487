import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/retail-list.scss';

import RetailListCard from './RetailListCard';
/**
 * RetailList Stateless Functional Component
 * @param {Object} props Component props
 * @returns {JSX.Element} HTML
 */
function RetailList({
    heading,
    retailItems,
    removeText,
    editClick,
    deleteClick,
    deactivateClick,
    activateClick,
}) {
    return (
        <div className="dibs-section">
            <div className="dibs-section-heading">{heading}</div>
            <div className="retail-section">
                {!retailItems?.length && (
                    <div className="no-retails">No retail items.</div>
                )}
                {retailItems.map((product) => (
                    <RetailListCard
                        key={product.id}
                        editClick={editClick}
                        deactivateClick={deactivateClick}
                        activateClick={activateClick}
                        deleteClick={deleteClick}
                        item={product}
                        removeText={removeText}
                    />
                ))}
            </div>
        </div>
    );
}

RetailList.propTypes = {
    retailItems: PropTypes.arrayOf(PropTypes.shape({})),
    editClick: PropTypes.func,
    activateClick: PropTypes.func,
    deactivateClick: PropTypes.func,
    deleteClick: PropTypes.func,
};

export default RetailList;
