import { Grid, Typography } from "@mui/material";

const ErrorText = ({ error }) => {
  return (
    <Grid>
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    </Grid>
  );
};
export default ErrorText;
