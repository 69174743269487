import axios from 'axios';

const moment = require('moment');
export default async function getAvailablePasses(dibsStudioId, userid) {
    const baseURL = process.env.REACT_APP_BASE_URL;

    const basicurl = `${baseURL}/get-client-available-passes`;
    let datatosend = {};
    const reqbody = {
        dibsStudioId,
        userid,
    };
    const getPackages = async () => {
        await axios
            .post(basicurl, reqbody)
            .then((res) => {
                const { data } = res;
                datatosend = data;
                datatosend.map((item) => {
                    if (item.expiresAt) {
                        item.expiresAt = moment(item.expiresAt).format(
                            'YYYY-MM-DD'
                        );
                    }
                    return item;
                });
                datatosend.sort((a, b) => {
                    if (a.studioPackage.unlimited && !b.studioPackage.unlimited)
                        return 1;
                    if (!a.expiresAt && b.expiresAt) return -1;
                    if (!a.expiresAt && !b.expiresAt) return 0;
                    if (!b.expiresAt) return 1;
                    return moment(a.expiresAt) - moment(b.expiresAt);
                });
            })
            .catch((err) => {
                console.log(`error from getting available passes: ${err}`);
                return err;
            });
    };
    await getPackages();
    return datatosend;
}
