import { Grid, Button, Typography, Fade } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const AddAnotherClientToEventButton = ({
    eventType,
    setShowBookedClients,
    setAddingMoreClientsToEvent,
    setHideSaveButtons,
}) => {
    return (
        <Fade in={true} timeout={1000}>
            <Grid item sx={{ p: 0, pt: 1, mb: 2 }}>
                <Button
                    id="add-new-client-button-in-search"
                    fullWidth
                    color="primary"
                    // className={classes.buttonBackground}
                    // startIcon={props.addButtonIcon}
                    onMouseDown={(event) => {
                        event.preventDefault();
                    }}
                    onClick={() => {
                        setShowBookedClients(false);
                        setAddingMoreClientsToEvent(true);
                        setHideSaveButtons(true);
                    }}
                    sx={{
                        paddingTop: '0px',
                        paddingBottom: '2px',
                        paddingLeft: '15px',
                        backgroundColor: '#fff',
                        color: '#e7b2a5',
                        justifyContent: 'flex-start',
                        '&:hover': {
                            backgroundColor: '#ffffff',
                        },
                        alignItems: 'center',
                    }}
                >
                    <AddIcon color="#e7b2a5" fontSize="small" />
                    <Typography
                        variant="caption"
                        sx={{
                            ml: 0.5,
                            fontSize: '12px',
                            fontWeight: '500',
                        }}
                    >
                        {`Add Another Client To This ${eventType}`}
                    </Typography>
                </Button>
            </Grid>
        </Fade>
    );
};
export default AddAnotherClientToEventButton;
