import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function doesUserHaveAccount(email, dibsId) {
    const adminURL = process.env.REACT_APP_STUDIOADMIN_URL;
    const basicurl = `${adminURL}/get-studio-admin-user-account`;

    let datatosend = {};
    const hasAccount = async () => {
        await axios
            .post(basicurl, {
                email,
                dibsId,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error from getting UserAccount Action: ${err}`);
                return err;
            });
    };
    await hasAccount();
    return datatosend;
}
