import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Grid } from '@mui/material';

import { useSelector } from 'store';

import setupIntent from 'actions/stripe/createStripeSetupIntent.js';

import SetupForm from './setupForm.js';
import './stripe.css';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripeKey = process.env.REACT_APP_STRIPE_KEY;
// console.log('stripe key is', stripeKey);
const stripePromise = loadStripe(stripeKey, { apiVersion: '2023-10-16' });

const StripeCaptureCard = () => {
    const { stripeid, userid, firstname, lastname, email } = useSelector(
        (state) => state.studioemployeeuser
    );
    const [requestedIntent, setRequestedIntent] = useState(false);
    const { itemsInCart } = useSelector((state) => state.cart);
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const [clientSecretValue, setClientSecret] = useState('');
    useEffect(() => {
        const getIntent = async () => {
            setRequestedIntent(true);
            const fullname = `${firstname} ${lastname}`;
            await setupIntent(
                stripeid,
                userid,
                email,
                fullname,
                dibsStudioId
            ).then((intent) => {
                setClientSecret(intent.clientSecret);
            });
        };
        if (!requestedIntent) {
            getIntent();
        }
    }, [
        dibsStudioId,
        email,
        firstname,
        itemsInCart,
        lastname,
        requestedIntent,
        stripeid,
        userid,
    ]);
    const appearance = {
        theme: 'minimal',
    };
    const options = {
        // passing the client secret obtained in step 3
        clientSecret: clientSecretValue,
        // Fully customizable with appearance API.
        appearance,
    };
    console.log(`clientSecretValue is: ${clientSecretValue}`);
    return (
        <Grid item xs={12}>
            {clientSecretValue?.length < 2 && (
                <Grid item xs={12}>
                    Loading...
                </Grid>
            )}
            {clientSecretValue?.length > 2 && (
                <Elements
                    key={clientSecretValue}
                    options={options}
                    stripe={stripePromise}
                >
                    <SetupForm />
                </Elements>
            )}
        </Grid>
    );
};
export default StripeCaptureCard;
