import axios from 'axios';
import { getApiUrl } from 'helpers';
// this sends cancellation emails to multiple users

export default async function sendCancelEmails(attendeeList, eventIdEditing) {
    const url = getApiUrl('/appts/send-cancel-notification');
    let emailsent = false;
    await axios
        .post(url, { attendeeList, eventIdEditing })
        .then((res) => {
            const { data } = res;
            emailsent = true;
        })
        .catch((error) => {
            console.log(
                'there was an error - sending cancellation email',
                error
            );
            emailsent = false;
        });
    return { emailsent: emailsent };
}
