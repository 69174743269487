import { ADD_ERROR, CLEAR_ERROR } from "../constants/ErrorConstants";

/**
 * addError
 * @param {string} error message
 * @returns {Object} action on the state
 */
export function addError(error) {
  console.log(`error passed into addError is: ${JSON.stringify(error)}`);
  return { type: ADD_ERROR, error };
}

/**
 * clearError
 * @returns {Object} action on the state
 */
export function clearError() {
  return { type: CLEAR_ERROR };
}
