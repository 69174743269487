// material-ui
import { styled } from '@mui/material/styles';

const ExperimentalStyled = styled('div')(({ theme }) => ({
    // hide license message
    '& .fc-license-message': {
        display: 'none',
    },
    // basic style
    '& .fc': {
        '--fc-bg-event-opacity': 0.5,
        '--fc-border-color': theme.palette.divider,
        '--fc-daygrid-event-dot-width': '10px',
        '--fc-today-bg-color':
            theme.palette.mode === 'dark'
                ? theme.palette.dark[800]
                : theme.palette.primary.light,
        '--fc-list-event-dot-width': '10px',
        '--fc-event-border-color': theme.palette.primary.dark,
        '--fc-now-indicator-color': theme.palette.error.main,
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
    },
    '& .fc-event-is-regular-event': {
        // minWidth: '50px',
        zIndex: 50,
        width: '100% !important',
    },
    '& .fc-event-is-background': {
        // minWidth: '50px',
        width: '100%',
        zIndex: 1,
        pointerEvents: 'none',
    },
    '& .fc-event-is-one-time-exception': {
        // minWidth: '50px',
        width: '100%',
        zIndex: 20,
        cursor: 'default !important',
        // pointerEvents: 'none',
        // cursor: 'none',
        // backgroundImage:
        //     'radial-gradient(circle, #C0B2AF 20%, transparent 20%), radial-gradient(circle, transparent 20%, #f0f0f0 20%)',
        // backgroundSize: '10px 10px',

        // /* Position the dots in a grid */
        // backgroundPosition: '0 0, 5px 5px',
        backgroundColor: '#f0f0f0',
        backgroundImage: `radial-gradient(circle, #BBB4B4 10%, transparent 10%)`,
        backgroundSize: '3px 3px',

        /* Ensure the background covers the event area and doesn't repeat */
        backgroundRepeat: 'repeat',
        // backgroundColor: '#f0f0f0',
        // backgroundImage: `linear-gradient(to right, ${theme.palette.divider} 1px, transparent 1px),
        //      linear-gradient(to bottom, ${theme.palette.divider} 1px, transparent 1px)`,
        // backgroundSize: '10px 10px',
    },

    // time
    '& .fc .fc-event-time': {
        fontWeight: 'bold',
    },

    '& .fc-theme-standard .fc-timegrid-slot': {
        borderColor: '#efefef',
    },
    '& .fc-theme-standard .fc-timegrid-col': {
        borderColor: '#efefef',
    },
    '& .fc-theme-standard .fc-day-past': {
        borderColor: '#efefef',
    },
    '& .fc-theme-standard .fc-day-future': {
        borderColor: '#efefef',
    },
    '& .fc-theme-standard .fc-day-today': {
        borderColor: '#efefef',
    },
    '& .fc .fc-timegrid-axis-frame': {
        borderColor: '#efefef',
    },

    // date text
    '& .fc .fc-daygrid-day-top': {
        display: 'grid',
        '& .fc-daygrid-day-number': {
            textAlign: 'center',
            marginTop: 12,
            marginBottom: 12,
        },
    },
    // '& .fc-daygrid-event': {
    //     minHeight: '60px'
    // },
    '& .fc-h-event .fc-event-main-frame': {
        display: 'block',
    },
    '& .fc-daygrid-event .fc-event-time': {
        paddingRight: 4,
    },

    // weekday
    '& .fc .fc-col-header-cell': {
        backgroundColor:
            theme.palette.mode === 'dark'
                ? theme.palette.dark.main
                : theme.palette.grey[50],
    },

    '& .fc .fc-col-header-cell-cushion': {
        // color: theme.palette.grey[900],
        // padding: 16,
    },
    '& .fc .fc-resource-label': {
        padding: 3,
        fontSize: '11px',
        fontWeight: 300,
        color: theme.palette.grey[500],
        backgroundColor: '#fcfbfb',
    },
    '& .fc-col-header-label-info': {
        padding: 12,
    },

    // events
    '& .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start':
        {
            marginLeft: 4,
            marginBottom: 6,
            borderRadius: '6px',
        },

    '& .fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end':
        {
            marginLeft: 4,
            marginBottom: 6,
            borderRadius: '6px',
        },
    '& .fc-event': {
        fontSize: '0.8em',
    },
    '& .fc-h-event .fc-event-main': {
        padding: 2,
        paddingLeft: 8,
    },

    // popover when multiple events
    '& .fc .fc-more-popover': {
        border: 'none',
        borderRadius: '14px',
    },

    '& .fc .fc-more-popover .fc-popover-body': {
        backgroundColor:
            theme.palette.mode === 'dark'
                ? theme.palette.dark[800]
                : theme.palette.grey[200],
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
    },

    '& .fc .fc-popover-header': {
        padding: 12,
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        backgroundColor:
            theme.palette.mode === 'dark'
                ? theme.palette.dark[800]
                : theme.palette.grey[200],
        color:
            theme.palette.mode === 'dark'
                ? theme.palette.dark.light
                : theme.palette.text.primary,
    },

    // agenda view
    '& .fc-theme-standard .fc-list-day-cushion': {
        backgroundColor:
            theme.palette.mode === 'dark'
                ? theme.palette.dark.main
                : theme.palette.grey[100],
    },

    '& .fc .fc-list-event:hover td': {
        backgroundColor:
            theme.palette.mode === 'dark'
                ? theme.palette.dark[800]
                : theme.palette.grey[100],
    },

    '& .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link':
        {
            padding: 0,
            margin: 0,
        },
}));

export default ExperimentalStyled;
