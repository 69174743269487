import axios from 'axios';

import { getApiUrl } from 'helpers';

// ==============================|| CREATE NEW STRIPE PRICE ||============================== //

export const createNewStripePrice = async (
    packToCreate,
    dibsStudioId,
    productId,
    packId
) => {
    try {
        const url = getApiUrl('/studio-admin/stripe/new-price');
        const response = await axios.post(url, {
            dibsStudioId,
            newPack: packToCreate,
            productId,
            packId,
        });
        const { priceCreated } = response.data;

        return {
            success: response.data.success,
            msg: response.data.msg,
            priceCreated,
        };
    } catch (err) {
        console.log(
            `error createNewStripePrice for dibsStudioId: ${dibsStudioId}\nerr is: ${err}\n\nCNPP - Package was ${packToCreate}`
        );
    }
    return 0;
};

export default createNewStripePrice;
