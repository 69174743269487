// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
const initialState = {
    apptTypes: [],
    isLoading: false,
    hasError: false,
    errorMsg: '',
};

const appointments = createSlice({
    name: 'appointments',
    initialState,
    reducers: {
        // HAS ERROR
        setHasError(state, action) {
            // state.hasError = false;
            // state.hasError = action.payload;
            return {
                ...state,
                hasError: action.payload,
            };
        },
        setErrorMsg(state, action) {
            return {
                ...state,
                errorMsg: action.payload,
            };
        },
        // SET SCHEDULE
        setAppts(state, action) {
            // state.schedule = action.payload;
            return {
                ...state,
                apptTypes: action.payload,
            };
        },
        // SET LOADING
        setLoading(state, action) {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        resetAppts(state) {
            return {
                ...state,
                apptTypes: [],
                isLoading: false,
            };
        },
    },
});

// Reducer
export default appointments.reducer;
export const { setHasError, setAppts, setErrorMsg, setLoading, resetAppts } =
    appointments.actions;

// ----------------------------------------------------------------------
