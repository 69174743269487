const moment = require('moment');

export default function associatePassToEvent(event, passes) {
    let howyapaying = {
        passid: null,
        passname: null,
    };
    const passpostfilter = passes
        .filter((p) => p.private_pass === event.private)
        .sort((a, b) => {
            if (!a.autopay && !b.autopay) return 0;
            if (a.autopay && !b.autopay) return -1;
            if (!a.autopay && b.autopay) return 1;
            return moment(a.expiresAt) - moment(b.expiresAt);
        })
        .sort((a, b) => {
            if (!a.expiresAt && !b.expiresAt) return 0;
            if (a.expiresAt && !b.expiresAt) return -1;
            if (!a.expiresAt && b.expiresAt) return 1;
            return moment(a.expiresAt) - moment(b.expiresAt);
        });

    if (event.price === 0) {
        console.log('No cost');
        return null;
    }
    if (passpostfilter?.length > 0) {
        howyapaying = {
            passid: passpostfilter[0].id,
            passname: passpostfilter[0].studioPackage.packageName,
            quantity: 1,
        };
        return howyapaying;
    } else {
        // console.log('no passes');

        return { passid: 0, passname: '' };
    }
}
