import propTypes from "prop-types";
import { Grid, TextField, MenuItem, Menu } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottomColor: "#e7b2a5",
  },
  "& .MuiInput-underline:before:hover": {
    borderBottomColor: "#e7b2a5",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#e7b2a5",
  },
});

export default function DropDownTextField(props) {
  const { valueString, widthNumber, onChange, options, mt, labeltouse } = props;
  return (
    <Grid item xs={12} sx={{ mt: mt || 0 }}>
      <Grid container sx={{ overflowY: "scroll", maxHeight: "250px" }}>
        <StyledTextField
          id="drop-down-select"
          select
          value={valueString}
          onChange={(e) => onChange(e.target.value)}
          variant="standard"
          label={labeltouse}
          size="small"
          sx={{ width: widthNumber, transition: "all .2s ease-in-out" }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledTextField>
      </Grid>
    </Grid>
  );
}
DropDownTextField.propTypes = {
  valueString: propTypes.string,
  widthNumber: propTypes.number,
  onChange: propTypes.func,
  options: propTypes.array,
};
