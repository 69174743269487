import {
  SET_PACKAGES_DATA,
  SET_PACKAGES_LOADING_TRUE,
  SET_PACKAGES_LOADING_FALSE,
  SET_PACKAGES_QUERY,
  FORGET_PACKAGES_QUERY,
  SET_OPEN_PACKAGE,
  FORGET_OPEN_PACKAGE,
} from "../constants/PackagesConstants";
import { addError } from "./ErrorActions";
import log from "../helpers/log";

/**
 * setStudioPackagesData
 * @param {Array<Object>} StudioPackages array of studio package objects
 * @returns {Object} action on the state
 */
export function setStudioPackagesData(StudioPackages) {
  return { type: SET_PACKAGES_DATA, StudioPackages };
}

/**
 * setStudioPackagesLoadingTrue
 * @returns {Object} action on the state
 */
export function setStudioPackagesLoadingTrue() {
  return { type: SET_PACKAGES_LOADING_TRUE };
}

/**
 * setStudioPackagesLoadingFalse
 * @returns {Object} action on the state
 */
export function setStudioPackagesLoadingFalse() {
  return { type: SET_PACKAGES_LOADING_FALSE };
}

/**
 * setStudioPackagesQuery
 * @param {string} query the search query
 * @returns {Object} action on the state
 */
export function setStudioPackagesQuery(query) {
  return { type: SET_PACKAGES_QUERY, query: query.toUpperCase() };
}

/**
 * forgetStudioPackagesQuery
 * @returns {Object} action on the state
 */
export function forgetStudioPackagesQuery() {
  return { type: FORGET_PACKAGES_QUERY };
}

/**
 * setOpenStudioPackage
 * @param {Object} studioPackage to open
 * @returns {Object} action on the state
 */
export function setOpenStudioPackage(studioPackage) {
  return { type: SET_OPEN_PACKAGE, studioPackage };
}

/**
 * forgetOpenStudioPackage
 * @returns {Object} action on the state
 */
export function forgetOpenStudioPackage() {
  return { type: FORGET_OPEN_PACKAGE };
}

/**
 * @returns {function} redux thhunk
 */
export function requestStudioPackagesData() {
  return function innerRequestStudioPackagesData(dispatch, getState) {
    if (getState().StudioPackages.loading) return;
    dispatch(setStudioPackagesLoadingTrue());
    // $.ajax({
    //   url: '/studios/api/packages',
    //   method: 'GET',
    //   success({ success, StudioPackages, err, message }) {
    //     if (success) {
    //       dispatch(setStudioPackagesData(StudioPackages));
    //       dispatch(setStudioPackagesLoadingFalse());
    //     } else {
    //       if (err) log(err);
    //       dispatch(addError(message));
    //     }
    //   },
    //   error(err) {
    //     console.log(err);
    //     dispatch(addError('Failed to get your studio\'s packages'));
    //   },
    //   complete() {
    //     dispatch(setStudioPackagesLoadingFalse());
    //   },
    // });
  };
}
