import axios from 'axios';
import { getApiUrl } from 'helpers';

// ==============================|| GET STAFF AVAILABILITY ||============================== //

export const getStaffAvailability = async (
    employeeId,
    dibsId,
    openTime,
    closeTime
) => {
    try {
        const response = await axios.post(
            getApiUrl('/studio/get-staff-availability'),
            {
                employeeId,
                dibsId,
                openTime,
                closeTime,
            }
        );

        return response.data;
    } catch (err) {
        console.log(`error getting get STAFF Availability. Error is: ${err}`);
    }
    return 0;
};

export default getStaffAvailability;
