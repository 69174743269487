import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import { Grid, Stack, Fade } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import InfoIcon from 'shared/components/HelpInfo/infoIcon';
import InfoIconSmall from 'shared/components/HelpInfo/infoIconSmall';
import Dropdown from 'ui-component/dropdown/standardDropdown.js';
import RadioGroup from 'ui-component/pickers/StandardRadio.js';
import StandardInputTextField from 'ui-component/textfields/StandardInputTextFieldWidth';
import MultiLineTextField from 'ui-component/textfields/MultiLineTextFieldWidth';
import MultiCheckboxes from 'ui-component/checkboxes/multiCheckboxes';
import TimeDatePicker from 'ui-component/pickers/TimeDatePickerSm';
dayjs.extend(utc);
dayjs.extend(timezone);

const KeyAccordianInfo = (props) => {
    const {
        actionFunction,
        ques,
        extraAction,
        dropdownWidth,
        questionCriteria,
        keytouse,
        beingCreatedInfo,
        setTrueFalseFields,
        multiCriteria,
    } = props;

    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [criteriaToTest, setCriteriaToTest] = useState('none'); // whether to show secondary question
    const [criteriaChosen, setCriteriaChosen] = useState('');
    const [multiCriteriaChosen, setMultiCriteriaChosen] =
        useState(multiCriteria);
    const [criteriaToExclude, setCriteriaToExclude] = useState('none');

    const [secondaryInputType, setSecondaryInputType] = useState('');
    const [showDollarSignForType, setShowDollarSignForType] = useState(false);
    const [showPercentageSignForType, setShowPercentageSignForType] =
        useState(false);
    const [secondaryValue, setSecondaryValue] = useState(null);
    const [secondaryFieldWidth, setSecondaryFieldWidth] = useState(110);
    const [showSummaryStatement, setShowSummaryStatement] = useState(false);
    const [secondaryFunctionType, setSecondaryFunctionType] = useState('');
    const [summaryStatement, setSummaryStatement] = useState('');
    const [matchesSecondaryCriteria, setMatchesCriteria] = useState(false);
    const [choiceSelectedToSend, setChoiceSelectedToSend] = useState(null);
    const [secondaryFieldName, setSecondaryFieldName] = useState(null);
    const [showNumberOfVisits, setShowNumberOfVisits] = useState(false);
    const [secondaryChoiceSelectedToSend, setSecondaryChoiceSelectedToSend] =
        useState(null);
    const { type, options, secondaryInfo } = ques;
    let questionAction = null;
    let secondaryQuestionAction = null;
    const optionstosend = options?.length > 0 ? options : [];
    const updateMultiCriteriaChosen = (newArray) => {
        setMultiCriteriaChosen(newArray);
    };
    useEffect(() => {
        const { showSummary, summaryStatement, variables } = ques;

        if (showSummary) {
            const lowercaseCriteria = questionCriteria.toLowerCase();

            const { summarySentence } = summaryStatement?.find((ss) => {
                return ss.criteria === lowercaseCriteria;
            });

            let summarysentencetosend = summarySentence;
            if (variables?.length > 0) {
                let shouldAddS = false;
                variables.forEach((variable) => {
                    const {
                        variablename,
                        startingvalue,
                        drivesPlurality,
                        getsPluralized,
                    } = variable;

                    let valueToUse =
                        beingCreatedInfo[variablename] || startingvalue;
                    if (drivesPlurality) {
                        const variableAsNumber = parseInt(valueToUse);
                        if (variableAsNumber > 1) shouldAddS = true;
                    }
                    if (getsPluralized) {
                        if (shouldAddS) valueToUse = `${valueToUse}s`;
                    }
                    summarysentencetosend = summarysentencetosend.replace(
                        `[${variablename}]`,
                        valueToUse
                    );
                });

                setSummaryStatement(summarysentencetosend);
                setShowSummaryStatement(showSummary);
            }
        } else {
            setShowSummaryStatement(false);
        }
    }, [beingCreatedInfo, ques, questionCriteria]);

    useEffect(() => {
        // autopayIncrement

        const { fieldnameForChoice, options, type } = ques;
        if (fieldnameForChoice) {
            const choiceSelected = beingCreatedInfo[fieldnameForChoice];

            if (type === 'dropdown') {
                const optionSelected = options.find((option) => {
                    return option.codeValue === choiceSelected;
                });
                setChoiceSelectedToSend(optionSelected);
            } else if (type === 'radio') {
                const radiooptionSelected = options.find((option) => {
                    return option.codeValue === choiceSelected;
                });
                setChoiceSelectedToSend(radiooptionSelected);
            } else {
                setChoiceSelectedToSend(choiceSelected);
            }
        }
        if (secondaryFieldName) {
            const secondaryChoiceSelected =
                beingCreatedInfo[secondaryFieldName];
            if (secondaryChoiceSelected) {
                setSecondaryChoiceSelectedToSend(secondaryChoiceSelected);
            }
        }
    }, [beingCreatedInfo, ques, secondaryFieldName]);

    // the next few lines display questions from constants/memberships-packages/create-new-pack-questions.js

    switch (type) {
        case 'dropdown':
            questionAction = (
                <Dropdown
                    key={`dropdown-${keytouse}`}
                    options={optionstosend}
                    dropdownWidth={dropdownWidth}
                    extraAction={extraAction}
                    actionFunction={actionFunction}
                    functionType={ques?.functiontype}
                    choiceSelectedToSend={choiceSelectedToSend}
                    setCriteriaChosen={setCriteriaChosen}
                />
            );
            break;
        case 'radio':
            questionAction = (
                <RadioGroup
                    key={`radio-${keytouse}`}
                    options={optionstosend}
                    setCriteriaChosen={setCriteriaChosen}
                    extraAction={extraAction}
                    functionType={ques?.functiontype}
                />
            );
            break;
        case 'numberfield':
            questionAction = (
                <StandardInputTextField
                    idtouse={`input-${keytouse}`}
                    helperText={`Must be a number.`}
                    value={choiceSelectedToSend || ''}
                    onChangeAction={(e) => {
                        const numValue = Number(e);

                        if (Number.isFinite(numValue)) {
                            if (Number.isInteger(numValue)) {
                                // It's an integer
                                setSecondaryChoiceSelectedToSend(numValue);
                                extraAction(numValue, secondaryFunctionType);
                            } else {
                                // It's a float
                                setSecondaryChoiceSelectedToSend(numValue);
                                extraAction(numValue, secondaryFunctionType);
                            }
                        } else {
                            setSecondaryChoiceSelectedToSend('');
                        }
                    }}
                    hasError={false}
                    boxWidth={secondaryFieldWidth}
                    showDollarSign={ques?.isMoney}
                />
            );
            break;
        case 'datechoice':
            questionAction = (
                <TimeDatePicker
                    idtouse={`input-${keytouse}`}
                    helperText={`Must be a number.`}
                    value={choiceSelectedToSend || ''}
                    onChange={(e) => {
                        setChoiceSelectedToSend(e);
                        const datetopassbackup = dayjs(e).format('YYYY-MM-DD');
                        extraAction(datetopassbackup, ques?.functiontype);
                    }}
                    hasError={false}
                    boxWidth={secondaryFieldWidth}
                    showDollarSign={ques?.isMoney}
                />
            );
            break;
        case 'multilinetext':
            questionAction = (
                <MultiLineTextField
                    idtouse={`multi-checkboxes-${keytouse}`}
                    valuePassedIn={choiceSelectedToSend || ''}
                    onChangeAction={(e) => {
                        setChoiceSelectedToSend(e);
                        extraAction(e, ques?.functiontype);
                    }}
                    placeholder={ques?.placeholder || ''}
                    boxWidth={ques?.fieldWidth || 350}
                />
            );
            break;
        case 'checkboxes':
            const { checkboxValues } = ques;
            questionAction = (
                <MultiCheckboxes
                    idtouse={`multicheck-${keytouse}`}
                    checkboxes={checkboxValues}
                    questionCriteria={questionCriteria}
                    beingCreatedInfo={beingCreatedInfo}
                    handleCheckboxChange={(e) => {
                        console.log('\n\n\n\ncheckbox changing is', e);
                    }}
                    hasError={false}
                    setTrueFalseFields={setTrueFalseFields}
                    boxWidth={ques?.fieldWidth || 250}
                    setMultiCriteriaChosen={updateMultiCriteriaChosen}
                    multiCriteriaChosen={multiCriteriaChosen}
                />
            );
            break;
        default:
            console.error('Invalid type of field being sent');
    }

    // FOR SECONDARY VALUES ONLY ////
    if (secondaryInputType === 'numberfield') {
        secondaryQuestionAction = (
            <StandardInputTextField
                idtouse={`input-${keytouse}`}
                helperText={`Must be a number.`}
                value={secondaryChoiceSelectedToSend || ''}
                showDollarSign={showDollarSignForType}
                showPercentageSign={showPercentageSignForType}
                onChangeAction={(e) => {
                    const numValue = Number(e);

                    if (Number.isFinite(numValue)) {
                        if (Number.isInteger(numValue)) {
                            // It's an integer
                            setSecondaryChoiceSelectedToSend(numValue);
                            extraAction(numValue, secondaryFunctionType);
                        } else {
                            // It's a float
                            setSecondaryChoiceSelectedToSend(numValue);
                            extraAction(numValue, secondaryFunctionType);
                        }
                    } else {
                        setSecondaryChoiceSelectedToSend('');
                    }
                }}
                hasError={false}
                boxWidth={secondaryFieldWidth}
            />
        );
    } else if (secondaryInputType === 'checkboxes') {
        secondaryQuestionAction = (
            <MultiCheckboxes
                idtouse={`checkbox-secondary-${keytouse}`}
                checkboxes={secondaryInfo?.secondaryCheckboxValues}
                questionCriteria={questionCriteria}
                beingCreatedInfo={beingCreatedInfo}
                handleCheckboxChange={(e) => {
                    console.log('\n\n\n\ncheckbox changing is', e);
                }}
                hasError={false}
                setTrueFalseFields={setTrueFalseFields}
                boxWidth={ques?.fieldWidth || 250}
                setMultiCriteriaChosen={updateMultiCriteriaChosen}
                multiCriteriaChosen={multiCriteriaChosen}
            />
        );
    }
    useEffect(() => {
        const { promocodeType } = beingCreatedInfo;
        if (promocodeType === 'cashoff') {
            setShowDollarSignForType(true);
            setShowPercentageSignForType(false);
        } else {
            setShowDollarSignForType(false);
        }
        if (promocodeType === 'percentoff' || promocodeType === 'PERCENT_OFF') {
            setShowPercentageSignForType(true);
            setShowDollarSignForType(false);
        } else {
            setShowPercentageSignForType(false);
        }
    }, [beingCreatedInfo]);

    useEffect(() => {
        if (secondaryInfo) {
            setCriteriaToTest(secondaryInfo?.appliesTo);
            setSecondaryInputType(secondaryInfo?.secondaryType);
            setCriteriaToExclude(secondaryInfo?.excludes || 'none');

            const { secondaryInitialValue, fieldWidth } = secondaryInfo;
            if (secondaryInitialValue) setSecondaryValue(secondaryInitialValue);
            else setSecondaryValue(null);
            if (secondaryFieldWidth) setSecondaryFieldWidth(fieldWidth);
            if (secondaryInfo?.functiontype) {
                setSecondaryFunctionType(secondaryInfo?.functiontype);
            }
            if (secondaryInfo?.secondaryFieldnameForChoice) {
                setSecondaryFieldName(
                    secondaryInfo?.secondaryFieldnameForChoice
                );
            }
        } else {
            setCriteriaToTest('none');
            setSecondaryInputType(''); // this is a default value
            setSecondaryValue(null);
            setSecondaryFieldWidth(110);
            setSecondaryFunctionType('');
            setSecondaryFieldName(null);
        }
    }, [secondaryFieldWidth, secondaryInfo]);

    useEffect(() => {
        if (criteriaToExclude.length > 0) {
            if (criteriaToExclude.includes(criteriaChosen)) {
                setMatchesCriteria(false);
            } else {
                setMatchesCriteria(true);
            }
        } else if (criteriaToTest === 'all') {
            setMatchesCriteria(true);
        } else if (criteriaToTest === criteriaChosen) {
            setMatchesCriteria(true);
        } else if (criteriaToTest === 'none') {
            setMatchesCriteria(false);
        } else {
            if (multiCriteriaChosen?.includes(criteriaToTest)) {
                setMatchesCriteria(true);
            } else {
                setMatchesCriteria(false);
            }
        }
    }, [
        criteriaChosen,
        criteriaToExclude,
        criteriaToTest,
        multiCriteriaChosen,
        ques,
        questionCriteria,
    ]);

    let questionToShow = ques?.defaultQuestion || 'tbd';
    let helptoshow = ques?.help || 'tbd';
    let secondaryQuestionToShow = secondaryInfo?.secondaryQuestion || 'tbd';
    if (questionCriteria) {
        const lowercaseCriteria = questionCriteria.toLowerCase();

        let lowercasecriteriaToUse = lowercaseCriteria;
        if (lowercasecriteriaToUse === 'singlesession') {
            lowercasecriteriaToUse = 'single session';
        }
        questionToShow = questionToShow.replace(
            '[REPLACEVALUE]',
            lowercasecriteriaToUse
        );
        helptoshow = helptoshow.replace(
            '[REPLACEVALUE]',
            lowercasecriteriaToUse
        );
        secondaryQuestionToShow = secondaryQuestionToShow.replace(
            '[REPLACEVALUE]',
            lowercasecriteriaToUse
        );
    }

    return (
        <Grid container>
            <Grid item xs={12} sx={{ mt: 2, ml: 2 }}>
                <Stack direction="row" spacing={1}>
                    <Typography variant="h6">{questionToShow}</Typography>
                    <InfoIcon msg={helptoshow || `placeholder tbd`} />
                </Stack>
                {hasError && (
                    <Typography variant="h6" color="error">
                        {errorMessage}
                    </Typography>
                )}
                <Grid item xs={12} sx={{ mt: 2 }} key={``} id="question-action">
                    {questionAction}
                </Grid>
                {matchesSecondaryCriteria && (
                    <Fade in timeout={500}>
                        <Grid
                            item
                            xs={12}
                            sx={{ mt: 4, mb: 1, ml: 0.5 }}
                            id={`secondary-question-ques-${keytouse}`}
                        >
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems={`flex-start`}
                            >
                                <Typography variant="h6butlighter">
                                    {secondaryQuestionToShow}
                                </Typography>
                                {secondaryInfo?.help &&
                                    secondaryInfo?.showHelp && (
                                        <InfoIconSmall
                                            msg={
                                                secondaryInfo?.help ||
                                                `placeholder tbd`
                                            }
                                            marginTopToUse={0}
                                        />
                                    )}
                            </Stack>

                            <Grid
                                item
                                xs={12}
                                id={`secondary-question-action-${keytouse}`}
                            >
                                {secondaryQuestionAction}
                            </Grid>
                            {showSummaryStatement && (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ mt: 3 }}
                                    id="secondary-summary-statement"
                                >
                                    <Typography variant="h6buthighlighted">
                                        {summaryStatement}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Fade>
                )}
            </Grid>
        </Grid>
    );
};

export default KeyAccordianInfo;
KeyAccordianInfo.propTypes = {
    actionFunction: propTypes.func,
};
