import React from "react";
import withRouter from "helpers/withRouter";
import PropTypes from "prop-types";

import RosePlusIcon from "../../../../shared/graphics/Icons/RosePlusIcon";
import {
  CLASS_SCHEDULE_ROUTE,
  ADD_EVENT_ROUTE,
} from "../../../constants/RouteConstants";

import "./styles.scss";

/**
 * @class RightButton
 * @extends {React.PureComponent}
 */
class RightButton extends React.PureComponent {
  /**
   * onAddClassClick
   * @returns {undefined}
   */
  onAddClassClick = () => {
    this.props.router.push(ADD_EVENT_ROUTE);
  };

  /**
   * render
   * @returns {JSX.Element} HTML
   */
  render() {
    const pathname = this.props.location;

    switch (true) {
      case pathname === CLASS_SCHEDULE_ROUTE:
        return (
          <button
            className="layout-right-button__container"
            onClick={this.onAddClassClick}
          >
            <RosePlusIcon />
          </button>
        );
      default:
        return null;
    }
  }
}

RightButton.propTypes = {
  location: PropTypes.shape(),
  router: PropTypes.shape(),
};

export default withRouter(RightButton);
