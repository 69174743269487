import axios from 'axios';
import { getApiUrl } from 'helpers';
// import moment from 'moment-timezone';

// ==============================|| DEACTIVATE MEMBERSHIP OR PACKAGE FOR STUDIO ||============================== //

export const deactivateAppt = async (apptId, employeeId) => {
    try {
        const response = await axios.post(getApiUrl('/appt/deactivate-appt'), {
            apptId,
            employeeId,
        });

        if (response.data.msg === 'success') {
            return {
                msg: 'success',
            };
        }
        console.log(`Deactivate APPT Error: ${JSON.stringify(response.data)}`);
        return { msg: 'failure', error: response.data.error };
    } catch (err) {
        console.log(
            `error deactivating promo code for promoid: ${apptId}\nerr is: ${err}`
        );
    }
    return 0;
};

export default deactivateAppt;
