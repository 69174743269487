export const newPromoCodeQuestions = [
    {
        id: 1,
        isFinalQuestion: false,
        keytouse: 'promo-code-type',
        title: 'Type of Discount To Offer',
        type: 'dropdown',
        functiontype: 'choosepromotype',
        defaultQuestion:
            'What type of discount will this promo code provide (e.g. for 10% off, choose Percentage Off)?',
        help: 'Memberships usually renew at the end of a specified period (e.g. monthly), while packages typically include a certain number of visits that can be used by a certain date. Packages do not renew.',
        options: [
            {
                id: 1,
                name: 'Percentage Off',
                codeValue: 'percentoff',
            },
            {
                id: 2,
                name: 'Cash Amount Off',
                codeValue: 'cashoff',
            },
            {
                id: 3,
                name: 'Free Session',
                codeValue: 'freesession',
            },
        ],
        nextQuestion: 2,
        secondaryInfo: {
            secondaryQuestion: 'How much of a discount should be applied?',
            appliesTo: 'all',
            secondaryType: 'numberfield',
            secondaryTitle: 'Amount of Discount',
            secondaryCodedValue: 'discountamount',
            secondaryInitialValue: 10,
            fieldWidth: 90,
            functiontype: 'chooseamountoff',
            secondaryFieldnameForChoice: 'amountOff',
            excludes: ['freesession'],
        },
    },
    {
        id: 2,
        isFinalQuestion: false,
        keytouse: 'expiration',
        title: 'Expiration Date',
        type: 'datechoice',
        functiontype: 'chooseexpiration',
        defaultQuestion: 'When should the promo code expire?',
        help: '',
        nextQuestion: 3,
        fieldnameForChoice: 'expiration',
    },
    {
        id: 3,
        isFinalQuestion: false,
        keytouse: 'usesperclient',
        title: 'Number of Uses Per Client',
        type: 'numberfield',
        functiontype: 'chooseusageperuserlimit',
        defaultQuestion: 'How many times can a client use this promo code?',
        help: '',
        fieldnameForChoice: 'userUsageLimit',
        nextQuestion: 4,
    },
    {
        id: 4,
        isFinalQuestion: false,
        keytouse: 'usesperclient',
        title: 'Number of Uses Overall',
        type: 'numberfield',
        functiontype: 'choosecodeusagelimit',
        defaultQuestion: 'How many times can this promo code be used in total?',
        help: 'Set a value to limit the number of times this promo code can be used. E.g. 100 times across all clients. This promo code will no longer be valid after this limit is reached. Leave blank for no limit.',
        nextQuestion: 5,
        fieldnameForChoice: 'codeUsageLimit',
    },

    {
        id: 5,
        title: 'Application',
        keytouse: 'applicationtouse',
        functiontype: 'chooseapplication',
        defaultQuestion: 'Choose all valid applications for this promo code.',
        type: 'checkboxes',
        nextQuestion: 6,
        isFinalQuestion: true,
        help: `Use this section to indicate any restrictions that apply to this promo code.`,
        checkboxValues: [
            {
                id: 1,
                name: 'Memberships',
                codeValue: 'applies-to-memberships',
                needsOwnLine: false,
                fieldnameForChoice: 'appliesToMemberships',
                infoguidance: `If checked, this promo code can be used on memberships.`,
            },
            {
                id: 2,
                name: 'Packages',
                codeValue: 'applies-to-packages',
                needsOwnLine: false,
                fieldnameForChoice: 'appliesToPackages',
                infoguidance: `If checked, this promo code can be used on packages.`,
            },

            {
                id: 3,
                name: 'Single Sessions',
                codeValue: 'applies-to-singlesessions',
                needsOwnLine: false,
                fieldnameForChoice: 'appliesToSingleSessions',
                infoguidance: `If checked, this promo code can be used on single sessions.`,
            },
            {
                id: 4,
                name: 'Retail',
                codeValue: 'applies-to-retail',
                needsOwnLine: false,
                fieldnameForChoice: 'appliesToRetail',
                infoguidance: `If checked, this promo code can be used on retail items.`,
            },
        ],
        secondaryInfo: {
            secondaryQuestion:
                'Should this promo code also be applied to the renewal of memberships?',
            appliesTo: 'appliesToMemberships',
            secondaryType: 'checkboxes',
            secondaryTitle: 'Amount of Discount',
            secondaryCodedValue: 'appliesToRenewal',
            secondaryInitialValue: true,
            fieldWidth: 90,
            functiontype: 'chooseappliestorenewal',
            secondaryFieldnameForChoice: 'appliesToRenewal',
            showHelp: true,
            help: `If checked, this promo code will also be applied to the renewal of memberships. For example, if a client has a membership that is set to renew on a certain date, this promo code will be applied to the renewal charge (e.g. 10% off until cancelled). If unchecked, this promo code will only be applied to the initial purchase of a membership.`,
            secondaryCheckboxValues: [
                {
                    id: 1,
                    name: 'Applies to Renewal',
                    codeValue: 'appliestorenewal',
                    needsOwnLine: false,
                    fieldnameForChoice: 'appliesToRenewal',
                    // pivotal tracker - applies to renewal - edit here - turn to switch
                },
            ],
        },
    },
    // {
    //     id: 3,
    //     isFinalQuestion: false,
    //     title: 'Limit Number of Uses',
    //     keytouse: 'limit-code-usage',
    //     functiontype: 'choosecodeusagelimit',
    //     help: 'Choose the number of times this promo code can be used overall (by all clients). For example, if the promo code can be used 10 times, enter 10.',
    //     defaultQuestion:
    //         'How should the duration period be measured (e.g. months, days)?',
    //     type: 'dropdown',
    //     fieldnameForChoice: 'autopayIncrement',
    //     options: [
    //         {
    //             id: 1,
    //             name: 'Months',
    //             codeValue: 'month',
    //         },
    //         {
    //             id: 2,
    //             name: 'Days',
    //             codeValue: 'day',
    //         },
    //         {
    //             id: 3,
    //             name: 'Weeks',
    //             codeValue: 'week',
    //         },
    //         {
    //             id: 4,
    //             name: 'Years',
    //             codeValue: 'year',
    //         },
    //     ],
    //     nextQuestion: 4,
    //     secondaryInfo: {
    //         secondaryQuestion:
    //             'How long should each period of the promo code last?',
    //         appliesTo: 'all',
    //         secondaryType: 'numberfield',
    //         secondaryTitle: 'no title',
    //         secondaryCodedValue: 'novalue',
    //         secondaryInitialValue: 1,
    //         fieldWidth: 90,
    //         functiontype: 'choosenumperiods',
    //         secondaryFieldnameForChoice: 'passesValidFor',
    //     },
    //     showSummary: true,
    //     summaryStatement: [
    //         {
    //             id: 1,
    //             criteria: 'membership',
    //             summarySentence:
    //                 'This membership will automatically renew after [passesValidFor] [autopayIncrement] (until cancelled).',
    //         },
    //         {
    //             id: 2,
    //             criteria: 'package',
    //             summarySentence:
    //                 'Each package will be valid for [passesValidFor] [autopayIncrement]. This package will not automatically renew.',
    //         },
    //         {
    //             id: 3,
    //             criteria: 'singlesession',
    //             summarySentence:
    //                 'This single session will be valid for [passesValidFor] [autopayIncrement]. This single session will not automatically renew.',
    //         },
    //     ],
    //     variables: [
    //         {
    //             id: 1,
    //             variablename: 'passesValidFor',
    //             startingvalue: '1',
    //             drivesPlurality: true,
    //             getsPluralized: false,
    //         },
    //         {
    //             id: 2,
    //             variablename: 'autopayIncrement',
    //             codeValue: 'month',
    //             drivesPlurality: false,
    //             getsPluralized: true,
    //         },
    //     ],
    // },
    // {
    //     id: 4,
    //     title: 'Limit Number of Uses per Client',
    //     keytouse: 'limitperuser',
    //     functiontype: 'chooseusageperuserlimit',
    //     type: 'numberfield',
    //     fieldnameForChoice: 'usagePerUserLimit',
    //     isMoney: false,
    //     isFinalQuestion: false,
    //     defaultQuestion: 'How many times can each client use this promo code?',
    //     help: `TBD`,
    //     nextQuestion: 5,
    // },
    // {
    //     id: 5,
    //     isFinalQuestion: false,
    //     title: 'Application',
    //     keytouse: 'application',
    //     functiontype: 'chooseapplication',
    //     help: 'How can this promo code be applied?',
    //     defaultQuestion: 'How can clients use this promo code?',
    //     type: 'dropdown',
    //     fieldnameForChoice: 'application',
    //     options: [
    //         {
    //             id: 1,
    //             name: 'Universal',
    //             codeValue: 'universal',
    //         },
    //         {
    //             id: 2,
    //             name: 'Packages',
    //             codeValue: 'packages',
    //         },
    //         {
    //             id: 3,
    //             name: 'Memberships',
    //             codeValue: 'memberships',
    //         },
    //     ],
    //     nextQuestion: 6,

    //     showSummary: true,
    //     summaryStatement: [
    //         {
    //             id: 1,
    //             criteria: 'membership',
    //             summarySentence:
    //                 'This membership will automatically renew after [passesValidFor] [autopayIncrement] (until cancelled).',
    //         },
    //         {
    //             id: 2,
    //             criteria: 'package',
    //             summarySentence:
    //                 'Each package will be valid for [passesValidFor] [autopayIncrement]. This package will not automatically renew.',
    //         },
    //         {
    //             id: 3,
    //             criteria: 'singlesession',
    //             summarySentence:
    //                 'This single session will be valid for [passesValidFor] [autopayIncrement]. This single session will not automatically renew.',
    //         },
    //     ],
    // },

    {
        id: 6,
        title: 'Any Restrictions?',
        keytouse: 'restrictions',
        functiontype: 'chooserestrictions',
        defaultQuestion:
            "Are there any restrictions that you'd like to add to this promo code.",
        type: 'checkboxes',
        nextQuestion: 'end',
        isFinalQuestion: true,
        help: `Use this section to indicate any restrictions that apply to this promo code.`,
        checkboxValues: [
            {
                id: 1,
                name: 'New Clients Only',
                codeValue: 'new-clients',
                needsOwnLine: false,
                fieldnameForChoice: 'newClientsOnly',
                infoguidance: `If checked, this promo code will be exclusively available to new clients on their first purchase. New clients are defined as those who have never made a purchase at your location before. Once a client has made a purchase, they will no longer be eligible for new client offers.`,
            },
        ],
    },
];

export default newPromoCodeQuestions;
