import React from "react";
import { lowercaseString } from "helpers";
import { gridSpacing } from "store/constant";

import { dispatch } from "store";
import {
  setThisUserEmail,
  setEncryptedPassword,
  setHasSetPwdFirebase,
  setThisUserEmployeeId,
} from "store/slices/studioemployeeuser";
import checkUserExists from "actions/users/userAlreadyExists";

// material-ui
import {
  Grid,
  Button,
  InputLabel,
  Typography,
  TextField,
  Fade,
} from "@mui/material";

// third party
import { useFormik } from "formik";
import * as yup from "yup";

// project imports
import LoginCard from "ui-component/cards/AccountSubCardNew";
import ErrorMessage from "views/feedback-messages/error/errorMessage";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Your email address looks incorrect. Please try again")
    .required("Required"),
});

// ============================|| NEW - LOGIN PAGE ||============================ //

const LoginAuthenticationCard = ({ setCurrentStep }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        const emailtosend = lowercaseString(values.email);
        dispatch(setThisUserEmail(emailtosend));
        await checkUserExists(emailtosend).then((userExists) => {
          if (userExists) {
            const {
              userid,
              hasAccount,
              encryptedPassword,
              hasSetFirebasedPwd,
            } = userExists;
            if (hasAccount) {
              dispatch(setEncryptedPassword(encryptedPassword));
              dispatch(setHasSetPwdFirebase(hasSetFirebasedPwd));
              dispatch(setThisUserEmployeeId(userid));
              setCurrentStep("password");
            } else {
              setCurrentStep("register");
            }
          }
        });
      } catch (error) {
        console.log(`error was caught: ${JSON.stringify(error)}`);
        setStatus({ success: false });
        setErrors({ submit: error.message });
        setSubmitting(false);
      }
    },
  });
  return (
    <Grid container justifyContent="center">
      <Grid item xs={11} md={5} sx={{ mb: 5 }}>
        <Fade in={true} timeout={500}>
          <LoginCard title="Sign In">
            <Grid container spacing={gridSpacing} justifyContent="flex-start">
              <ErrorMessage />
              <Grid item xs={12} sx={{ ml: 1 }}>
                <Typography variant="regularText">
                  Enter your email address to sign in
                </Typography>
                <form className="form-profile" onSubmit={formik.handleSubmit}>
                  <Grid container justifyContent="flex-start">
                    <Grid item xs={10} md={9} sx={{ mt: 2, ml: 0.5 }}>
                      <InputLabel>Email</InputLabel>
                      <TextField
                        fullWidth
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                        sx={{ mt: 1 }}
                      >
                        Email
                      </TextField>
                    </Grid>
                    <Grid item xs={6} sx={{ mt: 4, mb: 2.5 }}>
                      <Button
                        variant="checkout"
                        type="submit"
                        // fullWidth
                        disabled={formik.isSubmitting}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </LoginCard>
        </Fade>
      </Grid>
    </Grid>
  );
};
export default LoginAuthenticationCard;
