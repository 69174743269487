import React from "react";

import {
  registerWithEmailAndPassword,
  addFirebaseProfile,
} from "firebaseConfig";
import { Grid, TextField, Typography, Button, Stack } from "@mui/material";
import "yup-phone-lite";

// third party
import { useFormik } from "formik";
import * as yup from "yup";

import { dispatch } from "store";

import InputLabel from "ui-component/extended/Form/InputLabel";
import { useSelector } from "store";
import {
  // setCurrentStep,
  setAlreadyHasAccount,
  setThisUserFirstName,
} from "store/slices/studioemployeeuser";

import { lowercaseString } from "helpers";

// yup validation-schema
const validationSchema = yup.object().shape({
  firstname: yup
    .string()
    .min(2, "Your name is too short. Please try again.")
    .max(90, "Your name is too long. Please try again.")
    .required("Required"),
  lastname: yup
    .string()
    .min(2, "Your lastname is too short. Please try again.")
    .max(90, "Your name is too long. Please try again.")
    .required("Required"),
  password: yup.string().max(255).required("Password is required"),
  phone: yup
    .string()
    .phone("US", "Please enter a valid phone number")
    .required("A phone number is required"),
});

const StepsToCheckoutPassword = () => {
  const { email } = useSelector((state) => state.studioemployeeuser);
  const formik = useFormik({
    initialValues: {
      password: "",
      phone: "",
      email: email,
    },
    validationSchema,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      // create a new account
      try {
        const emailtosend = lowercaseString(values.email);
        await registerWithEmailAndPassword(emailtosend, values.password)
          .then(() => {
            addFirebaseProfile(values.firstname);
          })
          .then(() => {
            // dispatch(setCurrentStep('payment'));
            dispatch(setAlreadyHasAccount(true));
            dispatch(setThisUserFirstName(values.firstname));
          })
          .catch((error) => {
            console.log(`error creating user: ${JSON.stringify(error)}`);
          });
        // navigate('/dashboard', { replace: true });
      } catch (error) {
        setStatus({ success: false });
        setErrors({ submit: error.message });
        setSubmitting(false);
      }
    },
  });
  return (
    <Grid container>
      <Grid item xs={12} sx={{ mb: 4 }}>
        <Typography variant="subtitle1">
          Complete your profile now to check out.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ ml: 1 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={9} md={6}>
            <form onSubmit={formik.handleSubmit}>
              <Grid item xs={10}>
                <Grid item xs={12} sx={{ mb: 3 }}>
                  <InputLabel>Email</InputLabel>
                  <TextField
                    name="email"
                    fullWidth
                    value={formik.values.email || ""}
                    placeholder={email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
              <Grid item xs={10} sx={{ mb: 3 }}>
                <Stack direction="row">
                  <Grid item xs={6} sx={{ mr: 3 }}>
                    <InputLabel>First name</InputLabel>
                    <TextField
                      name="firstname"
                      value={formik.values.firstname || ""}
                      fullWidth
                      onChange={formik.handleChange}
                      placeholder="Enter your first name"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.firstname &&
                        Boolean(formik.errors.firstname)
                      }
                      helperText={
                        formik.touched.firstname && formik.errors.firstname
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>Last name</InputLabel>
                    <TextField
                      name="lastname"
                      value={formik.values.lastname || ""}
                      fullWidth
                      onChange={formik.handleChange}
                      placeholder="Enter your last name"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.lastname &&
                        Boolean(formik.errors.lastname)
                      }
                      helperText={
                        formik.touched.lastname && formik.errors.lastname
                      }
                    />
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={10} sx={{ mb: 3 }}>
                <InputLabel>Phone Number</InputLabel>
                <TextField
                  name="phone"
                  value={formik.values.phone || ""}
                  fullWidth
                  onChange={formik.handleChange}
                  placeholder="Enter your phone number"
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={10}>
                <InputLabel>Enter your Password</InputLabel>
                <TextField
                  name="password"
                  value={formik.values.password || ""}
                  fullWidth
                  autoComplete="current-password"
                  onChange={formik.handleChange}
                  placeholder="Enter your password"
                  type="password"
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={10} sx={{ mt: 2 }}>
                <Grid container>
                  <Grid item sx={{ mr: 1 }}>
                    <Button
                      variant="checkout"
                      type="submit"
                      disabled={formik.isSubmitting}
                      sx={{ mr: 2 }}
                    >
                      {" "}
                      Submit
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={formik.handleReset}
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default StepsToCheckoutPassword;
