import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function createPromoCode(
    promocode,
    dibsStudioId,
    studioEmployeeId,
    timeZone
) {
    const urlstring = '/studio/promo-codes/create';
    const url = getApiUrl(urlstring);
    let valuetosend = {};
    await axios
        .post(url, {
            codeInfo: promocode,
            dibsStudioId,
            employeeId: studioEmployeeId,
            timeZone,
        })
        .then(async (res) => {
            valuetosend = res.data;
        })
        .catch((error) => {
            valuetosend = error;
        });
    return valuetosend;
}
