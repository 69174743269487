import React from 'react';
import PropTypes from 'prop-types';
import isIEorEdge from '../../../shared/helpers/is-ie-or-edge';

const CheckBoxIcon = ({ height = 20, checked = false }) => {
    const boxStyle = {};
    if (isIEorEdge()) boxStyle.stroke = '#999';
    return (
        <svg
            className="dibs-check-icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 53 39"
            enableBackground="new 0 0 53 39"
            xmlSpace="preserve"
            height={height}
            filter="url(#checkbox-dropshadow)"
        >
            <defs>
                <filter id="checkbox-dropshadow">
                    <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
                    <feOffset dx="1" dy="1" result="offsetblur" />
                    <feComponentTransfer>
                        <feFuncA type="linear" slope=".5" />
                    </feComponentTransfer>
                    <feMerge>
                        <feMergeNode />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <path
                style={boxStyle} // fucking IE...
                fill="#FFFFFF"
                d="M33.3,32H17.7c-3.2,0-5.7-2.6-5.7-5.7V13.7c0-3.2,2.6-5.7,5.7-5.7h15.5c3.2,0,5.7,2.6,5.7,5.7v12.5 C39,29.4,36.4,32,33.3,32z"
            />
            {checked && (
                <g>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="none"
                        d="M31.1,15.5c-0.1,0-0.1,0-0.1,0.1c-2.7,3-5.4,6.3-8.1,9.3l-4.1-3.3c-0.1-0.1-0.3-0.1-0.4,0
            c-0.1,0.1-0.1,0.3,0,0.4c0,0,0,0,0,0l4.2,3.4c0.1,0.1,0.2,0.1,0.3,0c2.8-3.1,5.5-6.4,8.2-9.5c0.1-0.1,0.1-0.3,0-0.4
            C31.3,15.5,31.2,15.5,31.1,15.5"
                    />
                    <path
                        fill="none"
                        className="dibs-highlight-stroke"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        d="M43.6,4.7c-0.1,0-0.2,0.1-0.3,0.2c-6,6.7-11.9,13.9-17.9,20.6l-9-7.2c-0.2-0.2-0.6-0.2-0.8,0
            c-0.2,0.2-0.2,0.6,0,0.8c0,0,0.1,0.1,0.1,0.1l9.4,7.6c0.2,0.2,0.5,0.1,0.7-0.1C32.1,19.8,38,12.4,44.1,5.7c0.2-0.2,0.2-0.6,0-0.8
            C44,4.7,43.8,4.7,43.6,4.7"
                    />
                </g>
            )}
        </svg>
    );
};

// CheckBoxIcon.defaultProps = {
//     checked: false,
//     height: 20,
// };

CheckBoxIcon.propTypes = {
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CheckBoxIcon;
