import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
} from '@mui/material';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0, border: 0 },
    border: 0,
    display: 'flex',
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = React.forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            borderColor,
            bgColor,
            variantType,
            secondary,
            shadow,
            sx = {},
            addBorder,
            title = '',
            borderThickness,
            idtouse,
            ...others
        },
        ref
    ) => {
        // const theme = useTheme();
        let thickness = borderThickness ? borderThickness : 1;
        if (!addBorder) thickness = 0;

        const bordertext = `${thickness}px solid`;
        let borderColorToUse = borderColor || '#dddddd';

        return (
            <Card
                id={`card-main-card-${idtouse}`}
                variant={variantType}
                ref={ref}
                {...others}
                sx={{
                    mb: 6,
                    p: 0,
                    mx: 0,
                    // border: border ? '1px solid' : 'none',
                    border: border ? bordertext : 'none',
                    borderColor: borderColorToUse,
                    // borderColor:
                    //     theme.palette.mode === 'dark'
                    //         ? theme.palette.background.default
                    //         : theme.palette.primary[200] + 75,
                    // ':hover': {
                    //     boxShadow: boxShadow
                    //         ? shadow ||
                    //           (theme.palette.mode === 'dark'
                    //               ? '0 2px 14px 0 rgb(33 150 243 / 10%)'
                    //               : '0 2px 14px 0 rgb(32 40 45 / 8%)')
                    //         : 'inherit',
                    // },
                    boxShadow: 'none',
                    ...sx,
                }}
            >
                {/* card header and action */}
                <CardHeader
                    sx={headerSX}
                    title={<Typography variant="h3">{title}</Typography>}
                    action={secondary}
                    id={`main-card-header-idtouse-${idtouse}`}
                />

                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.object,
    ]),
    shadow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sx: PropTypes.object,
    title: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.object,
    ]),
};

export default MainCard;
