import axios from "axios";
import { getApiUrl } from "helpers";

export default async function isUserNew(email, dibsId) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const basicurl = `${baseURL}/widget/get-user-new-status`;
  let datatosend = {};
  const checkIsNew = async () => {
    await axios
      .post(basicurl, {
        email,
        dibsId,
      })
      .then((res) => {
        const { data } = res;
        datatosend = data;
      })
      .catch((err) => {
        console.log(`error checking whether the user is new - Action: ${err}`);
        return err;
      });
  };
  await checkIsNew();
  return datatosend;
}
