/**
 * findLocale description
 * @return {string} [description]
 */
function findLocale() {
  switch (navigator.language.toLowerCase()) {
    case "en-uk":
    case "en-gb":
      return "uk";
    case "en":
    case "en-us":
    default:
      return "us";
  }
}

export default findLocale;
