import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function createNewDibsUser(thisdata) {
    const url = getApiUrl('/create-new-dibs-user');
    let datatosend = {};
    const createUser = async () => {
        await axios
            .post(url, {
                thisdata,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error creating new dibs user: ${err}`);
                return err;
            });
    };
    await createUser();
    return datatosend;
}
