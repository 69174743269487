/**
 * @param {Object} state in store
 * @returns {Object} user state
 */
export function getUserInstructor(state) {
  return state.instructor || {};
}

/**
 * @param {Object} state in store
 * @returns {string} user firstname
 */
export function getUserInstructorId(state) {
  // console.log(`\ntotal state of the getUserInstructorId: ${getUserInstructor(state).id}`)
  return getUserInstructor(state).id || "";
}
