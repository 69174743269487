import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function createNewAppt(apptToConfirm) {
    const url = getApiUrl('/appts/create-new-appt');
    let returndata;
    await axios
        .post(url, { apptToConfirm })
        .then((res) => {
            const { data } = res;
            returndata = data;
        })
        .catch((error) => {
            console.log('the error is', error);
            returndata = error;
        });
    return returndata;
}
