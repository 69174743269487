// import { format as formatCurrency } from 'currency';

/**
 * returns passDisplayName for upcoming events (ex. 'Applied 4 classes package')
 * @param {Object} event with the pass used
 * @param {Object} formattedPrice of the event
 * @returns {string} passDisplayName
 */
function formatPassDisplayName(event, currency) {
    let passUsedCount = 0;
    const passesUsed = event.passes.filter(
        (pass) => pass.id && pass.studioPackage && pass.studioPackage.name
    );
    const formatCurrency = (price, { code }) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: code,
        }).format(price);
    };
    if (passesUsed?.length === 0) return '';
    const passCountObj = {};
    passesUsed.forEach((pass) => {
        passUsedCount += 1;
        if (!passCountObj[pass.studioPackage.name]) {
            passCountObj[pass.studioPackage.name] = 1;
        } else {
            passCountObj[pass.studioPackage.name] += 1;
        }
    });
    let passDisplayName = 'Applied ';
    Object.keys(passCountObj).forEach((key) => {
        passDisplayName += ` ${key} package`;
        const passCount = passCountObj[key];
        if (passCount > 1) {
            passDisplayName += ` x${passCount}`;
        }
        passDisplayName += ' +';
    });
    passDisplayName = passDisplayName.substr(0, passDisplayName?.length - 2);
    if (passUsedCount > 0 && passUsedCount !== event.quantity) {
        const displayPrice = formatCurrency(
            event.price * (event.quantity - passUsedCount),
            { code: currency }
        );
        passDisplayName += ` + ${displayPrice}`;
    }
    return passDisplayName;
}

export default formatPassDisplayName;
