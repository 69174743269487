import propTypes from "prop-types";
import { Grid, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottomColor: "#e7b2a5",
  },
  "& .MuiInput-underline:before:hover": {
    borderBottomColor: "#e7b2a5",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#e7b2a5",
  },
});

export default function NormalTextField(props) {
  const { valueString, widthNumber, onChange, touse, label } = props;
  return (
    <Grid item xs={12}>
      <StyledTextField
        id={`textfield-${touse}`}
        label={label}
        variant="standard"
        value={valueString}
        onChange={(e) => onChange(e.target.value)}
        sx={{ width: widthNumber }}
      />
    </Grid>
  );
}
NormalTextField.propTypes = {
  valueString: propTypes.string,
  widthNumber: propTypes.number,
  onChange: propTypes.func,
};
