import { dispatch } from 'store';
import {
    setAlreadyHasAccount,
    setThisUserEmail,
    setThisUserFirstName,
    setThisUserLastName,
    setThisUserPhone,
    // setStripeId,
    setThisUserEmployeeId,
    setBirthday,
    setIsInLoginLinkFlow,
    // setHasSignedInFirebase,
    setHasSetPwdFirebase,
    setThisUserProfilePicture,
    setThisEmployeeIsAdmin,
    setThisEmployeeIsInstructorOnly,
    setIsAlsoInstructor,
    setInstructorId,
} from 'store/slices/studioemployeeuser';
import doesUserHaveAccount from 'actions/doesUserHaveAccount.js';

export const addUserInfoAfterLogin = async (email, dibsId) => {
    await doesUserHaveAccount(email, dibsId).then((acct) => {
        const { hasAccount, info } = acct;
        dispatch(setAlreadyHasAccount(hasAccount));
        if (hasAccount) {
            dispatch(setThisUserEmail(info.email));
            dispatch(setThisEmployeeIsAdmin(info.admin));
            dispatch(setThisEmployeeIsInstructorOnly(info.instructor_only));
            dispatch(setThisUserFirstName(info.firstName));
            dispatch(setThisUserLastName(info.lastName));
            dispatch(setThisUserPhone(info.phone));
            dispatch(setThisUserEmployeeId(info.id));
            dispatch(setIsInLoginLinkFlow(false));
            // dispatch(setHasSignedInFirebase(true));
            dispatch(setHasSetPwdFirebase(true));
            dispatch(setThisUserProfilePicture(info.profile_picture));
            dispatch(setIsAlsoInstructor(acct.isAlsoInstructor));
            dispatch(setInstructorId(acct.instructorId));
        } else {
            dispatch(setThisUserLastName(''));
            dispatch(setThisUserPhone(''));
            dispatch(setThisUserEmployeeId(0));
        }
    });
};
