import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
// import { Avatar, Box, Card, Grid, InputAdornment, OutlinedInput, TextField, Popper } from '@mui/material';
import { Avatar, Box, Card, Grid, TextField, Popper } from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
// import { IconAdjustmentsHorizontal, IconSearch, IconX } from '@tabler/icons-react';
import { IconSearch } from '@tabler/icons-react';
import { shouldForwardProp } from '@mui/system';

// components
import AutocompleteSearch from '../../search/AutoCompleteClientSearch';
// import { useSelector, dispatch } from 'store';
// import { dispatch } from 'store';
// import { setSearchTerm } from 'store/slices/clientsearch';

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: '80%',
    top: '-55px !important',
    padding: '12px 12px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 0 0 50px',
    },
}));

// eslint-disable-next-line no-unused-vars
const OutlineTextFieldStyle = styled(TextField, { shouldForwardProp })(
    ({ theme }) => ({
        width: 434,
        height: 40,
        marginLeft: 0,
        paddingLeft: 16,
        paddingRight: 16,
        '& input': {
            background: 'transparent !important',
            paddingLeft: '4px !important',
        },
        [theme.breakpoints.down('lg')]: {
            width: 250,
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginLeft: 4,
            background:
                theme.palette.mode === 'dark'
                    ? theme.palette.dark[800]
                    : '#fff',
        },
    })
);

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
    ({ theme }) => ({
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        background:
            theme.palette.mode === 'dark'
                ? theme.palette.dark.main
                : theme.palette.secondary.light,
        color:
            theme.palette.mode === 'dark'
                ? theme.palette.secondary.main
                : theme.palette.secondary.dark,
        '&:hover': {
            background:
                theme.palette.mode === 'dark'
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
            color:
                theme.palette.mode === 'dark'
                    ? theme.palette.secondary.light
                    : theme.palette.secondary.light,
        },
    })
);

// ==============================|| SEARCH INPUT - MOBILE||============================== //

// eslint-disable-next-line no-unused-vars
const MobileSearch = ({ value, setValue, popupState }) => (
    //  = useTheme();
    // Start Here - react-query - may be the way to do this - check it out

    <div className="AutoComplete">
        <AutocompleteSearch />
    </div>
);
MobileSearch.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    popupState: PopupState,
};

// ==============================|| SEARCH INPUT NOT MOBILE ||============================== //

const ClientSearch = () => {
    const theme = useTheme();
    // const [results, setResults] = useState([]);
    // const { term } = useSelector((state) => state.clientsearch);
    const [value, setValue] = useState('');
    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <PopupState
                    variant="popper"
                    popupId="client-search-mobile-popper"
                >
                    {(popupState) => (
                        <>
                            <Box sx={{ ml: 1 }}>
                                <HeaderAvatarStyle
                                    variant="rounded"
                                    {...bindToggle(popupState)}
                                >
                                    <IconSearch stroke={1.5} size="1.2rem" />
                                </HeaderAvatarStyle>
                            </Box>
                            <PopperStyle {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <>
                                        <Transitions
                                            type="zoom"
                                            {...TransitionProps}
                                            sx={{
                                                transformOrigin: 'center left',
                                            }}
                                        >
                                            <Card
                                                sx={{
                                                    background:
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? theme.palette
                                                                  .dark[900]
                                                            : '#fff',
                                                    [theme.breakpoints.down(
                                                        'sm'
                                                    )]: {
                                                        border: 0,
                                                        boxShadow: 'none',
                                                    },
                                                }}
                                            >
                                                <Box sx={{ p: 2 }}>
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <Grid item xs>
                                                            <MobileSearch
                                                                value={value}
                                                                setValue={
                                                                    setValue
                                                                }
                                                                popupState={
                                                                    popupState
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Transitions>
                                    </>
                                )}
                            </PopperStyle>
                        </>
                    )}
                </PopupState>
            </Box>
            <Box
                sx={{ display: { xs: 'none', md: 'block' } }}
                id="box-holding-client-search"
            >
                <AutocompleteSearch idtouse="client-search-studio-admin" />
            </Box>
        </>
    );
};

export default ClientSearch;
