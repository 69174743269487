// SearchContext.js
import React, { createContext, useContext, useState } from 'react';

const LocaleContext = createContext();
const initaleLocale = 'en';

export function useLocale() {
    return useContext(LocaleContext);
}

export function LocaleProvider({ children }) {
    const [locale, setLocale] = useState(initaleLocale);

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            {children}
        </LocaleContext.Provider>
    );
}
