// third-party
import { createSlice } from "@reduxjs/toolkit";

// this is the case when the user needs to reset session - clear cache and stuff.

// ----------------------------------------------------------------------

const initialState = {
  plsResetSession: true,
};

const session = createSlice({
  name: "session",
  initialState,
  reducers: {
    resetUserSession(state, action) {
      state.plsResetSession = action.payload;
    },
  },
});

// Reducer
export default session.reducer;
export const { resetUserSession } = session.actions;

// ----------------------------------------------------------------------
