import React from 'react';
import { gridSpacing } from 'store/constant';

import { dispatch, useSelector } from 'store';
import {
    setHasSetPwdFirebase,
    setThisUserEmployeeId,
    setIsLoggedIn,
    setRecentlyLoggedIn,
    setAlreadyHasAccount,
} from 'store/slices/studioemployeeuser';
import { setIsNewUser } from 'store/slices/thisuser';
import { registerWithEmailAndPassword } from 'firebaseConfig';

// material-ui
import {
    Grid,
    Button,
    InputLabel,
    Typography,
    TextField,
    Fade,
} from '@mui/material';

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project imports
import LoginCard from 'ui-component/cards/AccountSubCardNew';
import ErrorMessage from 'views/feedback-messages/error/errorMessage';
import createNewDibsUser from 'actions/users/createNewDibsUser';

const validationSchema = yup.object().shape({
    newpassword: yup
        .string()
        .required('Password is required')
        .min(
            7,
            'Your password must be at least 8 characters. Please try again.'
        ),
});

// ============================|| NEW - LOGIN PAGE ||============================ //

const LoginAuthenticationCard = ({ setCurrentStep }) => {
    const { email, firstname, lastname, phone, birthday } = useSelector(
        (state) => state.studioemployeeuser
    );
    const [buttonText, setButtonText] = React.useState('Submit');
    const formik = useFormik({
        initialValues: {
            newpassword: '',
        },
        validationSchema,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                setButtonText('Submitting...');
                await registerWithEmailAndPassword(
                    email,
                    values.newpassword
                ).then(async (res) => {
                    dispatch(setHasSetPwdFirebase(true));
                    const datatosend = {
                        email,
                        firstname,
                        lastname,
                        phone,
                        birthday,
                    };
                    await createNewDibsUser(datatosend).then((res) => {
                        console.log('account was created', res);
                        setCurrentStep('confirmation');
                        setButtonText('Submit');
                        if (res) {
                            dispatch(setThisUserEmployeeId(res.userid));
                            dispatch(setRecentlyLoggedIn(true));
                            dispatch(setIsNewUser(true));
                            dispatch(setIsLoggedIn(true));
                            dispatch(setAlreadyHasAccount(true));
                            setTimeout(() => {
                                dispatch(setRecentlyLoggedIn(false));
                            }, 300000);
                        }
                    });
                });
            } catch (error) {
                console.log(`error was caught: ${JSON.stringify(error)}`);
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
            }
        },
    });
    return (
        <Grid container justifyContent="center">
            <Grid item xs={11} md={5} sx={{ mb: 5 }}>
                <Fade in timeout={500}>
                    <LoginCard title="Register">
                        <Grid
                            container
                            spacing={gridSpacing}
                            justifyContent="flex-start"
                        >
                            <ErrorMessage />
                            <Grid item xs={12} sx={{ ml: 1, mr: 1 }}>
                                <Typography variant="regularText">
                                    And finally, enter a password for your
                                    account
                                </Typography>
                                <form
                                    className="form-profile"
                                    onSubmit={formik.handleSubmit}
                                >
                                    <Grid container justifyContent="flex-start">
                                        <Grid
                                            item
                                            xs={10}
                                            md={6}
                                            sx={{ mt: 2, ml: 1 }}
                                        >
                                            <InputLabel>Password</InputLabel>
                                            <TextField
                                                fullWidth
                                                name="newpassword"
                                                value={
                                                    formik.values.newpassword ||
                                                    ''
                                                }
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="********"
                                                type="password"
                                                error={
                                                    formik.touched
                                                        .newpassword &&
                                                    Boolean(
                                                        formik.errors
                                                            .newpassword
                                                    )
                                                }
                                                helperText={
                                                    formik.touched
                                                        .newpassword &&
                                                    formik.errors.newpassword
                                                }
                                                sx={{ mt: 1 }}
                                            >
                                                Password
                                            </TextField>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={11}
                                            sx={{ mt: 4, mb: 2.5 }}
                                        >
                                            <Grid
                                                container
                                                justifyContent="flex-start"
                                            >
                                                <Grid item xs={11} md={7.5}>
                                                    <Button
                                                        variant="checkout"
                                                        type="submit"
                                                        fullWidth
                                                        disabled={
                                                            formik.isSubmitting
                                                        }
                                                    >
                                                        {buttonText}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </LoginCard>
                </Fade>
            </Grid>
        </Grid>
    );
};
export default LoginAuthenticationCard;
