import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Tab, Tabs } from '@mui/material';

// assets
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';

import { useSelector } from 'store';

import FrontDeskClientsSubPage from '../sub-pages/FrontDesk/subpage-frontdesk-clients';
import FrontDeskClassesSubPage from '../sub-pages/FrontDesk/subpage-frontdesk-classes';
import FrontDeskPromoCodesSubPage from '../sub-pages/FrontDesk/subpage-frontdesk-promocodes';
import FrontDeskMemberPackagePage from '../sub-pages/FrontDesk/subpage-frontdesk-memberships-packages';
import FrontDeskRetailPage from '../sub-pages/FrontDesk/subpage-frontdesk-retail';

import { dispatch } from 'store';
import { setShowClientSearchTop } from 'store/slices/menu';
import translate from 'helpers/translate';

// tab content customize
function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        p: 3,
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// ================================|| UI TABS - FrontDeskTabs ||================================ //

export default function FrontDeskTabs() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [showPromoCodes, setShowPromoCodes] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { classesAltName, classAltName } = useSelector(
        (state) => state.dibsstudio.studioConfig
    );
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    useEffect(() => {
        if (dibsStudioId === '226' || dibsStudioId === '260') {
            setShowPromoCodes(false);
        } else {
            setShowPromoCodes(true);
        }
    }, [dibsStudioId]);
    const clientsTranslate = translate({
        id: 'clients',
        msg: 'Clients',
    });
    const classesAltNameT = translate({
        id: classesAltName,
        msg: classesAltName,
    });

    return (
        <>
            <Tabs
                value={value}
                variant="scrollable"
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                allowScrollButtonsMobile
                sx={{
                    mb: 3,
                    '& a': {
                        minHeight: 'auto',
                        minWidth: 180,
                        py: 1.5,
                        px: 6,
                        pl: 2,
                        // mr: 3,
                        color: theme.palette.grey[550],
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                    '& a.Mui-selected': {
                        color: theme.palette.primary.main,
                    },
                    '& a > svg': {
                        mb: '0px !important',
                        mr: 1.1,
                    },
                }}
            >
                <Tab
                    component={Link}
                    to="#"
                    icon={
                        <PersonOutlineIcon
                            sx={{
                                fontSize: '1.3rem',
                                color: theme.palette.chart.light,
                            }}
                        />
                    }
                    label={clientsTranslate}
                    {...a11yProps(0)}
                />
                <Tab
                    component={Link}
                    to="#"
                    icon={
                        <LibraryBooksOutlinedIcon
                            sx={{
                                fontSize: '1.3rem',
                                color: theme.palette.chart.light,
                            }}
                        />
                    }
                    label={classesAltNameT}
                    {...a11yProps(1)}
                />
                {showPromoCodes && (
                    <Tab
                        component={Link}
                        to="#"
                        icon={
                            <DiscountOutlinedIcon
                                sx={{
                                    fontSize: '1.3rem',
                                    color: theme.palette.chart.light,
                                }}
                            />
                        }
                        label="Promo Codes"
                        {...a11yProps(2)}
                    />
                )}

                {dibsStudioId !== '226' && dibsStudioId !== '260' && (
                    <Tab
                        component={Link}
                        to="#"
                        icon={
                            <CollectionsBookmarkOutlinedIcon
                                sx={{
                                    fontSize: '1.3rem',
                                    color: theme.palette.chart.light,
                                }}
                            />
                        }
                        label="Memberships & Packages"
                        {...a11yProps(3)}
                    />
                )}
                {dibsStudioId !== '226' && dibsStudioId !== '260' && (
                    <Tab
                        component={Link}
                        to="#"
                        icon={
                            <ShoppingBasketOutlinedIcon
                                sx={{
                                    fontSize: '1.3rem',
                                    color: theme.palette.chart.light,
                                }}
                            />
                        }
                        label="Retail Items"
                        {...a11yProps(4)}
                    />
                )}

                {/* <Tab
                    component={Link}
                    to="#"
                    icon={<OndemandVideoOutlinedIcon sx={{ fontSize: '1.3rem', color: theme.palette.chart.light }} />}
                    label="Video On Demand (beta)"
                    {...a11yProps(4)}
                /> */}
            </Tabs>
            <TabPanel value={value} index={0}>
                <FrontDeskClientsSubPage altClassesText={classesAltName} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <FrontDeskClassesSubPage
                    altClassesText={classesAltName}
                    classAltName={classAltName}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <FrontDeskPromoCodesSubPage altClassesText={classesAltName} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <FrontDeskMemberPackagePage altClassesText={classesAltName} />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <FrontDeskRetailPage altClassesText={classesAltName} />
            </TabPanel>
        </>
    );
}
