import { useEffect, useState } from 'react';

// material-ui
import { Grid, Fade, Divider, Chip } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import { dispatch, useSelector } from 'store';

import { setInstructorData } from 'store/slices/instructors';

import CancelIcon from '@mui/icons-material/Cancel';
import capitalizeString, { getLightRandomHexColor } from 'helpers';
import { capitalizeFirstLetter } from 'helpers/general';

// ==============================|| AVAILABILITY OPTION UNDER TOOLBAR ||============================== //

const EmptyIcon = () => {
    return '';
};

const AvailabilityOption = ({
    showAvailability,
    setInstructorAvailabilityThatIsShowing,
    instructorAvailabilityThatIsShowing,
    updateAvailabilityAfterChipClick,
    changeInstructorAsResource,
}) => {
    const theme = useTheme();
    const { instructorData } = useSelector((state) => state.instructors);
    const [instructorsToUse, setInstructorsToUse] = useState([]);
    const [alreadyResetInRedux, setAlreadyResetInRedux] = useState(false);

    useEffect(() => {
        if (instructorData?.length > 0) {
            const tempArray = [];
            const filteredInstructorData = instructorData.filter(
                (instructor) => {
                    return instructor.enabled;
                }
            );
            const instructorDataWithColor = filteredInstructorData.map(
                (instructor, i) => {
                    const { associated_color } = instructor;
                    tempArray.push(instructor.id);
                    if (!associated_color) {
                        const newRandomColor = getLightRandomHexColor();
                        return {
                            ...instructor,
                            associated_color: newRandomColor,
                            show_availability: true,
                        };
                    } else {
                        return {
                            ...instructor,
                            show_availability: true,
                        };
                    }
                }
            );
            setInstructorsToUse(instructorDataWithColor);
            setInstructorAvailabilityThatIsShowing(tempArray);
            // dispatch(setInstructorData(instructorDataWithColor));
        }
    }, [instructorData, setInstructorAvailabilityThatIsShowing]);
    useEffect(() => {
        if (!alreadyResetInRedux && instructorsToUse?.length > 0) {
            dispatch(setInstructorData(instructorsToUse));
            setAlreadyResetInRedux(true);
        }
    }, [instructorsToUse, alreadyResetInRedux]);

    const addToArrayShowing = (instructorIdChosen) => {
        const tempArray = [...instructorAvailabilityThatIsShowing];
        tempArray.push(instructorIdChosen);
        setInstructorAvailabilityThatIsShowing(tempArray);
        updateAvailabilityAfterChipClick(tempArray);
    };
    const removeFromArrayShowing = (instructorIdChosen) => {
        const filteredArray = instructorAvailabilityThatIsShowing.filter(
            (instructorId) => {
                return instructorId !== instructorIdChosen;
            }
        );
        setInstructorAvailabilityThatIsShowing(filteredArray);
        updateAvailabilityAfterChipClick(filteredArray);
    };
    const handleDeleteClickChip = (instructorIdChosen) => {
        const newInstructorsToUse = instructorsToUse.map((instructor, i) => {
            if (instructor.id === instructorIdChosen) {
                return {
                    ...instructor,
                    show_availability: !instructor.show_availability,
                };
            }
            return instructor;
        });
        setInstructorsToUse(newInstructorsToUse);
        removeFromArrayShowing(instructorIdChosen);
        changeInstructorAsResource(instructorIdChosen, false);
    };

    const handleClickedChip = (instructorIdChosen) => {
        const newInstructorsToUse = instructorsToUse.map((instructor, i) => {
            if (instructor.id === instructorIdChosen) {
                if (instructor.show_availability) {
                    removeFromArrayShowing(instructorIdChosen);
                    changeInstructorAsResource(instructorIdChosen, false);
                } else {
                    addToArrayShowing(instructorIdChosen);
                    changeInstructorAsResource(instructorIdChosen, true);
                }
                return {
                    ...instructor,
                    show_availability: !instructor.show_availability,
                };
            }
            return instructor;
        });
        setInstructorsToUse(newInstructorsToUse);

        // setInstructorAvailabilityThatIsShowing(instructorIdChosen);
    };
    if (!showAvailability) {
        return null;
    }

    return (
        <Fade
            in={showAvailability}
            timeout={{ appear: 1500, enter: 1500, exit: 1500 }}
        >
            <Grid
                alignItems="center"
                container
                justifyContent="flex-start"
                // spacing={3}
                sx={{ pb: 1, px: 0.5 }}
            >
                <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: '10px' }}
                    id="divider-holder"
                >
                    <Divider variant="fullWidth" />
                </Grid>
                <Grid
                    item
                    id="div-availability-options-showing-schedule"
                    xs={12}
                    border={0}
                >
                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        {instructorsToUse.map((instructor, i) => {
                            let colortouse = instructor.associated_color;
                            let lightcolor = alpha(colortouse, 0.1);
                            const hovercolor = alpha(colortouse, 0.2);
                            let textColor = theme.palette.text.primary;

                            // this instructor should have a hidden chip
                            if (!instructor.show_availability) {
                                colortouse = '#D7D6D6';
                                lightcolor = alpha(colortouse, 0.1);
                                textColor = '#A4A2A2';
                            }
                            const lastname = capitalizeFirstLetter(
                                instructor.lastname
                            );
                            const firstname = capitalizeFirstLetter(
                                instructor.firstname
                            );

                            const instructorLabel = `${firstname} ${lastname}`;

                            return (
                                <Grid
                                    item
                                    id={`div-instructor-name-schedule-${instructor.id}`}
                                    key={`div-chip-instructor-${instructor.id}`}
                                    border={0}
                                    sx={{ mr: 0.5 }}
                                >
                                    <Chip
                                        label={instructorLabel}
                                        variant="outlined"
                                        key={`instructor-${instructor.id}`}
                                        sx={{
                                            height: '25px',
                                            color: textColor,
                                            bgcolor: lightcolor,
                                            ':hover': {
                                                bgcolor: hovercolor,
                                            },
                                            m: 0.25,
                                            borderColor: colortouse,
                                            '&.MuiChip-deletable .MuiChip-deleteIcon':
                                                {
                                                    color: colortouse,
                                                },
                                        }}
                                        deleteIcon={
                                            instructor.show_availability ? (
                                                <CancelIcon />
                                            ) : (
                                                <EmptyIcon />
                                            )
                                        }
                                        onClick={() => {
                                            handleClickedChip(instructor.id);
                                        }}
                                        onDelete={() => {
                                            handleDeleteClickChip(
                                                instructor.id
                                            );
                                        }}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Fade>
    );
};

export default AvailabilityOption;
