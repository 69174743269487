import React from "react";
import PropTypes from "prop-types";
import SearchIcon from "assets/icons/SearchIcon";
import "../styles/search-bar.scss";

/**
 * SearchBar React Component
 * @extends {module:React.PureComponent}
 * @returns {JSX.Element} HTML
 */
class SearchBar extends React.PureComponent {
  /**
   * constructor
   * @constructs SearchBar
   * @param {Object} props Component props
   */
  constructor(props) {
    super(props);
    this.state = { isFocused: false };
    this.handleFocus = this.handleFocus.bind(this);
    this.handleEnterPress = this.handleEnterPress.bind(this);
    this.clear = this.clear.bind(this);
  }
  /**
   * handleEnterPress
   * @param {Event} e the event
   * @param {number} keyCode the event key code
   * @returns {undefined}
   */
  handleEnterPress({ keyCode }) {
    if (keyCode === 13) {
      this.props.onSubmit();
    }
  }
  /**
   * handleFocus
   * @param {boolean} value this.state.inputFocused is set to
   * @returns {undefined}
   */
  handleFocus(value) {
    this.setState({ inputFocused: value });
  }
  /**
   * Clear search
   * @returns {Promise<undefined>} ensures order of actions to clear search
   */
  clear() {
    return new Promise((resolve) => {
      this.props.onChange({ target: { value: "" } });
      return resolve();
    }).then(() => this.props.onSubmit());
  }
  /**
   * render
   * @returns {JSX.Element} HTML
   */
  render() {
    let focused = this.state.inputFocused;
    focused = true;

    return (
      <div className={`search-bar-container`}>
        <div
          className={`search-bar ${
            focused ? "is-focused" : ""
          } dibs-highlight-border`}
        >
          <SearchIcon />
          {React.Children.count(this.props.children) ? (
            React.cloneElement(this.props.children, this.props)
          ) : (
            <input
              className="dibs-font"
              onChange={this.props.onChange}
              onKeyDown={this.handleEnterPress}
              placeholder=""
              type="text"
              value={this.props.value}
              onFocus={() => this.handleFocus(true)}
              onBlur={() => this.handleFocus(false)}
              disabled={this.props.disabled}
            />
          )}
          {/* {Boolean(this.props.value) && this.props.clearButton && (
                        <button className="clear-button" onClick={this.clear}>
                            <XIcon className="clear" strokeWidth={1.5} />
                        </button>
                    )} */}
        </div>
        {this.props.submitButton && (
          <button
            className="dibs-button dibs-highlight-border dibs-highlight-background dibs-font"
            onClick={this.props.onSubmit}
          >
            {this.props.searchButtonText}
          </button>
        )}
      </div>
    );
  }
}

SearchBar.defaultProps = {
  disabled: false,
  submitButton: false,
  onSubmit() {},
  clearButton: true,
  searchButtonText: "SEARCH",
};

SearchBar.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  submitButton: PropTypes.bool,
  clearButton: PropTypes.bool,
  className: PropTypes.string,
  searchButtonText: PropTypes.string,
  children: PropTypes.node,
};

export default SearchBar;
