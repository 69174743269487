import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { clearError } from "../../actions/ErrorActions";
import {
  fetchRetailProducts,
  editRetailProduct,
  addRetailProduct,
  deleteRetailProduct,
  restoreRetailProduct,
  deactivateRetailProduct,
  activateRetailProduct,
} from "../../actions/RetailActions";
import {
  RetailList,
  RetailEditModal,
  RetailActivationModal,
  RetailDeleteModal,
  RetailRestoreModal,
} from "shared/components/Retail";
import "./styles.scss";

import { getUserStudioCurrency } from "../../selectors/user";

import {
  getFormattedProducts,
  getProducts,
  getDeletedProducts,
} from "../../selectors/retail/index";

/**
 * @class Layout
 * @extends {module:React.PureComponent}
 * @returns {JSX.Element} HTML
 */
class RetailListContainer extends React.PureComponent {
  /**
   * constructor
   * @constructs RetailListContainer
   * @param {Object} props component props
   */
  constructor(props) {
    super(props);

    this.addClick = this.addClick.bind(this);
    this.editClick = this.editClick.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.toggleActivation = this.toggleActivation.bind(this);
    this.deleteClick = this.deleteClick.bind(this);
    this.restoreClick = this.restoreClick.bind(this);
  }

  state = {
    isShowingAddModal: false,
    isShowingEditModal: false,
    isShowingActivationModal: false,
    currentRetailItem: null,
    isShowingDeleteModal: false,
    isShowingRestoreModal: false,
  };

  /**
   * componentWillMount
   * @returns {undefined}
   */
  componentWillMount() {
    this.props.fetchRetailProducts(true);
  }

  /**
   * editClick
   * @param {item} item
   * @returns {undefined}
   */
  editClick(item) {
    this.setState({ currentRetailItem: item, isShowingEditModal: true });
  }

  /**
   * addClick
   * @returns {undefined}
   */
  addClick() {
    this.setState({ isShowingAddModal: true });
  }

  /**
   * addClick
   * @returns {undefined}
   */
  deleteClick(item) {
    this.setState({ currentRetailItem: item, isShowingDeleteModal: true });
  }

  /**
   * addClick
   * @returns {undefined}
   */
  restoreClick(item) {
    this.setState({ currentRetailItem: item, isShowingRestoreModal: true });
  }

  handleClose = (prop) =>
    this.setState({ [prop]: false, currentRetailItem: null });

  handleEdit = async (type, val) => {
    const {
      addRetailProduct,
      editRetailProduct,
      deleteRetailProduct,
      restoreRetailProduct,
      activateRetailProduct,
      deactivateRetailProduct,
    } = this.props;
    switch (type) {
      case "edit":
        this.editModal.setSubmission(false);
        await editRetailProduct(val.id, {
          name: val.name,
          description: val.description,
          price: val.price,
          displayOnRoster: val.displayOnRoster,
        });
        break;
      case "activation":
        switch (val.type) {
          case "activate":
            await activateRetailProduct(val.item.id);
            break;
          default:
          case "deactivate":
            await deactivateRetailProduct(val.item.id);
            break;
        }
        break;
      case "delete": {
        await deleteRetailProduct(val.item.id);
        break;
      }
      case "restore": {
        await restoreRetailProduct(val.item.id);
        break;
      }
      case "add":
        await addRetailProduct({
          name: val.name,
          description: val.description,
          price: val.price,
          displayOnRoster: val.displayOnRoster,
        });
        break;
      default:
        break;
    }
  };

  toggleActivation = async (item) => {
    this.setState({
      currentRetailItem: item,
      isShowingActivationModal: true,
    });
  };

  /**
   * render
   * @returns {JSX.Element} HTML
   */
  render() {
    const {
      currentRetailItem,
      isShowingAddModal,
      isShowingEditModal,
      isShowingActivationModal,
      isShowingDeleteModal,
      isShowingRestoreModal,
    } = this.state;
    const { retailProducts, deletedRetailProducts, currency } = this.props;
    return (
      <div className="retail-list-container">
        <h2>Available Retail Items</h2>
        <div className="retail-list-container__add-product">
          <button
            className="dibs-button card-button dibs-font dibs-highlight-background"
            onClick={this.addClick}
          >
            ADD RETAIL ITEM
          </button>
        </div>
        <RetailList
          heading="Retail Items"
          retailItems={retailProducts}
          editClick={this.editClick}
          deleteClick={(item) => this.deleteClick(item)}
          deactivateClick={(item) => this.toggleActivation(item)}
          activateClick={(item) => this.toggleActivation(item)}
        />
        <RetailList
          heading="Unavailable Retail Items"
          retailItems={deletedRetailProducts}
          removeText={"RESTORE"}
          deleteClick={(item) => this.restoreClick(item)}
        />
        {isShowingEditModal && (
          <RetailEditModal
            ref={(input) => (this.editModal = input)}
            open={isShowingEditModal}
            currency={currency}
            okText={"SAVE"}
            header="Edit Retail Item"
            item={currentRetailItem}
            handleClose={() => this.handleClose("isShowingEditModal")}
            handleSubmit={async (val) => {
              await this.handleEdit("edit", val);
            }}
          />
        )}
        {isShowingActivationModal && (
          <RetailActivationModal
            open={isShowingActivationModal}
            item={currentRetailItem}
            currency={currency}
            handleSubmit={async (val) =>
              await this.handleEdit("activation", val)
            }
            handleClose={() => this.handleClose("isShowingActivationModal")}
          />
        )}
        {isShowingAddModal && (
          <RetailEditModal
            ref={(input) => (this.addModal = input)}
            open={isShowingAddModal}
            currency={currency}
            okText={"ADD"}
            header="Add Retail Item"
            handleClose={() => this.handleClose("isShowingAddModal")}
            handleSubmit={async (val) => {
              await this.handleEdit("add", val);
            }}
          />
        )}
        {isShowingDeleteModal && (
          <RetailDeleteModal
            item={currentRetailItem}
            currency={currency}
            open={isShowingDeleteModal}
            handleClose={() => this.handleClose("isShowingDeleteModal")}
            handleSubmit={async (val) => {
              await this.handleEdit("delete", val);
            }}
          />
        )}
        {isShowingRestoreModal && (
          <RetailRestoreModal
            item={currentRetailItem}
            currency={currency}
            open={isShowingRestoreModal}
            handleClose={() => this.handleClose("isShowingRestoreModal")}
            handleSubmit={async (val) => {
              await this.handleEdit("restore", val);
            }}
          />
        )}
      </div>
    );
  }
}

RetailListContainer.contextTypes = {
  router: PropTypes.shape({}),
};

RetailListContainer.propTypes = {
  formattedRetailProducts: PropTypes.arrayOf(PropTypes.shape({})),
  retailProducts: PropTypes.arrayOf(PropTypes.shape({})),
  deletedRetailProducts: PropTypes.arrayOf(PropTypes.shape({})),
  fetchRetailProducts: PropTypes.func,
  addRetailProduct: PropTypes.func,
  editRetailProduct: PropTypes.func,
  activateRetailProduct: PropTypes.func,
  deactivateRetailProduct: PropTypes.func,
  deleteRetailProduct: PropTypes.func,
  restoreRetailProduct: PropTypes.func,
  currency: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.user,
  retailProducts: getProducts(state),
  deletedRetailProducts: getDeletedProducts(state),
  formattedRetailProducts: getFormattedProducts(state),
  currency: getUserStudioCurrency(state),
});

const mapDispatchToProps = {
  clearError,
  fetchRetailProducts,
  addRetailProduct,
  editRetailProduct,
  deleteRetailProduct,
  restoreRetailProduct,
  deactivateRetailProduct,
  activateRetailProduct,
};

const SmartLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RetailListContainer);

export default SmartLayout;
