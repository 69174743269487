import React from "react";
import PropTypes from "prop-types";

import "../../styles/dibs-loader.scss";

/**
 * DibsLoader
 * @param {Object} props for component
 * @returns {JSX.Element} HTML
 */
function DibsLoader(props) {
  let classnames = "white-dot dibs-highlight-border-text-color";
  if (props.reverseColor) {
    classnames = "blue-dot dibs-highlight-border";
  }
  return (
    <div className="dibs-loader-container">
      <div className={`dibs-loader${props.isInButton ? " is-button" : ""}`}>
        <div className={`dot ${classnames}`} />
        <div className={`dot ${classnames}`} />
        <div className={`dot ${classnames}`} />
      </div>
    </div>
  );
}

DibsLoader.propTypes = {
  reverseColor: PropTypes.bool,
  isInButton: PropTypes.bool,
};

export default DibsLoader;
