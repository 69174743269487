import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function reconstructUtcInLocal(dateToConvert, timeZone) {
    const dateEntry = dayjs.utc(dateToConvert);

    const localDateObj = dateEntry.tz(timeZone, {
        keepLocalTime: true,
    });

    return localDateObj;
}
