import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { gridSpacing } from 'store/constant';
import { useSelector } from 'store';
// material-ui
import { Button, Grid, TextField, Typography, Box, Fade } from '@mui/material';
import checkPromoCodeExists from 'actions/promo-codes/checkPromoCodeExists';
import PromoCodeQuestions from 'components/sub-components/FrontDesk/PromoCodes/questionsForPromoCodes';
import { capitalizeWords } from 'helpers/general';
import SubmitButton from 'components/button/submitButton';
import CancelButton from 'components/button/cancelButton';

import createNewPromoCode from 'actions/promo-codes/create-promo-code';
import createCoupon from 'actions/stripe/coupons/createCoupon';
import updatePromoCodeStripeCouponId from 'actions/stripe/coupons/updatePromoCodeStripeCouponId';
import setAlert from 'actions/status/setAlert';
import deactivatePackage from 'actions/studios/memberships-packages/deactivateMembershipsPackages';

import createNewStripeProduct from 'actions/stripe/plans/createNewStripeProduct';

import { useTheme, alpha } from '@mui/material/styles';
import { useSearch } from 'routes/searchContext';

// ==============================|| PROMO CODES ||============================== //
const expiredateDefault = dayjs().add(1, 'month').format('YYYY-MM-DD');
const currrentYear = dayjs().year();

const defaultValues = {
    amountOff: 10,
    appliesToMemberships: true,
    appliesToPackages: true,
    appliesToRenewal: true,
    appliesToRetail: true,
    appliesToSingleSessions: true,
    codeUsageLimit: 100,
    discountAmount: 10,
    expiration: expiredateDefault,
    firstTimeDibs: false,
    firstTimeStudio: false,
    multiCriteria: [
        'appliesToSingleSessions',
        'appliesToPackages',
        'appliesToMemberships',
        'appliesToRetail',
    ],
    newClientsOnly: false,
    product: 'UNIVERSAL',
    promocodeName: `DISCOUNT${currrentYear}`,
    promocodeType: 'PERCENT_OFF',
    stripeCouponId: '',
    userUsageLimit: 1,
};

const PromoCodeTextField = () => {
    const theme = useTheme();
    const { setShowSearch } = useSearch();
    const [value, setValue] = useState('');
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId, timeZone } = config;
    const { promocodes } = useSelector((state) => state.datatables);
    const { needsRefresh } = promocodes;
    const { studioEmployeeId } = useSelector(
        (state) => state.studioemployeeuser
    );
    const [promoCodeAlreadyExists, setPromoCodeAlreadyExists] = useState(false);
    const [checkedExistence, setCheckedExistence] = useState(false);
    const [promoCodeName, setPackageName] = useState('');
    const [promoCodeInfo, setPromoCodeInfo] = useState(defaultValues);
    const [confirmationText, setConfirmationText] = useState('');
    const [showConfirmationText, setShowConfirmationText] = useState(true);
    // const [newCodeNow, setNewCodeNow] = useState(false);
    useEffect(() => {
        setShowSearch(false);
    }, [setShowSearch]);
    useEffect(() => {
        if (needsRefresh) {
            setPromoCodeAlreadyExists(false);
            setCheckedExistence(false);
        }
    }, [needsRefresh]);
    const clearPromoCodeName = () => {
        setValue('');
        setCheckedExistence(false);
        setPromoCodeAlreadyExists(false);
        setPromoCodeInfo(defaultValues);
        setShowConfirmationText(false);
        setConfirmationText('');
    };
    const handleChange = (e) => {
        const newvalue = e.target.value;
        const strNoSpace = newvalue.replace(/\s/g, '');
        const strCap = strNoSpace.toUpperCase();
        setValue(strCap);
    };
    const updatePromoCodeInfoMultiCriteria = (fieldname, value) => {
        let holdingArray = [...promoCodeInfo.multiCriteria];
        if (value) {
            holdingArray = [...holdingArray, fieldname];
        } else {
            const tempArray = holdingArray.filter((item) => item !== fieldname);
            holdingArray = [];
            holdingArray = [...tempArray];
        }
        setPromoCodeInfo({
            ...promoCodeInfo,
            multiCriteria: holdingArray,
            [fieldname]: value,
        });
    };
    const setTrueFalseFields = (fieldname, value) => {
        setPromoCodeInfo({
            ...promoCodeInfo,
            [fieldname]: value,
        });
        updatePromoCodeInfoMultiCriteria(fieldname, value);
    };
    // REFACTOR THIS FUNCTION INTO ITS OWN ACTION
    // better as a Switch statement
    const updateMembershipPackageInfo = (functiontype, newinfo) => {
        switch (functiontype) {
            case 'choosepromotype':
                setPromoCodeInfo({
                    ...promoCodeInfo,
                    promocodeType: newinfo,
                });
                break;
            case 'chooseamountoff':
                setPromoCodeInfo({
                    ...promoCodeInfo,
                    amountOff: newinfo,
                });
                break;
            case 'chooseexpiration':
                setPromoCodeInfo({
                    ...promoCodeInfo,
                    expiration: newinfo,
                });
                break;
            case 'choosecodeusagelimit':
                setPromoCodeInfo({
                    ...promoCodeInfo,
                    codeUsageLimit: newinfo,
                });
                break;
            case 'chooseusageperuserlimit':
                setPromoCodeInfo({
                    ...promoCodeInfo,
                    userUsageLimit: newinfo,
                });

                break;
            case 'choosefirsttimestudio':
                setPromoCodeInfo({
                    ...promoCodeInfo,
                    firstTimeStudio: newinfo,
                });
                break;
            case 'chooseapplication':
                setPromoCodeInfo({
                    ...promoCodeInfo,
                    product: newinfo,
                });
                break;
            case 'chooserestrictions':
                setPromoCodeInfo({
                    ...promoCodeInfo,
                    restrictions: newinfo,
                });
                break;
            case 'chooseappliestorenewal':
                setPromoCodeInfo({
                    ...promoCodeInfo,
                    appliesToRenewal: newinfo,
                });
                break;
            default:
                console.error('Invalid functiontype');
                console.log('function type passed in was', functiontype);
        }
    };

    useEffect(() => {
        return () => {
            setValue('');
            setPromoCodeAlreadyExists(false);
            setCheckedExistence(false);
        };
    }, []);

    const handleFirstNext = async () => {
        setCheckedExistence(false);
        setPromoCodeAlreadyExists(false);
        setPackageName(capitalizeWords(value));
        const newpromocodeinfo = {
            ...promoCodeInfo,
            promocodeName: capitalizeWords(value),
        };
        setPromoCodeInfo(newpromocodeinfo);
        await checkPromoCodeExists(dibsStudioId, capitalizeWords(value)).then(
            (res) => {
                try {
                    const { promocodeAlreadyExists, msg } = res;

                    if (msg === 'success') {
                        setCheckedExistence(true);
                        setShowConfirmationText(true);
                        if (promocodeAlreadyExists) {
                            setPromoCodeAlreadyExists(true);
                        } else {
                            setPromoCodeAlreadyExists(false);
                        }
                    } else {
                        setCheckedExistence(false);
                    }
                } catch (err) {
                    console.log(
                        `error checking promo code name exists for studioid: ${dibsStudioId}\nerr is: ${err}`
                    );
                }
            }
        );
    };
    const pc = promoCodeInfo?.promocodeName;
    const pcCap = pc.toUpperCase();

    const newPromoCodeText = `You are creating a new promo code called ${pcCap}. `;
    const applicationText = `This promo code applies to ALL purchases.`;
    let restrictionText = '';

    useEffect(() => {
        setConfirmationText(
            `${newPromoCodeText} ${restrictionText} ${applicationText}`
        );
    }, [newPromoCodeText, restrictionText, applicationText]);
    const handleCancel = () => {
        clearPromoCodeName();
    };
    const handleSubmit = async () => {
        try {
            const promoShowName = promoCodeName.toUpperCase();
            const res = await createNewPromoCode(
                promoCodeInfo,
                dibsStudioId,
                studioEmployeeId,
                timeZone
            );
            if (res.success) {
                const stripeRes = await createCoupon(
                    promoCodeInfo,
                    dibsStudioId,
                    studioEmployeeId,
                    timeZone
                );
                if (!stripeRes?.success) {
                    setAlert({
                        msg: `There was a problem creating the Stripe coupon for ${promoShowName}. Please refresh your screen and try again. If you continue to experience issues, please contact support.`,
                        error: true,
                        seconds: 10,
                    });
                    return;
                }
                const { stripeCouponCreated } = stripeRes;
                const stripecouponid = stripeCouponCreated?.id;
                const promoid = res?.promoidcreated;
                await updatePromoCodeStripeCouponId(promoid, stripecouponid);
                setAlert({
                    msg: `${promoShowName} was successfully created.`,
                    success: true,
                    seconds: 7,
                });
                clearPromoCodeName();
                window.scrollTo(0, 0);
            } else {
                setAlert({
                    msg: `There was a problem creating ${promoShowName}. Please refresh your screen and try again.`,
                    warning: true,
                    seconds: 7,
                });
            }
        } catch (error) {
            console.error(error);
            setAlert({
                msg: `An unexpected error occurred. Please refresh your screen and try again.`,
                warning: true,
                seconds: 7,
            });
        }
    };
    return (
        <Grid container spacing={gridSpacing}>
            {checkedExistence && promoCodeAlreadyExists && (
                <Grid item xs={12} id="promo-code-already-exists">
                    <Typography variant="h6" color="error" sx={{ mb: 2 }}>
                        A promo code with this name already exists. Please
                        choose another text string.
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} sx={{ mb: 3 }} id="textfield-promo-code-name">
                <TextField
                    id="promo-code-field"
                    variant="outlined"
                    value={value}
                    onChange={(e) => handleChange(e)}
                    placeholder={`e.g. ${defaultValues.promocodeName}`}
                    size="small"
                    sx={{ width: '400px', height: '40px', mr: 2 }}
                />
                {(!checkedExistence || promoCodeAlreadyExists) && (
                    <Button
                        id="next-button-promo-code"
                        variant="contained"
                        disableElevation
                        onClick={handleFirstNext}
                        sx={{ width: '30px', height: '35px', mt: 0.3 }}
                    >
                        Next
                    </Button>
                )}
            </Grid>
            {!promoCodeAlreadyExists && checkedExistence && (
                <>
                    <Grid
                        item
                        xs={9.5}
                        sx={{ mt: 1.5, mb: 1.5 }}
                        id="promo-code-choose-specs"
                    >
                        <Typography variant="h5">
                            Set the information below to choose the
                            specifications for the new promo code (
                            {promoCodeName.toUpperCase()}).
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={9.5}
                        sx={{ mt: 1 }}
                        id="promo-code-questions"
                    >
                        <PromoCodeQuestions
                            promoCodeName={value}
                            clearPromoCodeName={clearPromoCodeName}
                            updateInfo={updateMembershipPackageInfo}
                            promoCodeInfo={promoCodeInfo}
                            setTrueFalseFields={setTrueFalseFields}
                            multiCriteria={
                                promoCodeInfo.multiCriteria ||
                                defaultValues.multiCriteria
                            }
                        />
                    </Grid>
                    {showConfirmationText && (
                        <Fade in timeout={500}>
                            <Grid
                                item
                                xs={9.5}
                                sx={{ mt: 2, mb: 2 }}
                                id="grid-showing-confirmation-text-new-promo-code"
                            >
                                <Box
                                    border={2}
                                    borderRadius={1.2}
                                    borderColor={
                                        theme.palette.globalcolors.submit
                                    }
                                    sx={{ p: 2 }}
                                    backgroundColor={alpha(
                                        theme.palette.globalcolors.cancel,
                                        0.1
                                    )}
                                >
                                    {confirmationText}
                                    <Grid
                                        xs={10}
                                        item
                                        id="submit-cancel-buttons-promo-code"
                                        sx={{ mt: 2, pt: 1, pb: 1 }}
                                    >
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            spacing={2}
                                            border={0}
                                        >
                                            <Grid
                                                item
                                                // border={1}
                                                sx={{ mt: 0, mb: 0 }}
                                                id="cancel-promo-code-btn-grid"
                                            >
                                                <CancelButton
                                                    id="cancel-button-promo-code"
                                                    valueString={`Cancel Creation of Promo Code`}
                                                    onClick={handleCancel}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                // border={1}
                                                sx={{ mt: 0, mb: 0, ml: 1 }}
                                                id="submit-promo-code-btn-grid"
                                            >
                                                <SubmitButton
                                                    id="submit-button-promo-code-textfield"
                                                    valueString={`Save New Promo Code`}
                                                    onClick={handleSubmit}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Fade>
                    )}
                </>
            )}
        </Grid>
    );
};

export default PromoCodeTextField;
