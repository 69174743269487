import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";

import { useSelector } from "store";
import { dispatch } from "store";
import {
  setIsLoggedIn,
  setLastLocation,
} from "store/slices/studioemployeeuser";

const Protected = ({ children, pagetogo }) => {
  const [user, loading] = useAuthState(auth);
  const { dibsId } = useParams();
  const navigate = useNavigate();
  const { hasSetPwdFirebase } = useSelector(
    (state) => state.studioemployeeuser,
  );
  const [hasAuthUser, setHasAuthUser] = useState(false);
  useEffect(() => {
    if (!user && !loading && !hasSetPwdFirebase)
      navigate(`/login-email-link/${dibsId}`);

    if (!user && !loading) navigate(`/login/${dibsId}`);
    if (user) setHasAuthUser(true);
    if (pagetogo && !user) {
      // dispatch(setLastLocation(`/${pagetogo}/${dibsId}`));
    }
    if (user) dispatch(setIsLoggedIn(true));
  }, [user, navigate, dibsId, loading, hasSetPwdFirebase, pagetogo]);

  if (hasAuthUser) {
    return children;
  }
};
export default Protected;
Protected.propTypes = {
  children: PropTypes.node,
};
