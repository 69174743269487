import axios from 'axios';
import { getApiUrl } from 'helpers';
import { dispatch } from 'store';
import {
    SET_EXCEPTIONS_DATA,
    SET_EXCEPTIONS_LOADING_TRUE,
    SET_EXCEPTIONS_LOADING_FALSE,
    SET_OPEN_EXCEPTION,
    FORGET_OPEN_EXCEPTION,
    SET_STUDIO_EVENT_NAMES,
    SET_STUDIO_INSTRUCTORS,
} from '../constants/ExceptionsConstants';
import { addNotice, clearNotice } from './NoticeActions';
import { addError, clearError } from './ErrorActions';
import log from '../helpers/log';

/**
 * setExceptionsData
 * @param {Array<Object>} data the exception data
 * @returns {Object} action on the state
 */
export function setExceptionsData(data) {
    return { type: SET_EXCEPTIONS_DATA, data };
}

/**
 * setExceptionsLoadingTrue
 * @returns {Object} action on the state
 */
export function setExceptionsLoadingTrue() {
    return { type: SET_EXCEPTIONS_LOADING_TRUE };
}

/**
 * setExceptionsLoadingFalse
 * @returns {Object} action on the state
 */
export function setExceptionsLoadingFalse() {
    return { type: SET_EXCEPTIONS_LOADING_FALSE };
}

/**
 * setOpenException
 * @param {Object} exception the exception being edited
 * @returns {Object} action on the state
 */
export function setOpenException(exception) {
    return { type: SET_OPEN_EXCEPTION, exception };
}

/**
 * forgetOpenException
 * @returns {Object} action on the state
 */
export function forgetOpenException() {
    return { type: FORGET_OPEN_EXCEPTION };
}

/**
 * setStudioEventNames
 * @param {Array<Object>} value array of objects with distinct event names
 * @returns {Object} action on the state
 */
export function setStudioEventNames(value) {
    return { type: SET_STUDIO_EVENT_NAMES, value };
}

/**
 * setStudioEventNames
 * @param {Array<string>} value array of objects with instructor data
 * @returns {Object} action on the state
 */
export function setStudioInstructors(value) {
    return { type: SET_STUDIO_INSTRUCTORS, value };
}

/**
 * requestExceptionsData from the server
 * @returns {function} redux thunk
 */
export function requestExceptionsData() {
    return function innerRequestExceptionsData(dispatch, getState) {
        if (getState().exceptions.loading) return;
        dispatch(setExceptionsLoadingTrue());
        axios
            .get('/exceptions')
            .then((res) => {
                if (res.success) {
                    dispatch(setExceptionsData(res.exceptions));
                    dispatch(setExceptionsLoadingFalse());
                } else {
                    if (res.err) log(res.err);
                    dispatch(addError(res.message));
                    dispatch(setExceptionsLoadingFalse());
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(addError('Failed to get pricing exceptions'));
                dispatch(setExceptionsLoadingFalse());
            });
    };
}

/**
 * requestStudioEventNames
 * @param {function} callback node style callback with single argument: error
 * @returns {function} redux thunk
 */
export function requestStudioEventNames(callback) {
    axios
        .get('/event-names')
        .then((res) => {
            if (res.success) {
                dispatch(setStudioEventNames(res.eventNames));
                return callback(null);
            } else {
                if (res.err) log(res.err);
                dispatch(addError(res.message));
                dispatch(setExceptionsLoadingFalse());
            }
        })
        .catch((err) => {
            console.log(err);
            dispatch(addError('Failed to get pricing exceptions'));
            dispatch(setExceptionsLoadingFalse());
        });
    return;
}

/**
 * requestStudioInstructors
 * @param {function} callback node style callback with single argument: error
 * @returns {function} redux thunk
 */
export function requestStudioInstructors(callback) {
    return function innerRequestStudioInstructors(dispatch) {
        axios
            .get('/instructors')
            .then((res) => {
                console.log('response from request studio instructors', res);
                if (res.success) {
                    dispatch(setStudioInstructors(res.instructors));
                    return callback(null);
                } else {
                    if (res.err) log(res.err);
                    return callback(new Error(res.message));
                }
            })
            .catch((err) => {
                console.log(err);
                return callback(err);
            });
    };
}

/**
 * requestStudioInstructors
 * @param {Object} ajaxParams the parameters of jQuery ajax
 * @param {function} callback node style callback with single argument: error
 * @returns {function} redux thunk
 */
export function submitException({ url, method, body }, callback) {
    return function innerSubmitException(dispatch) {
        dispatch(clearError());
        dispatch(clearNotice());
        axios.put(url, { method, data: body }).then((data) => {
            if (data) {
                if (data.success && method === 'PUT') {
                    dispatch(
                        addNotice('Successfully updated the pricing exception')
                    );
                    dispatch(forgetOpenException());
                    dispatch(requestExceptionsData());
                    return callback(null);
                } else if (data.success) {
                    dispatch(setOpenException(data.exception));
                    dispatch(requestExceptionsData());
                    return callback(null);
                }
            }
        });
    };
}

/**
 * deleteException
 * @param {number} exceptionId being restored
 * @param {function} callback when the request finishes
 * @returns {function} redux thunk deletes the exception
 */
export function deleteException(exceptionId, callback) {
    return function innerDeleteException(dispatch) {
        dispatch(clearError());
        dispatch(clearNotice());
        // $.ajax({
        //     method: 'DELETE',
        //     url: `/studios/api/exception/${exceptionId}`,
        //     success(data) {
        //         if (data.success) {
        //             dispatch(
        //                 addNotice(
        //                     'All set! That exception was removed. It’ll be listed below for 1 week if you change your mind before deleted.',
        //                 ),
        //             );
        //             dispatch(requestExceptionsData());
        //             callback(null);
        //         } else {
        //             if (data.err) log(data.err);
        //             dispatch(addError(data.message));
        //             callback(data);
        //         }
        //     },
        //     error(err) {
        //         console.log(err);
        //         dispatch(
        //             addError('Failed to deactivate the pricing exception'),
        //         );
        //         callback(err);
        //     },
        // });
    };
}

/**
 * reactivateException
 * @param {number} exceptionId being restored
 * @param {function} callback at the end
 * @returns {function} redux thunk makes request
 */
export function reactivateException(exceptionId, callback) {
    return function innerReactivateException(dispatch) {
        dispatch(clearError());
        dispatch(clearNotice());
        // $.ajax({
        //     method: 'PUT',
        //     url: `/studios/api/exception/restore/${exceptionId}`,
        //     success(data) {
        //         if (data.success) {
        //             dispatch(addNotice('All set! That exception is back up.'));
        //             dispatch(requestExceptionsData());
        //             callback(null);
        //         } else {
        //             if (data.err) log(data.err);
        //             dispatch(addError(data.message));
        //             callback(data);
        //         }
        //     },
        //     error(err) {
        //         console.log(err);
        //         dispatch(addError('Failed to restore the pricing exception'));
        //         callback(err);
        //     },
        // });
    };
}
