import axios from "axios";
import { getApiUrl } from "helpers";

export default async function setFirebasePwdUpdated(email, employeeId) {
  const url = getApiUrl("/employee/set-firebase-login-verified");
  let datatosend = {};
  const setlast = async () => {
    await axios
      .post(url, {
        email,
        employeeId,
      })
      .then((res) => {
        const { data } = res;
        datatosend = data;
      })
      .catch((err) => {
        console.log(`error setting last employee login: ${err}`);
        return err;
      });
  };
  await setlast();
  return datatosend;
}
