// third-party
import { FormattedMessage } from 'react-intl';
import FormattedMsg from './formatted-menu-msg';

// ==============================|| STUDIO ADMIN MENU ITEMS ||============================== //
// guide: studio admin left hand menu items

const studioAdminMenu = {
    id: 'studio-admin-menu',
    type: 'group',
    children: [
        // {
        //     id: 'dashboard',
        //     title: <FormattedMsg id="dashboard" defaultMessage="Dashboard" />,
        //     type: 'item',
        //     url: '/dashboard',
        //     // icon: icons.IconBrandChrome,
        //     breadcrumbs: false,
        // },

        {
            id: 'frontdesk',
            title: (
                <>
                    <FormattedMessage
                        id="front-desk"
                        defaultMessage="Front Desk 22"
                    />
                </>
            ),
            type: 'item',
            url: '/frontdesk',
            // url: '//studios/frontdesk',
            // icon: icons.IconSitemap
        },
        {
            id: 'reporting',
            title: (
                <FormattedMessage id="reporting" defaultMessage="Reporting" />
            ),
            type: 'item',
            url: '/reporting',
        },
        // {
        //     id: 'opportunities',
        //     title: <FormattedMessage id="opportunities" />,
        //     type: 'item',
        //     url: '//opportunities'
        //     // icon: icons.IconHelp
        // },
        {
            id: 'schedule',
            title: <FormattedMessage id="schedule" defaultMessage="Schedule" />,
            type: 'item',
            url: '/schedule',
        },
        {
            id: 'instructors',
            title: (
                <FormattedMessage
                    id="instructors"
                    defaultMessage="Instructors"
                />
            ),
            type: 'item',
            // url: '//studios/instructors',
            url: '/instructors',
        },
        // {
        //     id: 'payouts',
        //     title: <FormattedMsg id="payouts" defaultMessage="Payouts" />,
        //     type: 'item',
        //     // url: '//studios/payouts',
        //     url: '/payouts',
        // },
        {
            id: 'account',
            title: <FormattedMessage id="account" defaultMessage="Account" />,
            type: 'item',
            // url: '/studios/account',
            url: '/account',
        },
        {
            id: 'settings',
            title: <FormattedMessage id="settings" defaultMessage="Settings" />,
            type: 'item',
            // url: '/studios/settings',
            url: '/settings',
        },
    ],
};

export default studioAdminMenu;
