import { useState } from 'react';

// material-ui
import { Grid, Typography, Divider, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// project imports
import MembershipSearch from '../../sub-components/FrontDesk/createNewMembershipTextField';
import ExistingMemberships from '../../sub-components/FrontDesk/MembershipsPackages/ExistingMemberships';
import ExistingPackages from '../../sub-components/FrontDesk/MembershipsPackages/ExistingPackagesNew';

// ==============================|| SAMPLE PAGE ||============================== //

const newMembershipGuidance = `If you'd like to create a new type of membership or package, enter the name here.`;
const viewMembershipsGuidance = `Here is a list of your active memberships. Click on a row below to make view details and/or make edits.`;
const newPackageGuidance = `Here is a list of your active packages. Click on a row below to view more details and/or make edits.`;
const FrontDeskClients = () => {
    const [showPackages, setShowPackages] = useState(false);
    const [showMemberships, setShowMemberships] = useState(false);
    return (
        <Grid container direction="column">
            <Grid item xs={5} id="create-membership">
                <Typography gutterBottom variant="h4">
                    Create A New Membership or Package
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '8px' }}>
                <Typography gutterBottom variant="h7">
                    {newMembershipGuidance}
                </Typography>
            </Grid>
            <Grid item sx={{ marginTop: '40px' }} id="membership-search">
                <MembershipSearch />
            </Grid>
            <Grid
                item
                sx={{
                    marginTop:
                        showPackages || showMemberships ? '60px' : '25px',
                }}
            >
                <Divider variant="fullWidth" />
            </Grid>
            <Grid
                item
                sx={{
                    marginTop: showMemberships ? '60px' : '30px',
                    cursor: 'pointer',
                }}
                id="view-existing-memberships"
                onClick={() => {
                    setShowMemberships(!showMemberships);
                }}
            >
                <Stack direction="row" alignItems="center">
                    <Grid item border={0} sx={{ mr: 0.75 }}>
                        <Typography gutterBottom variant="h4">
                            View Your Existing Memberships
                        </Typography>
                    </Grid>
                    <Grid item>
                        {showMemberships ? (
                            <ExpandLessIcon color="secondary" />
                        ) : (
                            <ExpandMoreIcon color="secondary" />
                        )}
                    </Grid>
                </Stack>
            </Grid>

            {showMemberships && (
                <>
                    <Grid item sx={{ marginTop: '8px' }}>
                        <Typography gutterBottom variant="h7">
                            {viewMembershipsGuidance}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sx={{ marginTop: '45px', marginBottom: '25px' }}
                        id="existing-memberships-table"
                    >
                        <ExistingMemberships />
                    </Grid>
                </>
            )}

            <Grid item sx={{ marginTop: showMemberships ? '60px' : '30px' }}>
                <Divider variant="fullWidth" />
            </Grid>
            <Grid
                item
                sx={{
                    marginTop: showPackages ? '60px' : '30px',
                    cursor: 'pointer',
                    marginBottom: showPackages ? '1px' : '50px',
                }}
                onClick={() => {
                    setShowPackages(!showPackages);
                }}
                border={0}
            >
                <Stack direction="row" alignItems="center">
                    <Grid item border={0} sx={{ mr: 0.75 }}>
                        <Typography gutterBottom variant="h4">
                            View Your Existing Packages
                        </Typography>
                    </Grid>
                    <Grid item border={0} sx={{ mt: 0, pt: 0 }}>
                        {showPackages ? (
                            <ExpandLessIcon color="secondary" />
                        ) : (
                            <ExpandMoreIcon
                                color="secondary"
                                sx={{ mb: 0, mt: 0 }}
                            />
                        )}
                    </Grid>
                </Stack>
            </Grid>
            {showPackages && (
                <>
                    <Grid item sx={{ marginTop: '8px' }}>
                        <Typography gutterBottom variant="h7">
                            {newPackageGuidance}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ marginTop: '45px', marginBottom: '25px' }}>
                        <ExistingPackages />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default FrontDeskClients;
