// SearchContext.js
import React, { createContext, useContext, useState } from 'react';

const SearchContext = createContext();
const initialShowSearchValue = false;

export function useSearch() {
    return useContext(SearchContext);
}

export function SearchProvider({ children }) {
    const [showSearch, setShowSearch] = useState(initialShowSearchValue);

    return (
        <SearchContext.Provider value={{ showSearch, setShowSearch }}>
            {children}
        </SearchContext.Provider>
    );
}
