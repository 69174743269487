import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
// material-ui
import { Grid, Typography, Divider, Button, Fade } from '@mui/material';
import { capitalizeString } from 'helpers';
import CreateNewAppt from 'components/appointments/create-new';

// project imports

import ExistingAppointments from 'components/sub-components/FrontDesk/Appointments/ExistingAppointments';
import PlusIcon from 'assets/icons/plusIcon';
import translate from 'helpers/translate';

import { capitalizeEachWord } from 'helpers';
import { useSearch } from 'routes/searchContext';

// ==============================|| SAMPLE PAGE ||============================== //

const FrontDeskClassesOrAppts = ({ altClassesText, classAltName }) => {
    const intl = useIntl();
    const { setShowSearch } = useSearch();
    const [showCreateNewAppt, setShowCreateNewAppt] = useState(true);
    const aAn = altClassesText.charAt(0) === 'a' ? 'an' : 'a';
    const newAccountGuidance = `Here is a list of the ${classAltName} types that you've already created. Click on ${aAn} ${classAltName} type below to make edits.`;
    const newAccountGuidanceIt = `Ecco un elenco dei tipi di appuntamento che hai già creato. Fare clic su un tipo di appuntamento di seguito per apportare modifiche.`;
    const titleHead = `View Your Existing ${capitalizeString(
        classAltName
    )} Types`;
    let accGuidance = newAccountGuidance;
    if (intl.locale === 'it') {
        accGuidance = newAccountGuidanceIt;
    }
    const titleHeadT = capitalizeEachWord(
        translate({
            id: 'viewexistingappts',
            msg: titleHead,
        })
    );
    useEffect(() => {
        setShowSearch(false);
    }, [setShowSearch]);

    const showAppts = classAltName === 'appointment' ? true : false;
    const handleClickAddAppt = () => {
        setShowCreateNewAppt(!showCreateNewAppt);
    };
    const titletouse = `Create A New ${capitalizeString(classAltName)} Type`;
    const titleT = translate({ id: 'createappt', msg: titletouse });
    return (
        <Grid container direction="column">
            {!showCreateNewAppt && (
                <Fade in={!showCreateNewAppt} timeout={500}>
                    <Grid item sx={{ marginTop: '10px' }}>
                        <Grid container>
                            <Button
                                variant="checkoutinverse"
                                onClick={handleClickAddAppt}
                            >
                                <PlusIcon />
                                <Typography
                                    gutterBottom
                                    variant="h7"
                                    sx={{ ml: 1, mb: 0 }}
                                >
                                    {titleT}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Fade>
            )}
            {showCreateNewAppt && (
                <Fade in={showCreateNewAppt} timeout={500}>
                    <Grid item sx={{ ml: 0 }}>
                        <CreateNewAppt
                            setShowCreateNewAppt={setShowCreateNewAppt}
                        />
                    </Grid>
                </Fade>
            )}

            <Divider
                sx={{
                    marginTop: '40px',
                    marginBottom: '20px',
                }}
            />
            <Grid item sx={{ marginTop: '10px' }}>
                <Typography gutterBottom variant="h4">
                    {titleHeadT}
                </Typography>
            </Grid>
            <Grid item sx={{ marginTop: '8px' }} id="new-account-guidance">
                <Typography gutterBottom variant="h7">
                    {accGuidance}
                </Typography>
            </Grid>
            {showAppts && (
                <Grid
                    item
                    sx={{ marginTop: '45px', marginBottom: '50px' }}
                    id="existing-appts"
                >
                    <ExistingAppointments />
                </Grid>
            )}
        </Grid>
    );
};

export default FrontDeskClassesOrAppts;
