import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function BasicDateTimePicker({
    label,
    onChange,
    idtouse,
    // placeholderValue,
    value,
}) {
    const placeholderDate = dayjs().add(1, 'month');

    const placeholdervalue = dayjs(value);
    let phtouse;
    if (value) {
        phtouse = placeholdervalue;
    } else {
        phtouse = placeholderDate;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={label}
                onChange={onChange}
                id={idtouse}
                // timezone="UTC"
                placeholder={phtouse}
                value={phtouse}
                sx={{
                    input: {
                        '&.MuiOutlinedInput-input': {
                            padding: '7px !important',
                            fontSize: '12px',
                        },
                    },
                    '& .MuiIconButton-root': {
                        paddingRight: '1px !important',
                        paddingLeft: '0px !important',
                    },
                    '& .MuiInputAdornment-root': {
                        marginLeft: '0px !important',
                    },
                }}
            />
        </LocalizationProvider>
    );
}
