export const getChipTypes = (theme) => {
    const typesofchips = [
        {
            id: 0,
            value: 'first-purchase-only',
            chipType: 'First Purchase Only',
            color: '#fff',
            bgcolor: theme.palette.chart.light,
            hoverColor: theme.palette.chart.light,
            textWhenNotSelected: theme.palette.chips.blueUnselectedText,
        },
        {
            id: 1,
            value: 'front-desk-only',
            chipType: 'Front Desk Only',
            color: '#fff',
            bgcolor: theme.palette.chips.main,
            hoverColor: theme.palette.chips.main,
            textWhenNotSelected: theme.palette.chips.mainUnselectedText,
        },
        {
            id: 2,
            value: 'private',
            chipType: 'Private',
            color: '#fff',
            bgcolor: theme.palette.chips.private,
            hoverColor: theme.palette.chips.private,
        },
    ];
    return typesofchips;
};
