import {
  SET_PROMO_CODES_DATA,
  SET_PROMO_CODES_LOADING_TRUE,
  SET_PROMO_CODES_LOADING_FALSE,
  SET_PROMO_CODES_QUERY,
  FORGET_PROMO_CODES_QUERY,
  SET_OPEN_PROMO_CODE,
  FORGET_OPEN_PROMO_CODE,
} from "../constants/PromoCodesConstants";
import { addError } from "./ErrorActions";
import log from "../helpers/log";

/**
 * setPromoCodesData
 * @param {Array<Object>} promoCodes array of promo code objects
 * @returns {Object} action on the state
 */
export function setPromoCodesData(promoCodes) {
  return { type: SET_PROMO_CODES_DATA, promoCodes };
}

/**
 * setPromoCodesLoadingTrue
 * @returns {Object} action on the state
 */
export function setPromoCodesLoadingTrue() {
  return { type: SET_PROMO_CODES_LOADING_TRUE };
}

/**
 * setPromoCodesLoadingFalse
 * @returns {Object} action on the state
 */
export function setPromoCodesLoadingFalse() {
  return { type: SET_PROMO_CODES_LOADING_FALSE };
}

/**
 * setPromoCodesQuery
 * @param {string} query the search query
 * @returns {Object} action on the state
 */
export function setPromoCodesQuery(query) {
  return { type: SET_PROMO_CODES_QUERY, query: query.toUpperCase() };
}

/**
 * forgetPromoCodesQuery
 * @returns {Object} action on the state
 */
export function forgetPromoCodesQuery() {
  return { type: FORGET_PROMO_CODES_QUERY };
}

/**
 * setOpenPromoCode
 * @param {Object} promoCode to open
 * @returns {Object} action on the state
 */
export function setOpenPromoCode(promoCode) {
  return { type: SET_OPEN_PROMO_CODE, promoCode };
}

/**
 * forgetOpenPromoCode
 * @returns {Object} action on the state
 */
export function forgetOpenPromoCode() {
  return { type: FORGET_OPEN_PROMO_CODE };
}

/**
 * @returns {function} redux thhunk
 */
export function requestPromoCodesData() {
  return function innerRequestPromoCodesData(dispatch, getState) {
    if (getState().promoCodes.loading) return;
    dispatch(setPromoCodesLoadingTrue());
    // $.ajax({
    //   url: '/studios/api/promo-codes',
    //   method: 'GET',
    //   success({ success, promoCodes, err, message }) {
    //     if (success) {
    //       dispatch(setPromoCodesData(promoCodes));
    //       dispatch(setPromoCodesLoadingFalse());
    //     } else {
    //       if (err) log(err);
    //       dispatch(addError(message));
    //     }
    //   },
    //   error(err) {
    //     console.log(err);
    //     dispatch(addError('Failed to get your studio\'s promo codes'));
    //   },
    //   complete() {
    //     dispatch(setPromoCodesLoadingFalse());
    //   },
    // });
  };
}
