import { useNavigate, useParams } from 'react-router-dom';

// material-ui
import { styled } from '@mui/material/styles';
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Typography,
} from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

import { logout } from 'firebaseConfig';

// styles

const ErrorWrapper = styled('div')({
    maxWidth: 750,
    margin: '1 auto',
    textAlign: 'center',
});

const ErrorCard = styled(Card)({
    minHeight: '75vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    disableElevation: true,
    boxShadow: 'none',
});

// ==============================|| ERROR PAGE ||============================== //

const Error = () => {
    let { dibsId } = useParams();
    const navigate = useNavigate();
    const handleReturnToLogin = async () => {
        console.log('returning to login from error page');
        const dibsIdToUse = window.localStorage.getItem('dibsId');
        window.localStorage.removeItem('intentType');
        window.localStorage.removeItem('dibs-instructors');
        window.localStorage.removeItem('intentStatus');
        window.localStorage.removeItem('stripeReady');
        window.localStorage.removeItem('persist:studioemployeeuser');
        await logout().then(() => {
            if (dibsIdToUse) navigate(`/login/${dibsIdToUse}`);
            else navigate(`/login/${dibsId}`);
        });
    };

    return (
        <ErrorCard>
            <CardContent>
                <Grid container justifyContent="center" spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <ErrorWrapper>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Typography variant="h2" component="div">
                                        Something went wrong
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        The page you are looking for was moved,
                                        removed, renamed, or might have never
                                        existed!{' '}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        Click below to reset the page and login
                                        again.
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <AnimateButton>
                                        <Button
                                            variant="contained"
                                            size="large"
                                            sx={{
                                                bgcolor: '#7e92a3',
                                                ':hover': {
                                                    bgcolor: '#7e92a3',
                                                },
                                            }}
                                            onClick={handleReturnToLogin}
                                        >
                                            Go Back To Login Page
                                        </Button>
                                    </AnimateButton>
                                </Grid>
                            </Grid>
                        </ErrorWrapper>
                    </Grid>
                </Grid>
            </CardContent>
        </ErrorCard>
    );
};

export default Error;
