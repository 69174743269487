import React from "react";
import PropTypes from "prop-types";
/**
 * SearchIcon Stateless Functional Component
 * @returns {JSX.Element} HTML
 */
function SearchIcon({ stroke, fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
      <path
        d="M14,13c0-0.3-0.1-0.5-0.3-0.7L11,9.6c0.6-0.9,1-2,1-3.1C12,3.5,9.5,1,6.5,1S1,3.5,1,6.5S3.5,12,6.5,12c1.1,0,2.2-0.3,3.1-1l2.7,2.7c0.2,0.2,0.4,0.3,0.7,0.3C13.5,14,14,13.5,14,13z M10,6.5C10,8.4,8.4,10,6.5,10S3,8.4,3,6.5S4.6,3,6.5,3S10,4.6,10,6.5z"
        stroke={stroke}
        fill={fill}
      />
    </svg>
  );
}

SearchIcon.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
};

export default SearchIcon;
