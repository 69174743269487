import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import NextButton from 'components/button/nextButton';
import { Grid } from '@mui/material';

import KeyAccordianInfo from 'ui-component/accordians/InnerAccordianInfo';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    // borderRadius: '5px',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .08)'
            : 'rgba(0, 0, 0, .025)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const StandardAccordian = (props) => {
    // Add your component logic here
    const {
        expanded,
        handleChange,
        handleNextQuestion,
        actionFunction,
        title,
        id,
        ques,
        panelInfo,
        setTrueFalseFields,
        extraAction,
        dropdownWidth,
        questionCriteria,
        beingCreatedInfo,
        multiCriteria,
    } = props;

    const nextPanel = `panel${ques?.nextQuestion}`;

    return (
        <Accordion
            key={`${id}-accordian`}
            expanded={expanded === panelInfo}
            onChange={handleChange(panelInfo)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${panelInfo}bh-content-membership-package`}
                id={`panel${id}bh-header-new-member-pack`}
            >
                <Typography variant="h5" sx={{ flexShrink: 0 }}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <KeyAccordianInfo
                    actionFunction={actionFunction}
                    ques={ques}
                    extraAction={extraAction}
                    dropdownWidth={dropdownWidth}
                    questionCriteria={questionCriteria}
                    keytouse={id}
                    beingCreatedInfo={beingCreatedInfo}
                    setTrueFalseFields={setTrueFalseFields}
                    multiCriteria={multiCriteria}
                />
                <Grid item xs={12} sx={{ mt: 3, mb: 2, ml: 2 }}>
                    <NextButton
                        id={`next-button-${id}`}
                        valueString="Next"
                        handleClick={handleNextQuestion}
                        pos1={panelInfo}
                        pos2={nextPanel}
                    />
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default StandardAccordian;
