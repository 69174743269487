import axios from 'axios';
import { getApiUrl } from 'helpers';
// this sends cancellation emails to multiple users

export default async function sendCancelSmss(attendeeList, eventIdEditing) {
    const url = getApiUrl('/twilio/send-update');
    let response;
    await axios
        .post(url, { attendeeList, eventIdEditing })
        .then((res) => {
            const { data } = res;
            response = data;
        })
        .catch((error) => {
            console.log(
                'there was an error - update multi cancellation sms twilio',
                error
            );
        });
    return response;
}
