import PropTypes from "prop-types";
import { useMemo } from "react";

// material-ui
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// project import
// import useConfig from 'hooks/useConfig';
import Palette from "./palette";
import Typography from "./typography";

import componentStyleOverrides from "./compStyleOverride";
import customShadows from "./shadows";

import configInfo from "config";

export default function ThemeCustomization() {
  const {
    borderRadius,
    fontFamily,
    navType,
    outlinedFilled,
    presetColor,
    rtlLayout,
  } = configInfo;

  // const theme = useMemo(
  //     () => Palette(navType, presetColor),
  //     [navType, presetColor],
  // );
  const theme = Palette();
  // console.log(
  //     'resulting theme after palette is',
  //     JSON.stringify(theme, null, 4),
  // );

  const themeAfterTypography = Typography(theme, borderRadius, fontFamily);
  const themeAfterComponents = componentStyleOverrides(
    themeAfterTypography,
    borderRadius,
    true,
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const themeTypography = useMemo(
  //     () => Typography(theme, borderRadius, fontFamily),
  //     [theme, borderRadius, fontFamily],
  // );

  const themeAfterCustomShadows = customShadows(themeAfterComponents);
  const newThemePostOptions = createTheme({
    ...themeAfterCustomShadows,
    direction: rtlLayout ? "rtl" : "ltr",
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
  });

  return newThemePostOptions;

  // return (
  //     <StyledEngineProvider injectFirst>
  //         <ThemeProvider theme={themeagain}>
  //             <CssBaseline />
  //             {children}
  //         </ThemeProvider>
  //     </StyledEngineProvider>
  // );
}
