import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { InputAdornment } from '@mui/material';

const StyledTextField = styled(TextField)({
    '& input': {
        background: 'transparent !important',
        paddingLeft: '8px !important',
        paddingTop: '4px !important',
        paddingBottom: '1px !important',
    },
    '& .MuiFormHelperText-root': {
        fontSize: '0.65rem',
        marginLeft: '2px',
        lineHeight: '0.75rem',
    },
    '& .MuiInputAdornment-root': {
        marginRight: '0px',
        marginBottom: '3px',
    },

    '& div': {
        background: 'transparent !important',
        // paddingLeft: '2px !important',
        paddingTop: '4px !important',
        paddingBottom: '0px !important',
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:before:hover': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#c96248',
    },
});

export default function BasicTextFields({
    idtouse,
    helperText,
    onChangeAction,
    hasError,
    boxWidth,
    value,
    showDollarSign,
    showPercentageSign,
}) {
    const handleFocus = (e) => {
        // e.target.select();
        onChangeAction('');
    };
    const dollarSign = (
        <InputAdornment position="start">
            <Box sx={{ ml: 0.5, mr: 0.5 }}>$</Box>
        </InputAdornment>
    );
    const percentSign = (
        <InputAdornment position="end">
            <Box sx={{ ml: 1.75, mr: 0.75, pl: 0.5 }}>%</Box>
        </InputAdornment>
    );

    let inputpropsTu = null;
    if (showDollarSign) {
        inputpropsTu = { startAdornment: dollarSign };
    } else if (showPercentageSign) {
        inputpropsTu = { endAdornment: percentSign };
    }
    return (
        <Box width={boxWidth || 100}>
            <StyledTextField
                id={idtouse}
                variant="standard"
                value={value}
                helperText={helperText}
                onChange={(e) => {
                    onChangeAction(e.target.value);
                }}
                onFocus={handleFocus}
                error={hasError}
                fullWidth
                InputProps={inputpropsTu}
            />
        </Box>
    );
}
