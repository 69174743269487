import axios from 'axios';
import { getApiUrl } from 'helpers';
import addUserToAppt from '../appointments/addUserToAppt';
import sendConfirmationEmail from 'actions/emails/send-confirmation-appt';

export default async function updateExistingEvent(
    apptToPass,
    eventid,
    sendEmailConfirmation,
    fromLocation
) {
    const { itemsChanged } = apptToPass;
    const url = getApiUrl('/update-existing-event');
    const includesClient = itemsChanged.includes('client');
    const datatosend = {
        appttoedit: {
            apptToPass,
            eventid,
            itemsChanged,
            fromLocation,
        },
    };
    await axios.post(url, datatosend);

    // CLIENT CHANGED
    if (includesClient) {
        await addUserToAppt(apptToPass)
            .then((res) => {
                if (sendEmailConfirmation) {
                    // send email confirmation
                    sendConfirmationEmail(datatosend)
                        .then((res) => {
                            return res;
                        })
                        .catch((error) => {
                            console.log(
                                'error sending email confirmation',
                                error
                            );
                        });
                }
                return res;
            })
            .catch((error) => {
                console.log('error adding user to the appt', error);
            });
    }
    return datatosend;
}
