import { Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CheckCircleIconComponent = () => {
    return (
        <Grid item sx={{ mr: 2, ml: 1 }}>
            <span className="fa-layers fa-fw">
                <CheckCircleIcon color="success" sx={{ fontSize: 13 }} />
            </span>
        </Grid>
    );
};
export default CheckCircleIconComponent;
