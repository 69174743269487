import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// import currencies from 'currency/currencies';
import styled, { css } from "styled-components";
import "./styles.scss";

import { Tooltip as ReactTooltip } from "react-tooltip";
import InfoIcon from "../../graphics/Icons/InfoIcon";

/**
 * InputWithTip Stateless Functional Component
 * @param {Object} props Component props
 * @returns {JSX.Element} HTML
 */
function InputWithTip({
  className = "",
  name,
  label,
  placeholder = "",
  value,
  onChange,
  disabled,
  type = "text",
  invalid = false,
  inputRef,
  autoComplete,
  required,
  currency,
  zoomtip,
  tipid,
}) {
  let currentCurrency;
  if (currency) {
    // currentCurrency = currencies.find((f) => f.code === currency) || {
    //     symbolOnLeft: true,
    //     symbol: '$',
    // };
    currentCurrency = {
      symbolOnLeft: true,
      symbol: "$",
    };
  }

  const formattedInput = (
    <FormattedInput
      currency={currentCurrency}
      ref={inputRef && ((node) => inputRef(node))}
      className={classNames(
        "dibs-highlight-border",
        "dibs-font",
        `${className}`,
        invalid ? "invalid" : "valid",
      )}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      value={value}
      disabled={disabled}
      autoComplete={autoComplete}
      required={required}
    />
  );

  const input = disabled ? (
    <span className="dibs-font">{value || placeholder}</span>
  ) : currency ? (
    <CurrencyInput reverse={!currentCurrency.symbolOnLeft}>
      <label htmlFor={name}>{currentCurrency.symbol}</label>
      {formattedInput}
    </CurrencyInput>
  ) : (
    formattedInput
  );

  return (
    <div className={`input-field-container ${className}`}>
      <div className="input-field-label dibs-font add-space-to-right">
        {label}&nbsp;&nbsp;
        <InfoIcon data-tip data-for={tipid} />
        <ReactTooltip
          id={tipid}
          place="top"
          effect="solid"
          className="roster-table__tool-tip"
        >
          {zoomtip}
        </ReactTooltip>
      </div>
      {input}
    </div>
  );
}

InputWithTip.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  type: PropTypes.string,
  invalid: PropTypes.bool,
  inputRef: PropTypes.func,
  currency: PropTypes.shape({
    symbol: PropTypes.string,
  }),
};

export const InputWithTipPropTypes = InputWithTip.propTypes;

const CurrencyInput = styled.div`
  display: flex;
  ${({ reverse }) => reverse && "flex-direction: row-reverse;"}
  label {
    margin-right: auto;
    padding: 3px;
  }
`;
const FormattedInput = styled.input``;
export default InputWithTip;
