import React from "react";
import PropTypes from "prop-types";

/**
 * @class RosePlusIcon
 * @extends {React.PureComponent}
 */
class RosePlusIcon extends React.PureComponent {
  /**
   * render
   * @returns {JSX.Element} HTML
   */
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          d="M12 2v19.856M2 12h19.963"
          fill="none"
          stroke="#CC6B53"
          strokeLinecap="round"
          strokeWidth="3"
        />
      </svg>
    );
  }
}

RosePlusIcon.propTypes = {};

export default RosePlusIcon;
