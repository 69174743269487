import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Promise from 'bluebird';
import {
    hideTutorialChildren,
    showTutorialChildren,
    setTutorialStep,
    startTutorialScroll,
    completeTutorialScroll,
    closeTutorial,
} from '../../actions/TutorialActions';
import welcomeIcon from 'assets/images/dashboard_tutorial_svgs/welcome.svg';
import lightbulbIcon from 'assets/images/dashboard_tutorial_svgs/lightbulb.svg';
import boltIcon from 'assets/images/dashboard_tutorial_svgs/bolt.svg';
import thumbsUpIcon from 'assets/images/dashboard_tutorial_svgs/thumbsup.svg';
import '../../styles/tutorial-controller-modal.scss';

const icons = [
    <img alt="Welcome Icon" src={welcomeIcon} />,
    <img alt="Welcome Icon" src={lightbulbIcon} />,
    <img alt="Welcome Icon" src={boltIcon} />,
    <img alt="Welcome Icon" src={thumbsUpIcon} />,
];

const modalContent = [
    'Welcome to the Dibs data dashboard. Here are three things you need to know...',
    'This is how much Dibs customers spend relative to others who first visited your studio around the same time. We use this ratio to determine incremental spend from the transactions that come through Dibs.',
    "Dibs Flash Credits are designed to strategically spur bookings from the right customers. Here you'll find the total revenue generated from Flash Credits, and more detailed stats below.",
    "We're regularly adding and updating what's included in your dashboard. Questions? Something you'd like to see? Let us know here.",
];

/**
 * ControllerModal React Component
 * @extends {module:React.PureComponent}
 * @returns {JSX.Element} HTML
 */
class ControllerModal extends React.PureComponent {
    /**
     * constructor
     * @constructs ControllerModal
     * @param {Object} props Component props
     */
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            containerStyle: null,
            renderLeftTail: false,
            renderBottomTail: false,
            renderTopTail: false,
        };
        this.completeTutorial = this.completeTutorial.bind(this);
        this.movePosition = this.movePosition.bind(this);
        this.scrollToNode = this.scrollToNode.bind(this);
        this.transitionToNextStep = this.transitionToNextStep.bind(this);
    }
    /**
     * completeTutorial
     * @returns {Promise<undefined>} animation removes elements, scrolls to top
     */
    completeTutorial() {
        return new Promise((resolve) => {
            this.props.hideTutorialChildren();
            setTimeout(resolve, 100);
        })
            .then(
                () =>
                    new Promise((resolve) => {
                        '#app-content'.animate({ scrollTop: 0 }, 200, resolve);
                    })
            )
            .then(() => this.props.closeTutorial());
    }
    /**
     * movePosition positions modal to point to highlighted node
     * @param {function} resolve the outer Promise
     * @returns {undefined}
     */
    movePosition(resolve) {
        const originalNode = document.getElementById(
            `tutorial-highlight-${this.props.currentStep}`
        );
        const cloneContainerNode = document.getElementById(
            'tutorial-node-clone-container'
        );
        const $node = originalNode;
        const $cloneContainer = cloneContainerNode;
        const horizontalOffset = $node.offset().left + $node.width() + 6;
        let newState;

        if (this.props.currentStep === 3) {
            newState = {
                containerStyle: {
                    left: `${
                        $cloneContainer.offset().left +
                        $cloneContainer.width() / 2 +
                        -125
                    }px`,
                    bottom: `${window.innerHeight - $cloneContainer.offset().top + 25}px`,
                },
                renderLeftTail: false,
                renderTopTail: false,
                renderBottomTail: true,
            };
        } else if (
            window.innerWidth >= horizontalOffset + 400 &&
            this.container.height() + $cloneContainer.offset().top <
                window.innerHeight - 50
        ) {
            newState = {
                containerStyle: {
                    left: `${
                        $cloneContainer.offset().left +
                        $cloneContainer.width() +
                        25
                    }px`,
                    top: `${$cloneContainer.offset().top}px`,
                },
                renderLeftTail: true,
                renderTopTail: false,
                renderBottomTail: false,
            };
        } else {
            newState = {
                containerStyle: {
                    left: `${
                        $cloneContainer.offset().left +
                        $cloneContainer.width() / 2 +
                        -125
                    }px`,
                    top: `${
                        $cloneContainer.offset().top +
                        $cloneContainer.height() +
                        25
                    }px`,
                },
                renderLeftTail: false,
                renderTopTail: true,
                renderBottomTail: false,
            };
        }

        if (resolve) this.setState(newState, resolve);
        else this.setState(newState);
    }
    /**
     * scrollToNode
     * @param {function} resolve the outer Promise
     * @returns {undefined}
     */
    scrollToNode(resolve) {
        const node = document.getElementById(
            `tutorial-highlight-${this.props.currentStep}`
        );
        const { top } = node.offset();
        console.log('scroll to node is being hit here');
        let scrollTop = Math.min(
            Math.max(top - 100, 0),
            document.getElementById('app-content').scrollHeight -
                window.innerHeight
        );
        if (this.props.currentStep === 3)
            scrollTop =
                document.getElementById('app-content').scrollHeight -
                window.innerHeight;
        if (window.innerWidth < 767 && this.props.currentStep === 3)
            scrollTop += 100;
        this.props.startTutorialScroll();
        '#app-content'.animate({ scrollTop }, 200, 'linear', () => {
            setTimeout(() => {
                this.props.completeTutorialScroll();
                resolve();
            }, 100);
        });
    }
    /**
     * transitionToNextStep
     * @returns {Promise<undefined>} transition to next step, compound animation
     */
    transitionToNextStep() {
        if (this.state.clicked) return Promise.resolve();
        if (this.props.currentStep === 3) {
            return this.completeTutorial();
        }

        this.state.clicked = true;
        this.props.hideTutorialChildren();

        return new Promise((resolve) => {
            setTimeout(() => {
                this.props.setTutorialStep(this.props.currentStep + 1);
                resolve();
            }, 100);
        })
            .then(() => new Promise((resolve) => this.scrollToNode(resolve)))
            .then(() => new Promise((resolve) => this.movePosition(resolve)))
            .then(() => {
                this.props.showTutorialChildren();
                this.state.clicked = false;
            });
    }
    /**
     * render
     * @returns {JSX.Element} HTML
     */
    render() {
        return (
            <div
                ref={(node) => (this.container = node)}
                className={`tutorial-controller-modal ${
                    this.props.visible ? 'visible' : 'hidden'
                } ${this.props.currentStep === 0 ? 'first-step' : ''}`}
                style={this.state.containerStyle || {}}
            >
                {this.state.renderLeftTail && (
                    <svg
                        viewBox="0 0 30 33"
                        preserveAspectRatio="none"
                        className="left-tail"
                    >
                        <path d="M 30 3 L 5 18 L 30 33 Z" className="shadow" />
                        <path d="M 30 0 L 5 15 L 30 30 Z" />
                    </svg>
                )}
                {this.state.renderBottomTail && (
                    <svg
                        viewBox="0 0 30 30"
                        preserveAspectRatio="none"
                        className="bottom-tail"
                    >
                        <path d="M 0 0 L 15 28 L 30 0" className="shadow" />
                        <path d="M 0 0 L 15 25 L 30 0 Z" />
                    </svg>
                )}
                {this.state.renderTopTail && (
                    <svg
                        viewBox="0 0 30 30"
                        preserveAspectRatio="none"
                        className="top-tail"
                    >
                        <path d="M 0 30 L 15 5 L 30 30 Z" />
                    </svg>
                )}
                <div className="tutorial-controller-modal-heading">
                    {this.props.currentStep
                        ? `#${this.props.currentStep}`
                        : 'Welcome!'}
                    {icons[this.props.currentStep]}
                </div>
                <div className="tutorial-controller-modal-content">
                    {modalContent[this.props.currentStep]}
                </div>
                <div className="tutorial-controller-modal-button-container">
                    <button onClick={this.transitionToNextStep}>
                        {this.props.currentStep === 3 ? 'DONE' : 'NEXT'}
                    </button>
                </div>
            </div>
        );
    }
}

ControllerModal.propTypes = {
    currentStep: PropTypes.number,
    visible: PropTypes.bool,
    hideTutorialChildren: PropTypes.func,
    showTutorialChildren: PropTypes.func,
    setTutorialStep: PropTypes.func,
    startTutorialScroll: PropTypes.func,
    completeTutorialScroll: PropTypes.func,
    closeTutorial: PropTypes.func,
};

const mapStateToProps = ({ tutorial }) => ({
    ...tutorial,
    visible: true,
});

const mapDispatchToProps = {
    hideTutorialChildren,
    showTutorialChildren,
    setTutorialStep,
    startTutorialScroll,
    completeTutorialScroll,
    closeTutorial,
};

const SmartControllerModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ControllerModal);

export default SmartControllerModal;
