import routeReplacer from "../../shared/helpers/route-replace-helper";

export const ACCOUNT_ROUTE = "/account";

export * from "./RouteConstants/ClientRouteConstants";

export const DETAILED_PAYOUTS_ROUTE = "/payouts/:payoutIndex";
export const GET_DETAILED_PAYOUTS_ROUTE = (payoutIndex) =>
  `/payouts/${payoutIndex}`;

export const VIEW_ROSTER_ROUTE = "/schedule/roster/:eventid";
export const GET_VIEW_ROSTER_ROUTE = (eventid) => `/schedule/roster/${eventid}`;

export const SPOT_BOOKING_ROSTER_ROUTE = "/schedule/roster/:eventid/spots";
export const GET_SPOT_BOOKING_ROSTER_ROUTE = (eventid) =>
  routeReplacer(SPOT_BOOKING_ROSTER_ROUTE, {
    ":eventid": eventid,
  });

export const EDIT_EVENT_ROUTE = "/schedule/edit/:eventid";
export const GET_EDIT_EVENT_ROUTE = (eventid) => `/schedule/edit/${eventid}`;
export const ADD_EVENT_ROUTE = "/schedule/add";

export const EMPLOYEES_ROUTE = "/employees";
export const EMPLOYEE_CONFRIMATION_ROUTE = "/employees/confirmation";

export const ONBOARDING_ROUTE = "/onboarding";
export const DASHBOARD_ROUTE = "/dashboard";
export const REPORTING_ROUTE = "/reporting";
export const FRONT_DESK_ROUTE = "/front-desk";
export const STAFF_ROUTE = "/staff";
export const DISABLED_STAFF_ROUTE = "/staff/disabled";
export const INDEX_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const LOGOUT_ROUTE = "/logout";
export const RESET_PW_ROUTE = "/reset-password";
export const TRANSACTIONS_ROUTE = "/transactions";
export const STUDIO_SETTINGS_ROUTE = "/settings";
export const EXCEPTION_HISTORY_ROUTE = "/exceptions/history";
export const EDIT_EXCEPTION_ROUTE = "/exception/edit";
export const NEW_EXCEPTION_ROUTE = "/exception/new";
export const EXCEPTION_CONFIRMATION_ROUTE = "/exception/confirm";
export const PROMO_CODE_HISTORY_ROUTE = "/promo-codes/history";
export const EDIT_PROMO_CODE_ROUTE = "/promo-code/edit";
export const EDIT_INSTRUCTOR_ROUTE = "/instructor/edit";
export const EDIT_RETAIL_ROUTE = "/retail/edit";
export const PACKAGES_HISTORY_ROUTE = "/packages/history";
export const VIEW_PACKAGES_ROUTE = "/packages";
export const NEW_PACKAGE_ROUTE = "/packages/new";
export const EDIT_PACKAGE_ROUTE = "/package/edit";
export const PAYMENT_SUMMARY_ROUTE = "/summary";
export const PAYOUTS_SUMMARY_ROUTE = "/payouts";

export const CLASS_SCHEDULE_ROUTE = "/schedule";
export const VIDEOS_HISTORY_ROUTE = "/videos/history";
