import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  ONBOARDING_ROUTE,
  DASHBOARD_ROUTE,
  ACCOUNT_ROUTE,
  STUDIO_SETTINGS_ROUTE,
  FRONT_DESK_ROUTE,
  STAFF_ROUTE,
  PAYMENT_SUMMARY_ROUTE,
  REPORTING_ROUTE,
  PAYOUTS_SUMMARY_ROUTE,
  CLASS_SCHEDULE_ROUTE,
} from "../../constants/RouteConstants";
import {
  getUserStudioFrontDesk,
  getUserStudioHasPayouts,
  getUserIsAdmin,
  getUserStudioDibsId,
  getUserStudioStripeAccountId,
  getUserStudioLive,
} from "../../selectors/user";
import { logout } from "../../actions/UserActions";

import BurgerMenuBase from "shared/components/BurgerMenu";

/**
 * @class BurgerMenu
 * @extends {React.PureComponent}
 */
class BurgerMenu extends React.PureComponent {
  /**
   * render
   * @returns {JSX.Element} HTML
   */
  render() {
    return (
      <BurgerMenuBase>
        <Link to={DASHBOARD_ROUTE}>Dashboard</Link>
        {this.props.userIsAdmin &&
          (!this.props.stripeAccountId || !this.props.studioIsLive) && (
            <Link to={ONBOARDING_ROUTE}>Onboarding</Link>
          )}
        {this.props.userIsAdmin && <Link to={REPORTING_ROUTE}>Reporting</Link>}
        {this.props.frontDesk && <Link to={FRONT_DESK_ROUTE}>Front Desk</Link>}
        {this.props.frontDesk && (
          <Link to={CLASS_SCHEDULE_ROUTE}>Class Schedule</Link>
        )}
        {this.props.userIsAdmin && this.props.hasPayouts && (
          <Link to={PAYOUTS_SUMMARY_ROUTE}>Payouts</Link>
        )}
        {this.props.userIsAdmin && !this.props.hasPayouts && (
          <Link to={PAYMENT_SUMMARY_ROUTE}>Invoices</Link>
        )}
        {this.props.userIsAdmin && (
          <Link to={STUDIO_SETTINGS_ROUTE}>Settings</Link>
        )}
        {this.props.userIsAdmin && <Link to={STAFF_ROUTE}>Staff</Link>}
        {this.props.frontDesk && this.props.dibsStudioId === 100 && (
          <a
            href="http://studios.ondibs.com/cycle-house"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQ
          </a>
        )}
        <Link to={ACCOUNT_ROUTE}>Account</Link>
        <button onClick={this.props.logout}>
          <a>Logout</a>
        </button>
      </BurgerMenuBase>
    );
  }
}

BurgerMenu.propTypes = {
  frontDesk: PropTypes.bool,
  userIsAdmin: PropTypes.bool,
  hasPayouts: PropTypes.bool,
  dibsStudioId: PropTypes.number,
  logout: PropTypes.func,
  stripeAccountId: PropTypes.string,
  studioIsLive: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  frontDesk: getUserStudioFrontDesk(state),
  hasPayouts: getUserStudioHasPayouts(state),
  userIsAdmin: getUserIsAdmin(state),
  dibsStudioId: getUserStudioDibsId(state),
  stripeAccountId: getUserStudioStripeAccountId(state),
  studioIsLive: getUserStudioLive(state),
});
const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(BurgerMenu);
