import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Grid, Typography } from '@mui/material';
import getEventPaymentInfo from 'actions/events/getEventPaymentInfo';
import getPaymentInfoForUser from 'actions/clients/getPaymentInfoForUser';
import getAvailablePasses from 'actions/getAvailablePasses';

import capitalizeString from 'helpers';
import { roundtoN, formatDollarNew } from 'helpers';

const FromCalendar = ({
    eventidSelected,
    clientSelected,
    eventType,
    dibsStudioId,
    setPaymentMethodToUseParent,
    setAmountWillBeCharged,
    setPassIdToUseParent,
    setWillApplyPassParent,
    setTaxAmount,
    setSubPriceToCharge,
}) => {
    const [eventPaymentInfo, setEventPaymentInfo] = useState(null);
    const [isBookable, setIsBookable] = useState(null);
    const [taxRate, setTaxRate] = useState(null);
    const [priceToCharge, setPriceToCharge] = useState(null);
    const [priceWithTax, setPriceWithTax] = useState(null);
    const [creditAvailable, setCreditAvailable] = useState(null);
    const [passIsAvailable, setPassIsAvailable] = useState(false);
    const [membershipIsAvailable, setMembershipIsAvailable] = useState(false);
    const [hasCreditCardOnFile, setHasCreditCardOnFile] = useState(null);
    const [brand, setBrand] = useState(null);
    const [last4, setLast4] = useState(null);
    const [sentStart, setSentStart] = useState(null);
    const [sentEnd, setSentEnd] = useState(null);
    const [chargeSentence, setChargeSentence] = useState(null);
    const [expMonth, setExpMonth] = useState(null);
    const [expYear, setExpYear] = useState(null);
    const [canApplyPassThisEvent, setCanApplyPassThisEvent] = useState(false);
    const [passNameToUse, setPassNameToUse] = useState(null);
    const [numPassesRemaining, setNumPassesRemaining] = useState(null);
    const [passesExpiration, setPassesExpiration] = useState(null);
    const [passidToUse, setPassidToUse] = useState(null);
    const [paymentMethodToUse, setPaymentMethodToUse] = useState(null);
    useEffect(() => {
        const getpasses = async () => {
            const thispass = await getAvailablePasses(
                dibsStudioId,
                clientSelected?.id
            );
            if (thispass?.length > 0) {
                setPassNameToUse(thispass[0].studioPackage.packageName);
                const { totalUses, usesCount, expiresAt } = thispass[0];
                const passesRemaining = totalUses - usesCount - 1;
                setPassidToUse(thispass[0].id);
                setPassIdToUseParent(thispass[0].id);
                setWillApplyPassParent(true);
                setNumPassesRemaining(passesRemaining);
                setPaymentMethodToUse(thispass[0].studioPackage.packageName);
                setPassIsAvailable(true);
                const expirationDateForPass = dayjs
                    .utc(expiresAt)
                    .format('M/D/YY');
                setPassesExpiration(expirationDateForPass);
            }
        };
        if (canApplyPassThisEvent) {
            // check to see if this user has memberships
            if (clientSelected) {
                getpasses();
            }
        }
    }, [
        clientSelected,
        dibsStudioId,
        eventidSelected,
        canApplyPassThisEvent,
        setPassIdToUseParent,
        setWillApplyPassParent,
    ]);
    useEffect(() => {
        const getEventInfo = async () => {
            const ei = await getEventPaymentInfo(eventidSelected);

            const { eventinfo, taxrate } = ei;
            const { price_dibs, free_class, can_apply_pass } = eventinfo;
            if (eventinfo) setEventPaymentInfo(eventinfo);
            if (taxrate) setTaxRate(taxrate);
            if (price_dibs) setPriceToCharge(price_dibs);
            if (free_class) setPriceToCharge(0);
            if (can_apply_pass) {
                setCanApplyPassThisEvent(true);
            } else {
                setCanApplyPassThisEvent(false);
            }
        };
        if (eventidSelected) {
            getEventInfo();
        }
    }, [eventidSelected]);
    useEffect(() => {
        if (taxRate > 0) {
            const price = priceToCharge;
            const rate = taxRate / 100;
            const tax = price * rate;
            const total = price + tax;
            const rounded = roundtoN(total, 2);
            const taxRounded = roundtoN(tax, 2);
            const roundedAsDollar = formatDollarNew(rounded);
            setTaxAmount(taxRounded);

            // setPriceToCharge(rounded);
            setPriceWithTax(roundedAsDollar);
            setAmountWillBeCharged(rounded);
            setSubPriceToCharge(priceToCharge);
        }
    }, [
        taxRate,
        priceToCharge,
        setAmountWillBeCharged,
        setTaxAmount,
        setSubPriceToCharge,
    ]);
    useEffect(() => {
        if (eventPaymentInfo) {
            const { spots_booked, seats, isFull } = eventPaymentInfo;
            if (isFull || spots_booked >= seats) {
                setIsBookable(false);
            } else {
                setIsBookable(true);
            }
        }
    }, [eventPaymentInfo]);

    useEffect(() => {
        const getPms = async () => {
            const pMs = await getPaymentInfoForUser(
                clientSelected?.id,
                dibsStudioId
            );
            const { credit, paymentMethods } = pMs;
            if (credit) setCreditAvailable(credit);
            if (paymentMethods) {
                const { data } = paymentMethods;
                if (data) {
                    if (data?.length > 0) {
                        setHasCreditCardOnFile(true);
                        const { card, id } = data[0];
                        const { brand, last4, exp_month, exp_year } = card;
                        setBrand(brand);
                        setLast4(last4);
                        setPaymentMethodToUseParent(id);
                        setExpMonth(exp_month);
                        const shortExpYear = exp_year
                            .toString()
                            .substring(2, 4);
                        setExpYear(shortExpYear);
                    } else {
                        setHasCreditCardOnFile(false);
                        setBrand(null);
                        setLast4(null);
                        setPaymentMethodToUseParent(null);
                    }
                }
            }
        };
        if (clientSelected) {
            getPms();
        }
    }, [clientSelected, dibsStudioId, setPaymentMethodToUseParent]);
    useEffect(() => {
        let eType = eventType === 'appt' ? 'appointment' : 'class';
        let firstName = capitalizeString(clientSelected?.firstName || '');
        let sentenceStart = `${firstName} has a card (${capitalizeString(brand)}) on file ending in ${last4} with expiration ${expMonth}/${expYear}. Click the Book Now button below to add the client to the ${eType}. `;
        // let sentenceEnd = `Use the toggle switches below to choose whether to send the client an email or sms confirmation.`;
        // setSentEnd(null);
        let chargeSentenceTemp =
            priceToCharge > 0
                ? `${firstName} will be charged ${priceWithTax} (includes tax). `
                : `This ${eType} is free. The client will NOT be charged.`;
        if (!clientSelected) {
            setSentStart(null);
            setSentEnd(null);
            setChargeSentence(null);
        } else if (passIsAvailable) {
            setSentStart(
                `${firstName} has a ${passNameToUse} available. When you book this ${eType}, 1 pass will be applied. `
            );
            setChargeSentence(
                `${firstName} will have ${numPassesRemaining} passes remaining after this booking. ${
                    numPassesRemaining > 0
                        ? `The remaining pass(es) will expire on ${passesExpiration}.`
                        : ''
                }`
            );
        } else if (brand && hasCreditCardOnFile) {
            setSentStart(sentenceStart);
            setChargeSentence(chargeSentenceTemp);
        } else if (!hasCreditCardOnFile) {
            setSentStart(
                `${firstName} does not have a card on file. Click the button below to add a new card on file (FUNCTIONALITY IN PROGRESS).`
            );
            setSentEnd(null);
            setChargeSentence(chargeSentenceTemp);
        }
    }, [
        hasCreditCardOnFile,
        clientSelected,
        eventType,
        priceToCharge,
        passIsAvailable,
        priceWithTax,
        brand,
        last4,
        expMonth,
        expYear,
        passNameToUse,
        numPassesRemaining,
        passesExpiration,
    ]);
    if (isBookable) {
        return (
            <>
                <Grid item sx={{ mt: 1, mb: 2 }}>
                    <Typography variant="h8">
                        {sentStart} <strong>{chargeSentence}</strong>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h8">{sentEnd}</Typography>
                </Grid>
            </>
        );
    } else {
        return null;
    }
};

export default FromCalendar;
