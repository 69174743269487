// third-party
import { FormattedMessage } from 'react-intl';

// ==============================|| STUDIO ADMIN MENU ITEMS ||============================== //

const hairStylist = {
    id: 'hair-stylist',
    type: 'group',
    children: [
        // {
        //     id: 'dashboard',
        //     title: <FormattedMessage id="dashboard" defaultMessage="Dashboard" />,
        //     type: 'item',
        //     url: '/dashboard',
        //     // icon: icons.IconBrandChrome,
        //     breadcrumbs: false,
        // },
        {
            id: 'frontdesk',
            title: (
                <FormattedMessage id="front-desk" defaultMessage="Front Desk" />
            ),
            type: 'item',
            url: '/frontdesk',
            // url: '/studios/frontdesk',
            // icon: icons.IconSitemap
        },
        // {
        //     id: 'reporting',
        //     title: (
        //         <FormattedMessage id="reporting" defaultMessage="Reporting" />
        //     ),
        //     type: 'item',
        //     url: '/reporting',
        // },
        {
            id: 'schedule',
            title: <FormattedMessage id="schedule" defaultMessage="Schedule" />,
            type: 'item',
            url: '/schedule',
        },
        {
            id: 'availability',
            title: (
                <FormattedMessage
                    id="availability"
                    defaultMessage="Availability"
                />
            ),
            type: 'item',
            // url: '/studios/instructors',
            url: '/availability',
        },
        {
            id: 'barbers',
            title: <FormattedMessage id="barbers" defaultMessage="Barbers" />,
            type: 'item',
            // url: '/studios/instructors',
            url: '/barbers',
        },

        {
            id: 'account',
            title: <FormattedMessage id="account" defaultMessage="Account" />,
            type: 'item',
            // url: 'studios/account',
            url: '/account',
        },
        {
            id: 'settings',
            title: <FormattedMessage id="settings" defaultMessage="Settings" />,
            type: 'item',
            // url: 'studios/settings',
            url: '/settings',
        },
    ],
};

export default hairStylist;
