import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';

const XIcon = ({ color, shrink }) => {
    const colortoshow = `#${color}`;
    const shrinksize = shrink ? 'shrink-5' : '';
    if (shrinksize.length > 2) {
        return (
            <Grid item id="x-icon-shrink">
                <FontAwesomeIcon
                    icon="fa-solid fa-x"
                    color={colortoshow}
                    transform={shrinksize}
                />
            </Grid>
        );
    }

    // <FontAwesomeIcon icon="fa-regular fa-check" />;
    return (
        <Grid item id="x-icon-solo">
            <FontAwesomeIcon
                icon="fa-solid fa-x"
                color={colortoshow}
                // transform="grow-3"
            />
        </Grid>
    );
};
export default XIcon;
