import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { format as formatCurrency } from 'currency';
import log from "../../helpers/log";
import {
  PACKAGE_PRODUCT_OPTIONS,
  PACKAGE_PRODUCT_MEMBERSHIP,
  PACKAGE_PRODUCT_CLASS,
  PACKAGE_PRODUCT_PACKAGE,
  EXPIRES_AFTER_OPTIONS,
  AUTO_RENEWAL_OPTIONS,
  AUTORENEW_OPTIONAL,
  AUTORENEW_NONE,
  AUTORENEW_FORCE,
} from "../../constants/PackagesConstants";
import {
  PACKAGES_HISTORY_ROUTE,
  EDIT_PACKAGE_ROUTE,
} from "../../constants/RouteConstants";
import {
  getUserStudioCountry,
  getUserStudioCurrency,
  getUserStudioDateFormat,
} from "../../selectors/user";
import { addError, clearError } from "../../actions/ErrorActions";
import {
  setOpenStudioPackage,
  setStudioPackagesLoadingTrue,
} from "../../actions/AdminPackagesActions";
import DibsLoader from "../Layout/DibsLoader";
import InputField from "shared/components/InputField";
import SelectField from "../shared/SelectField";
import CheckBoxIcon from "../../../shared/graphics/Icons/CheckBoxIcon";
import "../../styles/add-promo-code.scss";

/**
 * AddPackage React Component
 * @extends {module:React.PureComponent}
 * @returns {JSX.Element} HTML
 */
class AddPackage extends React.PureComponent {
  /**
   * constructor
   * @constructs AddPackage
   * @param {Object} props Component props
   */
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      unlimited: false,
      frontDeskOnly: false,
      privatePackage: false,
      freePackage: false,
      newClientsOnly: false,
      packageName: "",
      renewsAfter: "",
      numClasses: "",
      expiresAfter: "",
      commitmentPeriod: "",
      classesPerPeriod: "",
      price: "",
      incentivePrice: "0",
      activeProduct: PACKAGE_PRODUCT_OPTIONS[0],
      activeTimePeriod: EXPIRES_AFTER_OPTIONS[0],
      autoRenewal: AUTO_RENEWAL_OPTIONS[0],
      customDescription: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openHistory = this.openHistory.bind(this);
    this.submitStudioPackage = this.submitStudioPackage.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectExpiresChange = this.handleSelectExpiresChange.bind(this);
    this.handleSelectAutorenewalChange =
      this.handleSelectAutorenewalChange.bind(this);
    this.setUnlimited = this.setUnlimited.bind(this);
    this.setFreePackage = this.setFreePackage.bind(this);
    this.setFrontDeskOnly = this.setFrontDeskOnly.bind(this);
    this.setPrivate = this.setPrivate.bind(this);
    this.setNewClientsOnly = this.setNewClientsOnly.bind(this);
    this.removeDollarSign = this.removeDollarSign.bind(this);
    this.getNumFromPeriod = this.getNumFromPeriod.bind(this);
    this.formatCurrency = this.formatCurrency.bind(this);
    this.getPeriodFromPeriod = this.getPeriodFromPeriod.bind(this);
  }
  /**
   * Changes unlimited state
   * @returns {undefined}
   */
  setUnlimited() {
    this.setState({ unlimited: !this.state.unlimited });
  }
  /**
   * getNumFromPeriod
   * @param {string} timePeriod value
   * @returns {string} Number extracted from string
   */
  getNumFromPeriod(timePeriod) {
    const str = timePeriod;
    const numValue = str
      .split("")
      .filter((s) => /[0-9]/i.test(s))
      .join("");
    return numValue;
  }
  formatCurrency(price, { code }) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: code,
    }).format(price);
  }
  /**
   *
   * getPeriodFromPeriod
   * @param {string} timePeriod value
   * @returns {string} Number extracted from string
   */
  getPeriodFromPeriod(timePeriod) {
    const str = timePeriod;
    let period = "";
    if (str.search("day") > 0) {
      period = "day";
    } else if (str.search("year") > 0) {
      period = "year";
    } else if (str.search("week") > 0) {
      period = "week";
    } else {
      period = "month";
    }
    return period;
  }
  /**
   * Changes frontDeskOnly state
   * @returns {undefined}
   */
  setFrontDeskOnly() {
    this.setState({ frontDeskOnly: !this.state.frontDeskOnly });
  }
  /**
   * Changes private state
   * @returns {undefined}
   */
  setPrivate() {
    this.setState({ privatePackage: !this.state.privatePackage });
  }
  /**
   * Changes freePackage state
   * @returns {undefined}
   */
  setFreePackage() {
    this.setState({ freePackage: !this.state.freePackage });
  }
  /**
   * Changes newClients state
   * @returns {undefined}
   */
  setNewClientsOnly() {
    this.setState({ newClientsOnly: !this.state.newClientsOnly });
  }
  /**
   * removeDollarSign
   * @param {string} money value
   * @returns {string} Number extracted from string
   */
  removeDollarSign(money) {
    const str = money;
    const numValue = str.replace(/[$,-]/g, "");
    return numValue;
  }
  /**
   * handleChange
   * @param {Event} event the event object
   * @param {string} event.target.name the name of the modified input
   * @param {string} event.target.value the value the user inputs
   * @returns {undefined}
   */
  handleChange({ target: { name, value: _value } }) {
    let value;
    switch (name) {
      case "price":
      case "incentivePrice":
        value = isNaN(_value[0]) ? +_value.slice(1) : +_value;
        if (!value) value = "";
        else
          value = this.formatCurrency(value, {
            code: this.props.currency,
            precision: 0,
          });
        break;
      default:
        value = _value;
    }
    this.setState({ [name]: value });
  }
  /**
   * openHistory
   * @returns {undefined}
   */
  openHistory() {
    this.context.router.push(PACKAGES_HISTORY_ROUTE);
  }
  /**
   *
   * @param {object} newValue new value to display
   * @returns {undefined}
   */
  handleSelectChange(newValue) {
    const stateChange = { activeProduct: newValue };
    this.setState(stateChange);
  }
  /**
   *
   * @param {object} newTimeValue new value to display
   * @returns {undefined}
   */
  handleSelectExpiresChange(newTimeValue) {
    const stateTimeChange = { activeTimePeriod: newTimeValue };
    this.setState(stateTimeChange);
  }
  /**
   *
   * @param {object} newRenewalValue new value to display
   * @returns {undefined}
   */
  handleSelectAutorenewalChange(newRenewalValue) {
    const stateRenewalChange = { autoRenewal: newRenewalValue };
    this.setState(stateRenewalChange);
  }
  /**
   * handleSubmit validates form entries
   * @returns {undefined}
   */
  handleSubmit() {
    if (this.state.submitting) return;

    this.props.clearError();

    const renewalPeriod = this.getPeriodFromPeriod(this.state.renewsAfter);
    const commitmentPeriod = this.getPeriodFromPeriod(
      this.state.commitmentPeriod,
    );

    let renewalTimeFrame = this.getNumFromPeriod(this.state.renewsAfter);
    let commitmentTimeFrame = this.getNumFromPeriod(
      this.state.commitmentPeriod,
    );

    renewalTimeFrame = parseInt(renewalTimeFrame, 10);
    commitmentTimeFrame = parseInt(commitmentTimeFrame, 10);

    if (!this.state.packageName) {
      this.props.addError(
        "Don't forget to set the name for your new package or membership.",
      );
    } else if (!this.state.freePackage && !this.state.price) {
      this.props.addError("Oops. Don't forget to set the price.");
    } else if (
      this.state.autoRenewal.value === AUTORENEW_OPTIONAL &&
      !this.state.incentivePrice
    ) {
      this.props.addError(
        "Don't forget to set the price for clients that choose to automatically renew their membership.",
      );
    } else if (
      this.state.activeProduct.value === PACKAGE_PRODUCT_PACKAGE &&
      !this.state.numClasses
    ) {
      this.props.addError("Don't forget to set the # of classes.");
    } else if (
      this.state.activeProduct.value === PACKAGE_PRODUCT_PACKAGE &&
      !this.state.expiresAfter
    ) {
      this.props.addError(
        "Don't forget to set the expiration period for this package.",
      );
    } else if (
      this.state.activeProduct.value === PACKAGE_PRODUCT_CLASS &&
      !this.state.expiresAfter
    ) {
      this.props.addError(
        "Don't forget to set the expiration period for this class.",
      );
    } else if (
      this.state.activeProduct.value === PACKAGE_PRODUCT_MEMBERSHIP &&
      !this.state.unlimited &&
      !this.state.classesPerPeriod
    ) {
      this.props.addError(
        "Since this membership isn't unlimited, you need to set the # of classes per period.",
      );
    } else if (
      this.state.autoRenewal.value === AUTORENEW_FORCE &&
      renewalPeriod !== commitmentPeriod
    ) {
      this.props.addError(
        "Please make sure that the measurement of time for commitment period matches the measurement of time for the renewal period (e.g. weeks, months, years, days).",
      );
    } else if (
      this.state.autoRenewal.value === AUTORENEW_FORCE &&
      !this.state.renewsAfter
    ) {
      this.props.addError(
        "When Automatic Renewal is set to required, you must set the renewal period and the commitment period.",
      );
    } else if (
      this.state.autoRenewal.value === AUTORENEW_FORCE &&
      !this.state.commitmentPeriod
    ) {
      this.props.addError(
        "When Automatic Renewal is set to required, you must set the renewal period and the commitment period.",
      );
    } else if (renewalTimeFrame > commitmentTimeFrame) {
      this.props.addError(
        "The commitment period must be longer than the renewal period. Alternatively, set Automatic Renewal to optional.",
      );
    } else {
      this.submitStudioPackage();
    }
  }
  /**
   * submitStudioPackage to the server
   * @returns {undefined}
   */
  submitStudioPackage() {
    const activeProduct = this.state.activeProduct.value;
    const packageName = this.state.packageName;
    const unlimitedStatus = this.state.unlimited;
    let numClasses = this.state.numClasses;
    const autoRenewalStatus = this.state.autoRenewal.value;
    const renewsAfter = this.state.renewsAfter;
    const expiresAfter = this.state.expiresAfter;
    // let commitmentPeriod = this.state.commitmentPeriod;
    const classesPerPeriod = this.state.classesPerPeriod;
    let price = this.state.price;
    let incentivePrice = this.state.incentivePrice;
    const frontDeskOnlyStatus = this.state.frontDeskOnly;
    const privateStatus = this.state.privatePackage;
    const freePackageStatus = this.state.freePackage;
    const newClientsOnlyStatus = this.state.newClientsOnly;
    let passesValidFor = "";
    let validForInterval = "";
    let autopayIncrement = "month";
    let autopayIncrementCount = 1;
    let commitmentNum = "";
    let autopay = "";

    price = this.removeDollarSign(price);
    incentivePrice = this.removeDollarSign(incentivePrice);

    if (expiresAfter) {
      passesValidFor = this.getNumFromPeriod(expiresAfter);
      validForInterval = this.getPeriodFromPeriod(expiresAfter);
    } else if (renewsAfter) {
      autopayIncrementCount = this.getNumFromPeriod(renewsAfter);
      autopayIncrement = this.getPeriodFromPeriod(renewsAfter);
      passesValidFor = autopayIncrementCount;
      validForInterval = autopayIncrement;
    }
    if (activeProduct === PACKAGE_PRODUCT_CLASS) {
      numClasses = 1;
      autopay = "NONE";
    }
    if (classesPerPeriod) {
      numClasses = classesPerPeriod;
    }
    if (activeProduct === PACKAGE_PRODUCT_PACKAGE) {
      autopay = "NONE";
    }
    if (freePackageStatus) {
      price = 0;
    }
    if (unlimitedStatus) {
      numClasses = 999;
    }
    if (this.state.commitmentPeriod) {
      commitmentNum = this.getNumFromPeriod(this.state.commitmentPeriod);
    } else {
      commitmentNum = 0;
    }
    if (autoRenewalStatus === AUTORENEW_FORCE) {
      autopay = "FORCE";
      incentivePrice = price;
    } else if (autoRenewalStatus === AUTORENEW_OPTIONAL) {
      autopay = "ALLOW";
    } else {
      autopay = "NONE";
    }

    const reqBody = {
      ...this.state,
      name: packageName,
      unlimitedStatus,
      price: Number(price),
      classesPerPeriod: Number(numClasses),
      autopay,
      passesValidFor,
      validForInterval,
      autopayIncrementCount,
      onlyFirstPurchase: Number(newClientsOnlyStatus),
      commitmentNum,
      frontDeskOnlyStatus,
      privateStatus,
      autopayIncrement,
      incentivePrice: Number(incentivePrice),
      customDescription: this.state.customDescription,
    };
    this.setState({ submitting: true }, () => {
      console.log("setting state for adding package");
      // $.ajax({
      //   method: 'POST',
      //   url: '/studios/api/packages/new',
      //   data: reqBody,
      // })
      // .done(({ success, studioPackage, message, err }) => {
      //   if (success) {
      //     this.props.setOpenStudioPackage({ ...studioPackage, new: true, packageType: activeProduct });
      //     this.props.setStudioPackagesLoadingTrue();
      //     this.context.router.push(EDIT_PACKAGE_ROUTE);
      //   } else {
      //     if (err) log(err);
      //     this.props.addError(message);
      //     this.setState({ submitting: false });
      //   }
      // })
      // .error((err) => {
      //   console.log(err);
      //   this.props.addError('Failed to create the new package - on addPackage file.');
      //   this.setState({ submitting: false });
      // });
    });
  }
  /**
   * render
   * @returns {JSX.Element} HTML
   */
  render() {
    return (
      <div className="dibs-section add-promo-code-container">
        <div className="dibs-section-heading">
          Add A New Package or Membership
        </div>
        <div className="dibs-input-fields first-input-container">
          <SelectField
            label="Type"
            value={this.state.activeProduct}
            onChange={this.handleSelectChange}
            options={PACKAGE_PRODUCT_OPTIONS}
          />
        </div>
        <div className="dibs-input-fields first-input-container">
          <InputField
            label="Name"
            name="packageName"
            placeholder="e.g. 10 Pack"
            onChange={this.handleChange}
            value={this.state.packageName}
          />
          {this.state.activeProduct.value === PACKAGE_PRODUCT_MEMBERSHIP && (
            <div className="promo-recurring-container">
              <button onClick={this.setUnlimited}>
                <CheckBoxIcon checked={this.state.unlimited} />
              </button>
              &nbsp;
              <span>Unlimited Membership?</span>
            </div>
          )}
          {this.state.activeProduct.value === PACKAGE_PRODUCT_PACKAGE && (
            <InputField
              label="# of Classes"
              name="numClasses"
              placeholder="e.g. 5"
              onChange={this.handleChange}
              value={this.state.numClasses}
            />
          )}
        </div>
        {this.state.activeProduct.value === PACKAGE_PRODUCT_MEMBERSHIP && (
          <div className="dibs-input-fields">
            <SelectField
              label="Automatic Renewal"
              value={this.state.autoRenewal}
              onChange={this.handleSelectAutorenewalChange}
              options={AUTO_RENEWAL_OPTIONS}
            />
          </div>
        )}
        <div className="dibs-input-fields first-input-container">
          {this.state.activeProduct.value === PACKAGE_PRODUCT_MEMBERSHIP &&
            this.state.autoRenewal.value !== AUTORENEW_NONE && (
              <InputField
                label="Renews after"
                name="renewsAfter"
                placeholder="e.g. 1 month"
                onChange={this.handleChange}
                value={this.state.renewsAfter}
              />
            )}
          {this.state.activeProduct.value === PACKAGE_PRODUCT_MEMBERSHIP &&
            this.state.autoRenewal.value === AUTORENEW_NONE && (
              <InputField
                label="Expires after"
                name="expiresAfter"
                placeholder="e.g. 1 month"
                onChange={this.handleChange}
                value={this.state.expiresAfter}
              />
            )}
          {this.state.activeProduct.value === PACKAGE_PRODUCT_MEMBERSHIP &&
            this.state.autoRenewal.value === AUTORENEW_FORCE && (
              <InputField
                label="Commitment Period"
                name="commitmentPeriod"
                placeholder="e.g. 3 months"
                onChange={this.handleChange}
                value={this.state.commitmentPeriod}
              />
            )}
          {this.state.activeProduct.value === PACKAGE_PRODUCT_MEMBERSHIP &&
            !this.state.unlimited && (
              <InputField
                label="# Classes per period"
                name="classesPerPeriod"
                placeholder="e.g. 4"
                onChange={this.handleChange}
                value={this.state.classesPerPeriod}
              />
            )}
        </div>
        <div className="dibs-input-fields">
          {(this.state.activeProduct.value === PACKAGE_PRODUCT_PACKAGE ||
            this.state.activeProduct.value === PACKAGE_PRODUCT_CLASS) && (
            <InputField
              label="Expires after"
              name="expiresAfter"
              placeholder="e.g. 6 months"
              onChange={this.handleChange}
              value={this.state.expiresAfter}
            />
          )}
          {!this.state.freePackage && (
            <InputField
              label="Price"
              name="price"
              placeholder="e.g. 250"
              onChange={this.handleChange}
              value={this.state.price}
            />
          )}
          {this.state.activeProduct.value === PACKAGE_PRODUCT_MEMBERSHIP &&
            this.state.autoRenewal.value === AUTORENEW_OPTIONAL &&
            !this.state.freePackage && (
              <InputField
                label="Price for Auto Renewals"
                name="incentivePrice"
                placeholder="e.g. 200"
                onChange={this.handleChange}
                value={this.state.incentivePrice}
              />
            )}
        </div>
        <div className="dibs-input-fields">
          <InputField
            label="Custom description (Optional)"
            name="customDescription"
            placeholder="e.g. Spring Special"
            onChange={this.handleChange}
            value={this.state.customDescription}
          />
        </div>
        <div className="promo-recurring-container">
          <button onClick={this.setNewClientsOnly}>
            <CheckBoxIcon checked={this.state.newClientsOnly} />
          </button>
          &nbsp;
          <span>New Clients Only</span>
          &nbsp;&nbsp;
          <button onClick={this.setFreePackage}>
            <CheckBoxIcon checked={this.state.freePackage} />
          </button>
          &nbsp;
          <span>Free Package</span>
          &nbsp;&nbsp;
          <button onClick={this.setFrontDeskOnly}>
            <CheckBoxIcon checked={this.state.frontDeskOnly} />
          </button>
          &nbsp;
          <span>Admin Access Only</span>
          &nbsp;&nbsp;
          <button onClick={this.setPrivate}>
            <CheckBoxIcon checked={this.state.privatePackage} />
          </button>
          &nbsp;
          <span>For Private/Semi-Private Classes</span>
        </div>
        <div className="dibs-submit-btn-container" style={{ marginTop: 40 }}>
          <button
            className="dibs-button-special-case dibs-button-special-case-inverted"
            onClick={this.openHistory}
          >
            VIEW ALL PACKAGES
          </button>
          <button
            className="dibs-button"
            onClick={this.handleSubmit}
            disabled={this.state.submitting}
          >
            {this.state.submitting ? <DibsLoader /> : "SUBMIT"}
          </button>
        </div>
      </div>
    );
  }
}

AddPackage.contextTypes = {
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

AddPackage.propTypes = {
  currency: PropTypes.string,
  addError: PropTypes.func,
  clearError: PropTypes.func,
  setOpenStudioPackage: PropTypes.func,
  setStudioPackagesLoadingTrue: PropTypes.func,
};

const mapStateToProps = (state) => ({
  country: getUserStudioCountry(state),
  currency: getUserStudioCurrency(state),
  dateFormat: getUserStudioDateFormat(state),
});
const mapDispatchToProps = {
  addError,
  clearError,
  setOpenStudioPackage,
  setStudioPackagesLoadingTrue,
};

const SmartAddPackage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPackage);

export default SmartAddPackage;
