import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthCardWrapper from './AuthCardWrapperLogin';
import ForgotPassword from './ForgotPassword';
import Logo from 'ui-component/dibslogo.png';

import { dispatch, useSelector } from 'store';
import { setDibsStudioId } from 'store/slices/dibsstudio';
import { resetUserSession } from 'store/slices/session';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const Login = () => {
    const theme = useTheme();
    const { dibsId } = useParams();
    const { plsResetSession } = useSelector((state) => state.session);

    const isLoggedIn = false;
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const setDibsIdLocalStorage = (dibsId) => {
        window.localStorage.setItem('dibsId', dibsId);
        dispatch(setDibsStudioId(dibsId));
    };
    useEffect(() => {
        if (dibsId) setDibsIdLocalStorage(dibsId);
    }, [dibsId]);
    useEffect(() => {
        if (plsResetSession) {
            dispatch(resetUserSession(false));
            window.localStorage.removeItem('persist:studioemployeeuser');
            window.localStorage.removeItem('persist:dibscart');
        }
    }, [plsResetSession]);

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-end"
            sx={{ minHeight: '97vh', backgroundColor: '#ffffff' }}
        >
            <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                <Grid
                    container
                    justifyContent="left"
                    alignItems="left"
                    id="grid-container-top-login"
                >
                    <Grid item sx={{ mb: 3, marginTop: 1, marginLeft: 1 }}>
                        <Link to="#">
                            <img src={Logo} alt="Logo" height="25" />
                        </Link>
                    </Grid>
                </Grid>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="flex-start"
                    id="container-full-width"
                    sx={{ minHeight: 'calc(100vh - 68px)', pl: 0, ml: 0 }}
                >
                    <Grid
                        item
                        sx={{ m: { xs: 1, sm: 2, md: 3 } }}
                        id="grid-item-surrounds-login"
                    >
                        <AuthCardWrapper addBorder={false}>
                            <Grid
                                container
                                spacing={0}
                                alignItems="center"
                                justifyContent="center"
                                id="next-container-down"
                            >
                                <Grid item xs={12} sx={{ p: 0, m: 0 }}>
                                    <Grid
                                        container
                                        direction={
                                            matchDownSM ? 'column' : 'row'
                                        }
                                        alignItems="center"
                                        justifyContent="center"
                                        border={0}
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                marginTop: '38px',
                                                mb: '22px',
                                            }}
                                            id="item-holding-welcome-forgot"
                                        >
                                            <Stack
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={1}
                                                id="stack-content"
                                            >
                                                <Typography
                                                    color={
                                                        theme.palette.secondary
                                                            .main
                                                    }
                                                    gutterBottom
                                                    variant={
                                                        matchDownSM
                                                            ? 'h1'
                                                            : 'h1'
                                                    }
                                                >
                                                    Welcome Back!
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    fontSize="14px"
                                                    textAlign={
                                                        matchDownSM
                                                            ? 'center'
                                                            : 'inherit'
                                                    }
                                                >
                                                    Enter your email to reset
                                                    your password
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ForgotPassword />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AuthCardWrapper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Login;
