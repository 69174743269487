import axios from 'axios';
import { getApiUrl } from 'helpers';
export default async function createCoupon(promoid, stripeCouponId) {
    try {
        const url = getApiUrl('/stripe/update-promo-code-w-coupon-code');
        const tosend = {
            promoid,
            stripeCouponId,
        };
        const promocodeUpdated = await axios.post(url, tosend);
        const { data } = promocodeUpdated;
        return data;
    } catch (err) {
        return {
            success: false,
            error: err.msg,
        };
    }
}
