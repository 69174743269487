import PropTypes from "prop-types";

import React from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";

// ==============================|| CUSTOM SUB CARD ||============================== //

const AccountSubCard = React.forwardRef(
  (
    {
      children,
      content,
      contentClass,
      secondary,
      sx = {},
      contentSX = {},
      title,
      ...others
    },
    ref,
  ) => {
    const theme = useTheme();
    return (
      <Card
        ref={ref}
        sx={{
          border: "1px solid",
          borderRadius: 1.75,
          borderColor: theme.palette.common.border,
          boxShadow: "none",
          // ':hover': {
          //     boxShadow:
          //         theme.palette.mode === 'dark'
          //             ? '0 2px 14px 0 rgb(33 150 243 / 10%)'
          //             : '0 2px 14px 0 rgb(32 40 45 / 8%)',
          // },
          ...sx,
        }}
        {...others}
      >
        {/* card header and action */}
        {title && (
          <CardHeader
            variant="profile"
            sx={{ px: 2, py: 1.5, bgcolor: "#faf9f9" }}
            title={<Typography variant="h5">{title}</Typography>}
            action={secondary}
          />
        )}

        {/* content & header divider */}
        {title && (
          <Divider
            sx={{
              opacity: 1,
              borderColor: theme.palette.common.border,
            }}
          />
        )}

        {/* card content */}
        {content && (
          <CardContent
            sx={{ p: 2, ...contentSX }}
            className={contentClass || ""}
          >
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  },
);

AccountSubCard.propTypes = {
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
  sx: PropTypes.object,
  contentSX: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
};

AccountSubCard.defaultProps = {
  content: true,
};

export default AccountSubCard;
