import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function setLastLogin(email, employeeId) {
    const url = getApiUrl('/employee/set-last-login');
    let datatosend = {};
    const setlast = async () => {
        await axios
            .post(url, {
                email,
                employeeId,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error setting last employee login: ${err}`);
                return err;
            });
    };
    await setlast();
    return datatosend;
}
