import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { capitalizeString } from "helpers";
import { gridSpacing } from "store/constant";

import { dispatch } from "store";
import {
  setEncryptedPassword,
  setHasSetPwdFirebase,
  setThisUserEmployeeId,
  setThisUserFirstName,
  setThisUserLastName,
} from "store/slices/studioemployeeuser";
import checkUserExists from "actions/users/userAlreadyExists";

// material-ui
import {
  Grid,
  Button,
  InputLabel,
  Typography,
  TextField,
  Link,
} from "@mui/material";

// third party
import { useFormik } from "formik";
import * as yup from "yup";

// project imports
import LoginCard from "ui-component/cards/AccountSubCardNew";
import ErrorMessage from "views/feedback-messages/error/errorMessage";

const validationSchema = yup.object().shape({
  phone: yup.string().phone("US", "Please enter a valid phone number"),
  birthday: yup.date("MM/DD"),
});

// ============================|| NEW - LOGIN PAGE ||============================ //

const LoginAuthenticationCard = ({ setCurrentStep }) => {
  const navigate = useNavigate();
  const { dibsId } = useParams();
  const goToSchedule = () => {
    navigate(`/schedule/${dibsId}`);
  };
  const goToAccount = () => {
    navigate(`/account/${dibsId}`);
  };
  return (
    <Grid container justifyContent="center">
      <Grid item xs={11} md={5} sx={{ mb: 5 }}>
        <LoginCard title="Account Created">
          <Grid container spacing={gridSpacing} justifyContent="flex-start">
            <ErrorMessage />
            <Grid item xs={12} sx={{ ml: 1, mr: 1, mt: 1 }}>
              <Typography variant="regularText">
                Thanks! You're logged in.
              </Typography>
              <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                <Grid item xs={8} md={4} sx={{ mt: 1 }}>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={() => goToAccount()}
                  >
                    <Typography variant="guidance">
                      Go to your account page
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={8} md={4} sx={{ mt: 1 }}>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={() => goToSchedule()}
                  >
                    <Typography variant="guidance">
                      Go to the schedule page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </LoginCard>
      </Grid>
    </Grid>
  );
};
export default LoginAuthenticationCard;
