// material-ui
import { createTheme } from '@mui/material/styles';

// assets
import defaultColor from 'assets/scss/_themes-vars.module.scss';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = () => {
    const colors = defaultColor;
    return createTheme({
        palette: {
            common: {
                black: colors.darkPaper,
            },
            primary: {
                light: colors.primaryLight,
                main: colors.primaryMain,
                dark: colors.primaryDark,
                slightlyDarker: colors.primarySlightlyDarker,
                200: colors.primary200,
                button: colors.primaryButton,
                300: colors.primary300,
                400: colors.primary400,
                800: colors.primary800,
            },
            green: {
                100: colors.lightGreen,
                main: colors.green,
            },
            secondary: {
                light: colors.secondaryLight,
                main: colors.secondaryMain,
                notaslight: colors.secondaryNotAsLight,
                dark: colors.secondaryDark,
                200: colors.secondary200,
                800: colors.secondary800,
                400: colors.secondary400,
                button: colors.primaryButton,
            },
            chips: {
                main: colors.chipsMain,
                private: colors.chipsPrivate,
                mainUnselectedText: colors.chipTextUnselected,
                blueUnselectedText: colors.chipsBlueUnselected,
            },
            chart: {
                light: colors.chartLight,
                main: colors.chartMain,
                dark: colors.chartDark,
                200: colors.chartDark,
                500: colors.chartYellow,
                600: colors.chartSmallest,
                800: colors.chart800,
            },
            error: {
                light: colors.errorLight,
                main: colors.errorMain,
                dark: colors.errorDark,
            },
            orange: {
                light: colors.orangeLight,
                main: colors.orangeMain,
                dark: colors.orangeDark,
            },
            dibstextfield: {
                main: colors.dibsTextFieldMain,
            },
            globalcolors: {
                cancel: colors.grey300,
                hover: colors.grey400,
                submit: colors.successDibsGreen,
                hoverSubmit: colors.submitGreen,
                option: colors.grey50,
                hoverOption: colors.packageLight,
            },
            warning: {
                light: colors.warningLight,
                main: colors.warningMain,
                dark: colors.warningDark,
            },
            success: {
                light: colors.successLight,
                200: colors.success200,
                main: colors.successMain,
                dark: colors.successDark,
                dibsgreen: colors.successDibsGreen,
            },
            packages: {
                light: colors.packageLight,
                medium: colors.packageMedium,
                button: colors.packageButton,
            },
            tableValues: {
                main: colors.tableValuesMain,
            },
            tileHeaders: {
                header: colors.tileHeaders,
                main: colors.tileRevenueNumber,
            },
            grey: {
                50: colors.grey50,
                100: colors.grey100,
                200: colors.grey200,
                300: colors.grey300,
                500: colors.grey500,
                550: colors.grey600,
                600: colors.grey700,
                650: colors.grey650,
                700: colors.grey700,
                900: colors.grey900,
            },
            dark: {
                light: colors.darkTextPrimary,
                main: colors.darkLevel1,
                dark: colors.darkLevel2,
                800: colors.darkBackground,
                900: colors.darkPaper,
            },
            text: {
                primary: colors.grey700,
                secondary: colors.grey500,
                clientProfile: colors.grey650,
                dark: colors.grey900,
                hint: colors.grey400,
            },
            divider: colors.grey300,
            background: {
                paper: colors.paper,
                default: colors.paper,
            },
        },
    });
};

export default Palette;
