import processAvailability from './processAvailability';

// ==============================|| GET AVAILABILITY BLOCK WITH EXCEPTION ||============================== //

export const getAvailabilityBlockWithException = (availabilityArray) => {
    try {
        const {
            availabilityAffected,
            availabilityId,
            availabilityIdIsBasedOn,
            dow,
            exceptionIds,
            exceptionsThatAffects,
            instructorId,
            backgroundColor,
        } = availabilityArray;

        const thisAvailability = availabilityAffected;
        const newAvailability = processAvailability(
            thisAvailability,
            exceptionsThatAffects
        );

        const newarray = {
            oldAvailability: availabilityAffected,
            availabilityId,
            availabilityIdIsBasedOn,
            daysOfWeek: [dow],
            newAvailability,
            instructorId,
            exceptionIds,
            backgroundColor,
        };
        return newarray;
    } catch (err) {
        console.log(
            `error getAvailabilityBlockWithException for display in calendar. Error is: ${err}`
        );
    }
    // return 0;
};

export default getAvailabilityBlockWithException;
