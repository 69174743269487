import routeReplacer from "../../../shared/helpers/route-replace-helper";
import {
  TransactionHistorySections,
  TRANSACTION_HISTORY_BASE_PATH,
} from "../../../shared/constants/TransactionHistoryConstants";

export const CLIENT_SEARCH_RESULTS_ROUTE = "/clients";

export const CLIENT_PROFILE_ROUTE = "/clients/:clientid";
export const GET_CLIENT_PROFILE_ROUTE = (clientid) =>
  routeReplacer(CLIENT_PROFILE_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_CREATED_ROUTE = "/clients/:clientid/created";
export const GET_CLIENT_CREATED_ROUTE = (clientid) =>
  routeReplacer(CLIENT_CREATED_ROUTE, {
    ":clientid": clientid,
  });

export const INSTRUCTOR_CREATED_ROUTE = "/instructors/:instructorid/created";
export const GET_INSTRUCTOR_CREATED_ROUTE = (instructorid) =>
  routeReplacer(INSTRUCTOR_CREATED_ROUTE, {
    ":instructorid": instructorid,
  });

export const CLIENT_DISABLED_ROUTE = "/clients/:clientid/disabled";
export const GET_CLIENT_DISABLED_ROUTE = (clientid) =>
  routeReplacer(CLIENT_DISABLED_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_DROP_ROUTE = "/clients/:clientid/events/:eventid";
export const GET_DROP_ROUTE = (clientid, eventid) =>
  routeReplacer(CLIENT_DROP_ROUTE, {
    ":clientid": clientid,
    ":eventid": eventid,
  });

export const ADD_CLIENT_CREDITS_ROUTE = "/clients/:clientid/credits";
export const GET_ADD_CLIENT_CREDITS_ROUTE = (clientid) =>
  routeReplacer(ADD_CLIENT_CREDITS_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_BOOK_CLASSES_ROUTE = "/clients/:clientid/book";
export const GET_CLIENT_BOOK_CLASSES_ROUTE = (clientid) =>
  routeReplacer(CLIENT_BOOK_CLASSES_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_BOOK_CLASSES_CART_ROUTE = "/clients/:clientid/book/cart";
export const GET_CLIENT_BOOK_CLASSES_CART_ROUTE = (clientid) =>
  routeReplacer(CLIENT_BOOK_CLASSES_CART_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_EVENTS_SPOT_BOOKING_ROUTE =
  "/clients/:clientid/spot-booking";
export const GET_CLIENT_EVENTS_SPOT_BOOKING_ROUTE = (clientid) =>
  routeReplacer(CLIENT_EVENTS_SPOT_BOOKING_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_EVENTS_CONFIRMED_ROUTE =
  "/clients/:clientid/booking-confirmed";
export const GET_CLIENT_EVENTS_CONFIRMED_ROUTE = (clientid) =>
  routeReplacer(CLIENT_EVENTS_CONFIRMED_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_PURCHASE_PACKAGE_ROUTE = "/clients/:clientid/buy-plan";
export const GET_CLIENT_PURCHASE_PACKAGE_ROUTE = (clientid) =>
  routeReplacer(CLIENT_PURCHASE_PACKAGE_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_WAITLIST_CONFIRMATION_ROUTE =
  "/clients/:clientid/waitlist/confirm/:eventid";
export const GET_CLIENT_WAITLIST_CONFIRMATION_ROUTE = (clientid, eventid) =>
  routeReplacer(CLIENT_WAITLIST_CONFIRMATION_ROUTE, {
    ":clientid": clientid,
    ":eventid": eventid,
  });

export const CLIENT_WAITLIST_DROP_ROUTE =
  "/clients/:clientid/waitlist/drop/:eventid";
export const GET_CLIENT_WAITLIST_DROP_ROUTE = (clientid, eventid) =>
  routeReplacer(CLIENT_WAITLIST_DROP_ROUTE, {
    ":clientid": clientid,
    ":eventid": eventid,
  });

// Client Transaction History Routes

export const CLIENT_TRANSACTIONS_ROUTE = `/clients/:clientid/${TRANSACTION_HISTORY_BASE_PATH}`;
export const GET_CLIENT_TRANSACTIONS_ROUTE = (clientid) =>
  routeReplacer(CLIENT_TRANSACTIONS_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_TRANSACTIONS_PURCHASES_ROUTE = `/clients/:clientid/${TRANSACTION_HISTORY_BASE_PATH}/${TransactionHistorySections.PURCHASES}`;
export const GET_CLIENT_TRANSACTIONS_PURCHASES_ROUTE = (clientid) =>
  routeReplacer(CLIENT_TRANSACTIONS_PURCHASES_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_TRANSACTIONS_AVAILABLE_PACKS_ROUTE = `/clients/:clientid/${TRANSACTION_HISTORY_BASE_PATH}/${TransactionHistorySections.AVAILABLE_PACKS}`;
export const GET_CLIENT_TRANSACTIONS_AVAILABLE_PACKS_ROUTE = (clientid) =>
  routeReplacer(CLIENT_TRANSACTIONS_AVAILABLE_PACKS_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_TRANSACTIONS_UNAVAILABLE_PACKS_ROUTE = `/clients/:clientid/${TRANSACTION_HISTORY_BASE_PATH}/${TransactionHistorySections.UNAVAILABLE_PACKS}`;
export const GET_CLIENT_TRANSACTIONS_UNAVAILABLE_PACKS_ROUTE = (clientid) =>
  routeReplacer(CLIENT_TRANSACTIONS_UNAVAILABLE_PACKS_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_TRANSACTIONS_CREDIT_ROUTE = `/clients/:clientid/${TRANSACTION_HISTORY_BASE_PATH}/${TransactionHistorySections.CREDIT}`;
export const GET_CLIENT_TRANSACTIONS_CREDIT_ROUTE = (clientid) =>
  routeReplacer(CLIENT_TRANSACTIONS_CREDIT_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_TRANSACTIONS_PAST_ROUTE = `/clients/:clientid/${TRANSACTION_HISTORY_BASE_PATH}/${TransactionHistorySections.PAST_CLASSES}`;
export const GET_CLIENT_TRANSACTIONS_PAST_ROUTE = (clientid) =>
  routeReplacer(CLIENT_TRANSACTIONS_PAST_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_TRANSACTIONS_DROPPED_ROUTE = `/clients/:clientid/${TRANSACTION_HISTORY_BASE_PATH}/${TransactionHistorySections.DROPPED_CLASSES}`;
export const GET_CLIENT_TRANSACTIONS_DROPPED_ROUTE = (clientid) =>
  routeReplacer(CLIENT_TRANSACTIONS_DROPPED_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_TRANSACTIONS_UPCOMING_ROUTE = `/clients/:clientid/${TRANSACTION_HISTORY_BASE_PATH}/${TransactionHistorySections.UPCOMING_CLASSES}`;
export const GET_CLIENT_TRANSACTIONS_UPCOMING_ROUTE = (clientid) =>
  routeReplacer(CLIENT_TRANSACTIONS_UPCOMING_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_TRANSACTIONS_FLASH_CREDIT_ROUTE = `/clients/:clientid/${TRANSACTION_HISTORY_BASE_PATH}/${TransactionHistorySections.FLASH_CREDIT}`;
export const GET_CLIENT_TRANSACTIONS_FLASH_CREDIT_ROUTE = (clientid) =>
  routeReplacer(CLIENT_TRANSACTIONS_FLASH_CREDIT_ROUTE, {
    ":clientid": clientid,
  });

export const CLIENT_TRANSACTIONS_PACK_BREAKDOWN_ROUTE = `/clients/:clientid/${TRANSACTION_HISTORY_BASE_PATH}/${TransactionHistorySections.PACK_BREAKDOWN}/:passid`;
export const GET_CLIENT_TRANSACTIONS_PACK_BREAKDOWN_ROUTE =
  (clientid) => (passid) =>
    routeReplacer(CLIENT_TRANSACTIONS_PACK_BREAKDOWN_ROUTE, {
      ":clientid": clientid,
      ":passid": passid,
    });

// Client Transaction History Detailed View Routes

export const CLIENT_TRANSACTIONS_DETAILED_PACKAGE_ROUTE =
  "/clients/:clientid/transactions/packages/:transactionid";
export const GET_CLIENT_TRANSACTIONS_DETAILED_PACKAGE_ROUTE = (
  clientid,
  transactionid,
) =>
  routeReplacer(CLIENT_TRANSACTIONS_DETAILED_PACKAGE_ROUTE, {
    ":clientid": clientid,
    ":transactionid": transactionid,
  });

export const CLIENT_TRANSACTIONS_DETAILED_CREDIT_ROUTE =
  "/clients/:clientid/transactions/credit/:transactionid";
export const GET_CLIENT_TRANSACTIONS_DETAILED_CREDIT_ROUTE = (
  clientid,
  transactionid,
) =>
  routeReplacer(CLIENT_TRANSACTIONS_DETAILED_CREDIT_ROUTE, {
    ":clientid": clientid,
    ":transactionid": transactionid,
  });
