// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "axios";
import { getApiUrl } from "helpers";
import { dispatch } from "../index";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  customers: [],
  orders: [],
  products: [],
  productreviews: [],
};

const slice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET CUSTOMERS
    getCustomersSuccess(state, action) {
      state.customers = action.payload;
    },

    // GET ORDERS
    getOrdersSuccess(state, action) {
      state.orders = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.products = action.payload;
    },

    // GET PRODUCT REVIEWS
    getProductReviewsSuccess(state, action) {
      state.productreviews = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCustomers() {
  return async () => {
    try {
      const response = await axios.get("/api/customer/list");
      dispatch(slice.actions.getCustomersSuccess(response.data.customers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrders() {
  return async () => {
    try {
      const response = await axios.get("/api/customer/order/list");
      dispatch(slice.actions.getOrdersSuccess(response.data.orders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProducts() {
  return async () => {
    try {
      const response = await axios.get("/api/customer/product/list");
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductReviews() {
  return async () => {
    try {
      const response = await axios.get("/api/customer/product/reviews");
      dispatch(
        slice.actions.getProductReviewsSuccess(response.data.productreviews),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
