import { createSelector } from "reselect";

/**
 * getMobileBackButton
 * @param {Object} state tree in the Redux store
 * @returns {Object} the state of the mobile back button
 */
export function getMobileBackButton(state) {
  return state.mobileBackButton;
}

export const getIsMobileBackButtonVisible = createSelector(
  getMobileBackButton,
  (mobileBackButton) => mobileBackButton.visible,
);

export const getIsMobileBackButtonClientPictureShowing = createSelector(
  getMobileBackButton,
  (mobileBackButton) => mobileBackButton.showClientPicture,
);

export const getMobileBackButtonRoute = createSelector(
  getMobileBackButton,
  (mobileBackButton) => mobileBackButton.route,
);
