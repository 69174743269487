import React, { useState } from 'react';
import {
    Grid,
    Typography,
    List,
    ListItemIcon,
    ListItemButton,
    ListItem,
    ListItemText,
    Divider,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';

// THIS IS FOR NEWLY CREATED CLIENTS - WHILE APPT IS BEING CREATED

import ConfirmClientRemoval from 'components/schedule-sections/confirmClientRemoval';

const PotentialClients = ({
    attendees,
    setHideSaveButtons,
    thiseventid,
    dibsId,
    startTime,
    setUpdateEvents,
    setAttendeeForThisEvent,
    setShowAutoCompleteClient,
    setClientSelected,
}) => {
    if (!attendees) return null;
    const removeClient = () => {
        setAttendeeForThisEvent([]);
        setShowAutoCompleteClient(true);
        setClientSelected(null);
    };

    if (attendees?.length === 0) {
        return null;
    }
    return (
        <Grid container>
            <Grid item xs={12} sx={{ p: 0, m: 0 }}>
                <List>
                    <Grid
                        key="booked-clients-list-container"
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {attendees.map((attendee, i) => {
                            const {
                                firstname,
                                userid,
                                lastname,
                                email,
                                mobilephone,
                            } = attendee;

                            let phonetouse = mobilephone;
                            if (!phonetouse) phonetouse = 'No phone on file';
                            return (
                                <React.Fragment
                                    key={`book-client-fragment-${userid}-${i}`}
                                >
                                    <Grid item xs={11} border={0} sx={{ m: 0 }}>
                                        <ListItem
                                            key={`listitem-${userid}`}
                                            sx={{ pl: 0 }}
                                        >
                                            <ListItemButton
                                                sx={{ p: 0 }}
                                                key={`listbutton-${userid}`}
                                            >
                                                <ListItemIcon
                                                    sx={{ minWidth: '22px' }}
                                                    key={`listitemicon-${userid}`}
                                                >
                                                    <CircleIcon
                                                        color="secondary"
                                                        sx={{ fontSize: 10 }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    key={`listitemtext-${userid}`}
                                                    primary={
                                                        <>
                                                            <Grid
                                                                container
                                                                justifyContent="flex-start"
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    border={0}
                                                                >
                                                                    <Typography variant="bookedclientsname">{`${firstname} ${lastname}`}</Typography>
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    border={0}
                                                                    sx={{
                                                                        mr: 1.5,
                                                                    }}
                                                                >
                                                                    <Typography variant="bookedclients">
                                                                        {email}
                                                                    </Typography>
                                                                </Grid>
                                                                <Divider
                                                                    orientation="vertical"
                                                                    sx={{
                                                                        borderColor:
                                                                            '#ccc',
                                                                    }}
                                                                    flexItem
                                                                />
                                                                <Grid
                                                                    item
                                                                    sx={{
                                                                        ml: 1.5,
                                                                    }}
                                                                    border={0}
                                                                >
                                                                    <Typography variant="bookedclients">
                                                                        {
                                                                            phonetouse
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                        border={0}
                                        key={`clearicon-${userid}`}
                                    >
                                        <Grid
                                            container
                                            key={`cleariconcontainer-${userid}`}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                border={0}
                                                key={`clearicon-item-${userid}`}
                                            >
                                                <ClearIcon
                                                    ley={`clearicon-maybe--icon-${userid}`}
                                                    color="secondary"
                                                    onClick={() =>
                                                        removeClient(attendee)
                                                    }
                                                    sx={{
                                                        fontSize: 20,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                </List>
            </Grid>
        </Grid>
    );
};

export default PotentialClients;
