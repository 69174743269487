import React from 'react';
import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from 'App';
import Loadable from 'ui-component/Loadable';
// import Root from './root';

import WidgetPage from 'views/index';
import PackagesPage from 'views/packages';
import CheckoutPage from 'views/checkout';
import PaymentPage from 'views/payment';
import PurchaseConfirmationPage from 'views/reviewPurchase';
import PurchaseCompletePage from 'views/purchaseIsComplete';
// import AccountPage from 'views/account';
import Protected from 'views/auth/protected';
import LoginPageView from 'views/auth/login';
import RegisterPage from 'views/authentication/register';
import RegisterAddProfile from 'views/authentication/registerAddProfile';
import ForgotPassword from 'studio-admin/components/Auth/Forgot';
import LoginEmailLink from 'views/auth/loginNoPwd';
import LoginNew from 'views/new-authentication/login-new';
import ValidateLink from 'views/auth/validationFromLink';
import BookClass from 'views/bookClass';
// import DashboardPage from 'views/dashboard';
// import Onboarding from 'studio-admin/containers/Onboarding';
import StudioAdminLogin from 'studio-admin/components/Auth/Login';
// import StudioAdminLogin from 'views/authentication/login';
import StudioAdminLayout from 'studio-admin/containers/Layout';
import StudioAdminFrontDesk from 'studio-admin/containers/FrontDesk';
// import MainLayout from 'layouts-new/MainLayout/main-layout-new';
import MainLayout from 'layouts-new/MainLayout';
import FrontDeskNew from 'studio-admin/containers/FrontDesk';
import ErrorPage from 'views/error';
import SchedulePageNotLazy from 'views/studio-admin/class-schedule/calendar';
import Availability from 'views/studio-admin/class-schedule/calendar';
import FrontDeskPageNonLazy from 'views/studio-admin/front-desk';
// check on account layout
// import Onboarding from 'studio-admin/containers/Onboarding';
// ADMIN TESTING is the one to use right now
// import BarbersPage from 'views/studio-admin/instructors';

const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
const FrontDeskPage = Loadable(
    lazy(() => import('views/studio-admin/front-desk'))
);

const AvailabilityPage = Loadable(
    lazy(() => import('views/studio-admin/availability'))
);
const ReportingPage = Loadable(
    lazy(() => import('views/studio-admin/reporting'))
);
const SchedulePage = Loadable(
    lazy(() => import('views/studio-admin/class-schedule/calendar'))
);
const InstructorPage = Loadable(
    lazy(() => import('views/studio-admin/instructors'))
);
const BarbersPage = Loadable(
    lazy(() => import('views/studio-admin/instructors'))
);
const BarbersPageTest = Loadable(
    lazy(() => import('views/studio-admin/front-desk'))
);
const SettingsPage = Loadable(
    lazy(() => import('views/studio-admin/settings'))
);
const AccountsPage = Loadable(lazy(() => import('views/studio-admin/account')));
const PayoutsPage = Loadable(lazy(() => import('views/studio-admin/payouts')));
const ClientAccountPage = Loadable(
    lazy(() => import('views/studio-admin/front-desk/clientAccount'))
);

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            // {
            //     path: 'packages/:dibsId',
            //     element: <PackagesPage />,
            // },
            {
                path: '/login/:dibsId',
                element: <StudioAdminLogin />,
            },
            {
                path: '/register/:dibsId',
                element: <StudioAdminLogin />,
            },
            {
                path: '/forgot/:dibsId',
                element: <ForgotPassword />,
            },
            {
                path: 'dashboard/:dibsId',
                element: (
                    <MainLayout>
                        <DashboardPage />
                    </MainLayout>
                ),
            },
            {
                path: 'frontdesk/:dibsId',
                element: (
                    <MainLayout>
                        <FrontDeskPage />
                    </MainLayout>
                ),
            },
            {
                path: 'frontdesk/:dibsId/clients/:userid',
                element: (
                    <MainLayout>
                        <ClientAccountPage />
                    </MainLayout>
                ),
            },
            {
                path: 'frontdesk/:dibsId/clients/:userid/transactions',
                element: (
                    <MainLayout>
                        <ClientAccountPage selectedTab="1" />
                    </MainLayout>
                ),
            },
            {
                path: 'frontdesk/:dibsId/clients/:userid/retail',
                element: (
                    <MainLayout>
                        <ClientAccountPage selectedTab="2" />
                    </MainLayout>
                ),
            },
            {
                path: 'frontdesk/:dibsId/clients/:userid/account-settings',
                element: (
                    <MainLayout>
                        <ClientAccountPage selectedTab="3" />
                    </MainLayout>
                ),
            },
            {
                path: 'reporting/:dibsId',
                element: (
                    <MainLayout>
                        <ReportingPage />
                    </MainLayout>
                ),
            },
            {
                path: 'schedule/:dibsId',
                element: (
                    <MainLayout>
                        <SchedulePageNotLazy />
                    </MainLayout>
                ),
            },
            {
                path: 'availability/:dibsId',
                element: (
                    <MainLayout>
                        <AvailabilityPage />
                    </MainLayout>
                ),
            },
            {
                path: 'instructors/:dibsId',
                element: (
                    <MainLayout>
                        <InstructorPage />
                    </MainLayout>
                ),
            },
            {
                path: 'barbers/:dibsId',
                element: (
                    <MainLayout>
                        <BarbersPage />
                    </MainLayout>
                ),
            },
            {
                path: 'account/:dibsId',
                element: (
                    <MainLayout>
                        <AccountsPage />
                    </MainLayout>
                ),
            },
            {
                path: 'settings/:dibsId',
                element: (
                    <MainLayout>
                        <SettingsPage />
                    </MainLayout>
                ),
            },
            {
                path: 'payouts/:dibsId',
                element: (
                    <MainLayout>
                        <AccountsPage />
                    </MainLayout>
                ),
            },
        ],
    },
]);
export default router;
