import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import Decimal from 'decimal.js';
// import { format as formatCurrency } from 'currency';

import { SpotBookingTypes } from '../../constants/RosterConstants';
import createUnboundedSelector from '../unbounded-selector';
import {
    getUserStudioCurrency,
    getUserStudioHasMultipleLocations,
    getStudioCustomTimeFormat,
    getUserStudioFirstClassFixedPrice,
    getUserStudioCancelTime,
    getUserStudioShorterDateFormat,
} from '../user';
import { getSelectedLocationId } from '../locations';
import { getUserIsInstructorOnly } from '../user';
import { getUserInstructorId } from '../instructor';
import { getClientCartItems, getClientCartTotalQuantity } from '../client/cart';
import { getClientEvents } from '../client/events';
import {
    getClientNextPass,
    getClientFixedPrice,
    getClientNextPassId,
    getClientNextPassValue,
    getClientHasPasses,
} from '../client/passes';
import {
    getClientFirstName,
    getClientId,
    getClientHasMadePurchaseAtStudio,
} from '../client/index';
import { setSpotInCart } from '../../actions/ClientActions/CartActions';

/**
 * getFeed
 * @param {Object} state in store
 * @returns {Object} feed state
 */
export function getFeed(state) {
    return state.feed || {};
}
const formatCurrency = (price, { code }) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: code,
    }).format(price);
};
/**
 * @param {Object} state in store
 * @returns {Object} current selected date on the feed
 */
export function getFeedCurrentDate(state) {
    return getFeed(state).currentDate;
}

/**
 * @param {Object} state in store
 * @returns {Array<Object>} events displayed on the feed
 */
export function getFeedEvents(state) {
    return getFeed(state).events;
}

/**
 * @param {Object} state in store
 * @returns {Object} map to what events are loading
 */
export function getFeedFetchingEvents(state) {
    return getFeed(state).fetchingEvents || {};
}

export const getFeedEventsWithMemberFixedPrice = createSelector(
    [getFeedEvents, getClientNextPass, getClientFixedPrice],
    (events, getPass, fixedPrice) =>
        events.map((event) => ({
            ...event,
            price:
                !getPass(event.id) && fixedPrice && event.can_apply_pass
                    ? Math.min(fixedPrice, event.price)
                    : event.price,
        }))
);

export const getFeedEventsWithFixedPrice = createSelector(
    [
        getClientId,
        getClientHasMadePurchaseAtStudio,
        getClientCartTotalQuantity,
        getFeedEventsWithMemberFixedPrice,
        getUserStudioFirstClassFixedPrice,
    ],
    (
        clientid,
        clientHasMadePurchaseAtStudio,
        cartQuantity,
        events,
        firstClassFixedPrice
    ) =>
        !clientid ||
        clientHasMadePurchaseAtStudio ||
        cartQuantity ||
        !firstClassFixedPrice
            ? events
            : events.map((event) => ({
                  ...event,
                  price: Math.min(firstClassFixedPrice, event.price),
              }))
);

export const getFetchingFeedEvents = createSelector(
    [getFeed, getFeedCurrentDate],
    (feed, currentDate) =>
        feed.fetchingEvents[moment(currentDate).toISOString()]
);

export const getFeedEventsOnCurrentDate = createUnboundedSelector(
    [getFeedEventsWithFixedPrice, getFeedCurrentDate],
    (events, currentDate) =>
        events.filter((event) => {
            const classTime = moment(event.start_time).tz(event.mainTZ);
            return classTime.isSame(currentDate, 'day');
        })
);

export const getDetailedFeedEvents = createSelector(
    [
        getFeedEventsOnCurrentDate,
        getUserStudioCurrency,
        getClientCartItems,
        getClientEvents,
        getUserStudioHasMultipleLocations,
        getStudioCustomTimeFormat,
        (state) => state.windowSize.isMobileSize,
        getClientNextPassId,
        getClientNextPassValue,
        getClientHasPasses,
        getClientFirstName,
        getUserStudioShorterDateFormat,
    ],
    (
        events,
        currency,
        cartItems,
        upcomingEvents,
        studioHasMultipleLocations,
        customTimeFormat,
        isMobileSize,
        getPassId,
        getPassValue,
        userHasPasses,
        clientFirstName,
        shortDateFormat
    ) =>
        events.map(({ instructor, location, ...event }) => {
            const localeTimeInTZ = moment(event.start_time).tz(event.mainTZ);
            const eventItemsInCart = cartItems.filter(
                (cartEvent) => cartEvent.eventid === event.id
            );
            const quantityInCart = eventItemsInCart
                .map(({ quantity }) => quantity)
                .reduce((acc, quantity) => acc + quantity, 0);
            const maxSeatsReached = Boolean(
                eventItemsInCart?.length &&
                    quantityInCart + event.current_enrollment ===
                        event.maximum_enrollment
            );
            const bookedEvent = upcomingEvents.find(
                (clientEvent) => clientEvent.eventid === event.id
            );
            const classDescription = event.description;
            const passValue = getPassValue(event.id);
            const valueBack = passValue
                ? Math.max(
                      0,
                      Decimal(passValue || 0)
                          .minus(event.price)
                          .toDecimalPlaces(2)
                          .toNumber()
                  )
                : 0;
            const formattedDayMonth = moment(event.start_time).format(
                `ddd ${shortDateFormat}`
            );
            return {
                ...event,
                hasWaitList: event.has_waitlist,
                isOnDemand: event.on_demand,
                isWaitlist: Boolean(bookedEvent && bookedEvent.isWaitlist),
                eventid: event.id,
                startTimeInLocalTZ: localeTimeInTZ.format(
                    customTimeFormat || 'LT'
                ),
                timeDuration: moment
                    .duration(
                        moment(event.end_time).diff(moment(event.start_time))
                    )
                    .asMinutes(),
                trainerid: instructor.id,
                instructorName: instructor.name,
                locationName: location.name,
                locationId: location.id,
                formattedPrice: formatCurrency(event.price, {
                    precision: event.price % 1 > 0 ? 2 : 0,
                    code: currency,
                }),
                soldOut: event.seats_remaining === 0,
                maxSeatsReached,
                quantity: quantityInCart,
                seatsSold: event.current_enrollment,
                seatsUserBooked: bookedEvent ? bookedEvent.quantity : 0,
                taxRate: location.tax_rate,
                classDescription,
                studioHasMultipleLocations,
                isMobileSize,
                userHasPasses,
                clientFirstName,
                passid: getPassId(event.id),
                valueBack,
                formattedValueBack: valueBack
                    ? formatCurrency(valueBack, {
                          precision: valueBack % 1 && 2,
                          code: currency,
                      })
                    : null,
                formattedDayMonth,
            };
        })
);

export const getDetailedFeedEventsForTableRow = createSelector(
    [
        getDetailedFeedEvents,
        getSelectedLocationId,
        getUserIsInstructorOnly,
        getUserInstructorId,
    ],
    (feedEvent, locationId, instructor, instructorId) =>
        feedEvent
            .map((event) => {
                const waitlistCue =
                    event.hasWaitList && event.isfull ? '*' : '';
                const classCount = `${event.seatsSold}/${event.maximum_enrollment}${waitlistCue}`;
                // console.log(`user is instructor only value => ${instructor}`);
                // console.log(`user - getinstructorid ==> ${instructorId}`);
                // console.log(`locationId ==> ${locationId}`);
                return {
                    ...event,
                    classCount,
                    locationName: event.locationName,
                    eventName: event.name,
                    trainerid: event.trainerid,
                    instructorName: event.instructorName,
                    formattedDayMonth: event.formattedDayMonth,
                    startTimeInLocalTZ: event.startTimeInLocalTZ,
                    eventid: event.eventid,
                    seatsSold: event.seatsSold,
                    name: event.name,
                };
            })
            .filter((ev) => {
                if (!locationId) return ev;
                return ev.locationId === locationId;
            })
);

export const getRoomForEvent = createSelector(
    getFeedEvents,
    (events) => (eventid) =>
        (events.find((event) => Number(eventid) === event.id) || {}).room || {}
);

export const getAvailableSpotsForEvent = createSelector(
    getRoomForEvent,
    (getRoom) => (eventid) => getRoom(eventid).availableSpots || []
);

export const getSpotGridForFeedEvent = createSelector(
    getRoomForEvent,
    (getRoom) => (eventid) => getRoom(eventid).spotGrid || []
);

export const getFullSpotGridForFeedEvent = createSelector(
    getSpotGridForFeedEvent,
    (getSpotGrid) => (eventid) => {
        const grid = getSpotGrid(eventid);
        const maxRowLength = Math.max(...grid.map((arr) => arr?.length));
        const fullGrid = [];
        for (let i = 0; i < grid?.length; i += 1) {
            const fullRow = [];
            for (let j = 0; j < maxRowLength; j += 1) {
                const spot = grid[i][j];
                fullRow.push(spot || null);
            }
            fullGrid.push(fullRow);
        }
        return fullGrid;
    }
);

export const getTypedSpotGridForFeedEvent = createSelector(
    getFullSpotGridForFeedEvent,
    (getSpotGrid) => (eventid) =>
        getSpotGrid(eventid).map((row) =>
            row.map((spot) => {
                if (!spot) return null;
                switch (spot.type) {
                    case SpotBookingTypes.BOOKABLE:
                        return {
                            ...spot,
                            label: String(spot.source_id || spot.spot_label),
                        };
                    case SpotBookingTypes.INSTRUCTOR:
                        return {
                            ...spot,
                            instructor: true,
                        };
                    default:
                        return null;
                }
            })
        )
);

export const getSpotGridForFeedEventWithSelectedSpots = createSelector(
    [getSpotGridForFeedEvent, getClientId],
    (getSpotGrid, clientid) => (eventid) =>
        getSpotGrid(eventid).map((spotRow) =>
            spotRow.map((spot) =>
                spot
                    ? {
                          ...spot,
                          userSelected: spot.userid === clientid,
                      }
                    : null
            )
        )
);

export const getSpotGridForFeedEventWithSetter = createSelector(
    [getSpotGridForFeedEventWithSelectedSpots, getClientId],
    (getGridWithSelections, clientid) => (eventid) => {
        const spotGrid = getGridWithSelections(eventid).map((spotRow) =>
            spotRow.map((spot) =>
                spot
                    ? {
                          ...spot,
                          addUserToSpot: setSpotInCart(
                              eventid,
                              spot.id,
                              clientid
                          ),
                      }
                    : null
            )
        );
        return spotGrid;
    }
);

export const getAllDetailedFeedEvents = createSelector(
    [
        getFeedEvents,
        getUserStudioCurrency,
        getClientCartItems,
        getClientEvents,
        getUserStudioHasMultipleLocations,
        getStudioCustomTimeFormat,
        (state) => state.windowSize.isMobileSize,
        getClientNextPassId,
        getClientNextPassValue,
        getClientHasPasses,
        getClientFirstName,
        getUserStudioShorterDateFormat,
    ],
    (
        events,
        currency,
        cartItems,
        upcomingEvents,
        studioHasMultipleLocations,
        customTimeFormat,
        isMobileSize,
        getPassId,
        getPassValue,
        userHasPasses,
        clientFirstName,
        shortDataFormat
    ) =>
        events.map(({ instructor, location, ...event }) => {
            const localeTimeInTZ = moment(event.start_time).tz(event.mainTZ);
            const eventItemsInCart = cartItems.filter(
                (cartEvent) => cartEvent.eventid === event.id
            );
            const quantityInCart = eventItemsInCart
                .map(({ quantity }) => quantity)
                .reduce((acc, quantity) => acc + quantity, 0);
            const maxSeatsReached = Boolean(
                eventItemsInCart?.length &&
                    quantityInCart + event.current_enrollment ===
                        event.maximum_enrollment
            );
            const bookedEvent = upcomingEvents.find(
                (clientEvent) => clientEvent.eventid === event.id
            );
            const classDescription = event.description;
            const passValue = getPassValue(event.id);
            const valueBack = passValue
                ? Math.max(
                      0,
                      Decimal(passValue || 0)
                          .minus(event.price)
                          .toDecimalPlaces(2)
                          .toNumber()
                  )
                : 0;
            const formattedDayMonth = moment(event.start_time).format(
                `ddd ${shortDataFormat}`
            );
            return {
                ...event,
                hasWaitList: event.has_waitlist,
                isOnDemand: event.on_demand,
                isWaitlist: Boolean(bookedEvent && bookedEvent.isWaitlist),
                eventid: event.id,
                startTimeInLocalTZ: localeTimeInTZ.format(
                    customTimeFormat || 'LT'
                ),
                timeDuration: moment
                    .duration(
                        moment(event.end_time).diff(moment(event.start_time))
                    )
                    .asMinutes(),
                instructorName: instructor.name,
                trainerid: instructor.id,
                locationName: location.name,
                locationId: location.id,
                formattedPrice: formatCurrency(event.price, {
                    precision: event.price % 1 > 0 ? 2 : 0,
                    code: currency,
                }),
                soldOut: event.seats_remaining === 0,
                maxSeatsReached,
                quantity: quantityInCart,
                seatsSold: event.current_enrollment,
                seatsUserBooked: bookedEvent ? bookedEvent.quantity : 0,
                taxRate: location.tax_rate,
                classDescription,
                studioHasMultipleLocations,
                isMobileSize,
                userHasPasses,
                clientFirstName,
                passid: getPassId(event.id),
                valueBack,
                formattedValueBack: valueBack
                    ? formatCurrency(valueBack, {
                          precision: valueBack % 1 && 2,
                          code: currency,
                      })
                    : null,
                formattedDayMonth,
            };
        })
);

export const getAllDetailedFeedEventsForTableRow = createSelector(
    [getAllDetailedFeedEvents, getSelectedLocationId],
    (feedEvent, locationId) =>
        feedEvent
            .map((event) => {
                const waitlistCue =
                    event.hasWaitList && event.isfull ? '*' : '';
                const classCount = `${event.seatsSold}/${event.maximum_enrollment}${waitlistCue}`;

                return {
                    ...event,
                    classCount,
                    locationName: event.locationName,
                    eventName: event.name,
                    instructorName: event.instructorName,
                    trainerid: event.trainerid,
                    formattedDayMonth: event.formattedDayMonth,
                    startTimeInLocalTZ: event.startTimeInLocalTZ,
                    eventid: event.eventid,
                    startTime: event.start_time,
                    seatsSold: event.seatsSold,
                    name: event.name,
                };
            })
            .filter((ev) => {
                if (!locationId) return ev;
                return ev.locationId === locationId;
            })
);

export const getAllDetailedFeedEventsForTableRowByWeek = createSelector(
    [getFeedCurrentDate, getAllDetailedFeedEventsForTableRow],
    (currentDate, events) =>
        events.filter((event, i) =>
            moment(event.startTime).isSame(
                moment(currentDate)
                    .startOf('week')
                    .add(i + 1, 'day'),
                'days'
            )
        )
);

export const getFeedEventById = createSelector(
    [getAllDetailedFeedEvents, (_, eventid) => +eventid],
    (events, eventid) => events.find((event) => event.eventid === eventid)
);

export const getFeedEventNameById = createSelector(getFeedEventById, (event) =>
    event ? event.name : ''
);

export const getLoadingFeedEventById = createSelector(
    [getFeedFetchingEvents, (_, eventid) => eventid],
    (fetchingEvents, eventid) => Boolean(fetchingEvents[eventid])
);

export const getFeedEventIsEarlyCancelById = createSelector(
    getFeedEventById,
    getUserStudioCancelTime,
    (event, cancelTime) =>
        moment(event.start_time)
            .tz(event.mainTZ)
            .subtract(cancelTime, 'h')
            .isAfter(moment())
);

export const getFeedEventNames = createSelector(
    getFeed,
    (feed) => feed.eventNames
);

export const getFeedEventNameOptions = createSelector(
    getFeedEventNames,
    (eventNames) => [
        { label: 'All', value: 'all' },
        ...eventNames.map(({ name }) => ({ label: name, value: name })),
    ]
);
