import moment from "moment-timezone";
import { createSelector } from "reselect";
import { getUserStudioMainTZ, getUserStudioDateFormat } from "../user/index";

/**
 * getPayoutState
 * @param {object} state redux state
 * @returns {object} payout data
 */
export function getPayoutsState(state) {
  return state.payouts;
}

/**
 *
 * @param {object} state  redux state
 * @returns {object} all payouts
 */
export function getAllPayouts(state) {
  return state.payouts.all || [];
}

/**
 *
 * @param {object} state  redux state
 * @returns {object} active payout
 */
export function getActivePayout(state) {
  return state.payouts.active || { charges: {} };
}

export const getFormattedPayouts = createSelector(
  [getAllPayouts, getUserStudioMainTZ, getUserStudioDateFormat],
  (payouts, timezone, dateFormat) =>
    payouts.map(({ id, date, amount, bankAccount }) => ({
      id,
      date: moment(date).tz(timezone).format(dateFormat),
      amount,
      bankAccount,
    })),
);

export const getActivePayoutTransactions = createSelector(
  getActivePayout,
  (payout) => payout.transactions || [],
);
