import { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useSelector } from 'store';

export default function SimpleBackdrop() {
    const [open, setOpen] = useState(true);
    const { pageIsLoading } = useSelector((state) => state.loadingStatus);
    useEffect(() => {
        if (pageIsLoading) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [pageIsLoading]);

    return (
        <div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
