import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { clearNotice } from "../../actions/NoticeActions";
import "../../styles/banner.scss";

/**
 * @class NoticeBar
 * @extends {React.PureComponent}
 */
class NoticeBar extends React.PureComponent {
  /**
   * @returns {undefined}
   */
  componentDidMount() {
    this.clearNotice = setTimeout(this.props.clearNotice, 5000);
  }
  /**
   * @returns {undefined}
   */
  componentWillReceiveProps() {
    if (this.clearNotice) clearInterval(this.clearNotice);
    this.clearNotice = setTimeout(this.props.clearNotice, 5000);
  }
  /**
   * @returns {undefined}
   */
  componentWillUnmount() {
    if (this.clearNotice) clearInterval(this.clearNotice);
  }
  /**
   * render
   * @returns {JSX.Element} HTML
   */
  render() {
    return (
      <div className={`banner notice ${this.props.notice ? "" : "hidden"}`}>
        <div className="message">
          <div>{this.props.notice}</div>
        </div>
        <button onClick={this.props.clearNotice} className="clear-btn">
          <svg height="12px" viewBox="0 0 12 12">
            <path d="M 0 0 L 12 12" />
            <path d="M 0 12 L 12 0" />
          </svg>
        </button>
      </div>
    );
  }
}

NoticeBar.propTypes = {
  notice: PropTypes.string,
  clearNotice: PropTypes.func,
};

export default connect((state) => ({ notice: state.notice }), { clearNotice })(
  NoticeBar,
);
