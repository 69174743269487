import React, { Component, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import classNames from "classnames";
import { dispatch, useSelector } from "store";
import { setMenuLocation } from "store/slices/menu";
import ClientSearchBar from "../components/FrontDesk/ClientSearch";
import CreateClientAccount from "../components/FrontDesk/CreateClientAccount";
import AddPromoCode from "../components/FrontDesk/AddPromoCode";
import AddPackage from "../components/FrontDesk/AddPackage";
import AddVideo from "../components/FrontDesk/AddVideo";
import RetailListContainer from "./Retail/RetailListContainer";

import { getUserIsAdmin } from "../selectors/user/index";
import "../styles/front-desk.scss";
import { forgetClient } from "../actions/ClientActions";
// import { maintainRecurringClasses } from '../actions/RosterActions';
import { Tabs, Tab, Box, Typography, Grid } from "@mui/material";
import "../styles/tabs.scss";

const DISABLED_TEXT_COLOR = "#d4d4d4";
const ENABLED_TEXT_COLOR = "#000";
// guide: front desk menu tabs

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="front-desk-container"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "100%" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const styles = {
  buttonStyle: {
    background: "#fff",
    borderBottom: "1px solid #d4d4d4",
    color: DISABLED_TEXT_COLOR,
  },
  black: { color: "#000" },
  grayedOut: { color: DISABLED_TEXT_COLOR },
  tabs: {
    fontSize: "12px",
    fontFamily: "Roboto",
    textTransform: "none",
    background: "#fff",
    width: "100%",
  },
};
const FrontDeskNew = () => {
  const [showClients, setShowClients] = useState(true);
  const [clientSelected, setClientSelected] = useState(null);
  const [value, setValue] = useState(0);
  const [userIsAdmin, setUserIsAdmin] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const handleChange = (event, newValue) => {
    console.log(`newValue: ${newValue}`);
    console.log("event", event);
    setValue(newValue);
    dispatch(setMenuLocation(newValue));
  };
  return (
    <div
      className="tab__component"
      id="frontdesktabs-menu"
      // style={{ minHeight: '95vh' }}
    >
      <Box sx={{ width: "100%" }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="front-desk"
            indicatorColor="secondary"
            className="tab__tabs"
            textColor="inherit"
            variant="fullWidth"
            style={styles.tabs}
          >
            <Tab
              label="Clients"
              id="menu-clients-tab"
              {...a11yProps(0)}
              sx={{ borderBottom: "1px solid #d4d4d4" }}
            />
            <Tab
              label="Promos"
              {...a11yProps(1)}
              sx={{ borderBottom: "1px solid #d4d4d4" }}
            />
            <Tab
              label="Packages"
              {...a11yProps(2)}
              sx={{ borderBottom: "1px solid #d4d4d4" }}
            />
            <Tab
              label="Retail"
              {...a11yProps(3)}
              sx={{ borderBottom: "1px solid #d4d4d4" }}
            />
            {/* <Tab
                            label="Videos"
                            {...a11yProps(4)}
                            sx={{ borderBottom: '1px solid #d4d4d4' }}
                        /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ClientSearchBar />
          <CreateClientAccount />
        </TabPanel>
        <TabPanel value={value} index={1}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </Box>
    </div>
  );
};
export default FrontDeskNew;
