import { initializeApp } from 'firebase/app';
import { useParams } from 'react-router-dom';
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    updateProfile,
    sendSignInLinkToEmail,
    isSignInWithEmailLink,
    signInWithEmailLink,
    updatePassword,
} from 'firebase/auth';
import { dispatch } from 'store';

import { addUserInfoAfterLogin } from 'actions/updateUserProfileInStore';
import addColorsToInstructors from 'actions/studios/instructors/addColorsToInstructors';
import {
    USER_NOT_FOUND,
    NO_USER_MSG,
    WRONG_PASSWORD,
    WRONG_PASSWORD_MSG,
    SUCCESSFUL_PASSWORD_RESET,
    TOO_MANY_REQUESTS,
    TOO_MANY_REQUESTS_MSG,
    NETWORK_FAILURE,
    NETWORK_FAILURE_MSG,
    BEING_REDIRECTED_LOGIN_MSG,
    UNKNOWN_ERROR_MSG,
    NEW_ACCOUNT_CREATED,
    EMAIL_ALREADY_IN_USE,
    EMAIL_ALREADY_IN_USE_MSG,
} from 'constants/error-success-codes';
import {
    setHasErrorReducer,
    setErrorMessageReducer,
    clearErrorMessageReducer,
    setHasSuccessReducer,
    setSuccessMessageReducer,
    clearSuccessMessageReducer,
} from 'store/slices/error';
import {
    clearThisUser,
    setThisUserEmail,
    setThisUserFirstName,
    setIsLoggedIn,
    // setIsInLoginLinkFlow,
    // setHasSignedInFirebase,
    // setRecentlyLoggedIn,
    // setHasSetPwdFirebase,
} from 'store/slices/studioemployeeuser';

import { FIREBASE_CONFIG } from 'config';

const app = initializeApp(FIREBASE_CONFIG);
const auth = getAuth(app);

const checkStatusOfUser = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user;
};

const setTokenForUser = async (auth) => {
    await auth.currentUser
        .getIdToken()
        .then((idToken) => {
            localStorage.setItem('dibs-token', idToken);
        })
        .catch((error) => {
            console.log(`error getting token: ${error}`);
        });
};

const assignErrorMessage = (errorCode) => {
    switch (errorCode) {
        case USER_NOT_FOUND:
            dispatch(setErrorMessageReducer(NO_USER_MSG));
            break;
        case WRONG_PASSWORD:
            dispatch(setErrorMessageReducer(WRONG_PASSWORD_MSG));
            break;
        case TOO_MANY_REQUESTS:
            dispatch(setErrorMessageReducer(TOO_MANY_REQUESTS_MSG));
            break;
        case NETWORK_FAILURE:
            dispatch(setErrorMessageReducer(NETWORK_FAILURE_MSG));
            break;
        case EMAIL_ALREADY_IN_USE:
            dispatch(setErrorMessageReducer(EMAIL_ALREADY_IN_USE_MSG));
            break;
        default:
            dispatch(setErrorMessageReducer(UNKNOWN_ERROR_MSG));
            break;
    }
};
const triggerError = () => {
    dispatch(setHasErrorReducer(true));
    setTimeout(() => {
        dispatch(clearErrorMessageReducer());
    }, 15000);
};
const setSuccessMessage = (successMsg) => {
    dispatch(setSuccessMessageReducer(successMsg));
};
const triggerSuccess = () => {
    dispatch(setHasSuccessReducer(true));
    setTimeout(() => {
        dispatch(clearSuccessMessageReducer());
    }, 12000);
};
const triggerShortSuccess = () => {
    dispatch(setHasSuccessReducer(true));
    setTimeout(() => {
        dispatch(clearSuccessMessageReducer());
    }, 2000);
};

const logInWithEmailAndPassword = async (email, password, dibsId) => {
    const promises = [];

    try {
        let user;
        const signin = async () => {
            await signInWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    // Signed in

                    user = userCredential.user;
                    dispatch(setThisUserEmail(user.email));
                    dispatch(setThisUserFirstName(user.displayName));
                    dispatch(setIsLoggedIn(true));
                    setTokenForUser(auth);
                    // getDibsStudioConfig();
                    addUserInfoAfterLogin(user.email, dibsId);
                    addColorsToInstructors(dibsId);

                    // dispatch(setRecentlyLoggedIn(true));
                    // setTimeout(() => {
                    //     dispatch(setRecentlyLoggedIn(false));
                    // }, 300000);
                    // ...
                })
                .catch((error) => {
                    const errorCode = error.code;
                    console.log(
                        `error code from firebase is: ${JSON.stringify(
                            errorCode
                        )}`
                    );
                    assignErrorMessage(errorCode);
                    triggerError();
                    return errorCode;
                });
        };
        promises.push(signin());
        await Promise.all(promises);
        return user;
    } catch (err) {
        console.error(err);
        // alert(err.message);
        return err;
    }
};

const setAPassword = async (password) => {
    // Get the current user
    let passwordSet = false;
    await updatePassword(auth.currentUser, password)
        .then(() => {
            passwordSet = true;
            // dispatch(setHasSetPwdFirebase(true));
        })
        .catch((error) => {
            console.log('error updating password', error);
            if (!passwordSet) return 0;
        });
    return 1;
};

const resetPasswordFromProfile = async (
    email,
    currentpassword,
    newpassword
) => {
    let verifiedoldpassword = false;
    let passwordUpdated = false;
    await signInWithEmailAndPassword(auth, email, currentpassword)
        .then(() => {
            setTokenForUser(auth);
            verifiedoldpassword = true;
        })
        .then(async () => {
            await updatePassword(auth.currentUser, newpassword)
                .then(() => {
                    passwordUpdated = true;
                })
                .catch((error) => {
                    console.log('error updating password', error);
                });
        })
        .catch((error) => {
            console.log(
                `error logging in with old email password combo: ${error}`
            );
        });
    const toreturn = {
        verifiedoldpassword,
        passwordUpdated,
    };
    return toreturn;
};
const getActionCodeSettings = (dibsId) => {
    const environ = process.env.NODE_ENV;
    let prefix = 'http://';
    let url = `http://localhost:3000/validate-link/${dibsId}`;
    if (environ !== 'development') {
        prefix = 'https://';
        url = `${prefix}${window.location.hostname}/validate-link/${dibsId}`;
        console.log(`url for validation is: ${url}`);
    }
    return {
        // url: `${prefix}${window.location.hostname}/validate-link/${dibsId}`,
        url,
        handleCodeInApp: true,
    };
};
// URL you want to redirect back to. The domain (www.example.com) for this
// URL must be in the authorized domains list in the Firebase Console.
// url: `${window.location.hostname}/validate-link`,
// This must be true.
// handleCodeInApp: true,

const verifyUserClickedEmailLink = async (email) => {
    let emailtouse = email;
    let valuetoreturn = 0;
    if (isSignInWithEmailLink(auth, window.location.href)) {
        let emailtotest = window.localStorage.getItem('emailForSignIn');
        if (email?.length < 3) {
            emailtouse = emailtotest;
        }
        if (!emailtouse) {
            emailtouse = window.prompt(
                'Please provide your email for login confirmation'
            );
        }
    }
    await signInWithEmailLink(auth, emailtouse, window.location.href)
        .then((result) => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            // set that they have been verified

            if (result.user) {
                dispatch(setIsLoggedIn(true));
                // dispatch(setIsInLoginLinkFlow(false));
                console.log(`successfully logged in with email link`);
                // dispatch(setHasSignedInFirebase(true));
                dispatch(setThisUserEmail(result.user.email));
                // dispatch(setRecentlyLoggedIn(true));
                addUserInfoAfterLogin(result.user.email);
                // setTimeout(() => {
                //     dispatch(setRecentlyLoggedIn(false));
                // }, 500000);
            }
            setTokenForUser(auth);
            valuetoreturn = 1;
        })
        .catch((error) => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
            console.log(
                `sign in with email link error is: ${JSON.stringify(
                    error,
                    null,
                    2
                )}`
            );
            valuetoreturn = 0;
        });
    return valuetoreturn;
};
const sendEmailAuthLink = async (email, dibsId, lastLocation) => {
    let thisthing = 0;
    const sendLink = async () => {
        const actionCodeSettings = getActionCodeSettings(dibsId);

        await sendSignInLinkToEmail(auth, email, actionCodeSettings)
            .then(() => {
                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('emailForSignIn', email);
                window.localStorage.setItem('lastLocation', lastLocation);
                // ...
                thisthing = 1;
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(`error code is: ${errorCode}`);
                console.log(`error message is: ${errorMessage}`);
                thisthing = 0;
            });
    };

    await sendLink();
    return thisthing;
};

const registerWithEmailAndPassword = async (email, password) => {
    let successvalue = 0;
    const registerFirebaseUser = async () => {
        try {
            console.log(
                `email and password received are: ${email} ${password}`
            );
            await createUserWithEmailAndPassword(auth, email, password)
                .then((res) => {
                    // const user = res.user;
                    console.log(`user created: ${JSON.stringify(res)}`);
                    // getEmployeeUserInfo(email);
                    // getDibsStudioBasicInfo(email);
                    setTokenForUser(auth);
                    successvalue = 1;
                    return 1;
                })
                .catch((error) => {
                    console.log(
                        `error creating user on firebase: ${JSON.stringify(
                            error
                        )}`
                    );
                    const { code } = error;
                    console.log(
                        'code returned line 329 - creating user on firstbase',
                        code
                    );
                    if (code === 'auth/email-already-in-use') {
                        successvalue = 2;
                    }
                    return 2; // todo - confirm that the error is that user already exists
                });
        } catch (err) {
            console.log(
                `Error from firebase register new user (${err.message})`
            );
            console.error(err);
            const errorCode = err.code;
            assignErrorMessage(errorCode);
            triggerError();
            // successvalue = 0;
            return successvalue;
        }
    };
    const promises = [];
    promises.push(registerFirebaseUser());
    await Promise.all(promises);
    return successvalue;
};

const addFirebaseProfile = async (fname) => {
    const createProfileForFirebaseUser = async () => {
        try {
            // need to add phone number to the database - later add to firebase
            // set the email address to the redux store
            // send firebase firstname as the displayName
            await updateProfile(auth.currentUser, {
                displayName: fname,
            })
                .then(() => {
                    setSuccessMessage(NEW_ACCOUNT_CREATED);
                    triggerShortSuccess();
                })
                .catch((error) => {
                    console.log(
                        `error adding profile to firebase user - ${fname}`
                    );
                    console.error(error);
                    assignErrorMessage(error.code);
                    triggerError();
                });
            return 1; // return true
        } catch (err) {
            console.log(
                `Error from firebase update profile for new user (${err.message})`
            );
            console.error(err);
            const errorCode = err.code;
            assignErrorMessage(errorCode);
            triggerError();
            return 0;
        }
    };
    const callCreateProfileForFirebaseUser = async () => {
        const result = await createProfileForFirebaseUser();
        return result;
    };
    return callCreateProfileForFirebaseUser();
};
const sendPasswordReset = async (email) => {
    try {
        return await sendPasswordResetEmail(auth, email).then((res) => {
            // reset sent
            setSuccessMessage(SUCCESSFUL_PASSWORD_RESET);
            triggerSuccess();
            setTimeout(() => {
                setSuccessMessage(BEING_REDIRECTED_LOGIN_MSG);
            }, 8000);
            console.log('returning response now');
            return 1;
        });
        // return 1; // return true
    } catch (err) {
        console.log(`Error from firebase password reset (${err.message})`);
        console.error(err);
        const errorCode = err.code;
        assignErrorMessage(errorCode);
        triggerError();
        return err;
    }
};
const logout = async () => {
    await signOut(auth).then(() => {
        dispatch(clearThisUser());
        window.localStorage.removeItem('dibs-token');
        window.localStorage.removeItem('persist:studioemployeeuser');
        window.localStorage.removeItem('persist:dibscart');
        window.localStorage.removeItem('datatables-datatables');
        window.localStorage.removeItem('dibsstudio-dibsstudio');
        window.localStorage.removeItem('dibsdashboard-dibsdashboard');
        window.localStorage.removeItem('persist:thisuser');
        window.localStorage.removeItem('apptBeingConfirmed');
        window.localStorage.removeItem('stripeKeyConnected');
        window.localStorage.removeItem('lastLocation');
        window.localStorage.removeItem('lastlocation');
        window.localStorage.removeItem('dibs-instructors');
        return true;
    });
};
export {
    auth,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    addFirebaseProfile,
    sendEmailAuthLink,
    verifyUserClickedEmailLink,
    resetPasswordFromProfile,
    setTokenForUser,
    setAPassword,
    checkStatusOfUser,
};
