import React, { useState } from 'react';
import { FormControl, Select, MenuItem, Box, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root fieldset

export default function RowRadioButtonsGroup({
    options,
    setCriteriaChosen,
    extraAction,
    functionType,
}) {
    const [value, setValue] = React.useState(options[0].codedValue);
    const handleChange = (event) => {
        setValue(event.target.value);
        setCriteriaChosen(event.target.value);
        let thisvalue = event.target.value;
        if (extraAction) {
            extraAction(thisvalue, functionType);
        }
    };
    return (
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={value}
                onChange={handleChange}
            >
                {options.map((option) => {
                    return (
                        <FormControlLabel
                            key={`radio-${option.id}-${option.codedValue}`}
                            id={`radio-${option.id}-${option.codedValue}`}
                            value={option.codedValue}
                            control={<Radio />}
                            label={option.name}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
}
