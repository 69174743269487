import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { shouldForwardProp } from '@mui/system';
import { useSelector, useDispatch } from 'store';
import {
    addToRecentsSearch,
    addOrUpdateSearchResults,
} from '../../store/slices/clientsearch';
import {
    clearPreviousClient,
    setCurrentClientProfileStudioAdmin,
} from '../../store/slices/currentclient';
import { getNewSearchResults } from '../../actions/studios/getNewSearchResults';
import translate from 'helpers/translate';

const OutlineInputStyle = styled(TextField)(({ theme }) => ({
    width: 320,
    marginLeft: 0,
    paddingLeft: 6,
    paddingRight: 6,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important',
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        height: '20px',
    },
    '& .MuiOutlinedInput-root': {
        padding: '6px !important',
        border: 1,
        borderColor: 'red',
    },
    [theme.breakpoints.down('lg')]: {
        width: 250,
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background:
            theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff',
    },
}));

export default function AutocompleteSearch({ idtouse }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { results } = useSelector((state) => state.clientsearch);
    // const { userid } = useSelector((state) => state.currentclient);
    const { recents } = results;
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const [searchTerm, setSearchTerm] = React.useState('');
    const [countLastSearchTerm, setCountLastSearchTerm] = React.useState(0);
    const [countSearchResults, setCountSearchResults] = React.useState(100);
    const [searchResults, setSearchResults] = React.useState(recents);
    const [keyToUse, setKeyToUse] = React.useState(9); // random - just to get it to refresh

    const [searchedUserid, setSearchedUserid] = React.useState(5);
    // const searchOptions = [];
    React.useEffect(() => {
        if (searchTerm.length > 1) {
            getNewSearchResults(config.dibsStudioId, searchTerm).then(
                (result) => {
                    if (result !== 0) {
                        dispatch(addOrUpdateSearchResults(result));
                        setSearchResults(result);
                        setCountSearchResults(result?.length);
                    }
                    if (searchedUserid > 5) {
                        const urltolink = `/frontdesk/${dibsStudioId}/clients/${searchedUserid}`;
                        navigate(urltolink);
                    }
                }
            );
        }

        return () => {
            setSearchedUserid(5);
        };
    }, [
        config.dibsStudioId,
        searchTerm,
        dispatch,
        countSearchResults,
        searchedUserid,
        navigate,
        dibsStudioId,
    ]);
    const filterOptions = createFilterOptions({
        stringify: ({ label, email, id, phone }) =>
            `${label} ${email} ${id} ${phone}`,
    });
    const renderSuggestion = (suggestion) => (
        <div key={suggestion.key} id={idtouse}>
            <span style={{ fontWeight: '450' }}>{suggestion.label}</span>
            <div>
                <span style={{ fontWeight: '300', fontStyle: 'italic' }}>
                    {suggestion.email}
                </span>
            </div>
            <div>
                <span style={{ fontWeight: '300' }}>
                    {suggestion.phonelabel}
                </span>
            </div>
        </div>
    );
    // todo - check change here
    const setRecentOptions = (event, value) => {
        setSearchTerm(value?.label || '');
        setSearchedUserid(value?.id || 5);
        if (value) {
            dispatch(clearPreviousClient());
            dispatch(setCurrentClientProfileStudioAdmin(value));
            dispatch(addToRecentsSearch(value));
        }
        setKeyToUse(keyToUse + 1);
    };
    const testResetOfSearch = (valuefromfield) => {
        if (
            valuefromfield?.length < countLastSearchTerm ||
            countLastSearchTerm === 0
        ) {
            setCountSearchResults(100);
            if (valuefromfield?.length > 1) {
                setCountLastSearchTerm(valuefromfield?.length);
                console.log(
                    '\n\n\n\nCONTINUE TO MONITOR THIS - resetting add or update search results to empty array - maybe should not do that'
                );
                dispatch(addOrUpdateSearchResults([]));
            }
        }
    };
    // const pht = translate({
    //     id: 'client-search-placeholder',
    //     msg: placeholderText,
    //     usedefault: true,
    // });
    let pht = translate({
        id: 'searchclient',
        msg: 'Search for a different client',
    });
    const nooptionstext =
        'No clients were found. You can create a new account for them in the section below.';
    return (
        <Autocomplete
            // id="combo-box-demo"
            autoComplete
            key={keyToUse}
            id={`autocomplete-${idtouse}`}
            // options={recents}
            options={searchResults}
            filterOptions={filterOptions}
            name="clientSearch"
            clearOnEscape
            noOptionsText={nooptionstext}
            onInputChange={(event, value) => {
                setSearchTerm(value);
                testResetOfSearch(value);
                return value;
            }}
            getOptionLabel={({ label }) => {
                const optiontoDisplay = `${label}`;
                return optiontoDisplay;
            }}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            filterSelectedOptions
            renderOption={(props, option) => {
                const htmlForList = renderSuggestion(option);
                return (
                    <li {...props} key={option.id}>
                        {htmlForList}
                    </li>
                );
            }}
            onChange={(event, value) => setRecentOptions(event, value)}
            sx={{ py: 0 }}
            renderInput={(params) => (
                <OutlineInputStyle placeholder={pht} {...params} />
            )}
        />
    );
}
