import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import ControllerModal from "../components/Tutorial/ControllerModal";
import NodeCloneContainer from "../components/Tutorial/NodeCloneContainer";
import "../styles/tutorial.scss";

/**
 * Tutorial Stateless Functional Component
 * @param {Object} props Component props
 * @returns {JSX.Element} HTML
 */
function Tutorial({ isOpen }) {
  return (
    <div
      className={`app-container tutorial-container ${isOpen ? "" : "hidden"}`}
    >
      <ControllerModal />
      <NodeCloneContainer />
    </div>
  );
}

Tutorial.propTypes = {
  isOpen: PropTypes.bool,
};

// const SmartTutorial = connect(({ tutorial: { isOpen } }) => ({ isOpen }))(
//     Tutorial,
// );

export default withRouter(Tutorial);
