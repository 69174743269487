import axios from 'axios';

import { getApiUrl } from 'helpers';

// ==============================|| CREATE NEW MEMBERSHIP OR PACKAGE FOR STUDIO ||============================== //

export const createNewMembershipOrPackage = async (
    packToCreate,
    dibsStudioId
) => {
    try {
        const url = getApiUrl('/studio-admin/new-membership-or-package');
        const response = await axios.post(url, {
            dibsStudioId,
            newPackage: packToCreate,
        });

        return {
            success: response.data.success,
            msg: response.data.msg,
            newPackageCreated: response.data.packCreated,
        };
    } catch (err) {
        console.log(
            `error createNewMembershipOrPackage for dibsStudioId: ${dibsStudioId}\nerr is: ${err}\n\nCNMP - Package was ${packToCreate}`
        );
    }
    return 0;
};

export default createNewMembershipOrPackage;
