import React, { useEffect, useState } from 'react';
import { Grid, Typography, Fade, Button } from '@mui/material';

import removeAttendeefromClass from 'actions/schedule/removeAttendeeFromClass';
import setAlert from 'actions/status/setAlert';

import { capitalizeFirstLetter } from 'helpers/general';
import { dispatch, useSelector } from 'store';

import { getEvents } from 'store/slices/calendar';

const ConfirmClientRemoval = ({
    currentClient,
    cancelRemovingClient,
    thiseventid,
    dibsId,
    startTime,
    setUpdateEvents,
    eventType,
    setClientSelected,
    handleChangeWasMade,
}) => {
    const [text, setText] = useState('');
    const { timeZone } = useSelector((state) => state.dibsstudio.config);
    const eventTypeToUse = eventType === 'class' ? 'class' : 'appointment';
    useEffect(() => {
        if (currentClient)
            setText(
                `Are you sure you'd like to remove ${capitalizeFirstLetter(currentClient.firstname)} ${capitalizeFirstLetter(currentClient.lastname)} from this ${eventTypeToUse}? The ${eventTypeToUse} will remain on the schedule. If you want to cancel this ${eventTypeToUse} entirely, click the 'trash can' button in the top right hand corner.`
            );
    }, [currentClient, eventTypeToUse]);
    const handleCancel = () => {
        cancelRemovingClient();
    };
    const handleYes = async () => {
        const dibsTransId = Number(currentClient.attendeeID);
        await removeAttendeefromClass({
            eventid: thiseventid,
            userid: currentClient.userid,
            id: currentClient.id,
            dibstransactionid: dibsTransId,
            dibsId,
            startTime,
        })
            .then(() => {
                handleChangeWasMade();
                setAlert({
                    msg: `Client was successfully removed. The ${eventTypeToUse} will remain on the schedule unless it is cancelled.`,
                    seconds: 5,
                    success: true,
                });
                setUpdateEvents(true);
                cancelRemovingClient();
                setClientSelected(null);
            })
            .catch((err) => {
                console.log('remove attendee from class', err);
                setAlert({
                    msg: 'Error removing attendee from this event. Please refresh your screen and try again.',
                    seconds: 5,
                    error: true,
                });
            });
    };

    return (
        <Fade in={true} timeout={500}>
            <Grid container>
                <Typography variant="bookedclientsname" sx={{ mt: 2 }}>
                    {text}
                </Typography>
                <Grid item xs={11.5} sx={{ mt: 1 }}>
                    <Grid container justifyContent="flex-end">
                        <Grid item sx={{ mr: 2 }}>
                            <Button
                                id="cancel-appt-button"
                                variant="cancel"
                                sx={{ mt: 1, mb: 2 }}
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item sx={{ mr: 1 }}>
                            <Button
                                variant="confirm"
                                color="secondary"
                                id="save-appt-button-client-removal"
                                sx={{ mt: 1, mb: 2 }}
                                onClick={handleYes}
                            >
                                Yes
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fade>
    );
};

export default ConfirmClientRemoval;
