import React from "react";
import PropTypes from "prop-types";
import "../../styles/dibs-loader.scss";

/**
 * @typedef DibsLoaderProps
 * @property reverseColor {boolean} if true will make the loader blue instead of white
 * @property isInButton {boolean} add css rules for loader in button
 */

/**
 * DibsLoader
 * @param {DibsLoaderProps} props for component
 * @returns {JSX.Element} HTML
 */
function DibsLoader(props) {
  let classnames = "white-dot dibs-highlight-border-text-color";

  if (props.reverseColor) {
    classnames = "blue-dot dibs-highlight-border";
  }

  if (props.isRosey) {
    classnames = "rose-dot dibs-highlight-border";
  }

  return (
    <div className="dibs-loader-container">
      <div className={`dibs-loader${props.isInButton ? " is-button" : ""}`}>
        <div className={`dot ${classnames}`} />
        <div className={`dot ${classnames}`} />
        <div className={`dot ${classnames}`} />
      </div>
    </div>
  );
}

DibsLoader.propTypes = {
  reverseColor: PropTypes.bool,
  isInButton: PropTypes.bool,
  isRosey: PropTypes.bool,
};

export default DibsLoader;
