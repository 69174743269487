// third-party
import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------
const initialState = {
  packages: [],
  isLoading: false,
  hasError: false,
  errorMsg: "",
};

const packages = createSlice({
  name: "packages",
  initialState,
  reducers: {
    // HAS ERROR
    setHasError(state, action) {
      // state.hasError = false;
      // state.hasError = action.payload;
      return {
        ...state,
        hasError: action.payload,
      };
    },
    setErrorMsg(state, action) {
      return {
        ...state,
        errorMsg: action.payload,
      };
    },
    // SET SCHEDULE
    setPackages(state, action) {
      // state.schedule = action.payload;
      return {
        ...state,
        packages: action.payload,
      };
    },
    // SET LOADING
    setLoading(state, action) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    resetPackages(state) {
      return {
        ...state,
        packages: [],
        isLoading: false,
      };
    },
  },
});

// Reducer
export default packages.reducer;
export const {
  setHasError,
  setPackages,
  setErrorMsg,
  setLoading,
  resetPackages,
} = packages.actions;

// ----------------------------------------------------------------------
