import { createSelector } from "reselect";
import moment from "moment";
// import { format as formatCurrency } from 'currency';
import { groupBy, sortBy, map } from "lodash";
import { getClient } from "../index";
import {
  getUserStudioCurrency,
  getUserStudioName,
  getStudioCustomTimeFormat,
} from "../../user/index";
import findLocale from "../../../../shared/helpers/locale-helper";
import formatPassDisplayName from "../../../../shared/helpers/format-pass-display-event";

/**
 * getClientEvents
 * @param {Object} state in Redux store
 * @returns {Array<Object>} array of event objects
 */
export function getClientEvents(state) {
  return getClient(state).events || [];
}

export const getDetailedClientEvents = createSelector(
  [
    getClientEvents,
    getUserStudioCurrency,
    getUserStudioName,
    getStudioCustomTimeFormat,
  ],
  (events, currency, studioName, customTimeFormat) =>
    events.map(({ location, instructor, ...event }) => {
      const localeTimeInTZ = moment(event.start_time)
        .locale(findLocale())
        .tz(event.mainTZ);
      const passDisplayName = formatPassDisplayName(event, currency);
      return {
        ...event,
        naturalStartTimeInLocalTZ: localeTimeInTZ.format("dddd, MMMM D, YYYY"),
        startTimeInLocalTZ: localeTimeInTZ.format(customTimeFormat || "LT"),
        locationName: location.name,
        instructorName: instructor.name,
        address: location.address,
        formattedPrice: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(event.price),
        // formattedValueBack: formatCurrency(event.valueBack, {
        //     code: currency,
        // }),
        formattedValueBack: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(event.valueBack),
        localeTimeInTZ,
        studioName,
        passUsedForEventDisplayName: passDisplayName,
        utcStartTime: moment(
          moment(event.start_time).format("YYYY-MM-DDTHH:mm:ss"),
        )
          .local()
          .format(),
        utcEndTime: moment(moment(event.end_time).format("YYYY-MM-DDTHH:mm:ss"))
          .local()
          .format(),
        spotsReservedByUser: event.spotIds,
      };
    }),
);

export const getDetailedClientEventsSortedByDate = createSelector(
  getDetailedClientEvents,
  (events) =>
    map(
      groupBy(sortBy(events, "start_time"), (event) =>
        moment(event.start_time).format("YYYY/MM/DD"),
      ),
      (event) => event,
    ),
);

export const getClientEventById = createSelector(
  [getDetailedClientEvents, (_, eventid) => +eventid],
  (events, eventid) => events.find((event) => event.eventid === eventid),
);
