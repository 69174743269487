import { createSelector } from 'reselect';
import { uniq } from 'lodash';
import { getClient } from '../index';

/**
 * @param {Object} state in store
 * @returns {Object} client's cart
 */
export function getClientCart(state) {
    return getClient(state).cart || {};
}

/**
 * @param {Object} state in store
 * @returns {boolean} true if purchasing client class
 */
export function getPurchasingClientClasses(state) {
    return Boolean(getClientCart(state).purchasingClasses);
}

/**
 * @param {Object} state in store
 * @returns {boolean} button state depending on purchasing state
 */
export function getCartPurchaseButtonState(state) {
    return getClientCart(state).purchaseState;
}

/**
 * @param {Object} state in store
 * @returns {Object} client's cart
 */
export function getClientCartItems(state) {
    return getClientCart(state).items || [];
}

/**
 * @param {Object} state in store
 * @returns {boolean} true if client cart should be open if quantity is zero (for actions)
 */
export function getClientCartIsVisible(state) {
    return Boolean(
        getClientCartItems(state)?.length || getClientCart(state).visible
    );
}

/**
 * @param {Object} state in store
 * @returns {Object} promoCode applied
 */
export function getClientCartAppliedPromo(state) {
    return getClientCart(state).promoCode || {};
}

/**
 * @param {Object} state in store
 * @returns {Object} promoCode applied
 */
export function getClientPackageAppliedPromo(state) {
    return getClientCartAppliedPromo(state).package || {};
}

/**
 * @param {Object} state in store
 * @returns {string} applied promo code
 */
export function getClientCartAppliedPromoCode(state) {
    return getClientCartAppliedPromo(state).code || '';
}

/**
 * @param {Object} state in store
 * @returns {Object} promoCode applied
 */
export function getClientPackageAppliedPromoCode(state) {
    return getClientPackageAppliedPromo(state).code || '';
}

/**
 * @param {Object} state in store
 * @returns {Object} promoCode applied
 */
export function getClientRetailAppliedPromo(state) {
    return getClientCartAppliedPromo(state).retail || {};
}

/**
 * @param {Object} state in store
 * @returns {string} applied promo code
 */
export function getClientRetailAppliedPromoCode(state) {
    return getClientRetailAppliedPromo(state).code || '';
}

/**
 * @param {Object} state in store
 * @returns {number} of items in the cart
 */
export function getNumberOfItemsInClientCart(state) {
    return getClientCartItems(state)?.length;
}

export const getClientHasItemsInCart = createSelector(
    getClientCartItems,
    (items) => items?.length > 0
);

export const getClientCartTotalQuantity = createSelector(
    getClientCartItems,
    (events) => events.reduce((acc, item) => acc + item.quantity, 0)
);

export const getSortedClientCartItems = createSelector(
    getClientCartItems,
    (cartItems) =>
        cartItems.sort((itemA, itemB) => {
            if (itemA.price === 0 && itemB.price) return 1;
            if (itemB.price === 0 && itemA.price) return -1;
            return itemA.price - itemB.price;
        })
);

export const getClientCartEventIds = createSelector(
    getClientCartItems,
    (events) => uniq(events.map((e) => e.eventid))
);
