import { Grid, Typography } from "@mui/material";

const SuccessText = ({ success }) => {
  return (
    <Grid>
      <Typography variant="successText">{success}</Typography>
    </Grid>
  );
};
export default SuccessText;
