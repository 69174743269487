import React from "react";
import PropTypes from "prop-types";

/**
 * @class RoseBurgerMenuIcon
 * @extends {React.PureComponent}
 */
class RoseBurgerMenuIcon extends React.PureComponent {
  /**
   * render
   * @returns {JSX.Element} HTML
   */
  render() {
    return (
      <svg
        className="hamburger-svg"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="22"
        viewBox="0 0 25 22"
        x="0px"
        y="0px"
      >
        <path
          d="M1.64 3.338h15.47c.898 0 1.64-.72 1.64-1.593S18.008.152 17.11.152H1.64C.743.152 0 .872 0 1.745c0 .872.742 1.593 1.64 1.593zM23.36 9.407H1.64C.743 9.407 0 10.127 0 11c0 .872.742 1.593 1.64 1.593h21.72c.898 0 1.64-.72 1.64-1.593 0-.872-.742-1.593-1.64-1.593zM17.11 18.662H1.64c-.898 0-1.64.72-1.64 1.593s.742 1.593 1.64 1.593h15.47c.898 0 1.64-.72 1.64-1.593 0-.872-.742-1.593-1.64-1.593z"
          fill="#CC6B53"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}

RoseBurgerMenuIcon.propTypes = {};

export default RoseBurgerMenuIcon;
