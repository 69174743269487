// material-ui
import { Grid, Typography, Divider } from '@mui/material';

// project imports
import RetailSearch from '../../sub-components/FrontDesk/SearchTextField';
import ExistingClasses from '../../sub-components/FrontDesk/ExistingClasses';

// ==============================|| SAMPLE PAGE ||============================== //

const newClassGuidance = `If you'd like to create a new retail item, you can enter the name of it here.`;
const newAccountGuidance = `Here is a list of the retail items that you've already created. Click on an item below to make edits.`;
const FrontDeskClients = () => (
    <Grid container direction="column">
        <Grid item xs={5}>
            <Typography gutterBottom variant="h4">
                Create New Retail Items
            </Typography>
        </Grid>
        <Grid container sx={{ marginTop: '8px' }}>
            <Typography gutterBottom variant="h7">
                {newClassGuidance}
            </Typography>
        </Grid>
        <Grid item sx={{ marginTop: '45px' }}>
            <RetailSearch placeholder="Eg. Black Tank Top" />
        </Grid>
        <Grid item sx={{ marginTop: '60px' }}>
            <Divider variant="fullWidth" />
        </Grid>
        <Grid item sx={{ marginTop: '60px' }}>
            <Typography gutterBottom variant="h4">
                IN PROGRESS - View Your Existing Retail Items
            </Typography>
        </Grid>
        <Grid item sx={{ marginTop: '8px' }}>
            <Typography gutterBottom variant="h7">
                {newAccountGuidance}
            </Typography>
        </Grid>
        {/* <Grid item sx={{ marginTop: "45px", marginBottom: "200px" }}>
      <ExistingClasses />
    </Grid> */}
        <Grid item sx={{ marginTop: '60px' }}>
            <Divider variant="fullWidth" />
        </Grid>
    </Grid>
);

export default FrontDeskClients;
