export const newPackQuestion = [
    {
        id: 1,
        isFinalQuestion: false,
        keytouse: 'membership-type',
        title: 'Type of Membership or Package',
        type: 'dropdown',
        functiontype: 'choosemembershiptype',
        defaultQuestion: 'Is this a membership or a package?',
        help: 'Memberships usually renew at the end of a specified period (e.g. monthly), while packages typically include a certain number of visits that can be used by a certain date. Packages do not renew.',
        options: [
            {
                id: 1,
                name: 'Membership',
                codeValue: 'membership',
            },
            {
                id: 2,
                name: 'Package',
                codeValue: 'package',
            },
            {
                id: 3,
                name: 'Single Session',
                codeValue: 'singlesession',
            },
        ],
        nextQuestion: 2,
        secondaryInfo: {
            secondaryQuestion: '',
            appliesTo: 'none',
            secondaryType: 'n/a',
            secondaryTitle: 'no title',
            secondaryCodedValue: 'novalue',
        },
    },
    {
        id: 2,
        isFinalQuestion: false,
        keytouse: 'num-visits-allowed',
        title: 'Number of Visits',
        type: 'radio',
        functiontype: 'chooseunlimitedorfixed',
        defaultQuestion: 'How many visits should be included in each period?',
        help: 'Select the number if times a client can visit during the specified period of time (e.g. unlimited or 10 visits per month). You will select the validity period in the next step.',
        options: [
            {
                id: 1,
                name: 'Unlimited',
                codedValue: 'unlimited',
            },
            {
                id: 2,
                name: 'Fixed # of Visits',
                codedValue: 'fixed',
            },
        ],
        nextQuestion: 3,
        secondaryInfo: {
            secondaryQuestion:
                'Enter the # of visits included per period. (E.g. 10)',
            appliesTo: 'fixed',
            secondaryType: 'numberfield',
            secondaryTitle: 'Number of Visits',
            secondaryCodedValue: 'numvisits',
            secondaryInitialValue: 10,
            fieldWidth: 90,
            functiontype: 'choosenumvisits',
            secondaryFieldnameForChoice: 'classAmount',
        },
    },
    {
        id: 3,
        isFinalQuestion: false,
        title: 'Duration Period',
        keytouse: 'duration-period',
        functiontype: 'chooseperiodtype',
        help: 'Most [REPLACEVALUE]s have a specified period of time that they are valid for. This is the period of time that the client has to use the visits included in the [REPLACEVALUE]. Most common is one month or 90 days.',
        defaultQuestion:
            'How should the duration period be measured (e.g. months, days)?',
        type: 'dropdown',
        fieldnameForChoice: 'autopayIncrement',
        options: [
            {
                id: 1,
                name: 'Months',
                codeValue: 'month',
            },
            {
                id: 2,
                name: 'Days',
                codeValue: 'day',
            },
            {
                id: 3,
                name: 'Weeks',
                codeValue: 'week',
            },
            {
                id: 4,
                name: 'Years',
                codeValue: 'year',
            },
        ],
        nextQuestion: 4,
        secondaryInfo: {
            secondaryQuestion:
                'How long should each period of the [REPLACEVALUE] last?',
            appliesTo: 'all',
            secondaryType: 'numberfield',
            secondaryTitle: 'no title',
            secondaryCodedValue: 'novalue',
            secondaryInitialValue: 1,
            fieldWidth: 90,
            functiontype: 'choosenumperiods',
            secondaryFieldnameForChoice: 'passesValidFor',
        },
        showSummary: true,
        summaryStatement: [
            {
                id: 1,
                criteria: 'membership',
                summarySentence:
                    'This membership will automatically renew after [passesValidFor] [autopayIncrement] (until cancelled).',
            },
            {
                id: 2,
                criteria: 'package',
                summarySentence:
                    'Each package will be valid for [passesValidFor] [autopayIncrement]. This package will not automatically renew.',
            },
            {
                id: 3,
                criteria: 'singlesession',
                summarySentence:
                    'This single session will be valid for [passesValidFor] [autopayIncrement]. This single session will not automatically renew.',
            },
        ],
        variables: [
            {
                id: 1,
                variablename: 'passesValidFor',
                startingvalue: '1',
                drivesPlurality: true,
                getsPluralized: false,
            },
            {
                id: 2,
                variablename: 'autopayIncrement',
                codeValue: 'month',
                drivesPlurality: false,
                getsPluralized: true,
            },
        ],
    },
    {
        id: 4,
        title: 'Price',
        keytouse: 'price',
        functiontype: 'chooseprice',
        type: 'numberfield',
        fieldnameForChoice: 'price',
        isMoney: true,
        isFinalQuestion: false,
        defaultQuestion: 'How much should this [REPLACEVALUE] cost?',
        help: `Enter an integer value that represents the price your clients should pay for this [REPLACEVALUE].`,
        nextQuestion: 5,
    },
    {
        id: 5,
        title: 'Description [Optional]',
        keytouse: 'description',
        functiontype: 'choosedescription',
        fieldnameForChoice: 'description',
        type: 'multilinetext',
        isMoney: false,
        defaultQuestion: 'Enter a description for this [REPLACEVALUE].',
        nextQuestion: 6,
        fieldWidth: 350,
        placeholder: 'Enter your description here',
        isFinalQuestion: false,
        help: `Provide a comprehensive description for your clients. Include any special features, gifts, or perks that come with the [REPLACEVALUE]. This helps clients fully understand what they are buying.`,
    },
    // {
    //     id: 6,
    //     title: 'Free Trial?',
    //     keytouse: 'freetrial',
    //     functiontype: 'choosefreetrial',
    //     type: 'numberfield',
    //     options: [
    //         {
    //             id: 1,
    //             name: 'free-trial',
    //             codeValue: 'free-trial',
    //         },
    //         {
    //             id: 2,
    //             name: 'No Free Trial',
    //             codeValue: 'no-free-trial',
    //         },
    //     ],
    // },
    {
        id: 6,
        title: 'Any Restrictions?',
        keytouse: 'restrictions',
        functiontype: 'chooserestrictions',
        defaultQuestion:
            "Are there any restrictions that you'd like to add to this [REPLACEVALUE].",
        type: 'checkboxes',
        nextQuestion: 'end',
        isFinalQuestion: true,
        help: `Use this section to indicate any restrictions that apply to this [REPLACEVALUE].`,
        checkboxValues: [
            {
                id: 1,
                name: 'New Clients Only',
                codeValue: 'new-clients',
                needsOwnLine: false,
                fieldnameForChoice: 'newClientsOnly',
                infoguidance: `If checked, this [REPLACEVALUE] will be exclusively available to new clients on their first purchase. New clients are defined as those who have never made a purchase at your location before. Once a client has made a purchase, they will no longer be eligible for new client offers.`,
            },
            {
                id: 2,
                name: 'Free [REPLACEVALUE]',
                codeValue: 'free-package',
                needsOwnLine: false,
                fieldnameForChoice: 'isFree',
                infoguidance: `If checked, clients will not be charged for this [REPLACEVALUE].`,
            },
            {
                id: 3,
                name: 'Admin Access Only',
                codeValue: 'admin-only',
                needsOwnLine: false,
                fieldnameForChoice: 'adminAccessOnly',
                infoguidance: `If checked, clients will not be able to purchase this [REPLACEVALUE] on their own. Only you or your staff can purchase this [REPLACEVALUE] on behalf of your client.`,
            },
            {
                id: 4,
                name: 'Private/Semi-Private Appts Only',
                codeValue: 'private',
                needsOwnLine: true,
                fieldnameForChoice: 'forPrivateSessionsOnly',
                infoguidance: `If checked, clients will only be able to use this [REPLACEVALUE] for private or semi-private appointments.`,
            },
        ],
    },
];

export default newPackQuestion;
