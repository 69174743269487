// material-ui
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Divider } from '@mui/material';

// project imports
import ClientSearch from '../../sub-components/FrontDesk/ClientSearch';
import NewClientAccount from '../../sub-components/FrontDesk/NewClientAccount';
import { updateBasicConfigsNew } from 'actions/updateBasicConfigsNew';

import { useSelector } from 'store';
import { dispatch } from 'store';

import {
    setColor,
    setTimeZone,
    setDibsStudioId,
    setInstructorAltName,
    setRafAwardRedux,
    setNumDaysToShowCalendar,
    setStudioCancelTime,
    setTaxesRedux,
    setOpenTime,
    setCloseTime,
    setApptExceptions,
    setLocationIds,
    setLocationIdShowing,
} from 'store/slices/dibsstudio';
import setStudioDataInStore from 'actions/setStudioDataInStore';
import translate from 'helpers/translate';
import { useSearch } from 'routes/searchContext';

// ==============================|| FRONT DESK CLIENT PAGE ||============================== //

const FrontDeskClients = ({ altClassesText }) => {
    let { dibsId } = useParams();
    const { setShowSearch } = useSearch();
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    useEffect(() => {
        setShowSearch(false);
    }, [setShowSearch]);
    useEffect(() => {
        if (dibsId !== dibsStudioId) {
            console.log(
                'dibsId and dibsStudioId are not the same, so dispatching dibsId:',
                dibsId
            );
            dispatch(setDibsStudioId(dibsId));
            const getNewData = async () => {
                await updateBasicConfigsNew(dibsId).then((res) => {
                    console.log('\n\n\nDIBS ID is', dibsId);
                    console.log('response is', res);
                    setStudioDataInStore(res);
                    dispatch(setApptExceptions(res.apptExceptionRules));
                    dispatch(setDibsStudioId(dibsId));
                    dispatch(setInstructorAltName(res.instructorAltName));
                    dispatch(setTimeZone(res.timezone));
                    dispatch(setNumDaysToShowCalendar(res.intervalEnd));
                    dispatch(setColor(res.color));
                    dispatch(setRafAwardRedux(res.friendReferralAmount));
                    dispatch(setOpenTime(res.locationApptsOpenTime));
                    dispatch(setCloseTime(res.locationApptsCloseTime));
                    dispatch(setLocationIds(res.locationIdsAll));
                    dispatch(setLocationIdShowing(res.locationIdShowing));
                    dispatch(setStudioCancelTime(res.cancelTime));
                    dispatch(
                        setTaxesRedux({
                            salesTax: res.salesTaxRate,
                            retailTax: res.retailTaxRate,
                        })
                    );
                });
            };
            getNewData();
        }
    }, [dibsId, dibsStudioId]);
    const searchGuidance = `Search by email, name, or phone number to edit client information\n and manage your client's memberships, packages, and ${altClassesText}.`;
    const newAccountGuidance = `Create a new account for a client that isn't already in your system.`;
    // dispatch(setShowClientSearchTop(false));
    const searchClientsT = translate({
        id: 'search-clients-title',
        msg: 'Search Clients',
    });
    const searchGuidanceT = translate({
        id: 'search-guidance',
        msg: searchGuidance,
        usedefault: true,
    });
    const createNewT = translate({
        id: 'new-account',
        msg: 'Create a new client account',
    });
    const createNewSubT = translate({
        id: 'new-account-sub',
        msg: newAccountGuidance,
    });
    return (
        <Grid container direction="column">
            <Grid item id="search-clients-title">
                <Typography gutterBottom component="span" variant="h4">
                    {searchClientsT}
                </Typography>
            </Grid>
            <Grid item sx={{ marginTop: '8px' }} id="search-guidance">
                <Typography gutterBottom variant="h7">
                    {searchGuidanceT}
                </Typography>
            </Grid>
            <Grid item sx={{ marginTop: '45px' }} id="client-search">
                <ClientSearch />
            </Grid>
            <Grid item sx={{ marginTop: '60px' }}>
                <Divider variant="fullWidth" />
            </Grid>
            <Grid item sx={{ marginTop: '60px' }} id="create-new-account-text">
                <Typography gutterBottom variant="h4">
                    {createNewT}
                </Typography>
            </Grid>
            <Grid item sx={{ marginTop: '8px' }}>
                <Typography gutterBottom variant="h7">
                    {createNewSubT}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{ marginTop: '45px', marginBottom: '200px' }}
            >
                <NewClientAccount />
            </Grid>
        </Grid>
    );
};

export default FrontDeskClients;
