export const SET_ROSTER_EVENTID = "SET_ROSTER_EVENTID";
export const SET_ROSTER_ATTENDEES = "SET_ROSTER_ATTENDEES";
export const SET_ROSTER_EVENT = "SET_ROSTER_EVENT";
export const SET_ROSTER_EVENT_CLASS_NOTES = "SET_ROSTER_EVENT_CLASS_NOTES";

export const SET_ROSTER_LOADING = "SET_ROSTER_LOADING";

export const SET_ROSTER_WAITLIST_ATTENDEES = "SET_ROSTER_WAITLIST_ATTENDEES";
export const SET_ROSTER_LOADING_WAITLIST = "SET_ROSTER_LOADING_WAITLIST";

export const SET_ROSTER_ATTENDEE_CHECKEDIN = "SET_ROSTER_ATTENDEE_CHECKEDIN";

export const SET_ROSTER_SWITCHING_SPOT = "SET_ROSTER_SWITCHING_SPOT";

export const SET_ROSTER_PURCHASING_PICKING_SPOT =
  "SET_ROSTER_PURCHASING_PICKING_SPOT";
export const SET_ROSTER_PURCHASING_SPOT_ID = "SET_ROSTER_PURCHASING_SPOT_ID";

export const SpotBookingTypes = {
  BOOKABLE: "BOOKABLE",
  INSTRUCTOR: "INSTRUCTOR",
};
