import axios from 'axios';
import { getApiUrl } from 'helpers';
// ==============================|| GET DASHBOARD DATA ||============================== //

export const getNewSearchResults = async (dibsStudioId, searchTerm) => {
    try {
        const url = getApiUrl('/get-client-search-results');
        const response = await axios.post(url, {
            dibsStudioId,
            searchTerm,
        });
        if (
            response.data.msg === 'success' &&
            response.data.matchestoreturn > 0
        ) {
            return response.data.newSortedMatches;
        } else {
            return [];
        }
    } catch (err) {
        console.log(
            `error getting search results for client search. Error is: ${err}`
        );
    }
    return 0;
};

export default getNewSearchResults;
