import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ComboBox({
    title,
    options,
    changeAction,
    valueSelected,
}) {
    return (
        <Autocomplete
            disablePortal
            id={`autocomplete-box-general-${title}`}
            options={options}
            size="small"
            isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
            }}
            value={valueSelected}
            renderInput={(params) => <TextField {...params} label={title} />}
            onChange={(event, value) => {
                changeAction(event, value);
            }}
        />
    );
}
