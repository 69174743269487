import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import { Fade } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export default function ChipsArray({
    chipOptions,
    chipsSelected,
    thisrowid,
    addToChangeLog,
}) {
    const [chipData, setChipData] = React.useState(chipOptions);
    const [chipsSelectedState, setChipsSelectedState] =
        React.useState(chipsSelected);
    const [alreadyRun, setAlreadyRun] = React.useState(false);

    const handleClick = (chipClicked) => {
        let potentialchangelog = {};
        const newchipData = chipData.map((chip) => {
            let isSelected = chip.isSelected;
            if (chip.id === chipClicked.id) {
                potentialchangelog.idchanged = thisrowid;
                potentialchangelog.fieldchanged = chipClicked.value;
                potentialchangelog.newvalue = !chipClicked.isSelected;
                potentialchangelog.searchid = `${thisrowid}-${chipClicked.value}`;
                if (isSelected) {
                    const newChipsSelectedState = chipsSelectedState.filter(
                        (chip) => chip !== chipClicked.chipType
                    );

                    setChipsSelectedState(newChipsSelectedState);
                } else {
                    const newChipsSelectedState = chipsSelectedState.concat(
                        chipClicked.chipType
                    );

                    setChipsSelectedState(newChipsSelectedState);
                }
                isSelected = !isSelected;
            }
            return {
                ...chip,
                isSelected: isSelected,
            };
        });
        console.log('potentialchangelog', potentialchangelog);
        setChipData(newchipData);
        addToChangeLog(potentialchangelog);
    };
    React.useEffect(() => {
        const findSelectedChips = () => {
            const newchipdata = chipData.map((chip) => {
                let isSelectedValue = false;
                if (chipsSelectedState.includes(chip.chipType)) {
                    isSelectedValue = true;
                }
                return {
                    ...chip,
                    isSelected: isSelectedValue,
                };
            });
            setChipData(newchipdata);
            setAlreadyRun(true);
        };
        if (chipData && chipsSelectedState) {
            if (!alreadyRun) {
                findSelectedChips();
            }
        }
    }, [alreadyRun, chipData, chipsSelectedState]);

    if (!alreadyRun) return null;

    return (
        <Fade in timeout={1000}>
            <Paper
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 0.5,
                    m: 0,
                }}
                component="ul"
            >
                {chipData.map((data) => {
                    const lighterbackgroundcolor = alpha(data.bgcolor, 0.3);
                    const hoverlightercolor = alpha(data.bgcolor, 0.4);
                    const normalhovercolor = alpha(data.bgcolor, 0.9);

                    let textcolorUnselected = data.bgcolor;
                    if (data.textWhenNotSelected) {
                        textcolorUnselected = data.textWhenNotSelected;
                    }
                    return (
                        <ListItem key={`chip-array-${data.id}-${data.value}`}>
                            {data.isSelected && (
                                <Chip
                                    label={data.chipType}
                                    sx={{
                                        color: data.color,
                                        bgcolor: data.bgcolor,
                                        ':hover': {
                                            bgcolor: normalhovercolor,
                                        },
                                        m: 0.25,
                                        px: 1,
                                    }}
                                    size="small"
                                    deleteIcon={<DoneIcon />}
                                    onDelete={(e) => {
                                        handleClick(data);
                                    }}
                                    onClick={(e) => {
                                        handleClick(data);
                                    }}
                                />
                            )}
                            {!data.isSelected && (
                                <Chip
                                    label={data.chipType}
                                    sx={{
                                        color: textcolorUnselected,
                                        bgcolor: lighterbackgroundcolor,
                                        ':hover': {
                                            bgcolor: hoverlightercolor,
                                        },
                                        borderColor: data.bgcolor,
                                        m: 0.25,
                                        pt: 0.25,
                                        px: 1,
                                    }}
                                    size="small"
                                    variant="outlined"
                                    onClick={(e) => {
                                        handleClick(data);
                                    }}
                                />
                            )}
                        </ListItem>
                    );
                })}
            </Paper>
        </Fade>
    );
}
