import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, toNumber } from "lodash";
// import currencyFormatter from 'currency';

import Modal from "../Modal";
import "../../styles/retail-activation-modal.scss";
const currencyFormatter = (price, { code }) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: code,
  }).format(price);
};
/**
 * RetailList Stateless Functional Component
 * @param {Object} props Component props
 * @returns {JSX.Element} HTML
 */
class RetailActivationModal extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.setSubmission = this.setSubmission.bind(this);
  }
  state = {
    canSubmit: true,
  };

  async handleSubmit() {
    const { handleSubmit, handleClose, item } = this.props;
    // turn off submission
    this.setSubmission(false);

    // once again inverse against what it currently is
    if (handleSubmit)
      await handleSubmit({
        item,
        type: item.display_on_roster ? "deactivate" : "activate",
      });

    if (handleClose) handleClose();
  }

  setSubmission(canSubmit) {
    this.setState({ canSubmit });
  }

  render() {
    const { canSubmit } = this.state;
    const {
      item: { name, price, display_on_roster },
      open,
      currency,
      handleClose,
    } = this.props;

    // display on roster will be current value, so we should inverse against it
    return (
      <Modal
        open={open}
        okText="YES"
        cancelText="NO"
        canSubmit={canSubmit}
        handleClose={handleClose}
        handleSubmit={this.handleSubmit}
        className="retail-activation-modal"
      >
        {display_on_roster && (
          <div className="retail-activation-modal-section retail-deactivate-modal-section">
            <h2>
              Are you sure you want to remove the item ‘{name} (
              {currencyFormatter.format(price, {
                code: currency,
              })}
              )?’
            </h2>
            <p>
              This will make the item unbuyable for your customers. You can
              quickly reenable it again after.
            </p>
          </div>
        )}
        {!display_on_roster && (
          <div className="retail-activation-modal-section retail-activate-modal-section">
            <h2>
              Are you sure you want to activate the item ‘{name} (
              {currencyFormatter.format(price, {
                code: currency,
              })}
              )?’
            </h2>
            <p>
              This will make the item buyable for your customers. You can
              quickly remove it again after.
            </p>
          </div>
        )}
      </Modal>
    );
  }
}

RetailActivationModal.propTypes = {
  open: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
    display_on_roster: PropTypes.bool,
  }),
  currency: PropTypes.string,
  handleClose: PropTypes.func,
};

export default RetailActivationModal;
