import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function getApptTypes(dibsStudioId) {
    const url = getApiUrl('/get-appt-types');
    return await axios
        .post(url, { dibsStudioId })
        .then((res) => {
            const { data } = res;

            return data;
        })
        .catch((error) => {
            console.log('the error is', error);
            return 0;
        });
}
