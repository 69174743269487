import { useEffect, useState } from "react";
import { Grid, Box, ButtonBase, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "store";
import { setDateSelected, setMaxDateForComparison } from "store/slices/menu";

const MenuButton = styled(ButtonBase)(({ theme }) => ({
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
  },
}));
const checkSelectedIsToday = (selected, comparison) => {
  const compareObj = new Date(comparison);
  const compareplusone = compareObj.setDate(compareObj.getDate() + 1);
  if (selected >= comparison && selected < compareplusone) {
    return true;
  } else {
    return false;
  }
};
const getDatesToShow = (numDays, offset) => {
  const dates = [];
  for (let i = 0; i < numDays; i++) {
    const offsetDays = i + offset;
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + offsetDays);
    const dayOfWeek = date.toLocaleString("en-us", { weekday: "short" });
    const dateFormatted = date.toLocaleString("en-us", {
      month: "short",
      day: "numeric",
    });
    const dayOfWeekCap = dayOfWeek.toUpperCase();

    dates.push({
      dateFormatted,
      dow: dayOfWeekCap,
      rawdate: date,
    });
  }
  return dates;
};

const DatesFormatted = ({ selected }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { studioConfig } = useSelector((state) => state.dibsstudio);
  const { color } = studioConfig;
  const [colortoshow, setColorToShow] = useState("#eeeeee");
  const [numDaysToShow, setNumDaystoShow] = useState(7);
  const { movedScheduleOut } = useSelector((state) => state.menu);
  const setSelectedDateRedux = (dateSelected) => {
    dispatch(setDateSelected(dateSelected));
    const thisnewdate = new Date(dateSelected);
    thisnewdate.setDate(thisnewdate.getDate() + 1);
    dispatch(setMaxDateForComparison(thisnewdate));
  };
  useEffect(() => {
    const colorcode = `#${color}`;
    setColorToShow(colorcode);
    if (isSm) {
      setNumDaystoShow(4);
    } else if (isMd) {
      setNumDaystoShow(5);
    }
  }, [color, isMd, isSm]);
  const datesToShow = getDatesToShow(numDaysToShow, movedScheduleOut);
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={3}
    >
      {datesToShow.map((date) => {
        let colorDate = "#8f8f8f";
        let colorDay = "#696969";
        let bgColor = "#fff";
        const shouldHighlight = checkSelectedIsToday(selected, date.rawdate);
        if (shouldHighlight) {
          colorDate = "#fff";
          colorDay = "#fff";
          bgColor = colortoshow;
        }
        return (
          <Grid item key={date.rawdate}>
            <Box
              sx={{
                pl: 1,
                pt: 0.5,
                backgroundColor: bgColor,
                borderRadius: 1.5,
              }}
            >
              <MenuButton
                onClick={() => {
                  setSelectedDateRedux(date.rawdate);
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      color: colorDate,
                      fontSize: 14,
                      fontWeight: 400,
                      // backgroundColor: '#ff0000',
                      pt: 0,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    {date.dateFormatted}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      color: colorDay,
                      fontSize: 28,
                      fontWeight: 500,
                      pt: 0,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    {date.dow}
                  </Grid>
                </Grid>
              </MenuButton>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
export default DatesFormatted;
