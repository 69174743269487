import React from 'react';
import {
    Grid,
    TextField,
    createTheme,
    ThemeProvider,
    Box,
} from '@mui/material';

const theme = createTheme({
    components: {
        MuiInputLabel: {
            // Targeting the label
            styleOverrides: {
                root: {
                    fontSize: '12px', // Change to the size you want
                    // Define styles for the root state
                    '&.Mui-focused': {
                        // Specifically when the label is focused
                        fontSize: '12px', // Change to the size you want the label to be when focused
                        color: '#c5b7b7', // Change to the color you want the label to be when focused
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&:before': {
                        // underline color when textfield is inactive
                        borderBottom: `1px solid #c5b7b7`,
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        // underline color when hovered
                        borderBottom: `2px solid #c5b7b7`,
                    },
                    '&:after': {
                        // underline color when textfield is focused
                        borderBottom: `1px solid #c5b7b7`,
                    },
                },
            },
        },
    },
});

const MultiLineTextField = ({
    valuePassedIn,
    idtouse,
    nametouse,
    // setNewValue,
    onChangeAction,
    hasError,
    boxWidth,
    placeholder,
}) => {
    const handleChange = (event) => {
        onChangeAction(event.target.value);
    };

    return (
        <Grid container justifyContent="flex-start">
            <Grid xs={12} item>
                <ThemeProvider theme={theme}>
                    <Box width={boxWidth || 100}>
                        <TextField
                            id={idtouse}
                            label={nametouse}
                            fullWidth
                            name={nametouse}
                            value={valuePassedIn}
                            onChange={handleChange}
                            // onBlur={formik.handleBlur}
                            multiline
                            placeholder={placeholder}
                            maxRows={8}
                            size="small"
                            variant="standard"
                            InputProps={{
                                style: {
                                    paddingLeft: '5px',
                                    paddingTop: '20px',
                                    fontSize: '14px',
                                },
                            }}
                        />
                    </Box>
                </ThemeProvider>
            </Grid>
        </Grid>
    );
};

export default MultiLineTextField;
