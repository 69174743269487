export const SET_FETCHING_PACKAGES = "SET_FETCHING_PACKAGES";
export const SET_PACKAGES = "SET_PACKAGES";
export const SET_ADDING_PACKAGES = "SET_ADDING_PACKAGES";
export const SET_PURCHASING_PACKAGE = "SET_PURCHASING_PACKAGE";
export const ADD_PACKAGE = "ADD_PACKAGE";
export const SET_PACKAGES_LOADING_TRUE = "SET_PACKAGES_LOADING_TRUE";
export const SET_PACKAGES_LOADING_FALSE = "SET_PACKAGES_LOADING_FALSE";
export const START_CONFIRMING_PACKAGE = "START_CONFIRMING_PACKAGE";
export const END_CONFIRMING_PACKAGE = "END_CONFIRMING_PACKAGE";
export const START_PURCHASING_PACKAGE = "START_PURCHASING_PACKAGE";
export const END_PURCHASING_PACKAGE = "END_PURCHASING_PACKAGE";
export const PACKAGES_MONTHS = "1 MONTH";
export const PACKAGES_WEEKS = "1 WEEK";
export const PACKAGES_2_MONTHS = "2 MONTHS";
export const PACKAGES_3_MONTHS = "3 MONTHS";
export const PACKAGES_14_DAYS = "14 DAYS";
export const PACKAGES_21_DAYS = "21 DAYS";
export const PACKAGES_28_DAYS = "28 DAYS";
export const PACKAGES_YEARS = "1 YEAR";
export const AUTORENEW_FORCE = "REQUIRED";
export const AUTORENEW_OPTIONAL = "OPTIONAL";
export const AUTORENEW_NONE = "NONE";
export const SET_PACKAGES_DATA = "SET_PACKAGES_DATA";
export const SET_PACKAGES_QUERY = "SET_PACKAGES_QUERY";
export const FORGET_PACKAGES_QUERY = "FORGET_PACKAGES_QUERY";
export const SET_OPEN_PACKAGE = "SET_OPEN_PACKAGE";
export const FORGET_OPEN_PACKAGE = "FORGET_OPEN_PACKAGE";

export const AUTOPAY_INCREMENTS = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
};

export const PACKAGE_PRODUCT_MEMBERSHIP = "MEMBERSHIP";
export const PACKAGE_PRODUCT_CLASS = "SINGLE CLASS";
export const PACKAGE_PRODUCT_PACKAGE = "PACKAGE";

export const PACKAGE_PRODUCT_OPTIONS = [
  PACKAGE_PRODUCT_MEMBERSHIP,
  PACKAGE_PRODUCT_PACKAGE,
  PACKAGE_PRODUCT_CLASS,
].map((item) => ({
  value: item,
  label: `${item[0]}${item.slice(1).toLowerCase()}`,
}));

export const EXPIRES_AFTER_OPTIONS = [
  PACKAGES_MONTHS,
  PACKAGES_WEEKS,
  PACKAGES_YEARS,
].map((item) => ({
  value: item,
  label: `${item[0]}${item.slice(1).toLowerCase()}`,
}));

export const AUTO_RENEWAL_OPTIONS = [
  AUTORENEW_NONE,
  AUTORENEW_OPTIONAL,
  AUTORENEW_FORCE,
].map((item) => ({
  value: item,
  label: `${item[0]}${item.slice(1).toLowerCase()}`,
}));
