import axios from 'axios';

import { getApiUrl } from 'helpers';
import createNewStripePrice from './createNewStripePrice';

// ==============================|| CREATE NEW STRIPE PRODUCT ||============================== //

export const createNewStripeProduct = async (
    packToCreate,
    dibsStudioId,
    packId
) => {
    try {
        const url = getApiUrl('/studio-admin/stripe/new-product');
        const response = await axios.post(url, {
            dibsStudioId,
            newPackage: packToCreate,
            packId,
        });
        const { productCreated } = response.data;
        const productId = productCreated?.id;
        if (productId) {
            const newPriceResponse = await createNewStripePrice(
                packToCreate,
                dibsStudioId,
                productId,
                packId
            );

            return {
                success: newPriceResponse.success,
                priceCreated: newPriceResponse.priceCreated,
            };
        }
    } catch (err) {
        console.log(
            `\n\n\nerror createNewStripeProduct for dibsStudioId: ${dibsStudioId}\nerr is: ${err}\n\nCNSP - Package was ${JSON.stringify(packToCreate, null, 4)}`
        );
        return {
            success: false,
            planCreated: null,
        };
    }
};

export default createNewStripeProduct;
