import axios from 'axios';
import { getApiUrl } from 'helpers';

// ==============================|| CHECK PACKAGE NAME EXISTS FOR STUDIO ||============================== //

export const checkPromoCodeExists = async (dibsStudioId, pcName) => {
    try {
        const url = getApiUrl('/studio/promo-codes/check-code');
        const response = await axios.post(url, {
            promoCodeName: pcName,
            dibsStudioId,
        });
        if (response.data.msg === 'success') {
            return {
                msg: 'success',
                promocodeAlreadyExists: response.data.codeExists,
            };
        } else {
            return { msg: 'failure', error: response.data.error };
        }
    } catch (err) {
        console.log(
            `error checking package name exist for dibsStudioId: ${dibsStudioId}\nerr is: ${err}`
        );
        return { msg: 'failure', error: err };
    }
};

export default checkPromoCodeExists;
