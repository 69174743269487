import { useState, useEffect } from 'react';
import { TextField, MenuItem, Grid, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

const getWidthField = (textlabel, lineMultiplier) => {
    const textlabelstring = `${textlabel}`;

    if (textlabelstring?.length < 10) {
        return '80px';
    }
    if (lineMultiplier) {
        const newwidthM = textlabelstring?.length * lineMultiplier + 5;
        const newwidthIntegerM = Math.round(newwidthM);
        const newwidthstringM = `${newwidthIntegerM}px`;
        return newwidthstringM;
    }

    const newwidth = textlabelstring?.length * 7.3;
    const newwidthInteger = Math.round(newwidth);
    const newwidthstring = `${newwidthInteger}px`;
    return newwidthstring;
};

const CssTextField = styled(TextField)({
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important',
        paddingTop: '4px !important',
        paddingBottom: '1px !important',
    },
    '& div': {
        background: 'transparent !important',
        paddingLeft: '4px !important',
        paddingTop: '4px !important',
        paddingBottom: '0px !important',
    },
    '& textarea': {
        background: 'transparent !important',
        paddingLeft: '4px !important',
        paddingTop: '4px !important',
        paddingBottom: '1px !important',
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:before:hover': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#c96248',
    },
});

const DropdownTextField = ({
    fieldNameEditing,
    id,
    fieldValue,
    handleChange,
    optionsfield,
}) => {
    return (
        <CssTextField
            id={`data-table-editing-field-${fieldNameEditing}-${id}`}
            key={`data-table-editing-field-${fieldNameEditing}-${id}`}
            select
            variant="standard"
            sx={{ mt: 1 }}
            value={fieldValue}
            InputProps={{
                sx: {
                    '& .MuiSvgIcon-root': {
                        color: '#e7b2a5',
                    },
                },
            }}
            onChange={(e) => handleChange(e)}
        >
            {optionsfield.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </CssTextField>
    );
};
const TextFieldMoreSpace = ({
    fieldNameEditing,
    id,
    fieldValue,
    handleChange,
    specifiedwidth,
}) => {
    let widthtouse = '240px';
    if (specifiedwidth) {
        widthtouse = specifiedwidth;
    }
    return (
        <CssTextField
            id={`data-table-editing-fieldmulti-${fieldNameEditing}-${id}`}
            key={`data-table-editing-fieldmulti-${fieldNameEditing}-${id}`}
            variant="standard"
            value={fieldValue}
            sx={{ mt: 1, width: widthtouse }}
            multiline
            maxRows={4}
            onChange={(e) => {
                handleChange(e);
            }}
        />
    );
};

const DefaultDataTextField = ({
    fieldNameEditing,
    id,
    fieldValue,
    specialfontsize,
    specialfontWeight,
    setFieldValue,
    specialfontcolor,
    handleChange,
    counter,
    widthtouse,
}) => {
    return (
        <CssTextField
            id={`data-table-editing-standard-${fieldNameEditing}-${id}-${counter}`}
            key={`data-table-editing-standard-${fieldNameEditing}-${id}-${counter}`}
            variant="standard"
            value={fieldValue}
            onChange={(e) => handleChange(e)}
            InputProps={{
                sx: {
                    fontSize: specialfontsize ? specialfontsize : 'inherit',
                    fontWeight: specialfontWeight
                        ? specialfontWeight
                        : 'inherit',
                    color: specialfontcolor ? specialfontcolor : 'inherit',
                },
            }}
            sx={{
                mt: 1,
                width: widthtouse,
            }}
        />
    );
};

const DataTableEditingField = ({
    id,
    isEditing,
    label,
    typefield,
    optionsfield,
    needsmorespace,
    specialfontsize,
    specifiedwidth,
    specialfontWeight,
    specialfontcolor,
    lineMultiplier,
    fieldEditing,
    addToChangeLog,
    counter,
}) => {
    // console.log('label and counter passed in:', label, counter);
    const [fieldValue, setFieldValue] = useState(label);
    const [fieldNameEditing, setFieldNameEditing] = useState(fieldEditing);

    useEffect(() => {
        setFieldValue(label);
    }, [counter, label]);

    const handleChange = (event) => {
        console.log('handling this change here');
        setFieldValue(event.target.value);
        const potentialchangelog = {
            fieldchanged: fieldNameEditing,
            idchanged: id,
            newvalue: event.target.value,
            searchid: `${id}-${fieldNameEditing}`,
        };
        console.log('potentialchangelog', potentialchangelog);
        addToChangeLog(potentialchangelog);
    };
    if (isEditing) {
        const widthfield = getWidthField(label, lineMultiplier);
        if (needsmorespace) {
            return (
                <TextFieldMoreSpace
                    fieldNameEditing={fieldNameEditing}
                    id={id}
                    fieldValue={fieldValue}
                    handleChange={handleChange}
                    specifiedwidth={specifiedwidth}
                />
            );
        }
        if (typefield === 'dropdown') {
            return (
                <DropdownTextField
                    fieldNameEditing={fieldNameEditing}
                    id={id}
                    fieldValue={fieldValue}
                    optionsfield={optionsfield}
                    handleChange={handleChange}
                />
            );
        }
        return (
            <DefaultDataTextField
                fieldNameEditing={fieldNameEditing}
                id={id}
                fieldValue={fieldValue}
                specialfontsize={specialfontsize}
                specialfontWeight={specialfontWeight}
                setFieldValue={setFieldValue}
                specialfontcolor={specialfontcolor}
                handleChange={handleChange}
                counter={counter}
                widthtouse={widthfield}
            />
        );
    }
    let labeltoreturn = label;
    if (typeof label === 'boolean') {
        labeltoreturn = label ? 'Yes' : 'No';
    }
    if (fieldEditing === 'autopay') {
        optionsfield.forEach((option) => {
            if (option.value === labeltoreturn) {
                labeltoreturn = option.label;
            }
        });
    }
    return labeltoreturn;
};

export default DataTableEditingField;
