import React from "react";

import { Grid, TextField, Typography, Button } from "@mui/material";

// third party
import { useFormik } from "formik";
import * as yup from "yup";

import InputLabel from "ui-component/extended/Form/InputLabel";

// yup validation-schema
const validationSchema = yup.object().shape({
  // name: Yup.string()
  //     .min(2, 'Too Short!')
  //     .max(70, 'Too Long!')
  //     .required('Required'),
  password: yup.string().max(255).required("Password is required"),
});

const StepsToCheckoutPassword = () => {
  const formik = useFormik({
    initialValues: {
      password: "123456789101112",
    },
    validationSchema,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      // now we will check to see if they have the correct password
    },
  });
  return (
    <Grid container>
      <Grid item xs={12} sx={{ mb: 4 }}>
        <Typography variant="subtitle1">Enter your information</Typography>
      </Grid>
      <Grid item xs={12} sx={{ ml: 1 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={9} md={6}>
            <form onSubmit={formik.handleSubmit}>
              <Grid item xs={10}>
                <InputLabel>Enter your Password</InputLabel>
                <TextField
                  name="password"
                  value={formik.values.password}
                  fullWidth
                  onChange={formik.handleChange}
                  placeholder="Enter your password"
                  type="password"
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={10} sx={{ mt: 2 }}>
                <Grid container>
                  <Grid item sx={{ mr: 1 }}>
                    <Button
                      variant="checkout"
                      type="submit"
                      disabled={formik.isSubmitting}
                      sx={{ mr: 2 }}
                    >
                      {" "}
                      Submit
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={formik.handleReset}
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default StepsToCheckoutPassword;
