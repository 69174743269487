import { cloneDeep, debounce } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { getApiUrl } from 'helpers';

import {
    ADD_ITEM_TO_CLIENT_CART,
    SET_CLIENT_CART,
    CLEAR_CLIENT_CART,
    SET_CLIENT_CART_VISIBLE,
    SET_CLIENT_PURCHASING_CLASSES,
    CLEAR_CLIENT_CART_PROMO,
    SET_CART_PURCHASE_BUTTON_STATE,
    ADD_ITEM_TO_CLIENT_CART_FROM_WAITLIST,
} from '../../constants/ClientConstants';
import range from '../../../shared/helpers/range';
import {
    getSortedClientCartItems,
    getClientCartEventIds,
    getClientCartItems,
} from '../../selectors/client/cart';
import { getFormattedClientCartTotal } from '../../selectors/client/cart/purchase-breakdown';
import { addError } from '../ErrorActions';
import {
    setClientEventsConfirmed,
    requestClientEvents,
    requestClientData,
} from '../ClientActions';
import { getClientNextPassId } from '../../selectors/client/passes/index';
import { requestFeedEvents, setClientForSpot } from '../FeedActions';
import { getClientId } from '../../selectors/client/index';

/**
 * @param {Object} value new cart item to add
 * @returns {Object} action on the state
 */
export function addEventToClientCart({
    start_time,
    eventid,
    passid,
    price,
    taxRate,
    spotIds = [],
}) {
    return {
        type: ADD_ITEM_TO_CLIENT_CART,
        value: { start_time, eventid, passid, price, taxRate, spotIds },
    };
}

/**
 * @param {Object} value new cart item to add
 * @returns {Object} action on the state
 */
export function addEventToClientCartFromWaitlist({
    userid,
    start_time,
    eventid,
    passid,
    price,
    taxRate,
    spotIds = [],
}) {
    return {
        type: ADD_ITEM_TO_CLIENT_CART_FROM_WAITLIST,
        value: { userid, start_time, eventid, passid, price, taxRate, spotIds },
    };
}

/**
 * @param {Array<Objects>} items new state.client.cart.items
 * @returns {Object} redux action
 */
export function setClientCart(items) {
    return { type: SET_CLIENT_CART, items };
}

/**
 * @returns {Object} action on the state
 */
export function clearClientCart() {
    return { type: CLEAR_CLIENT_CART };
}

// The following two are used to keep the cart open
// when there are no events (for modifying the current cart)

/**
 * @param {boolean} value true if cart should be kept visible if empty
 * @returns {Object} action on the state
 */
export function setCartVisible(value) {
    return { type: SET_CLIENT_CART_VISIBLE, value };
}

/**
 * @param {boolean} value of client.purchasingClasses in state
 * @returns {Object} action on the state
 */
export function setPurchasingClasses(value) {
    return {
        type: SET_CLIENT_PURCHASING_CLASSES,
        value,
    };
}

/**
 * @returns {Object} action on the state
 */
export function clearClientCartPromoCodeData() {
    return { type: CLEAR_CLIENT_CART_PROMO };
}
/**
 *
 * @param {Enum} value new state
 * @returns {object} action on the state
 */
export function setCartPurchaseButtonState(value) {
    return { type: SET_CART_PURCHASE_BUTTON_STATE, value };
}

/**
 * Refresh cart once user logs in or out to account for passes
 * @returns {function} redux thunk
 */
export function refreshCart() {
    return function innerRefreshCart(dispatch, getState) {
        const cartData = getState().client.cart.items;
        dispatch(setCartVisible(true));
        dispatch(clearClientCart());
        cartData.forEach((cartEvent) =>
            [...Array(cartEvent.quantity)].forEach(() => {
                const nextPassId = getClientNextPassId(getState())(
                    cartEvent.eventid
                );
                cartEvent.passid = nextPassId;
                dispatch(addEventToClientCart(cartEvent));
            })
        );
        dispatch(setCartVisible(false));
    };
}

/**
 * Remove a single item from the cart. In order to ensure that
 * users get all their eligible passes applied to their classes,
 * when a user removes a single event item, the cart is rebuilt
 * and reselects the next eligible pass on each dispatch of
 * addEventToClientCart.
 *
 * @param {number} eventid id of the event being removed
 * @param {object} options optional params
 * @param {boolean} [options.toggleVisibility=true] whether or not to change visibility state
 * @returns {Object} action on the state
 */
export function removeSingleEventItemFromCart(
    eventid,
    { toggleVisibility = true } = {}
) {
    return function innerRemoveSingleEventItemFromCart(dispatch, getState) {
        // stores items in cart before removal
        // in the order they are added
        let { items } = getState().client.cart;
        items = cloneDeep(items);

        const itemToRemoveFrom = items.find((item) => item.eventid === eventid);
        if (!itemToRemoveFrom) return;
        const indexOfItem = items.indexOf(itemToRemoveFrom);

        if (toggleVisibility) dispatch(setCartVisible(true)); // so the cart view won't close before this is done
        dispatch(setClientCart(items.slice(0, indexOfItem)));
        items.slice(indexOfItem).forEach((item) =>
            // will iterate over the quantity of each item
            // the item to be removed will be skipped once
            range(
                item.quantity - Number(item === itemToRemoveFrom && 1)
            ).forEach(() => {
                const passid = getClientNextPassId(getState())(item.eventid);
                dispatch(addEventToClientCart({ ...item, passid }));
            })
        );
        if (toggleVisibility) dispatch(setCartVisible(false));
    };
}

/**
 * Saves a previous version of the cart without any
 * items with eventids of the chosen event to remove.
 * It then rebuilds the cart, reselecting the next
 * pass to use for addEventToClientCart
 *
 * @param {type} eventid of the class to update
 * @returns {Object} action on the state
 */
export function removeEventFromCart(eventid) {
    return function innerRemoveEventFromCart(dispatch, getState) {
        // Storing all items in previous cart for every other event
        // in the order they were added
        let { items } = getState().client.cart;
        items = cloneDeep(items);
        items = items.filter((item) => item.eventid !== eventid);

        dispatch(setCartVisible(true)); // so the cart view won't close before this is done
        dispatch(clearClientCart());
        items.forEach((item) =>
            // will iterate over the quantity of each item
            range(item.quantity).forEach(() => {
                const passid = getClientNextPassId(getState())(item.eventid);
                dispatch(addEventToClientCart({ ...item, passid }));
            })
        );
        dispatch(setCartVisible(false));
    };
}

/**
 * @returns {Object} action on the state
 */
export function removeExpiredEventsFromCart() {
    return function innerRemoveExpiredEventsFromCart(dispatch, getState) {
        // Storing all items in previous cart for every other event
        // in the order they were added
        let { items } = getState().client.cart;
        items = cloneDeep(items);
        items = items.filter((item) =>
            moment(item.start_time).isAfter(moment().local().add(10, 'minutes'))
        );

        dispatch(setCartVisible(true)); // so the cart view won't close before this is done
        dispatch(clearClientCart());
        items.forEach((item) =>
            // will iterate over the quantity of each item
            range(item.quantity).forEach(() => {
                const passid = getClientNextPassId(getState())(item.eventid);
                dispatch(addEventToClientCart({ ...item, passid }));
            })
        );
        dispatch(setCartVisible(false));
    };
}

/**
 * If the promo code is a free class, the class
 * the code applied to has to be re-added to the
 * cart so that passes can be applied
 *
 * @returns {function} redux thunk
 */
export function clearClientCartPromoCode() {
    return function innerClearClientPromoCode(dispatch, getState) {
        const { promoCode } = getState().client.cart;
        const cartItem = getSortedClientCartItems(getState())[0];
        if (promoCode.type === 'FREE_CLASS') {
            dispatch(setCartVisible(true));
            dispatch(removeSingleEventItemFromCart(cartItem.eventid));
            const passid = getClientNextPassId(getState())(cartItem.eventid);
            dispatch(addEventToClientCart({ ...cartItem, passid }));
            dispatch(setCartVisible(false));
        }
        dispatch(clearClientCartPromoCodeData());
    };
}

/**
 * @returns {function} redux thunk
 */
export function applyFreeClassPromoToClientCart() {
    return function innerApplyFreeClassPromoToClientCart(dispatch, getState) {
        const cartData = getSortedClientCartItems(getState())[0];
        const copiedItem = { ...cartData, passid: null };
        dispatch(setCartVisible(true));
        dispatch(removeSingleEventItemFromCart(cartData.eventid), {
            toggleVisibility: false,
        });
        dispatch(addEventToClientCart(copiedItem));
        dispatch(setCartVisible(false));
    };
}

/**
 * updateCartItemQuantity of an item in the cart by adding and removing event items one by one
 * this is the best solution I can come up with to make sure the correct passes are
 * applied - DC
 * @param {Object} value the eventid and quantity of the new cart item
 * @returns {function} redux thunk
 */
export function updateCartItemQuantity({ eventid, quantity }) {
    return function innerUpdateQuantity(dispatch, getState) {
        const { items } = getState().client.cart;
        const initialQuantityInCart = items
            .filter((item) => item.eventid === eventid)
            .reduce((acc, { quantity: q }) => acc + q, 0);
        if (!initialQuantityInCart) return;
        range(Math.abs(quantity - initialQuantityInCart)).forEach(() => {
            if (quantity < initialQuantityInCart)
                return dispatch(removeSingleEventItemFromCart(eventid));
            const state = getState();
            const nextPassId = getClientNextPassId(state)(eventid);
            const cartItem = state.client.cart.items.find(
                (event) => event.eventid === eventid
            );
            return dispatch(
                addEventToClientCart({ ...cartItem, passid: nextPassId })
            );
        });
    };
}
/**
 * Submit current client cart for purchase
 *
 * @param {function} callback on completion
 * @returns {function} redux thunk
 */
export function submitCartForPurchase(callback = () => {}) {
    const innerSubmitCartForPurchase = async (dispatch, getState) => {
        const state = getState();
        const {
            client: { id: clientId, cart },
        } = state;
        if (cart.purchasingClasses) return {};
        dispatch(setPurchasingClasses(true));

        let success;
        try {
            const { data } = await axios.post(
                `/studios/api/clients/${clientId}/checkout`,
                {
                    cart: { events: cart.items },
                    promoCode: cart.promoCode,
                    purchasePlace: 'studio admin',
                }
            );
            if (data.success) {
                dispatch(setClientEventsConfirmed(data.transactions.events));
                callback(null);
                dispatch(requestClientData(clientId));
                dispatch(requestClientEvents(clientId));
                dispatch(clearClientCart());
                success = true;
            } else {
                if (clientId) dispatch(requestClientData(clientId));
                dispatch(addError(data.message));
                callback(data);
                success = false;
            }
        } catch (err) {
            console.log(err);
            dispatch(
                addError("Something went wrong checkout out the client's cart.")
            );
            callback(err);
            success = false;
        }
        const eventids = getClientCartEventIds(state);
        dispatch(requestFeedEvents({ eventids }));
        dispatch(setPurchasingClasses(false));

        return { success };
    };

    return debounce(innerSubmitCartForPurchase, 300, {
        leading: true,
        trailing: false,
    });
}
/**
 * Submit current client cart for purchase - from waitlist
 *
 * @param {function} callback on completion
 * @returns {function} redux thunk
 */
export function submitCartFromWaitlistForPurchase(callback = () => {}) {
    const innerSubmitCartFromWaitlistForPurchase = async (
        dispatch,
        getState
    ) => {
        const state = getState();
        const {
            client: { id: clientId, cart },
        } = state;
        if (cart.purchasingClasses) return {};
        console.log('client id', clientId);
        // const { client: { cart } } = state;
        const cartData = state.client.cart.items[0];
        // const promoCode = state.client.cart.promoCode;
        // const waitlist = state.roster.waitlistAttendees || '';
        dispatch(setPurchasingClasses(true));

        const userid = cartData.userid;

        let success;
        try {
            const { data } = await axios.post(
                `/studios/api/clients/${userid}/checkout-from-waitlist`,
                {
                    cart: { events: cart.items },
                    promoCode: cart.promoCode,
                    purchasePlace: 'studio admin',
                }
            );
            if (data.success) {
                dispatch(setClientEventsConfirmed(data.transactions.events));
                callback(null);
                dispatch(requestClientData(userid));
                dispatch(requestClientEvents(userid));
                dispatch(clearClientCart());
                success = true;
            } else {
                if (userid) dispatch(requestClientData(userid));
                dispatch(addError(data.message));
                callback(data);
                success = false;
            }
        } catch (err) {
            console.log(err);
            dispatch(
                addError(
                    "Something went wrong checkout out the waitlist client's cart."
                )
            );
            callback(err);
            success = false;
        }
        const eventids = getClientCartEventIds(state);
        dispatch(requestFeedEvents({ eventids }));
        dispatch(setPurchasingClasses(false));

        return { success };
    };

    return debounce(innerSubmitCartFromWaitlistForPurchase, 300, {
        leading: true,
        trailing: false,
    });
}

/**
 * setSpotsInCart
 * @param {number} eventid to set event in spot
 * @param {Object} spot spot data
 * @returns {Object} action on the state
 */
export function setSpotInCart(eventid, spot) {
    return function innerSetSpotsInCart(dispatch, getState) {
        const state = getState();
        const cartItems = getClientCartItems(state);
        const clientid = getClientId(state);
        const clonedCartItems = cloneDeep(cartItems);
        const i = clonedCartItems.findIndex(
            (event) => event.eventid === eventid
        );
        if (clonedCartItems[i].quantity === clonedCartItems[i].spotIds?.length)
            return null;
        clonedCartItems[i].spotIds.push(spot.id);
        dispatch(setClientCart(clonedCartItems));
        return dispatch(setClientForSpot(eventid, clientid, spot.x, spot.y));
    };
}
/**
 * removeSpotFromCart
 * @param {number} eventid to set event in spot
 * @param {Object} spot spot data
 * @returns {Object} action on the state
 */
export function removeSpotFromCart(eventid, spot) {
    return function innerRemoveSpotFromCart(dispatch, getState) {
        const cartItems = getClientCartItems(getState());
        const clonedCartItems = cloneDeep(cartItems);
        const i = clonedCartItems.findIndex(
            (event) => event.eventid === eventid
        );
        const j = clonedCartItems[i].spotIds.findIndex(
            (spotId) => spotId === spot.id
        );
        clonedCartItems[i].spotIds.splice(j, 1);
        dispatch(setClientCart(clonedCartItems));
        dispatch(setClientForSpot(eventid, null, spot.x, spot.y));
    };
}
/**
 *
 * @param {string} customMessage allows for custom messages to be displayed
 * @returns {function} inner thunk
 */
export function displayPaymentWarning(customMessage = null) {
    return function innerDisplayPaymentWarning(dispatch, getState) {
        return dispatch(
            addError(
                customMessage ||
                    `Looks like the client has already applied an unlimited pass to a class that day, so the client's card will be charged ${getFormattedClientCartTotal(
                        getState()
                    )}.`
            )
        );
    };
}
