import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import reconstructUtcInLocal from './reconstructUtcInLocal';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function compareNowToApptTime(
    dateForComparison,
    timeZone,
    unit
) {
    const dateEntry = dayjs.utc(dateForComparison);
    const now = dayjs().tz(timeZone);

    const convertedDateOfApptInTz = reconstructUtcInLocal(dateEntry, timeZone);

    const difference = convertedDateOfApptInTz.diff(now, unit);
    return difference;
}
