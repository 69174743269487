import {
  TOGGLE_TUTORIAL,
  SET_CURRENT_STEP,
  TOGGLE_CHILDREN_VISIBILITY,
  TOGGLE_TUTORIAL_SCROLL,
} from "../constants/TutorialConstants";

/**
 * openTutorial
 * @returns {Object} action on the state
 */
export function openTutorial() {
  return { type: TOGGLE_TUTORIAL, value: true };
}

/**
 * closeTutorial
 * @returns {Object} action on the state
 */
export function closeTutorial() {
  return { type: TOGGLE_TUTORIAL, value: false };
}

/**
 * setActiveTutorialStep
 * @param {number} value the tutorial step
 * @returns {Object} action on the state
 */
export function setTutorialStep(value) {
  return { type: SET_CURRENT_STEP, value };
}

/**
 * hideChildren
 * @returns {Object} action on the state
 */
export function hideTutorialChildren() {
  return { type: TOGGLE_CHILDREN_VISIBILITY, value: false };
}

/**
 * showChildren
 * @returns {Object} action on the state
 */
export function showTutorialChildren() {
  return { type: TOGGLE_CHILDREN_VISIBILITY, value: true };
}

/**
 * startTutorialScroll
 * @returns {Object} action on the state
 */
export function startTutorialScroll() {
  return { type: TOGGLE_TUTORIAL_SCROLL, value: true };
}

/**
 * completeTutorialScroll
 * @returns {Object} action on the state
 */
export function completeTutorialScroll() {
  return { type: TOGGLE_TUTORIAL_SCROLL, value: false };
}
