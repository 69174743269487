import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function getApptCategories(dibsId) {
    const url = getApiUrl('/studio/appts/get-categories');

    try {
        const res = await axios.post(url, {
            dibsId,
        });
        const { data } = res;
        const { success, categories } = data;
        if (success) return { success: true, data: categories };
        else return { success: false };
    } catch (error) {
        console.log('the error from getting appt categories is', error);
        return {
            success: false,
        };
    }
}
