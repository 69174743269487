import { createSelector } from "reselect";
import moment from "moment";
import getFormattedException from "./get-formatted-exception";
import { getUserStudioCurrency } from "../user";

/**
 * getExceptions
 * @param {Object} state the state in the Redux store
 * @returns {Object} state of the exceptions data
 */
export function getExceptions(state) {
  return state.exceptions;
}

export const getExceptionsLoading = createSelector(
  getExceptions,
  (exceptions) => exceptions.loading,
);

export const getExceptionsData = createSelector(getExceptions, (exceptions) =>
  exceptions.data.sort((e1, e2) => moment(e2.createdAt) - moment(e1.createdAt)),
);

export const getFormattedExceptionsData = createSelector(
  [getExceptionsData, getUserStudioCurrency],
  (data, currency) => data.map(getFormattedException.bind(null, currency)),
);

export const getActiveExceptions = createSelector(
  getFormattedExceptionsData,
  (data) => data.filter(({ deletedAt }) => deletedAt === null),
);

export const getInactiveExceptions = createSelector(
  getFormattedExceptionsData,
  (data) => data.filter(({ deletedAt }) => deletedAt !== null),
);

export const getOpenException = createSelector(
  getExceptions,
  (exceptions) => exceptions.openException || {},
);

export const getExceptionsEventNames = createSelector(
  getExceptions,
  (exceptions) => exceptions.eventNames,
);

export const getExceptionsEventNameOptions = createSelector(
  getExceptionsEventNames,
  (eventNames) => [
    { label: "ALL", value: "" },
    ...eventNames.map(({ name }) => ({ label: name, value: name })),
  ],
);

export const getExceptionsInstructors = createSelector(
  getExceptions,
  (exceptions) => exceptions.instructors,
);

export const getExceptionsInstructorOptions = createSelector(
  getExceptionsInstructors,
  (instructors) => [
    { label: "ALL", value: 0 },
    ...instructors.map(({ id, firstname, lastname }) => ({
      label: `${firstname} ${lastname}`,
      value: id,
    })),
  ],
);
