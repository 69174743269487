import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { format as formatCurrency } from 'currency';
import { parseDate as parseDateUS } from 'chrono-node';
// import { parseDateUK } from '../../helpers/chrono-node-uk';
import log from '../../helpers/log';
import {
    PROMO_CODE_PRODUCT_OPTIONS,
    PROMO_PRODUCT_PACKAGE,
    PROMO_PRODUCT_UNIVERSAL,
} from '../../constants/PromoCodesConstants';
import {
    PROMO_CODE_HISTORY_ROUTE,
    EDIT_PROMO_CODE_ROUTE,
} from '../../constants/RouteConstants';
import {
    getUserStudioCountry,
    getUserStudioCurrency,
    getUserStudioDateFormat,
} from '../../selectors/user';
import { addError, clearError } from '../../actions/ErrorActions';
import {
    setOpenPromoCode,
    setPromoCodesLoadingTrue,
} from '../../actions/PromoCodesActions';
import DibsLoader from '../Layout/DibsLoader';
import InputField from 'shared/components/InputField';
import SelectField from '../shared/SelectField';
import CheckBoxIcon from '../../../shared/graphics/Icons/CheckBoxIcon';
import '../../styles/add-promo-code.scss';

const formatCurrency = (price, { code }) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: code,
    }).format(price);
};
/**
 * AddPromoCode React Component
 * @extends {module:React.PureComponent}
 * @returns {JSX.Element} HTML
 */
class AddPromoCode extends React.PureComponent {
    /**
     * constructor
     * @constructs AddPromoCode
     * @param {Object} props Component props
     */
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            code: '',
            expirationDate: '',
            amountOff: '',
            percentOff: '',
            userUsageLimit: '',
            codeUsageLimit: '',
            activeProduct: PROMO_CODE_PRODUCT_OPTIONS[0],
            recurring: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.openHistory = this.openHistory.bind(this);
        this.submitPromoCode = this.submitPromoCode.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.setRecurring = this.setRecurring.bind(this);
    }
    /**
     * Changes recurring state
     * @returns {undefined}
     */
    setRecurring() {
        this.setState({ recurring: !this.state.recurring });
    }
    /**
     * handleChange
     * @param {Event} event the event object
     * @param {string} event.target.name the name of the modified input
     * @param {string} event.target.value the value the user inputs
     * @returns {undefined}
     */
    handleChange({ target: { name, value: _value } }) {
        let value;
        switch (name) {
            case 'code':
                value = _value
                    .split('')
                    .filter((s) => /[a-z0-9]/i.test(s))
                    .join('')
                    .toUpperCase();
                break;
            case 'amountOff':
                value = isNaN(_value[0]) ? +_value.slice(1) : +_value;
                if (!value) value = '';
                else
                    value = formatCurrency(value, {
                        code: this.props.currency,
                        precision: 0,
                    });
                break;
            case 'percentOff':
                value = String(+_value.split('%').join('') || '');
                if (_value === this.state.percentOff.split('%')[0])
                    value = value.slice(0, -1);
                if (!value) value = '';
                else value = `${value}%`;
                break;
            case 'userUsageLimit':
            case 'codeUsageLimit':
                value = String(parseInt(_value, 10));
                if (value === 'NaN') value = '';
                break;
            default:
                value = _value;
        }
        this.setState({ [name]: value });
    }
    /**
     * openHistory
     * @returns {undefined}
     */
    openHistory() {
        this.context.router.push(PROMO_CODE_HISTORY_ROUTE);
    }
    /**
     *
     * @param {object} newValue new value to display
     * @returns {undefined}
     */
    handleSelectChange(newValue) {
        const stateChange = { activeProduct: newValue };
        if (
            newValue !== PROMO_PRODUCT_PACKAGE &&
            newValue !== PROMO_PRODUCT_UNIVERSAL
        )
            stateChange.recurring = false;
        this.setState(stateChange);
    }
    /**
     * handleSubmit validates form entries
     * @returns {undefined}
     */
    handleSubmit() {
        if (this.state.submitting) return;

        const parseDate =
            // this.props.country === 'UK' ? parseDateUK : parseDateUS;
            (this.props.country = parseDateUS);
        const amountOff = this.state.amountOff
            ? this.state.amountOff.slice(1)
            : '';
        const percentOff = this.state.percentOff
            ? this.state.percentOff.slice(0, -1)
            : '';

        this.props.clearError();
        if (!this.state.code) {
            this.props.addError('Promo code cannot be blank');
        } else if (
            !this.state.expirationDate ||
            !parseDate(this.state.expirationDate)
        ) {
            this.props.addError('Please use a valid expiration date');
        } else if (parseDate(this.state.expirationDate) <= new Date()) {
            this.props.addError('The expiration date must be in the future');
        } else if (!amountOff && !percentOff) {
            this.props.addError(
                'Amount and percentage off cannot both be blank'
            );
        } else if (amountOff && percentOff) {
            this.props.addError(
                'You cannot set both an amount and a percentage off'
            );
        } else if (!amountOff && (percentOff <= 0 || percentOff > 100)) {
            this.props.addError(
                'Percentage off must be a number between 0 and 100'
            );
        } else if (!percentOff && amountOff <= 0) {
            this.props.addError('Amount must be a number greater than 0');
        } else if (!this.state.userUsageLimit && !this.state.codeUsageLimit) {
            this.props.addError(
                'Please set the number of uses and/or the number of total uses'
            );
        } else {
            this.submitPromoCode();
        }
    }
    /**
     * submitPromoCode to the server
     * @returns {undefined}
     */
    submitPromoCode() {
        const activeProduct = this.state.activeProduct;
        const parseDate =
            // this.props.country === 'UK' ? parseDateUK : parseDateUS;
            this.props.country === parseDateUS;
        const amountOff = this.state.amountOff
            ? this.state.amountOff.slice(1)
            : '';
        const percentOff = this.state.percentOff
            ? this.state.percentOff.slice(0, this.state.percentOff?.length - 1)
            : '';
        const reqBody = {
            ...this.state,
            product: activeProduct.value,
            amountOff,
            percentOff,
            expirationDate: parseDate(this.state.expirationDate).toISOString(),
            recurring: Number(this.state.recurring),
        };
        this.setState({ submitting: true }, () => {
            console.log('add new promo code');
            // $.ajax({
            //   method: 'POST',
            //   url: '/studios/api/promo-code/new',
            //   data: reqBody,
            // })
            // .done(({ success, promoCode, message, err }) => {
            //   if (success) {
            //     this.props.setOpenPromoCode({ ...promoCode, new: true });
            //     this.props.setPromoCodesLoadingTrue();
            //     this.context.router.push(EDIT_PROMO_CODE_ROUTE);
            //   } else {
            //     if (err) log(err);
            //     this.props.addError(message);
            //     this.setState({ submitting: false });
            //   }
            // })
            // .error((err) => {
            //   console.log(err);
            //   this.props.addError('Failed to create the promo code');
            //   this.setState({ submitting: false });
            // });
        });
    }
    /**
     * render
     * @returns {JSX.Element} HTML
     */
    render() {
        return (
            <div className="dibs-section add-promo-code-container">
                <div className="dibs-section-heading">Add Promo Code</div>
                <div className="dibs-input-fields first-input-container">
                    <InputField
                        label="Code Name"
                        name="code"
                        onChange={this.handleChange}
                        value={this.state.code}
                    />
                    <InputField
                        label="Expiration Date"
                        name="expirationDate"
                        placeholder={this.props.dateFormat}
                        onChange={this.handleChange}
                        value={this.state.expirationDate}
                    />
                </div>
                <div className="dibs-input-fields and-or-input-container">
                    <InputField
                        label="Amount"
                        name="amountOff"
                        onChange={this.handleChange}
                        value={this.state.amountOff}
                    />
                    <div className="and-or">Or</div>
                    <InputField
                        label="% Off"
                        name="percentOff"
                        onChange={this.handleChange}
                        value={this.state.percentOff}
                    />
                </div>
                <div className="dibs-input-fields and-or-input-container">
                    <InputField
                        label="# of Uses Per Person"
                        name="userUsageLimit"
                        onChange={this.handleChange}
                        value={this.state.userUsageLimit}
                    />
                    <div className="and-or">And/Or</div>
                    <InputField
                        label="# of Total Uses"
                        name="codeUsageLimit"
                        onChange={this.handleChange}
                        value={this.state.codeUsageLimit}
                    />
                </div>
                <div className="dibs-input-fields">
                    <SelectField
                        label="Type"
                        value={this.state.activeProduct}
                        onChange={this.handleSelectChange}
                        options={PROMO_CODE_PRODUCT_OPTIONS}
                    />
                    {(this.state.activeProduct.value ===
                        PROMO_PRODUCT_PACKAGE ||
                        this.state.activeProduct.value ===
                            PROMO_PRODUCT_UNIVERSAL) && (
                        <div className="promo-recurring-container">
                            <button onClick={this.setRecurring}>
                                <CheckBoxIcon checked={this.state.recurring} />
                            </button>
                            &nbsp;
                            <span>Apply on Renewal?</span>
                        </div>
                    )}
                </div>
                <div
                    className="dibs-submit-btn-container"
                    style={{ marginTop: 40 }}
                >
                    <button
                        className="dibs-button dibs-button-inverted"
                        onClick={this.openHistory}
                    >
                        VIEW HISTORY
                    </button>
                    <button
                        className="dibs-button"
                        onClick={this.handleSubmit}
                        disabled={this.state.submitting}
                    >
                        {this.state.submitting ? <DibsLoader /> : 'SUBMIT'}
                    </button>
                </div>
            </div>
        );
    }
}

AddPromoCode.contextTypes = {
    router: PropTypes.shape({
        push: PropTypes.func,
    }),
};

AddPromoCode.propTypes = {
    country: PropTypes.string,
    currency: PropTypes.string,
    dateFormat: PropTypes.string,
    addError: PropTypes.func,
    clearError: PropTypes.func,
    setOpenPromoCode: PropTypes.func,
    setPromoCodesLoadingTrue: PropTypes.func,
};

const mapStateToProps = (state) => ({
    country: getUserStudioCountry(state),
    currency: getUserStudioCurrency(state),
    dateFormat: getUserStudioDateFormat(state),
});
const mapDispatchToProps = {
    addError,
    clearError,
    setOpenPromoCode,
    setPromoCodesLoadingTrue,
};

const SmartAddPromoCode = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddPromoCode);

export default SmartAddPromoCode;
