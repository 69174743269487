import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, useParams } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import router from 'routes/index';
import { SearchProvider } from 'routes/searchContext';
import { LocaleProvider, useLocale } from 'routes/LocaleContext';

import enMessages from 'locales/en-US.json';
import itMessages from 'locales/it.json';

import useConfig from 'hooks/useConfig';

const client = new ApolloClient({
    uri: 'https://flyby-router-demo.herokuapp.com/',
    cache: new InMemoryCache(),
});

const messages = {
    en: enMessages,
    'en-US': enMessages,
    it: itMessages,
};

function AppWrapper() {
    const { locale: initialLocale } = useLocale();

    return (
        <IntlProvider locale={initialLocale} messages={messages[initialLocale]}>
            <RouterProvider router={router}>
                <ApolloProvider client={client}>
                    <App />
                </ApolloProvider>
            </RouterProvider>
        </IntlProvider>
    );
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <LocaleProvider>
            <SearchProvider>
                <AppWrapper />
            </SearchProvider>
        </LocaleProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
