import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function removeAttendee({
    eventid,
    userid,
    id,
    dibstransactionid,
    dibsId,
    startTime,
}) {
    const url = getApiUrl('/remove-attendee-from-class');
    let datatosend = {};
    const remove = async () => {
        await axios
            .post(url, {
                eventid,
                userid,
                id,
                dibstransactionid,
                dibsId,
                startTime,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(
                    `error removing attendees from eventid - ${eventid}: \n${err}`
                );
                return err;
            });
    };
    await remove();
    return datatosend;
}
