import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function deleteEventFromSchedule({
    eventid,
    studioEmployeeId,
}) {
    const url = getApiUrl('/delete-event-from-schedule');
    let datatosend = {};
    const remove = async () => {
        await axios
            .post(url, {
                eventid,
                studioEmployeeId,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(
                    `error deleting event from schedule eventid - ${eventid}: \n${err}`
                );
                return err;
            });
    };
    await remove();
    return datatosend;
}
