import * as React from 'react';
import DataTable from 'components/table/DataTableNew';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'store';
import { useTheme } from '@mui/material/styles';
import GetActiveMembershipsPackages from 'actions/studios/memberships-packages/getActiveMembershipsPackages';
import {
    setMembershipsNeedsRefresh,
    addMembershipHeaders,
    addMembershipData,
    addCurrentDataTableHeaders,
    addCurrentDataTableData,
    setCurrentDataTableNeedsRefresh,
    setCurrentDataTable,
} from 'store/slices/datatables';

import deactivateMembershipPackage from 'actions/studios/memberships-packages/deactivateMembershipsPackages';
import updateMembershipsPackages from 'actions/studios/memberships-packages/update-memberships-packages';
import { getChipTypes } from './MembershipChipTypes';

// table header
const headCells = [
    {
        id: '1',
        numeric: false,
        disablePadding: false,
        label: 'Name',
        leftAlignment: true,
        alignment: 'left',
    },
    {
        id: '2',
        numeric: true,
        disablePadding: false,
        label: '# of Classes',
        leftAlignment: true,
        alignment: 'left',
        // disableSort: true,
    },
    {
        id: '3',
        numeric: false,
        disablePadding: false,
        label: 'Price',
        leftAlignment: true,
        alignment: 'left',
    },
    {
        id: '4',
        numeric: false,
        disablePadding: false,
        label: 'Availability',
        leftAlignment: false,
        centerAlignment: true,
        alignment: 'center',
        disableSort: true,
    },
    {
        id: '5',
        numeric: false,
        disablePadding: false,
        label: 'Expires After',
        leftAlignment: true,
        alignment: 'left',
    },
];

// ==============================|| TABLE - DATA TABLE ||============================== //

export default function PackagesTable() {
    const dispatch = useDispatch();
    const [doneLoadingData, setDoneLoadingData] = React.useState(false);
    const [refreshData, setRefreshData] = React.useState(true);
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const { currentDataTable } = useSelector((state) => state.datatables);
    const { needsRefresh, data } = currentDataTable;
    const [datarowstosend, setDatarowstosend] = React.useState(data);
    const [chipOptions, setChipOptions] = React.useState([]);
    const theme = useTheme();

    React.useEffect(() => {
        if (data) {
            setDatarowstosend(data);
            setDoneLoadingData(true);
        }
    }, [data]);
    React.useEffect(() => {
        if (theme) {
            const chipOptionsToSend = getChipTypes(theme);
            setChipOptions(chipOptionsToSend);
        }
    }, [theme]);
    React.useEffect(() => {
        const fetchData = async () => {
            // setDoneLoadingData(false);
            await GetActiveMembershipsPackages(dibsStudioId)
                .then((result) => {
                    dispatch(addCurrentDataTableHeaders(headCells));
                    dispatch(addCurrentDataTableData(result.packages));
                    dispatch(setCurrentDataTable('packages'));
                    setDoneLoadingData(true);
                    setRefreshData(false);
                    dispatch(setCurrentDataTableNeedsRefresh(false));
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        if (needsRefresh || refreshData) {
            fetchData();
        }
    }, [dibsStudioId, dispatch, refreshData, needsRefresh]);
    const deactivatePackage = async (id) => {
        await deactivateMembershipPackage(id)
            .then((result) => {
                setRefreshData(true);
            })
            .catch((err) => {
                console.log('error deactivating package', id, err);
            });
    };
    const updatePackage = async (changelog) => {
        console.log('changelog is', changelog);
        return await updateMembershipsPackages(changelog)
            .then((result) => {
                setRefreshData(true);
                return result;
            })
            .catch((err) => {
                console.log('error updating package', changelog, err);
                return err;
            });
    };
    const columnstoshow = [
        'name',
        'numClasses',
        'price',
        'chipcolumn',
        'expirationTimeline',
    ];
    const secondaryValues = [
        {
            value: 'unlimited',
            label: 'Unlimited',
            typeSelect: 'dropdown',
            options: [
                { key: 'unlimitedtrue', value: true, label: 'Yes' },
                { key: 'unlimitedfalse', value: false, label: 'No' },
            ],
        },

        {
            value: 'customDescription',
            label: 'Custom Description',
            typeSelect: 'text',
            options: null,
            needsmorespace: true,
        },
        {
            value: 'onDemandAccess',
            label: 'Virtual Class Access',
            typeSelect: 'dropdown',
            options: [
                { key: 'vod-true', value: true, label: 'Yes' },
                { key: 'vod-false', value: false, label: 'No' },
            ],
        },
        {
            value: 'showPricePerClass',
            label: 'Show Price Per Class',
            typeSelect: 'dropdown',
            options: [
                { key: 'show-price-per-class-true', value: true, label: 'Yes' },
                {
                    key: 'show-price-per-class-false',
                    value: false,
                    label: 'No',
                },
            ],
        },
    ];
    return (
        <Grid container>
            {doneLoadingData ? (
                <DataTable
                    tabletype="membership"
                    setRefreshData={setRefreshData}
                    columnstoshow={columnstoshow}
                    datarows={datarowstosend}
                    headercellsdata={headCells}
                    deactivateAction={deactivatePackage}
                    secondaryValues={secondaryValues}
                    chipOptions={chipOptions}
                    saveAction={updatePackage}
                    numRowsToShow={5}
                />
            ) : (
                <div>Loading...</div>
            )}
        </Grid>
    );
}
