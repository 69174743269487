export const FEED_REFRESH_TIME = 5 * 60;

export const SET_FEED_EVENTS = "SET_FEED_EVENTS";
export const SET_FEED_CURRENT_DATE = "SET_FEED_CURRENT_DATE";
export const RESET_FEED_EVENTS = "RESET_FEED_EVENTS";

export const ADD_DATE_TO_FETCHING_FEED_EVENTS =
  "ADD_DATE_TO_FETCHING_FEED_EVENTS";
export const REMOVE_DATE_FROM_FETCHING_FEED_EVENTS =
  "REMOVE_DATE_FROM_FETCHING_FEED_EVENTS";
export const ADD_EVENTID_TO_FETCHING_EVENTS = "ADD_EVENTID_TO_FETCHING_EVENTS";
export const REMOVE_EVENTID_TO_FETCHING_EVENTS =
  "REMOVE_EVENTID_TO_FETCHING_EVENTS";

export const SET_ROOM_FOR_EVENT = "SET_ROOM_FOR_EVENT";
export const SET_CLIENT_FOR_SPOT = "SET_CLIENT_FOR_SPOT";

export const SET_FEED_EVENT_NAMES = "SET_FEED_EVENT_NAMES";
