import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';

import CloseIcon from '@mui/icons-material/Close';
import AddAlarmTwoToneIcon from '@mui/icons-material/AddAlarmTwoTone';

import { Grid } from '@mui/material';

export default function AddEventButton({ text, clickAction }) {
    const [open, setOpen] = React.useState(false);

    return (
        <Box
            sx={{ width: '100%', justifyContent: 'flex-end' }}
            align="flex-end"
            border={0}
        >
            <Grid container justifyContent="flex-end">
                <Grid item id="add-class-button" xs={12}>
                    <Button
                        disabled={open}
                        id="add-event-button-calendar-page"
                        variant="contained"
                        color="secondary"
                        disableElevation
                        onClick={() => {
                            // setOpen(true);
                            clickAction(true);
                            // setTimeout(() => {
                            //     setOpen(false);
                            // }, 6000);
                        }}
                    >
                        <AddAlarmTwoToneIcon
                            fontSize="small"
                            sx={{ mr: 0.75 }}
                        />
                        {text}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
