import { createSelector } from "reselect";

import createUnboundedSelector from "../../unbounded-selector";
import { getClient, getClientFullName } from "../index";
import {
  mapTransactionTypeToPathname,
  transactionDataMaps,
  applyTransactionTableTextFilter,
} from "../../../../shared/helpers/transaction-history";

/**
 * @param {Object} state in Redux store
 * @returns {Object} client transactions state
 */
export function getClientTransactions(state) {
  return getClient(state).transactions || {};
}

/**
 * @param {Object} state in Redux store
 * @returns {Array<Object>} client transactions from the server
 */
export function getClientTransactionsData(state) {
  return getClientTransactions(state).data || {};
}

/**
 * @param {Object} state in Redux store
 * @returns {string} client transactions search query
 */
export function getClientTransactionsSearchQuery(state) {
  return getClientTransactions(state).searchQuery || "";
}

/**
 * @param {Object} state in Redux store
 * @returns {boolean} true if fetching client transactions
 */
export function getClientTransactionsLoading(state) {
  return getClientTransactions(state).loading || {};
}

/**
 * @param {Object} state in store
 * @returns {Object} map of which transactions are refunding
 */
export function getClientTransactionsRefunding(state) {
  return getClientTransactions(state).refunding || {};
}

/**
 * @param {Object} state in store
 * @returns {Object} map of which transactions are dropping
 */
export function getClientTransactionsDropping(state) {
  return getClientTransactions(state).dropping || {};
}

/**
 * @param {Object} state in store
 * @returns {Object} map of which transactions are dropping
 */
export function getClientTransactionsDroppingPast(state) {
  return getClientTransactions(state).droppingPast || {};
}

/**
 * @param {Object} state in store
 * @returns {Object} map of which transactions are being marked as early cancel
 */
export function getClientTransactionsMarkingEarlyCancel(state) {
  return getClientTransactions(state).markingEarlyCancel || {};
}

/**
 * @param {Object} state in store
 * @returns {string} heading for transaction table
 */
export function getClientTransactionsHeading(state) {
  return getClientTransactions(state).heading || "";
}

export const getClientTransactionTableLoading = createSelector(
  [getClientTransactionsLoading, (_, pathname) => pathname],
  (loading, pathname) =>
    Boolean(mapTransactionTypeToPathname(loading)(pathname)),
);

export const getClientTransactionTableData = createUnboundedSelector(
  [getClientTransactionsData, (_, pathname) => pathname, getClientFullName],
  (data, pathname, clientName) =>
    (mapTransactionTypeToPathname(data)(pathname) || []).map((transaction) =>
      transactionDataMaps(pathname)(transaction, {
        isStudioAdmin: true,
        clientName,
      }),
    ),
);

export const getFilteredClientTransactionTableData = createSelector(
  [getClientTransactionTableData, getClientTransactionsSearchQuery],
  applyTransactionTableTextFilter,
);

export const getRefundingClientTransaction = createSelector(
  getClientTransactionsRefunding,
  (refunding) => (id) => Boolean(refunding[id]),
);

export const getDroppingClientTransaction = createSelector(
  getClientTransactionsDropping,
  (dropping) => (id) => Boolean(dropping[id]),
);

export const getDroppingPastClientTransaction = createSelector(
  getClientTransactionsDroppingPast,
  (droppingPast) => (id) => Boolean(droppingPast[id]),
);

export const getMarkingClientTransactionEarlyCancel = createSelector(
  getClientTransactionsMarkingEarlyCancel,
  (markingEarlyCancel) => (id) => Boolean(markingEarlyCancel[id]),
);
