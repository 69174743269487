import * as React from 'react';
import propTypes from 'prop-types';
import { useSelector, useDispatch } from 'store';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SubmitButton from 'components/button/submitButton';
import CancelButton from 'components/button/cancelButton';

import CreateNewPromoCode from 'actions/studios/promocodes/createNewPromoCode';
import { setPromoNeedsRefresh } from 'store/slices/datatables';
import StandardAccordian from 'ui-component/accordians/StandardAccordian.js';
import QuestionConstants from 'constants/promocodes/create-new-promo-code-questions.js';

export default function PromoCodeAccordian(props) {
    const {
        promoCodeName,
        clearPromoCodeName,
        updateInfo,
        promoCodeInfo,
        setTrueFalseFields,
        multiCriteria,
    } = props;
    const dispatch = useDispatch();
    const theme = useTheme();
    const { config } = useSelector((state) => state.dibsstudio);
    const { id, studioid, dibsStudioId } = config;
    const [expanded, setExpanded] = React.useState('panel1');
    const [expirationDate, setExpirationDate] = React.useState(null);
    const [newClientsOnly, setNewClientsOnly] = React.useState(false);
    const [allRequirementsFullfilled, setAllRequirementsFullfilled] =
        React.useState(false);
    const [hasSuccess, setHasSuccess] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [timeoutArray, setTimeoutArray] = React.useState([]);
    const [promoCodePlaceholderType, setPackageOrMembership] =
        React.useState('Percent Off');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleNextQuestion = (closePanel, openPanel) => () => {
        setExpanded(false);
        setExpanded(openPanel);
    };
    const handleErrorProcess = (errorMsg) => {
        setHasSuccess(false);
        setErrorMessage(errorMsg);
        setHasError(true);
        const timeoutiderror = setTimeout(() => {
            setHasError(false);
            setErrorMessage('');
        }, 10000);
        setTimeoutArray([...timeoutArray, timeoutiderror]);
    };
    const handleSuccessProcess = (successMsg) => {
        setHasError(false);
        setSuccessMessage(successMsg);
        setHasSuccess(true);
        setExpanded(false);
        const timeoutid = setTimeout(() => {
            setHasSuccess(false);
            setSuccessMessage('');
        }, 7000);
        const timeoutidname = setTimeout(() => {
            clearPromoCodeName();
            dispatch(setPromoNeedsRefresh(true));
        }, 3000);
        setTimeoutArray([...timeoutArray, timeoutid, timeoutidname]);
    };
    const handleCancel = () => {
        clearPromoCodeName();
    };
    const handleSubmit = async () => {
        if (allRequirementsFullfilled) {
            const promoCodeInfo = {
                employeeId: id,
                studioid,
                promoCodeName,
                expirationDate,
                newClientsOnly,
            };
            await CreateNewPromoCode(dibsStudioId, promoCodeInfo).then(
                (res) => {
                    if (res.msg === 'failure') {
                        handleErrorProcess(res.error);
                    }
                    if (res.msg === 'success') {
                        handleSuccessProcess(
                            'Successfully created a new promo code. You can create another promo code by submitting new information.'
                        );
                    }
                }
            );
        }
    };

    React.useEffect(() => {
        if (expirationDate) {
            setAllRequirementsFullfilled(true);
        } else {
            setAllRequirementsFullfilled(false);
        }
        return () => {
            timeoutArray.forEach((timeout) => {
                clearTimeout(timeout);
            });
        };
    }, [expirationDate, timeoutArray]);
    const testFunction = () => {
        console.log('test function running - for promo code questions');
    };

    // extraAction is used to display a secondary question that is based on answer of first question in the same section
    const extraActionFunction = (valuePassedIn, functiontype) => {
        updateInfo(functiontype, valuePassedIn);
    };
    return (
        <>
            <div>
                {QuestionConstants.map((ques) => {
                    const { id, title } = ques;
                    const panelInfo = `panel${id}`;
                    const { type } = promoCodeInfo;
                    const { keytouse } = ques;

                    return (
                        <StandardAccordian
                            key={id}
                            id={id}
                            expanded={expanded}
                            handleChange={handleChange}
                            handleNextQuestion={handleNextQuestion}
                            setExpirationDate={setExpirationDate}
                            actionFunction={testFunction}
                            title={title}
                            ques={ques}
                            panelInfo={panelInfo}
                            extraAction={extraActionFunction}
                            setTrueFalseFields={setTrueFalseFields}
                            dropdownWidth={156}
                            questionCriteria={promoCodePlaceholderType}
                            beingCreatedInfo={promoCodeInfo}
                            multiCriteria={multiCriteria}
                        />
                    );
                })}
            </div>
            <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                {hasError && (
                    <Grid item xs={12}>
                        <Typography variant="h6" color="error" sx={{ mb: 2 }}>
                            {errorMessage}
                        </Typography>
                    </Grid>
                )}
                {hasSuccess && (
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            sx={{ mb: 2, color: theme.palette.success.main }}
                        >
                            {successMessage}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            {allRequirementsFullfilled && (
                <Grid
                    item
                    xs={12}
                    sx={{ mt: 2, mb: 2, ml: 1 }}
                    id="submit-promocode-btn"
                >
                    <SubmitButton
                        id="submit-button-promocode-pack"
                        valueString="Save Promo Code"
                        onClick={handleSubmit}
                    />
                </Grid>
            )}
            {!expanded && (
                <Grid
                    item
                    xs={12}
                    sx={{ mt: 3.5, mb: 2, ml: 1 }}
                    id="cancel-promocode-btn"
                >
                    <CancelButton
                        id="cancel-button-promo-code-new"
                        valueString={`Cancel Creation of ${promoCodePlaceholderType}`}
                        onClick={handleCancel}
                    />
                </Grid>
            )}
        </>
    );
}
PromoCodeAccordian.propTypes = {
    promoCodeName: propTypes.string,
    clearPromoCodeName: propTypes.func,
};
