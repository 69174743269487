import { createSelector } from 'reselect';
// import { format as formatCurrency } from 'currency';
import Decimal from 'decimal.js';
import {
    getUserStudioCreditTiers,
    getUserStudioCurrency,
    getUserStudioDibsId,
} from '../user';

/**
 * getClientFromState
 * @param {Object} state in Redux store
 * @returns {Object} the client object in state
 */
export function getClient(state) {
    return state.client || {};
}

/**
 * getClientIsLoading
 * @param {Object} state in Redux store
 * @returns {boolean} true if getting client data
 */
export function getClientIsLoading(state) {
    return getClient(state).isLoading || false;
}
const formatCurrency = (price, { code }) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: code,
    }).format(price);
};

/**
 * getClientIsGettingCC
 * @param {Object} state in Redux store
 * @returns {boolean} true if getting client CC
 */
export function getClientIsGettingCC(state) {
    return getClient(state).gettingCC || false;
}

/**
 * @param {Object} state in store
 * @returns {number} client's userid
 */
export function getClientId(state) {
    return getClient(state).id;
}

/**
 * @param {Object} state in store
 * @returns {Array<Object>} active flash credits
 */
export function getClientFlashCredits(state) {
    return getClient(state).flash_credits || [];
}

/**
 * @param {Object} state in store
 * @returns {Array<Object>} user credits
 */
export function getClientCredits(state) {
    return getClient(state).credits || [];
}

/**
 * @param {Object} state in store
 * @returns {Object} clients suppression list array
 */
export function getClientEmailPreferences(state) {
    return getClient(state).suppression_lists || {};
}

/**
 * @param {Object} state in store
 * @returns {boolean} if fetching client credits
 */
export function getGettingClientEvents(state) {
    return Boolean(
        getClient(state).gettingEvents || getClient(state).syncingEvents
    );
}

/**
 * @param {Object} state in store
 * @returns {boolean} if the client is syncing passes
 */
export function getSyncingClientPasses(state) {
    return Boolean(getClient(state).syncingPasses);
}

/**
 * @param {Object} state in store
 * @returns {string} their first name
 */
export function getClientFirstName(state) {
    return getClient(state).firstName || '';
}

/**
 * @param {Object} state in store
 * @returns {string} their last name
 */
export function getClientLastName(state) {
    return getClient(state).lastName || '';
}

/**
 * @param {Object} state in store
 * @returns {Object} clients credit card
 */
export function getClientCC(state) {
    return getClient(state).cc || {};
}

/**
 * @param {Object} state in store
 * @returns {Boolean} If cc info is still being retrieved
 */
export function getClientCCLoading(state) {
    return getClient(state).gettingCC || false;
}

/**
 * @param {Object} state in store
 * @returns {boolean} if the client has no credit card
 */
export function getClientHasNoCreditCard(state) {
    return Boolean(getClientCC(state).noCreditCard);
}

/**
 * @param {Object} state in store
 * @returns {string} their profile pic url
 */
export function getClientPictureUrl(state) {
    return getClient(state).pictureUrl || '';
}

/**
 * @param {Object} state in store
 * @returns {string} their email
 */
export function getClientEmail(state) {
    return getClient(state).email || '';
}

/**
 * @param {Object} state in store
 * @returns {string} their phone number
 */
export function getClientMobilePhone(state) {
    return getClient(state).mobilePhone || '';
}

/**
 * @param {Object} state in store
 * @returns {string} their birthday
 */
export function getClientBirthday(state) {
    return getClient(state).birthday || '';
}

/**
 * @param {Object} state in store
 * @returns {boolean} if in the middle of canceling a client's subscription
 */
export function getCancelingClientPass(state) {
    return Boolean(getClient(state).cancelingPass) || false;
}

/**
 * @param {Object} state in store
 * @returns {boolean} if in the middle of update a client package expiration date
 */
export function getUpdatingPackageExpirationDate(state) {
    return Boolean(getClient(state).updatingPackageExpiration);
}

/**
 * @param {Object} state in store
 * @returns {boolean} true if the user has made a purchase at the studio
 */
export function getClientHasMadePurchaseAtStudio(state) {
    return Boolean(getClient(state).hasMadePurchaseAtStudio);
}

/**
 * @param {Object} state in store
 * @returns {boolean} true if the user has made a purchase at the studio
 */
export function getClientNumVisits(state) {
    return getClient(state).numVisitsHere;
}

export const getClientStudioCredits = createSelector(
    [getClientCredits, getUserStudioDibsId],
    (credits, id) =>
        (credits.find((c) => c.dibs_studio_id === id && c.source !== 'raf') &&
            credits.find((c) => c.dibs_studio_id === id).credit) ||
        0
);

export const getFormattedClientStudioCredits = createSelector(
    [getClientStudioCredits, getUserStudioCurrency],
    (studioCredAmount, code) =>
        typeof studioCredAmount === 'number'
            ? formatCurrency(studioCredAmount, { code })
            : ''
);

export const getClientGlobalCredits = createSelector(
    [getClientCredits],
    (credits) =>
        (credits.find((c) => c.source === 'dibs') &&
            credits.find((c) => c.source === 'dibs').credit) ||
        0
);

export const getClientRAFCredits = createSelector(
    [getClientCredits, getUserStudioDibsId],
    (credits, id) =>
        (credits.find((c) => c.source === 'raf' && c.dibs_studio_id === id) &&
            credits.find((c) => c.source === 'raf').credit) ||
        0
);

export const getClientSignedWaiver = createSelector(
    [getClient, getUserStudioDibsId],
    (client, dibsStudioId) => {
        const clientStudio =
            client.userStudios &&
            client.userStudios.find((us) => us.dibs_studio_id === dibsStudioId);
        return (clientStudio && clientStudio.signed_waiver) || false;
    }
);

export const getClientVaxProof = createSelector(
    [getClient, getUserStudioDibsId],
    (client, dibsStudioId) => {
        const clientStudio =
            client.userStudios &&
            client.userStudios.find((us) => us.dibs_studio_id === dibsStudioId);
        return (clientStudio && clientStudio.showed_vax_proof) || false;
    }
);

export const getClientNotes = createSelector(
    [getClient, getUserStudioDibsId],
    (client, dibsStudioId) => {
        const clientStudio =
            client.userStudios &&
            client.userStudios.find((us) => us.dibs_studio_id === dibsStudioId);
        return clientStudio ? clientStudio.client_notes : '';
    }
);

export const getClientCreditSpecials = createSelector(getClient, (client) =>
    client.creditSpecials.map((special) => ({ ...special, isSpecial: true }))
);

export const getClientCreditTiers = createSelector(
    [getUserStudioCreditTiers, getClientCreditSpecials],
    (defaultCreditTiers, creditSpecials) => {
        let creditTiers;
        if (!creditSpecials?.length) {
            creditTiers = defaultCreditTiers;
        } else if (creditSpecials?.length === defaultCreditTiers?.length) {
            creditTiers = creditSpecials;
        } else {
            creditTiers = [...defaultCreditTiers, ...creditSpecials];
        }
        return creditTiers.sort((a, b) => a.payAmount - b.payAmount);
    }
);

export const getClientCreditTierOptions = createSelector(
    [getClientCreditTiers, getUserStudioCurrency],
    (creditTiers, currency) =>
        creditTiers.map(({ payAmount, receiveAmount, isSpecial }) => {
            const formattedPayAmount = formatCurrency(payAmount, {
                code: currency,
                precision: payAmount % 1 && 2,
            });
            const formattedReceiveAmount = formatCurrency(receiveAmount, {
                code: currency,
                precision: receiveAmount % 1 && 2,
            });
            return {
                label: `Pays: ${formattedPayAmount} Receives: ${formattedReceiveAmount}`,
                value: {
                    payAmount,
                    receiveAmount,
                    isSpecial,
                },
            };
        })
);

export const getClientFullName = createSelector(
    getClient,
    (client) => `${client.firstName} ${client.lastName}`
);

export const getClientTotalCredits = createSelector(
    [getClientStudioCredits, getClientGlobalCredits, getClientRAFCredits],
    (studioCreds, globalCreds, rafCreds) =>
        +Decimal(studioCreds).plus(globalCreds).plus(rafCreds)
);

export const getFormattedClientTotalCredits = createSelector(
    [getClientTotalCredits, getUserStudioCurrency],
    (creds, code) => formatCurrency(creds, { code })
);

export const getClientCartFlashCreditAmount = createSelector(
    getClientFlashCredits,
    getUserStudioDibsId,
    (state) =>
        state.client.cart.items
            .filter((item) => item.price)
            .reduce((acc, item) => acc + item.quantity, 0),
    (flashCredits, userDibsStudioId, cartQuantity) =>
        flashCredits.reduce((acc, flashCredit, i) => {
            if (
                flashCredit.dibs_studio_id === userDibsStudioId &&
                i < cartQuantity
            ) {
                return acc + flashCredit.credit;
            }
            return acc;
        }, 0)
);

export const getFormattedClientFlashCreditAmount = createSelector(
    [getClientCartFlashCreditAmount, getUserStudioCurrency],
    (fcAmount, code) => formatCurrency(fcAmount, { code })
);
