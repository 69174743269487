import React, { useState } from 'react';
import { FormControl, Select, MenuItem, Box, Typography } from '@mui/material';

// MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root fieldset

function StandardDropdown({
    options,
    dropdownWidth,
    extraAction,
    functionType,
    choiceSelectedToSend,
    setCriteriaChosen,
}) {
    let thisOptionfirst = options[0];
    if (choiceSelectedToSend) {
        thisOptionfirst = options.find(
            (option) => option.id === choiceSelectedToSend.id
        );
    }
    const [thisOption, setThisOption] = useState(thisOptionfirst);

    const handleChange = (event) => {
        const selectedOption = options.find(
            (option) => option.id === event.target.value
        );
        setThisOption(
            options.find((option) => option.id === event.target.value)
        );
        if (extraAction) {
            extraAction(selectedOption?.codeValue, functionType);
        }
        if (setCriteriaChosen) {
            setCriteriaChosen(selectedOption.codeValue);
        }
    };
    // MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input css-1kl0080-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
    return (
        <Box
            sx={{
                width: dropdownWidth,
                margin: 0,
                textAlign: 'flex-start',
                p: 0,
            }}
        >
            <FormControl sx={{ m: 0, p: 0 }} fullWidth>
                <Select
                    // labelId="quantity-label"
                    id="quantity-select"
                    variant="outlined"
                    value={thisOption?.id}
                    // label="Quantity"
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    defaultValue={1}
                    // inputProps={{
                    //     fontSize: '11px',
                    //     p: '3px 4px', // Minimal padding around the text
                    // }}
                    sx={{
                        fontSize: '12px',
                        lineHeight: '22px',
                        m: 0,
                        p: '2px 4px', // Minimal padding around the text

                        '& .MuiFormControl-root': {
                            borderBottom: '#f69900',
                        },
                        '& .MuiSelect-select': {
                            height: '22px', // Ensuring the select height is low
                            fontSize: '12px',
                            lineHeight: '22px',
                            paddingTop: '2px', // Adjust as needed for visual alignment
                            paddingBottom: '0px', // Adjust as needed for visual alignment
                        },
                        '& .MuiSvgIcon-root': {
                            // Adjusts the icon size
                            fontSize: '14px',
                        },
                        '&& .MuiOutlinedInput-input': {
                            // Using higher specificity to override default styles
                            padding: '0px 3px', // Custom padding, adjust as necessary
                            paddingTop: '2px',
                        },
                        '&& .MuiSelect-select': {
                            // Targets the div inside the input that has the actual text
                            paddingRight: '12px', // Adjusts the padding to the right, reducing it if needed
                            border: '#f69900 !important',
                        },
                        '&&& .MuiOutlinedInput-input': {
                            // padding: '4px !important', // Override padding
                            // border: '1px solid rgba(50, 50, 93, 0.1) !important', // Override border
                            // borderRadius: '6px !important', // Ensure border-radius is as desired
                            fontSize: '12px !important', // Ensure font-size is not overridden
                        },
                    }}
                >
                    {options.map((option) => {
                        return (
                            <MenuItem
                                value={option.id}
                                key={`dropdown-standard-${option.id}`}
                                label={option.name}
                                sx={{
                                    fontSize: '11px',
                                    lineHeight: '22px',
                                    minHeight: '22px', // Reducing the height of menu items
                                }}
                            >
                                <Typography>{option.name}</Typography>
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
}

export default StandardDropdown;
