import axios from 'axios';
import { getApiUrl } from 'helpers';
export default async function bookFromSchedule(
    userid,
    eventid,
    paymentMethod,
    willApplyPass,
    passIdToUse,
    costToCharge,
    dibsId,
    taxAmount,
    subPriceToCharge,
    studioEmployeeId,
    locationBooked,
    eventType
) {
    if (willApplyPass) {
        const passUrl = getApiUrl('/studio-admin/checkout-with-pass');
        const passRes = await axios.post(passUrl, {
            eventid,
            userid,
            dibsStudioId: dibsId,
            passId: passIdToUse,
            studioEmployeeId,
        });
        const { data } = passRes;
        console.log('pass response is', data);
        return data;
    } else {
        const url = getApiUrl('/stripe/create-payment-new');

        const res = await axios.post(url, {
            userid,
            eventid,
            paymentMethod,
            costToCharge,
            dibsId,
            taxAmount,
            subPriceToCharge,
            studioEmployeeId,
            locationBooked,
            eventType,
        });
        const { data } = res;
        return data;
    }
}
