// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
const initialState = {
    schedule: [],
    isLoading: false,
    hasError: false,
    errorMsg: '',
    apptBeingCreated: {},
    dateSelectedForSearching: null,
    currentDateShowing: new Date(),
};

const schedule = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        // HAS ERROR
        setHasError(state, action) {
            // state.hasError = false;
            // state.hasError = action.payload;
            return {
                ...state,
                hasError: action.payload,
            };
        },
        setErrorMsg(state, action) {
            return {
                ...state,
                errorMsg: action.payload,
            };
        },
        // this is for the initial times being set
        setApptBeingCreatedStartEnd(state, action) {
            const { starttime, endtime } = action.payload;
            return {
                ...state,
                apptBeingCreated: {
                    ...state.apptBeingCreated,
                    starttime,
                    endtime,
                },
            };
        },
        setApptBeingCreatedName(state, action) {
            const { apptTitle, apptId } = action.payload;
            return {
                ...state,
                apptBeingCreated: {
                    ...state.apptBeingCreated,
                    apptTitle,
                    apptId,
                },
            };
        },
        // SET SCHEDULE
        setSchedule(state, action) {
            // state.schedule = action.payload;
            return {
                ...state,
                schedule: action.payload,
            };
        },
        setCurrentDateShowing(state, action) {
            return {
                ...state,
                currentDateShowing: action.payload,
            };
        },
        // SET LOADING
        setLoading(state, action) {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        resetSchedule(state) {
            return {
                ...state,
                schedule: [],
                isLoading: false,
            };
        },
        setDateSelectedForSearching(state, action) {
            // state.schedule = action.payload;
            return {
                ...state,
                dateSelectedForSearching: action.payload,
            };
        },
    },
});

// Reducer
export default schedule.reducer;
export const {
    setHasError,
    setDateSelectedForSearching,
    setSchedule,
    setErrorMsg,
    setLoading,
    resetSchedule,
    setApptBeingCreatedStartEnd,
    setApptBeingCreatedName,
    setCurrentDateShowing,
} = schedule.actions;

// ----------------------------------------------------------------------
