// third-party
import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const initialState = {
  hasSuccess: false,
  successMessage: "",
};

const success = createSlice({
  name: "success",
  initialState,
  reducers: {
    // HAS ERROR
    setHasSuccessReducer(state, action) {
      state.hasSuccess = action.payload;
    },
    // ADD STUDIO DATA
    setSuccessMessageReducer(state, action) {
      state.successMessage = action.payload;
    },
    clearSuccessMessageReducer(state) {
      state.hasSuccess = false;
      state.successMessage = "";
    },
  },
});

// Reducer
export default success.reducer;
export const {
  setSuccessMessageReducer,
  clearSuccessMessageReducer,
  setHasSuccessReducer,
} = success.actions;

// ----------------------------------------------------------------------
