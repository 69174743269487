import studioAdminMenu from './studio-admin-menu-items';
import instructorMenu from './instructor-menu-items';
import employeeMenu from './employee-menu-items';
import hairStylistMenu from './hair-stylist-menu-items';
import dibsSuperAdminMenu from './dibs-super-admin-menu-items';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [studioAdminMenu],
};
export const instructorMenuItems = {
    items: [instructorMenu],
};

export const employeeMenuItems = {
    items: [employeeMenu],
};
export const hairStylistMenuItems = {
    items: [hairStylistMenu],
};

export const dibsSuperAdminMenuItems = {
    items: [dibsSuperAdminMenu],
};

export default menuItems;
