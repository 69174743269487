import { useEffect } from 'react';
import { Grid, TextField, Typography, Button } from '@mui/material';

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';
import { dispatch } from 'store';
import {
    setAlreadyHasAccount,
    setThisUserEmail,
    setThisUserFirstName,
    setThisUserLastName,
    setThisUserPhone,
    setCurrentStep,
    // setStripeId,
    setThisUserEmployeeId,
    // setBirthday,
    setIsInLoginLinkFlow,
} from 'store/slices/studioemployeeuser';
import { useSelector } from 'store';
import { setPageSelected } from 'store/slices/menu';
import { lowercaseString } from 'helpers';
import {
    registerWithEmailAndPassword,
    sendEmailAuthLink,
} from 'firebaseConfig';

import InputLabel from 'ui-component/extended/Form/InputLabel';
import doesUserHaveAccount from 'actions/doesUserHaveAccount.js';
// yup validation-schema
const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Your email address looks incorrect. Please try again')
        .required('Required'),
});

const StepsToCheckout = () => {
    const { isLoggedIn } = useSelector((state) => state.studioemployeeuser);
    const goPassword = () => {
        dispatch(setPageSelected('password'));
        // dispatch(setCurrentStep('password'));
        dispatch(setAlreadyHasAccount(true));
        // navigate('/password');
    };
    const goCreateAccount = () => {
        dispatch(setPageSelected('create-account'));
        // dispatch(setCurrentStep('create-account'));
        // navigate('/create-account');
    };
    const goPayment = () => {
        dispatch(setPageSelected('payment'));
        // dispatch(setCurrentStep('payment'));
        // navigate('/create-account');
    };
    useEffect(() => {
        if (isLoggedIn) {
            console.log('user is already logged in');
            goPayment();
        }
    }, [isLoggedIn]);
    const createNewAccountFirebase = async (email) => {
        const emailtosend = lowercaseString(email);
        const startingpassword = 'startingpassword';
        await registerWithEmailAndPassword(emailtosend, startingpassword)
            .then((user) => {
                console.log(`user: ${JSON.stringify(user, null, 2)}`);
                // dispatch(setCurrentStep('check-email-for-link'));
            })
            .catch((error) => {
                console.log(
                    `CHECK OUT error is: ${JSON.stringify(error, null, 2)}`
                );
                if (error.code === 'auth/email-already-in-use') {
                    console.log('That email address is already in use!');
                }
            });
        await sendEmailAuthLink(emailtosend)
            .then((res) => {
                if (res) {
                    // dispatch(setIsInLoginLinkFlow(true));
                    console.log('email was sent');
                } else {
                    console.log('email was not sent');
                }
            })
            .catch((error) => {
                console.log(
                    `there was an error sending the auth link: ${error}`
                );
            });
    };
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            // alert(JSON.stringify(values, null, 2));
            const acct = await doesUserHaveAccount(values.email);
            console.log(
                `acct info received is: ${JSON.stringify(acct, null, 2)}`
            );
            const { hasAccount, info } = acct;
            dispatch(setAlreadyHasAccount(hasAccount));
            if (hasAccount) {
                dispatch(setThisUserEmail(info.email));
                dispatch(setThisUserFirstName(info.firstName));
                dispatch(setThisUserLastName(info.lastName));
                dispatch(setThisUserPhone(info.mobilephone));
                // dispatch(setStripeId(info.stripeid));
                // dispatch(setBirthday(info.birthday));
                dispatch(setThisUserEmployeeId(info.id));
                console.log(`firebase auth is: ${info.firebase_auth} `);
                if (info.firebase_auth) {
                    goPassword();
                } else {
                    createNewAccountFirebase(info.email);
                }
            } else {
                dispatch(setThisUserEmail(values.email));
                dispatch(setThisUserFirstName(''));
                dispatch(setThisUserLastName(''));
                dispatch(setThisUserPhone(''));
                goCreateAccount();
            }
        },
    });
    return (
        <Grid container>
            <Grid item xs={12} sx={{ mb: 4 }}>
                <Typography variant="subtitle1">
                    Enter your information
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ ml: 1 }}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={9} md={6}>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid item xs={10}>
                                <InputLabel>Email</InputLabel>
                                <TextField
                                    name="email"
                                    value={formik.values.email}
                                    fullWidth
                                    onChange={formik.handleChange}
                                    placeholder="Enter your email address"
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                    helperText={
                                        formik.touched.email &&
                                        formik.errors.email
                                    }
                                />
                            </Grid>
                            <Grid item xs={10} sx={{ mt: 2 }}>
                                <Grid container>
                                    <Grid item sx={{ mr: 1 }}>
                                        <Button
                                            variant="checkout"
                                            type="submit"
                                            sx={{ mr: 2 }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={formik.handleReset}
                                        >
                                            Clear
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default StepsToCheckout;
