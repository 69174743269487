import * as React from 'react';
import DataTable from 'components/table/DataTableNew';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'store';
import { useTheme } from '@mui/material/styles';
import GetActiveApptTypes from 'actions/studios/appointments/getActiveAppointments';
import {
    addCurrentDataTableHeaders,
    addCurrentDataTableData,
    setCurrentDataTableNeedsRefresh,
    setCurrentDataTable,
} from 'store/slices/datatables';

import deactivateAppt from 'actions/studios/appointments/deactivate-appt';
import updateAppointmentInfo from 'actions/studios/appointments/update-appt-info';
import { getChipTypes } from './AppointmentChipTypes';

// table header
const headCells = [
    {
        id: '1',
        numeric: false,
        disablePadding: false,
        label: 'Name',
        leftAlignment: true,
        alignment: 'left',
        translationid: 'name',
    },
    {
        id: '2',
        numeric: false,
        disablePadding: false,
        label: 'Price',
        leftAlignment: true,
        alignment: 'left',
        // disableSort: true,
        translationid: 'price',
    },
    {
        id: '3',
        numeric: false,
        disablePadding: false,
        label: 'Length (in minutes)',
        leftAlignment: true,
        alignment: 'left',
        translationid: 'lengthoftime',
    },
    {
        id: '4',
        numeric: false,
        disablePadding: false,
        label: 'Category',
        leftAlignment: false,
        alignment: 'left',
        translationid: 'category',
    },
];

// ==============================|| TABLE - APPTS TABLE ||============================== //

export default function ApptsTable() {
    const dispatch = useDispatch();
    const [doneLoadingData, setDoneLoadingData] = React.useState(false);
    const [refreshData, setRefreshData] = React.useState(true);
    const { config } = useSelector((state) => state.dibsstudio);
    const { studioEmployeeId } = useSelector(
        (state) => state.studioemployeeuser
    );
    const { dibsStudioId } = config;
    const { currentDataTable } = useSelector((state) => state.datatables);
    const { needsRefresh, data } = currentDataTable;
    const [datarowstosend, setDatarowstosend] = React.useState(data);
    const [chipOptions, setChipOptions] = React.useState([]);
    const theme = useTheme();

    React.useEffect(() => {
        if (data) {
            setDatarowstosend(data);
            setDoneLoadingData(true);
        }
    }, [data]);
    React.useEffect(() => {
        if (theme) {
            const chipOptionsToSend = getChipTypes(theme);
            setChipOptions(chipOptionsToSend);
        }
    }, [theme]);
    React.useEffect(() => {
        const fetchData = async () => {
            // setDoneLoadingData(false);
            await GetActiveApptTypes(dibsStudioId)
                .then((result) => {
                    dispatch(addCurrentDataTableHeaders(headCells));
                    dispatch(addCurrentDataTableData(result.data));
                    dispatch(setCurrentDataTable('appointment'));
                    setDoneLoadingData(true);
                    setRefreshData(false);
                    dispatch(setCurrentDataTableNeedsRefresh(false));
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        if (needsRefresh || refreshData) {
            fetchData();
        }
    }, [dibsStudioId, dispatch, refreshData, needsRefresh]);
    const handleDeactivateAppt = async (id) => {
        await deactivateAppt(id, studioEmployeeId)
            .then(() => {
                setRefreshData(true);
            })
            .catch((err) => {
                console.log('error deactivating appt', id, err);
            });
    };
    const updatePackage = async (changelog) => {
        return await updateAppointmentInfo(changelog)
            .then((result) => {
                setRefreshData(true);
                return result;
            })
            .catch((err) => {
                console.log('error updating appt', changelog, err);
                return err;
            });
    };
    const columnstoshow = [
        'appointment_type',
        'default_price',
        'length_minutes',
        'service_category',
    ];
    const secondaryValues = [
        {
            value: 'sort_order',
            label: 'Sort Order',
            typeSelect: 'text',
            specifiedwidth: '80px',
        },
        {
            value: 'description',
            label: 'Description (HTML)',
            typeSelect: 'text',
            needsmorespace: true,
            specifiedwidth: '80%',
        },
    ];
    return (
        <Grid container>
            {doneLoadingData ? (
                <DataTable
                    tabletype="appointment type"
                    setRefreshData={setRefreshData}
                    columnstoshow={columnstoshow}
                    datarows={datarowstosend}
                    headercellsdata={headCells}
                    deactivateAction={handleDeactivateAppt}
                    secondaryValues={secondaryValues}
                    chipOptions={chipOptions}
                    saveAction={updatePackage}
                    numRowsToShow={10}
                />
            ) : (
                <div>Loading...</div>
            )}
        </Grid>
    );
}
