// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
// import axios from 'axios';
import { getApiUrl } from 'helpers';
// import { dispatch } from '../index';
// import { useDispatch } from 'react-redux';

// ----------------------------------------------------------------------

const initialState = {
    promocodes: {
        headers: [],
        data: [],
        needsRefresh: false,
    },
    memberships: {
        headers: [],
        data: [],
        needsRefresh: true,
    },
    currentDataTable: {
        headers: [],
        data: [],
        needsRefresh: false,
        currentTable: '',
    },

    reporting: {
        title: '',
        headers: [],
        data: [],
        summary: [],
        csvData: [],
        needsRefresh: false,
    },
};

const datatables = createSlice({
    name: 'datatables',
    initialState,
    reducers: {
        // ADD STUDIO DATA
        addPromoCodeHeaders(state, action) {
            state.promocodes.headers = action.payload;
        },
        addCurrentDataTableHeaders(state, action) {
            state.currentDataTable.headers = action.payload;
        },
        addCurrentDataTableData(state, action) {
            state.currentDataTable.data = action.payload;
        },
        addPromoCodeData(state, action) {
            state.promocodes.data = action.payload;
        },
        setCurrentDataTableNeedsRefresh(state, action) {
            state.currentDataTable.needsRefresh = action.payload;
        },
        setCurrentDataTable(state, action) {
            state.currentDataTable.currentTable = action.payload;
        },
        setPromoNeedsRefresh(state, action) {
            state.promocodes.needsRefresh = action.payload;
        },
        setReportingDataForTable(state, action) {
            state.reporting.data = action.payload;
        },
        setSummaryForTable(state, action) {
            state.reporting.summary = action.payload;
        },
        addMembershipHeaders(state, action) {
            state.memberships.headers = action.payload;
        },
        addMembershipData(state, action) {
            state.memberships.data = action.payload;
        },
        setMembershipsNeedsRefresh(state, action) {
            state.memberships.needsRefresh = action.payload;
        },
        setCsvDataForTable(state, action) {
            state.reporting.csvData = action.payload;
        },
    },
});

// Reducer
export default datatables.reducer;
export const {
    addPromoCodeHeaders,
    addPromoCodeData,
    addCurrentDataTableHeaders,
    setMembershipsNeedsRefresh,
    setCurrentDataTable,
    addCurrentDataTableData,
    setPromoNeedsRefresh,
    setCsvDataForTable,
    addMembershipData,
    addMembershipHeaders,
    setCurrentDataTableNeedsRefresh,
    setReportingDataForTable,
    setSummaryForTable,
} = datatables.actions;

// ----------------------------------------------------------------------
