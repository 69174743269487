import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    term: '',
    results: {
        recents: [],
        matches: [],
    },
};

const clientsearch = createSlice({
    name: 'clientsearch',
    initialState,
    reducers: {
        setSearchTerm: (state, action) => {
            state.term = action.payload;
        },
        clearSearchTerms: (state) => {
            state.term = '';
        },

        addOrUpdateSearchResults: (state, action) => {
            state.results.matches = action.payload;
        },
        addToRecentsSearch: (state, action) => {
            const existingArray = [...state.results.recents];
            existingArray.push(action.payload);
            const uniqueArray = [...new Set(existingArray)];

            return {
                ...state,
                results: {
                    ...state.results,
                    recents: uniqueArray,
                },
            };
        },
        clearSearchResults: (state) => {
            state.results.matches = {};
        },
    },
});

export default clientsearch.reducer;
export const {
    setSearchTerm,
    clearSearchTerms,
    addOrUpdateSearchResults,
    clearSearchResults,
    addToRecentsSearch,
} = clientsearch.actions;
