import React from 'react';
import { Grid, Checkbox, FormControlLabel, Stack } from '@mui/material';
import InfoIcon from 'shared/components/HelpInfo/infoIconSmall';

const IndiCheckbox = ({
    idtouse,
    checkbox,
    handleCheckboxChange,
    beingCreatedInfo,
    questionCriteria,
    setTrueFalseFields,
    setMultiCriteriaChosen,
    multiCriteriaChosen,
}) => {
    const fieldnameForChoice = checkbox?.fieldnameForChoice;
    const valueused = beingCreatedInfo[fieldnameForChoice];

    let infoguidance = checkbox?.infoguidance;
    const replacementvalue = questionCriteria.toLowerCase();
    let unchangedLabel = questionCriteria;
    if (unchangedLabel === 'singlesession') {
        unchangedLabel = 'Single Session';
    }
    infoguidance = infoguidance?.replaceAll('[REPLACEVALUE]', replacementvalue);
    const handleThisCheckboxChange = (e) => {
        setTrueFalseFields(fieldnameForChoice, e.target.checked);
        if (multiCriteriaChosen) {
            const tempHolding = [...multiCriteriaChosen];

            if (e.target.checked) {
                tempHolding.push(fieldnameForChoice);

                setMultiCriteriaChosen(tempHolding);
            } else {
                const newArray = tempHolding.filter(
                    (item) => item !== fieldnameForChoice
                );
                setMultiCriteriaChosen(newArray);
            }
        }
    };
    let labelUsing = checkbox?.name;
    labelUsing = labelUsing?.replaceAll('[REPLACEVALUE]', unchangedLabel);
    // handleCheckboxChange(fieldnameForChoice, valueused, idtouse, checkbox?.id);
    return (
        <Grid
            item
            xs={12}
            id={`sub-${idtouse}`}
            key={`key-${idtouse}-${checkbox?.id}`}
        >
            <Stack direction="row" spacing={1}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={valueused}
                            onChange={handleThisCheckboxChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={labelUsing}
                />
                {infoguidance && <InfoIcon msg={infoguidance} />}
            </Stack>
        </Grid>
    );
};
export default IndiCheckbox;
