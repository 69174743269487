import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function BasicDatePicker({
    fieldName,
    initialStartDate,
    changeAction,
    valueSelected,
}) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    label={fieldName}
                    defaultValue={dayjs(initialStartDate)}
                    slotProps={{ textField: { size: 'small' } }}
                    onAccept={changeAction}
                    value={dayjs(valueSelected)}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}
