import { useState, useEffect, useMemo } from "react";
import { sum } from "lodash";
import { Link } from "react-router-dom";
import { useSelector, dispatch } from "store";
// import { updateStripeIds } from 'store/slices/thisuser';

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import { Fab, Badge, IconButton } from "@mui/material";

import getStripeIdForStudio from "actions/users/getStripeIdForStudio";

// assets
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
// import { setColor } from 'store/slices/dibsstudio';

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 0,
    top: 0,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: "0 0px",
    backgroundColor: theme.palette.success.light,
    fontSize: "0.45rem",
    // height: '0.75rem',
    // width: '0.45rem',
  },
}));

// ==============================|| CART ITEMS - FLOATING BUTTON ||============================== //

const FabCart = () => {
  const theme = useTheme();
  const { studioConfig, config } = useSelector((state) => state.dibsstudio);
  const { color } = studioConfig;
  const { dibsStudioId } = config;
  const { userid } = useSelector((state) => state.studioemployeeuser);
  const [colortoshow, setColortoshow] = useState("#999999");
  const [quantity, setQuantity] = useState(0);
  const { itemsInCart, packageItemsInCart } = useSelector(
    (state) => state.cart,
  );
  const stripeids = useMemo(async () => {
    let stripeinfo = {};
    const getStripeIdForStudioUpdate = async () => {
      await getStripeIdForStudio(userid, dibsStudioId).then((res) => {
        stripeinfo = res;
      });
      console.log("stripeinfo: ", stripeinfo);
      return stripeinfo;
    };
    const stid = await getStripeIdForStudioUpdate();
    console.log("stid: ", stid);
    return stid;
  }, [dibsStudioId, userid]);

  useEffect(() => {
    if (color !== undefined) {
      setColortoshow(`#${color}`);
    }
    stripeids.then((res) => {
      // dispatch(updateStripeIds(res));
    });
    const totalQuantity = sum(
      itemsInCart.map((item) =>
        item.dibsStudioId === dibsStudioId ? item.quantity : 0,
      ),
    );
    const totalPackageQuantity = sum(
      packageItemsInCart.map((item) =>
        item.dibsStudioId === dibsStudioId ? item.quantity : 0,
      ),
    );
    const quantitytoreturn = totalQuantity + totalPackageQuantity;
    setQuantity(quantitytoreturn);
  }, [color, dibsStudioId, itemsInCart, packageItemsInCart]);
  // const theme = useTheme();

  // const totalQuantity = sum(itemsInCart.map((item) => item.quantity));

  const urlToLink = `/checkout/${dibsStudioId}`;
  return (
    <Fab
      component={Link}
      to={urlToLink}
      size="large"
      sx={{
        top: "25%",
        position: "fixed",
        right: 10,
        zIndex: theme.zIndex.speedDial,
        // boxShadow: theme.customShadows.warning,
        bgcolor: colortoshow,
        color: colortoshow,
        borderRadius: "8px",
        // borderTopRightRadius: 0, // can set preference here
        // borderBottomRightRadius: 0,
        "&:hover": {
          bgcolor: colortoshow,
          color: colortoshow,
        },
      }}
    >
      <IconButton
        disableRipple
        aria-label="cart"
        sx={{ "&:hover": { bgcolor: "transparent" } }}
        size="large"
      >
        <StyledBadge badgeContent={quantity} color="success">
          <ShoppingCartTwoToneIcon sx={{ color: "grey.800" }} />
        </StyledBadge>
      </IconButton>
    </Fab>
  );
};

export default FabCart;
