// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
// import axios from 'axios';
import { getApiUrl } from "helpers";
// import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
  thisMonthRev: null,
  summaryStats: [],
  revenuetotals: [
    {
      title: "TODAY",
      revenue: "$-",
      stateUp: 1,
      comparedwith: "yesterday",
      percentage: 0,
    },
    {
      title: "THIS WEEK",
      revenue: "$-",
      stateUp: 1,
      comparedwith: "last week",
      percentage: 0,
    },
    {
      title: "THIS MONTH",
      revenue: "$-",
      stateUp: 1,
      comparedwith: "last month",
      percentage: 0,
    },
    {
      title: "THIS YEAR",
      revenue: "$-",
      stateUp: 1,
      comparedwith: "last year",
      percentage: 0,
    },
  ],
  doneLoadingRevenueTotals: false,
  yearstoshow: 5,
  salesRevChart: {
    timePeriod: "monthly",
    monthlyXAxisLabels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
    ],
    weeklyXAxisLabels: [],
    yearlyXAxisLabels: [],
    axisLabelToUse: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
    ],
    fullDataSet: [],
    doneLoadingFullDataSet: false,
    doneLoadingStrippedData: false,
    dataSets: {
      monthlySeries: [],
      weeklySeries: [],
      annualSeries: [],
    },
  },
};

const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    setDoneLoadingRevenueTotals(state, action) {
      state.doneLoadingRevenueTotals = action.payload;
    },
    // ADD REVENUE DATA
    addRevenueDataToDashboard(state, action) {
      state.revenuetotals = action.payload;
    },
    // ADD AXIS DATA
    addXAxisDataToDashboard(state, action) {
      state.xaxis = action.payload;
    },
    setThisMonthRevenue(state, action) {
      state.thisMonthRev = action.payload;
    },
    setSummaryStats(state, action) {
      state.summaryStats = action.payload;
    },

    // SET YEARS TO SHOW
    setNumYearsToShow(state, action) {
      state.yearstoshow = action.payload;
    },
    // CHARTS - SET SALES REVENUE TIME PERIOD
    setSalesRevenueTimePeriodChart(state, action) {
      state.salesRevChart.timePeriod = action.payload;
    },
    setSalesRevenueMonthXAxisChart(state, action) {
      state.salesRevChart.monthlyXAxisLabels = action.payload;
    },
    setSalesRevenueWeekXAxisChart(state, action) {
      state.salesRevChart.weeklyXAxisLabels = action.payload;
    },
    setSalesRevenueYearXAxisChart(state, action) {
      state.salesRevChart.yearlyXAxisLabels = action.payload;
    },
    setSalesRevenueXAxisToUse(state, action) {
      state.salesRevChart.axisLabelToUse = action.payload;
    },
    setDataSetsForSalesRevenueChart(state, action) {
      state.salesRevChart.fullDataSet = action.payload;
      state.salesRevChart.doneLoadingFullDataSet = true;
    },
    setStrippedDataSalesRevenueChart(state, action) {
      state.salesRevChart.dataSets = action.payload;
      state.salesRevChart.doneLoadingStrippedData = true;
    },
    // ADD SALES REVENUE GROWTH DATA
    addSalesRevenueGrowthData(state, action) {
      state.seriesreplace = action.payload;
    },
  },
});

// Reducer
export default dashboard.reducer;
export const {
  addRevenueDataToDashboard,
  hasError,
  setNumYearsToShow,
  setThisMonthRevenue,
  setSummaryStats,
  setDoneLoadingRevenueTotals,
  setSalesRevenueTimePeriodChart,
  setSalesRevenueMonthXAxisChart,
  setSalesRevenueWeekXAxisChart,
  setSalesRevenueYearXAxisChart,
  setSalesRevenueXAxisToUse,
  setDataSetsForSalesRevenueChart,
  setStrippedDataSalesRevenueChart,
  addXAxisDataToDashboard,
  addSalesRevenueGrowthData,
} = dashboard.actions;

// ----------------------------------------------------------------------
