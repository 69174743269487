import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getClientId } from "../../selectors/client";
import withRouter from "helpers/withRouter";
import {
  ACCOUNT_ROUTE,
  DASHBOARD_ROUTE,
  FRONT_DESK_ROUTE,
  TRANSACTIONS_ROUTE,
  STUDIO_SETTINGS_ROUTE,
  STAFF_ROUTE,
  EXCEPTION_HISTORY_ROUTE,
  EDIT_EXCEPTION_ROUTE,
  NEW_EXCEPTION_ROUTE,
  CLIENT_SEARCH_RESULTS_ROUTE,
  GET_CLIENT_PROFILE_ROUTE,
  PROMO_CODE_HISTORY_ROUTE,
  EDIT_PROMO_CODE_ROUTE,
  GET_ADD_CLIENT_CREDITS_ROUTE,
  GET_CLIENT_BOOK_CLASSES_ROUTE,
  GET_CLIENT_BOOK_CLASSES_CART_ROUTE,
  GET_CLIENT_EVENTS_CONFIRMED_ROUTE,
  GET_DROP_ROUTE,
  PAYMENT_SUMMARY_ROUTE,
} from "../../constants/RouteConstants";
import MobileBackButton from "./MobileBackButton";
import "../../styles/top-bar.scss";

/**
 * Topbar React Component
 * @extends {module:React.PureComponent}
 * @returns {JSX.Element} HTML
 */
class Topbar extends React.PureComponent {
  /**
   * constructor
   * @constructs Topbar
   * @param {Object} props Component props
   */
  constructor(props) {
    super(props);
    this.getTopbarHeading = this.getTopbarHeading.bind(this);
  }
  /**
   * getTopbarLabel
   * @returns {string} the label
   */
  getTopbarHeading() {
    switch (this.props.location) {
      case ACCOUNT_ROUTE:
        return "My Account";
      case DASHBOARD_ROUTE:
        return "Dashboard";
      case TRANSACTIONS_ROUTE:
        return "Transactions";
      case STUDIO_SETTINGS_ROUTE:
        return "Studio Settings";
      case STAFF_ROUTE:
        return "Staff";
      case EXCEPTION_HISTORY_ROUTE:
        return "Pricing Exceptions";
      case EDIT_EXCEPTION_ROUTE:
        return "Edit Exception";
      case NEW_EXCEPTION_ROUTE:
        return "Add Exception";
      case CLIENT_SEARCH_RESULTS_ROUTE:
        return "Search Clients";
      case PROMO_CODE_HISTORY_ROUTE:
      case EDIT_PROMO_CODE_ROUTE:
        return "Promo Codes";
      case GET_CLIENT_PROFILE_ROUTE(this.props.clientId):
        return "View Account";
      case GET_ADD_CLIENT_CREDITS_ROUTE(this.props.clientId):
        return "Add Credits";
      case GET_CLIENT_BOOK_CLASSES_ROUTE(this.props.clientId):
        return "Add Client to Class";
      case GET_CLIENT_BOOK_CLASSES_CART_ROUTE(this.props.clientId):
        return "Cart";
      case GET_CLIENT_EVENTS_CONFIRMED_ROUTE(this.props.clientId):
        return null;
      case GET_DROP_ROUTE(this.props.clientId):
        return "Drop Client from Class";
      case PAYMENT_SUMMARY_ROUTE:
        return "Invoices";
      case FRONT_DESK_ROUTE:
      default:
        return "Front Desk";
    }
  }
  /**
   * render
   * @returns {JSX.Element} HTML
   */
  render() {
    return (
      <div className="top-bar-container">
        <MobileBackButton />
        <div className="top-bar-heading">{this.getTopbarHeading()}</div>
      </div>
    );
  }
}

Topbar.propTypes = {
  location: PropTypes.string,
  clientId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  clientId: getClientId(state),
});

const SmartTopbar = connect(mapStateToProps)(Topbar);

export default withRouter(SmartTopbar);
