import React from "react";
// import { css as emoCSS } from 'emotion';
// import { css as emoCSS } from '@emotion/react';
import { gql } from "@apollo/client";
import styled from "@emotion/styled";
import { matchSorter } from "match-sorter";
import Downshift from "downshift";
import "shared/styles/search-bar.scss";

const startingNames = [
  { id: 1, name: "Luke Skywalker" },
  { id: 2, name: "C-3PO" },
  { id: 3, name: "R2-D2" },
  { id: 4, name: "Darth Vader" },
  { id: 5, name: "Leia Organa" },
  { id: 6, name: "Owen Lars" },
];

// const allItems = startingNames.map((s) => ({
//     name: s.,
//     id: s.toLowerCase(),
// }));

// const css = (...args) => ({ className: emoCSS(...args) });

const Item = styled.li(
  {
    position: "relative",
    cursor: "pointer",
    display: "block",
    border: "none",
    height: "auto",
    textAlign: "left",
    borderTop: "none",
    lineHeight: "1em",
    color: "#555",
    fontSize: "0.9rem",
    textTransform: "none",
    fontWeight: "400",
    boxShadow: "none",
    padding: ".8rem 1.1rem",
    whiteSpace: "normal",
    wordWrap: "normal",
  },
  ({ isActive, isSelected }) => {
    const styles = [];
    if (isActive) {
      styles.push({
        // color: 'rgba(0,0,0,.95)',
        fontWeight: "700",
        background: "rgba(22,33,0,.03)",
      });
    }
    if (isSelected) {
      styles.push({
        // color: 'rgba(0,0,0,.95)',
        fontWeight: "700",
      });
    }
    return styles;
  },
);
const onAttention = "&:hover, &:focus";
const Input = styled.input(
  {
    width: "250px", // full width - icon width/2 - border
    fontSize: "0.9rem",
    textTransform: "none",
    fontWeight: "400",
    wordWrap: "break-word",
    lineHeight: "1em",
    outline: 1,
    whiteSpace: "normal",
    minHeight: "2em",
    background: "#fff",
    display: "inline-block",
    padding: "0em 0em 0.2em 0em",
    marginLeft: "2em",
    color: "#555",
    boxShadow: "none",
    // border: '0px solid #000',
    borderRadius: ".30rem",
    transition: ".1s ease,width .1s ease",
    // [onAttention]: {
    //     borderColor: '#96c8da',
    //     boxShadow: '0 2px 3px 0 rgba(34,36,38,.15)',
    // },
  },
  ({ isOpen }) =>
    isOpen
      ? {
          borderBottomLeftRadius: "0",
          borderBottomRightRadius: "0",
          [onAttention]: {
            boxShadow: "none",
          },
        }
      : null,
);

const Label = styled("label")({
  fontWeight: "bold",
  display: "block",
  marginBottom: 10,
});

const BaseMenu = styled.div(
  {
    padding: 0,
    marginTop: 5,
    marginLeft: 0,
    paddingLeft: 0,
    position: "absolute",
    backgroundColor: "#fff",
    width: "90%",
    maxHeight: "20rem",
    overflowY: "auto",
    overflowX: "hidden",
    outline: "0",
    transition: "opacity .1s ease",
    borderRadius: "0 0 .28571429rem .28571429rem",
    // boxShadow: '0 2px 3px 0 rgba(34,36,38,.15)',
    // borderColor: '#96c8da',
    zIndex: 1000,
    borderTopWidth: "0",
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderStyle: "solid",
    color: "rgba(0,0,0,.87)",
  },
  ({ isOpen }) => ({
    border: isOpen ? null : "none",
  }),
);

const ControllerButton = styled("button")({
  backgroundColor: "transparent",
  border: "none",
  position: "absolute",
  boxShadow: "none",
  right: 0,
  bottom: 0,
  cursor: "pointer",
  width: "32px",
  display: "flex",
  flexDirection: "column",
  height: "32px",
  justifyContent: "center",
  alignItems: "bottom",
});

function ArrowIcon({ isOpen }) {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      width={16}
      fill="transparent"
      stroke="#979797"
      strokeWidth="1.1px"
      transform={isOpen ? "rotate(180)" : undefined}
    >
      <path d="M1,6 L10,15 L19,6" />
    </svg>
  );
}

function XIcon() {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      width={12}
      fill="transparent"
      stroke="#979797"
      strokeWidth="1.1px"
    >
      <path d="M1,1 L19,19" />
      <path d="M19,1 L1,19" />
    </svg>
  );
}

function getItems(filter) {
  return matchSorter(startingNames, filter, { keys: [(item) => item.name] });
}

function getStringItems(filter) {
  return getItems(filter).map(({ name }) => name);
}

const itemToString = (i) => (i ? i.name : "");

const menuStyles = {
  maxHeight: 150,
  maxWidth: 300,
  overflowY: "scroll",
  backgroundColor: "#eee",
  padding: 0,
  listStyle: "none",
  position: "relative",
};

const menuMultipleStyles = {
  maxHeight: "180px",
  overflowY: "auto",
  width: "135px",
  margin: 0,
  borderTop: 0,
  background: "white",
  position: "absolute",
  zIndex: 1000,
  listStyle: "none",
  padding: 0,
  left: "340px",
};

const selectedItemStyles = {
  marginLeft: "5px",
  backgroundColor: "aliceblue",
  borderRadius: "10px",
};

const selectedItemIconStyles = { cursor: "pointer" };

const comboboxStyles = { display: "inline-block", marginLeft: "5px" };

const comboboxWrapperStyles = {
  display: "inline-flex",
  flexWrap: "wrap",
};

export const toggleElementStyles = {
  backgroundColor: "buttonface",
  padding: "0px 4px",
  display: "inline",
  borderWidth: "1px",
  borderStyle: "outset",
  borderColor: "buttonborder",
  verticalAlign: "middle",
  cursor: "pointer",
};

export const SEARCH_CHARACTERS = gql`
  query Characters {
    characters {
      results {
        name
      }
    }
  }
`;
export default function SearchField() {
  return (
    <div
      // {...css({
      //     display: 'flex',
      //     flexDirection: 'column',
      //     marginTop: 0,
      // })}
      className="search-bar-container dibs-highlight-border"
    >
      <Downshift
        onChange={(selection) => console.log("selected", selection)}
        itemToString={itemToString}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          getToggleButtonProps,
          clearSelection,
          getRootProps,
        }) => (
          <div>
            {/* <Label {...getLabelProps()}>
                            This is where it wil go
                        </Label> */}
            {/* <div
                            style={{ display: 'inline-block' }}
                            {...getRootProps({}, { suppressRefError: true })}>
                            <input {...getInputProps()} />
                        </div> */}
            <div
              style={{ display: "inline-block" }}
              {...getRootProps({}, { suppressRefError: true })}
            >
              <Input
                {...getInputProps({
                  isOpen,
                  placeholder: "Enter a name or phone # here",
                })}
              />

              {selectedItem ? (
                <ControllerButton
                  onClick={clearSelection}
                  aria-label="clear selection"
                >
                  <XIcon />
                </ControllerButton>
              ) : (
                <ControllerButton {...getToggleButtonProps()}>
                  <ArrowIcon isOpen={isOpen} />
                </ControllerButton>
              )}
            </div>
            <div>
              <BaseMenu {...getMenuProps()}>
                {isOpen
                  ? getItems(inputValue).map((item, index) => (
                      <Item
                        key={item.id}
                        {...getItemProps({
                          item,
                          index,
                          isActive: highlightedIndex === index,
                          isSelected: selectedItem === item,
                        })}
                      >
                        {itemToString(item)}
                      </Item>
                    ))
                  : null}
              </BaseMenu>
            </div>
          </div>
        )}
      </Downshift>
    </div>
  );
}
