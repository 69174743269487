import axios from 'axios';
import { getApiUrl } from 'helpers';
import moment from 'moment';
import {
    SET_FEED_EVENTS,
    SET_FEED_CURRENT_DATE,
    ADD_DATE_TO_FETCHING_FEED_EVENTS,
    REMOVE_DATE_FROM_FETCHING_FEED_EVENTS,
    SET_ROOM_FOR_EVENT,
    SET_CLIENT_FOR_SPOT,
    ADD_EVENTID_TO_FETCHING_EVENTS,
    REMOVE_EVENTID_TO_FETCHING_EVENTS,
    SET_FEED_EVENT_NAMES,
    RESET_FEED_EVENTS,
} from '../constants/FeedConstants';
import { getFeedEventsOnCurrentDate, getFeedEvents } from '../selectors/feed';
import { addError } from './ErrorActions';
import { addNotice } from './NoticeActions';
import {
    getUserStudioHasSpotBooking,
    getUserStudioMainTZ,
    getUserIsInstructorOnly,
} from '../selectors/user/index';
// import { getUserInstructorId } from '../selectors/instructor/index';

/**
 * @param {Array<Object>} events from the database
 * @returns {Object} redux action
 */
export function setFeedEvents(events) {
    return {
        type: SET_FEED_EVENTS,
        events,
    };
}

/**
 * @param {Array<Object>} events from the database
 * @returns {Object} redux action
 */
export function resetFeedEvents(events) {
    return {
        type: RESET_FEED_EVENTS,
        events,
    };
}

/**
 * @param {Object} value to set to current date
 * @returns {Object} redux action
 */
export function setCurrentDate(value) {
    return {
        type: SET_FEED_CURRENT_DATE,
        value,
    };
}

/**
 * @returns {function} thunk sets current date to today
 */
export function setCurrentDateToday() {
    return function innerSetCurrentDateToday(dispatch, getState) {
        const tz = getUserStudioMainTZ(getState());
        dispatch(setCurrentDate(moment().tz(tz).startOf('day')));
    };
}

/**
 * @param {Array<Object>} value, event names from the database
 * @returns {Object} redux action
 */
export function setFeedEventNames(value) {
    return {
        type: SET_FEED_EVENT_NAMES,
        value,
    };
}

/**
 * @param {string} date current date
 * @returns {Object} action on the state
 */
export function addDateToFetchingFeedEvents(date) {
    return { type: ADD_DATE_TO_FETCHING_FEED_EVENTS, date };
}

/**
 * @param {string} date current date
 * @returns {Object} action on the state
 */
export function removeDateFromFetchingFeedEvents(date) {
    return { type: REMOVE_DATE_FROM_FETCHING_FEED_EVENTS, date };
}

/**
 * @param {number} eventid to add to fetching object
 * @returns {Object} action on the state
 */
export function addEventidToFetchingFeedEvents(eventid) {
    return { type: ADD_EVENTID_TO_FETCHING_EVENTS, eventid };
}

/**
 * @param {number} eventid to remove from fetching object
 * @returns {Object} action on the state
 */
export function removeEventidFromFetchingFeedEvents(eventid) {
    return { type: REMOVE_EVENTID_TO_FETCHING_EVENTS, eventid };
}

/**
 * @param {number} eventid of event to set room for
 * @param {Object} room to associate to event
 * @returns {Object} action on the state
 */
export function setRoomForEvent(eventid, room) {
    return { type: SET_ROOM_FOR_EVENT, value: { eventid, room } };
}

/**
 * @param {number} eventid for event we are adding client in
 * @param {number} userid of user being assigned to spot
 * @param {number} x coordinate of spot
 * @param {number} y coordinate of spot
 * @returns {Object} action on the state
 */
export function setClientForSpot(eventid, userid, x, y) {
    return { type: SET_CLIENT_FOR_SPOT, value: { x, y, userid, eventid } };
}

/**
 * Get feed events from the widget, caching data from previous dates selected
 * @returns {function} redux thunk
 */
export function requestFeedEvents({
    eventids,
    startDate,
    endDate,
    resetEvents,
} = {}) {
    return async function innerRequestFeedEvents(dispatch, getState) {
        const state = getState();
        try {
            if (state.feed.fetchingEvents[state.feed.currentDate.toISOString()])
                return;
            const isInstructorOnly = getUserIsInstructorOnly(state);
            const hasSpotBooking = getUserStudioHasSpotBooking(state);
            const events = getFeedEvents(state);
            const eventRoomMap = hasSpotBooking
                ? events.reduce((acc, val) => {
                      if (val.room) acc[val.id] = val.room;
                      else acc[val.id] = null;
                      return acc;
                  }, {})
                : {};
            const url = '/api/studio/events';
            const params = { studios: [state.user.studio.id] };
            if (eventids) params.eventids = eventids;
            else if (startDate && endDate) {
                params.start = moment
                    .tz(moment(startDate), state.user.studio.mainTZ)
                    .startOf('day')
                    .format('YYYY-MM-DD HH:mm:ss');
                params.end = moment
                    .tz(moment(endDate), state.user.studio.mainTZ)
                    .endOf('day')
                    .format('YYYY-MM-DD HH:mm:ss');
            } else {
                params.start = moment
                    .tz(
                        moment(state.feed.currentDate),
                        state.user.studio.mainTZ
                    )
                    .startOf('day')
                    .format('YYYY-MM-DD HH:mm:ss');
                params.end = moment
                    .tz(
                        moment(state.feed.currentDate),
                        state.user.studio.mainTZ
                    )
                    .endOf('day')
                    .format('YYYY-MM-DD HH:mm:ss');
            }
            if (resetEvents) {
                params.force_no_cache = Boolean(resetEvents);
            }
            if (isInstructorOnly) {
                params.instructor_only = true;
                params.instructor_email = state.user.email;
            }

            if (!getFeedEventsOnCurrentDate(state)?.length)
                dispatch(
                    addDateToFetchingFeedEvents(
                        state.feed.currentDate.toISOString()
                    )
                );
            const { data } = await axios.get(url, { params });
            if (data.success) {
                const eventData = hasSpotBooking
                    ? data.events.map((event) => ({
                          ...event,
                          room: eventRoomMap[event.id],
                      }))
                    : data.events;
                if (resetEvents) {
                    dispatch(resetFeedEvents(eventData));
                } else {
                    dispatch(setFeedEvents(eventData));
                }
                dispatch(
                    removeDateFromFetchingFeedEvents(
                        state.feed.currentDate.toISOString()
                    )
                );
            } else {
                dispatch(
                    addError("Failed to get your studio's events - Err 17")
                );
            }
        } catch (err) {
            console.log(err);
            dispatch(
                removeDateFromFetchingFeedEvents(
                    state.feed.currentDate.toISOString()
                )
            );
            dispatch(addError("Failed to get your studio's events - Err 18"));
        }
    };
}

/**
 * getRoomForEvents
 * @param {number} eventid of the event
 * @returns {function} requests the server for the event data
 */
export function fetchRoomForEvent(eventid) {
    return async function innerGetSpotsForEvent(dispatch) {
        try {
            const { data } = await axios.get(
                `/api/studio/event/${eventid}/spots`
            );
            if (data.success) {
                dispatch(setRoomForEvent(eventid, data.room));
                return data.room;
            }
        } catch (err) {
            console.log(err);
        }
        return dispatch(
            addError(
                'Something went wrong getting the spot list for your class.'
            )
        );
    };
}

/**
 *
 * @param {number} eventid  interal event identifier
 * @return {function} inner thunk
 */
export function fetchEventWithRoom(eventid) {
    return async function innerRequestEventAndRoom(dispatch, getState) {
        if (getState().feed.fetchingEvents[eventid]) return;
        dispatch(addEventidToFetchingFeedEvents(eventid));
        try {
            const { data } = await axios.get(
                `/api/studios/event/${eventid}/with-room?useTransactions=1`
            );
            if (data.success) {
                dispatch(setFeedEvents([data.event]));
            }
        } catch (err) {
            console.log(err);
            dispatch(addError('Something went wrong'));
        }
        dispatch(removeEventidFromFetchingFeedEvents(eventid));
    };
}

/**
 * requestStudioEventNames
 * @param {Date} startDate event names starting on date going forward
 * @returns {function} redux thunk
 */
export function requestStudioEventNames(startDate) {
    return async function innerRequestStudioEventNames(dispatch) {
        const params = {};
        if (startDate)
            params.start = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
        const { data } = await axios.get(getApiUrl('studio/event-names'), {
            params,
        });
        if (data.success) {
            dispatch(setFeedEventNames(data.eventNames));
        }
        if (data.err) {
            console.log(data.err);
            dispatch(addError('Something went wrong'));
        }
    };
}

/**
 * cancelStudioEvent
 * @param {Object} payload trainerid and eventid to update
 * @returns {function} redux thunk
 */
export function cancelStudioEvent(payload) {
    return async function innerCancelStudioEvent(dispatch) {
        const { data } = await axios.put(
            '/studios/api/studio/cancel-event',
            payload
        );
        if (data.success) {
            dispatch(addNotice(data.message));
        }
        if (data.err) {
            console.log(data.err);
            dispatch(addError('Something went wrong canceling the event.'));
        }
    };
}

/**
 * updateStudioEventInstructor
 * @param {Object} payload trainerid and eventid to update
 * @returns {function} redux thunk
 */
export function updateStudioEventInstructor(payload) {
    return async function innerUpdateStudioEventInstructor(dispatch) {
        const { data } = await axios.put(
            '/studios/api/studio/event/instructor',
            payload
        );
        if (data.success) {
            dispatch(addNotice('All set! The instructor has been updated.'));
        }
        if (data.err) {
            console.log(data.err);
            dispatch(addError('Something went wrong updating the event.'));
        }
    };
}
