import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../../styles/tutorial-node-clone-container.scss";

/**
 * NodeCloneContainer React Component
 * @extends {module:React.PureComponent}
 * @returns {JSX.Element} HTML
 */
class NodeCloneContainer extends React.PureComponent {
  /**
   * constructor
   * @constructs NodeCloneContainer
   * @param {Object} props Component props
   */
  constructor(props) {
    super(props);
    this.state = {
      containerStyle: null,
    };
  }
  /**
   * componentWillReceiveProps
   * @param {Object} newProps the new component props
   * @returns {undefined}
   */
  componentWillReceiveProps(newProps) {
    if (
      this.props &&
      this.props.isScrolling !== newProps.isScrolling &&
      newProps.currentStep < 4
    ) {
      if (!newProps.currentStep) return;

      // $(this.container).empty();

      // const node = document.getElementById(`tutorial-highlight-${newProps.currentStep}`);
      // const clone = $(node).clone();
      // const { top } = $(node).offset();
      // let { left } = $(node).offset();

      // if (this.props.currentStep === 2) {
      //   $(this.container).css({ width: `${30 + $('#flash-credit-total-revenue').width()}px` });
      // } else {
      //   $(this.container).css({ width: '' });
      //   clone.css({ width: `${$(node).width() + 6}px` });
      // }
      // if (newProps.currentStep === 2) left -= 27;
      // this.state.containerStyle = {
      //   left: `${left - 3}px`,
      //   top: `${top - 3}px`,
      // };

      // if (this.container) $(this.container).append(clone);
    }
  }
  /**
   * render
   * @returns {JSX.Element} HTML
   */
  render() {
    return (
      <div
        style={this.state.containerStyle}
        id="tutorial-node-clone-container"
        ref={(node) => (this.container = node)}
        className={`tutorial-node-clone-container ${
          this.props.visible ? "visible" : "hidden"
        } step-${this.props.currentStep}`}
      />
    );
  }
}

NodeCloneContainer.propTypes = {
  currentStep: PropTypes.number,
  visible: PropTypes.bool,
  isScrolling: PropTypes.bool,
};

const mapStateToProps = ({ tutorial }) => ({
  ...tutorial,
  visible: true,
});

const SmartNodeCloneContainer = connect(mapStateToProps)(NodeCloneContainer);

export default SmartNodeCloneContainer;
