import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'helpers/withRouter';

import BackArrowIcon from '../../graphics/Icons/BackArrowIcon';
import XIcon from '../../graphics/Icons/XIcon';
import {
    getClientFirstName,
    getClientPictureUrl,
} from '../../selectors/client';
import {
    getIsMobileBackButtonVisible,
    getIsMobileBackButtonClientPictureShowing,
    getMobileBackButtonRoute,
} from '../../selectors/mobile-back-button';
import '../../styles/mobile-back-button.scss';

/**
 * @class MobileBackButton
 * @extends {module:React.PureComponent}
 * @returns {JSX.Element} HTML
 */
class MobileBackButton extends React.PureComponent {
    /**
     * constructor
     * @constructs MobileBackButton
     * @param {Object} props the component props
     */
    constructor(props) {
        super(props);
        this.redirect = this.redirect.bind(this);
    }

    /**
     * redirect
     * @returns {undefined}
     */
    redirect() {
        if (this.props.location.state && this.props.location.state.useGoBack) {
            return this.props.router.goBack();
        }

        if (this.context.router.location.pathname === this.props.route) {
            window.location.reload();
        } else {
            this.context.router.push(this.props.route);
        }
    }

    /**
     * render
     * @returns {JSX.Element} HTML
     */
    render() {
        const visible = false;
        if (!visible) return null;
        return (
            <div className="dibs-mobile-back-button">
                <button onClick={this.redirect}>
                    <XIcon size={14} stroke="#000" />
                </button>
                {this.props.showClientPicture && (
                    <button
                        onClick={this.redirect}
                        className="mobile-profile-picture-button"
                    >
                        <img
                            alt={this.props.clientFirstName}
                            src={this.props.clientPictureUrl}
                        />
                    </button>
                )}
            </div>
        );
    }
}

MobileBackButton.contextTypes = {
    router: PropTypes.shape(),
};

MobileBackButton.propTypes = {
    visible: PropTypes.bool,
    route: PropTypes.string,
    showClientPicture: PropTypes.bool,
    clientFirstName: PropTypes.string,
    clientPictureUrl: PropTypes.string,
    location: PropTypes.shape(),
    router: PropTypes.shape(),
};

const mapStateToProps = (state) => ({
    // visible: getIsMobileBackButtonVisible(state),
    visible: false,
    // showClientPicture: getIsMobileBackButtonClientPictureShowing(state),
    showClientPicture: false,
    route: '/studio-admin/frontdesk',
    // route: getMobileBackButtonRoute(state),
    clientFirstName: '',
    clientPictureUrl:
        'https://d1f9yoxjfza91b.cloudfront.net/dibs-user-placeholder2.png',
});

export default withRouter(connect(mapStateToProps)(MobileBackButton));
