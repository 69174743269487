import { createSelector } from 'reselect';

import { PLACEHOLDER } from '../../../constants/ClientSearchConstants';

/**
 * @param {Object} state in Redux store
 * @returns {Object} client search state
 */
export function getClientSearch(state) {
    return state.clientSearch || {};
}

/**
 * @param {Object} state in Redux store
 * @returns {Object} client search term
 */
export function getClientSearchTerm(state) {
    return getClientSearch(state).term || '';
}

/**
 * @param {Object} state in Redux store
 * @returns {Object} client search results
 */
export function getClientSearchResults(state) {
    return getClientSearch(state).results || {};
}

export const getTrimmedClientSearchTerm = createSelector(
    getClientSearchTerm,
    (str) => str.trim()
);

export const getCachedClientSearchResult = createSelector(
    [getTrimmedClientSearchTerm, getClientSearchResults],
    (term, results) => results[term?.length > 0 ? term : PLACEHOLDER] || {}
);

export const getRecentResults = createSelector(
    getCachedClientSearchResult,
    (cachedResults) =>
        (cachedResults.recents &&
            cachedResults.recents.filter((r) => Object.keys(r)?.length > 0)) ||
        []
);

export const getMatchingResults = createSelector(
    getCachedClientSearchResult,
    (cachedResults) => cachedResults.matches || []
);
