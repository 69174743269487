// import { format as formatCurrency } from 'currency';
import moment from "moment";

/**
 * getFormattedException
 * @param {string} currency code
 * @param {Object} exception the exception object
 * @returns {Object} exception with formatted values
 */
function getFormattedException(currency, exception) {
  const formattedCreatedAt = moment(exception.createdAt)
    .local()
    .format("dddd MMMM D, YYYY");
  const formattedInstructorName = exception.instructorFirstName
    ? `${exception.instructorFirstName} ${exception.instructorLastName}`
    : "ALL";
  const { start_time: startTime, end_time: endTime } = exception;
  const formatCurrency = (price, { code }) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: code,
    }).format(price);
  };
  const formatTime = (time) =>
    moment()
      .startOf("day")
      .add(Math.floor(+time / 100), "hours")
      .add(+time % 100, "minutes")
      .format("h:mm a");

  let formattedDaysOfWeek;
  let formattedTimes;
  let formattedPriceHeading;
  let formattedPrice;

  if (exception.daysOfWeek) {
    formattedDaysOfWeek = exception.days_of_week
      .sort((a, b) => a - b)
      .map((day) =>
        moment()
          .startOf("week")
          .add(day - 1, "days")
          .format("dd"),
      )
      .map((day) => (day[0] === "T" || day[0] === "S" ? day : day[0]))
      .join(", ");
  } else {
    formattedDaysOfWeek = "ALL";
  }

  if (startTime && startTime === endTime) {
    formattedTimes = formatTime(startTime);
  } else if (startTime) {
    formattedTimes = `${formatTime(startTime)} - ${formatTime(endTime)}`;
  } else {
    formattedTimes = "ALL";
  }

  if (exception.fixed_price) {
    formattedPriceHeading = "Fixed price:";
    formattedPrice = formatCurrency(exception.fixed_price, {
      code: currency,
      precision: 0,
    });
  } else if (exception.new_min_price && exception.new_max_price) {
    formattedPriceHeading = "Min/max price:";
    formattedPrice = formatCurrency(exception.new_min_price, {
      code: currency,
      precision: 0,
    });
    formattedPrice += ` - ${formatCurrency(exception.new_max_price, {
      code: currency,
      precision: 0,
    })}`;
  } else if (exception.new_min_price) {
    formattedPriceHeading = "Min price:";
    formattedPrice = formatCurrency(exception.new_min_price, {
      code: currency,
      precision: 0,
    });
  } else {
    formattedPriceHeading = "Max price:";
    formattedPrice = formatCurrency(exception.new_max_price, {
      code: currency,
      precision: 0,
    });
  }

  const formattedToDate = exception.to_date
    ? moment(exception.to_date).format("MMMM D, YYYY")
    : "N/A";
  const formattedFromDate = exception.from_date
    ? moment(exception.from_date).format("MMMM D, YYYY")
    : "N/A";

  return {
    ...exception,
    formattedLocationName: exception.locationName || "ALL",
    formattedCreatedAt,
    formattedEventName: exception.name || "ALL",
    formattedInstructorName,
    formattedDaysOfWeek,
    formattedTimes,
    formattedPriceHeading,
    formattedPrice,
    formattedToDate,
    formattedFromDate,
  };
}

export default getFormattedException;
