import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pageIsLoading: false,
};

const menu = createSlice({
    name: 'loadingstatus',
    initialState,
    reducers: {
        setPageIsLoading(state, action) {
            return {
                ...state,
                pageIsLoading: action.payload,
            };
        },
    },
});

export default menu.reducer;
export const { setPageIsLoading } = menu.actions;
