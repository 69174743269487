import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function getEventPaymentInfo(eventid) {
    const url = getApiUrl('/studio/events/checkout/get-info');
    let valuetoreturn;
    await axios
        .post(url, { eventid })
        .then((res) => {
            const { data } = res;
            valuetoreturn = data;
        })
        .catch((error) => {
            console.log(
                'there was an error - getting event payment info for',
                eventid,
                error
            );
        });
    return valuetoreturn;
}
