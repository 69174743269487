import { createSelector } from 'reselect';
import Decimal from 'decimal.js';
// import { format as formatCurrency } from 'currency';
import {
    PROMO_TYPE_FREE_CLASS,
    PROMO_TYPE_PERCENT_OFF,
    PROMO_TYPE_CASH_OFF,
    PROMO_TYPE_FIXED,
} from '../../../../constants/PromoCodesConstants';
import {
    getSortedClientCartItems,
    getClientCartAppliedPromo,
    getClientCartItems,
} from '../index';
import { getUserStudioCurrency } from '../../../user/index';
import {
    getClientCartFlashCreditAmount,
    getClientStudioCredits,
    getClientRAFCredits,
    getClientGlobalCredits,
} from '../../index';
import {
    getClientPassesInCart,
    getClientHasUnlmitedMembershipWithDailyUsageLimit,
} from '../../passes/index';

export const getClientCartPromoCodeAmount = createSelector(
    [getSortedClientCartItems, getClientCartAppliedPromo],
    (cartItems, promoCode) => {
        if (!cartItems?.length) return 0;
        switch (promoCode.type) {
            case PROMO_TYPE_FIXED:
                return +Decimal(cartItems[0].price).minus(promoCode.amount); // TODO make this only for classes greater than fixed price
            case PROMO_TYPE_FREE_CLASS:
                return cartItems[0].price;
            case PROMO_TYPE_PERCENT_OFF:
                return Decimal(promoCode.amount)
                    .dividedBy(100)
                    .times(cartItems[0].price)
                    .toDP(2)
                    .toNumber();
            case PROMO_TYPE_CASH_OFF:
                return Math.min(promoCode.amount, cartItems[0].price);
            default:
                return 0;
        }
    }
);

const formatCurrency = (promoCodeAmount, { code }) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: code,
    }).format(promoCodeAmount);
};
export const getFormattedPromoCodeAmount = createSelector(
    [getClientCartPromoCodeAmount, getUserStudioCurrency],
    (promoCodeAmount, code) => formatCurrency(promoCodeAmount, { code })
);

export const getClientCartDiscountAmount = createSelector(
    [getClientCartPromoCodeAmount, getClientCartFlashCreditAmount],
    (promoCodeAmount, flashCreditAmount) =>
        +Decimal(promoCodeAmount).plus(flashCreditAmount)
);

/*

PURCHASE BREAKDOWN WHEN CLIENT HAS PASSES

*/

export const getClientCartPassesValue = createSelector(
    getClientPassesInCart,
    (passes) =>
        passes
            .reduce(
                (acc, pass) =>
                    acc.plus(
                        Decimal(
                            pass.displayPassValue
                                ? pass.passValue
                                : pass.eventPrices
                        ).times(pass.displayPassValue ? pass.quantity : 1)
                    ),
                Decimal(0)
            )
            .toNumber()
);

export const getFormattedClientCartPassValue = createSelector(
    [getClientCartPassesValue, getUserStudioCurrency],
    (passValue, code) => formatCurrency(passValue, { code })
);

export const getClientCartEventAdjustedPrices = createSelector(
    [getClientCartItems, getClientPassesInCart],
    (cartItems, passesInCart) =>
        cartItems.map((cartItem) => {
            const pass = passesInCart.find((p) => p.id === cartItem.passid);
            if (!pass) return 0; // events paid without passes will be handled separately
            const eventPassValue = Math.min(
                pass.displayPassValue ? pass.passValue : cartItem.price,
                cartItem.price
            );
            return Decimal(eventPassValue).times(cartItem.quantity).toNumber(); // classes priced higher than their pass value earn zero credit
        })
);

export const getClientCartEventsAdjustedValue = createSelector(
    getClientCartEventAdjustedPrices,
    (prices) =>
        prices.reduce((acc, price) => acc.plus(price), Decimal(0)).toNumber()
);

export const getClientCartValueBack = createSelector(
    [
        getClientPassesInCart,
        getClientCartPassesValue,
        getClientCartAppliedPromo,
        getClientCartPromoCodeAmount,
        getClientCartFlashCreditAmount,
        getClientCartEventsAdjustedValue,
    ],
    (
        passes,
        passesValue,
        promoCode,
        promoAmount,
        flashCreditAmount,
        adjustedEventsValue
    ) =>
        passes?.length
            ? Math.max(
                  0,
                  Decimal(passesValue)
                      .plus(flashCreditAmount)
                      .plus(
                          promoCode.type === PROMO_TYPE_FREE_CLASS
                              ? 0
                              : promoAmount
                      )
                      .minus(adjustedEventsValue)
                      .toNumber()
              )
            : 0
);

export const getFormattedClientCartValueBack = createSelector(
    [getClientCartValueBack, getUserStudioCurrency],
    (valueBack, code) => formatCurrency(valueBack, { code })
);

/*

BREAKDOWN FOR WHEN CLIENT DOES NOT HAVE PASSES

*/

export const getClientCartItemsWithoutPasses = createSelector(
    getSortedClientCartItems,
    (cartItems) => cartItems.filter((cartItem) => !cartItem.passid)
);

export const getClientCartSubtotal = createSelector(
    getClientCartItemsWithoutPasses,
    (items) =>
        items
            .reduce(
                (acc, item) =>
                    acc.plus(Decimal(item.price).times(item.quantity)),
                Decimal(0)
            )
            .toNumber()
);

export const getFormattedClientCartSubtotal = createSelector(
    [getClientCartSubtotal, getUserStudioCurrency],
    (subtotal, code) => formatCurrency(subtotal, { code })
);

export const getClientCartTaxAmount = createSelector(
    [
        getClientCartItemsWithoutPasses,
        getClientPassesInCart,
        getClientCartDiscountAmount,
    ],
    (items, passes, discount) =>
        items
            .reduce((acc, item, i) => {
                let price = Decimal(item.price).times(item.quantity);
                const taxRate = Decimal(item.taxRate).dividedBy(100);
                if (!i && !passes?.length) {
                    price = price.minus(Math.min(discount, item.price));
                }
                return acc.plus(price.times(taxRate).toDecimalPlaces(2));
            }, new Decimal(0))
            .toNumber()
);

export const getFormattedClientCartTaxAmount = createSelector(
    [getClientCartTaxAmount, getUserStudioCurrency],
    (taxAmount, code) => formatCurrency(taxAmount, { code })
);

export const getClientCartSubtotalAfterTax = createSelector(
    [
        getClientCartSubtotal,
        getClientCartDiscountAmount,
        getClientCartTaxAmount,
    ],
    (subtotal, discount, taxAmount) =>
        new Decimal(subtotal).minus(discount).plus(taxAmount).toNumber()
);

export const getClientCartStudioCreditsApplied = createSelector(
    [getClientCartSubtotalAfterTax, getClientStudioCredits],
    (currentAmount, studioCredits) =>
        +Decimal(Math.min(currentAmount, studioCredits))
);

export const getFormattedClientCartStudioCreditsApplied = createSelector(
    [getClientCartStudioCreditsApplied, getUserStudioCurrency],
    (credits, code) => formatCurrency(credits, { code })
);

export const getClientCartStudioCreditsRemaining = createSelector(
    [getClientStudioCredits, getClientCartStudioCreditsApplied],
    (totalCredits, creditsApplied) =>
        +Decimal(totalCredits).minus(creditsApplied)
);

export const getFormattedClientStudioCreditsRemaining = createSelector(
    [getClientCartStudioCreditsRemaining, getUserStudioCurrency],
    (credits, code) => formatCurrency(credits, { code })
);

export const getClientCartAmountAfterStudioCredits = createSelector(
    [getClientCartSubtotalAfterTax, getClientCartStudioCreditsApplied],
    (currentAmount, creditsApplied) =>
        +Decimal(currentAmount).minus(creditsApplied)
);

export const getClientCartRAFCreditApplied = createSelector(
    [getClientCartAmountAfterStudioCredits, getClientRAFCredits],
    (currentAmount, rafCredits) => Math.min(currentAmount, rafCredits)
);

export const getFormattedClientCartRAFCreditApplied = createSelector(
    [getClientCartRAFCreditApplied, getUserStudioCurrency],
    (credits, code) => formatCurrency(credits, { code })
);

export const getClientCartAmountAfterRAFCredits = createSelector(
    [getClientCartAmountAfterStudioCredits, getClientCartRAFCreditApplied],
    (currentAmount, creditsApplied) =>
        +Decimal(currentAmount).minus(creditsApplied)
);

export const getClientCartGlobalCreditApplied = createSelector(
    [getClientCartAmountAfterRAFCredits, getClientGlobalCredits],
    (currentAmount, globalCredits) => Math.min(currentAmount, globalCredits)
);

export const getFormattedClientCartGlobalCreditApplied = createSelector(
    [getClientCartGlobalCreditApplied, getUserStudioCurrency],
    (credits, code) => formatCurrency(credits, { code })
);

/*

PURCHASE BREAKDOWN TOTAL

*/

export const getClientCartSubtotalWithPackageClasses = createSelector(
    [getClientCartEventsAdjustedValue, getClientCartSubtotal],
    (adjustedEventPrices, subtotal) =>
        +Decimal(adjustedEventPrices).plus(subtotal)
);

export const getFormattedCartSubtotalWithPackageClasses = createSelector(
    [getClientCartSubtotalWithPackageClasses, getUserStudioCurrency],
    (subtotal, code) => formatCurrency(subtotal, { code })
);

export const getClientCartTotal = createSelector(
    [getClientCartAmountAfterRAFCredits, getClientCartGlobalCreditApplied],
    (currentAmount, creditsApplied) =>
        +Decimal(currentAmount).minus(creditsApplied)
);

export const getFormattedClientCartTotal = createSelector(
    [getClientCartTotal, getUserStudioCurrency],
    (total, code) => formatCurrency(total, { code })
);

export const getShouldDisplayPaymentWarning = createSelector(
    [getClientCartTotal, getClientHasUnlmitedMembershipWithDailyUsageLimit],
    (total, limitedUnlimitedPass) => total > 0 && limitedUnlimitedPass
);
