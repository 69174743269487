import React from "react";
import PropTypes from "prop-types";
import "../../styles/card.scss";
/**
 * Reusable Card Component
 */
class Card extends React.PureComponent {
  /**
   * Render
   * @returns {JSX} html element
   */
  render() {
    return (
      <div className={`card-container ${this.props.containerClassNames}`}>
        <div className={`card ${this.props.classNames}`}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  containerClassNames: PropTypes.string,
  classNames: PropTypes.string,
  children: PropTypes.node,
};

export default Card;
