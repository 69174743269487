import React, { useState } from 'react';
import {
    Grid,
    Typography,
    List,
    ListItemIcon,
    ListItemButton,
    ListItem,
    ListItemText,
    Divider,
    FormControl,
    FormGroup,
    FormControlLabel,
    Switch,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import { styled, alpha } from '@mui/material/styles';
import { green } from '@mui/material/colors';

import ConfirmClientRemoval from 'components/schedule-sections/confirmClientRemoval';

const AffectedClients = ({
    attendees,
    setHideSaveButtons,
    thiseventid,
    dibsId,
    startTime,
    setUpdateEvents,
    setAttendeeList,
    eventType,
    isUpdating,
}) => {
    const [isChangingClient, setIsChangingClient] = useState(false);
    const [currentClient, setCurrentClient] = useState(null);
    const [labelColor, setLabelColor] = useState('#616161');
    if (!attendees) return null;
    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
            color: theme.palette.success.dibsgreen,
            '&:hover': {
                backgroundColor: alpha(
                    green[500],
                    theme.palette.success.successDibsGreen
                ),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: green[400],
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: green[400],
        },
    }));

    const ColoredFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
        '& .MuiFormControlLabel-label': {
            color: labelColor,
        },
    }));

    const removeClient = (client) => {
        setCurrentClient(client);
        setHideSaveButtons(true);
        setIsChangingClient(true);
    };
    const cancelRemovingClient = () => {
        setHideSaveButtons(false);
        setIsChangingClient(false);
    };

    if (isChangingClient)
        return (
            <ConfirmClientRemoval
                currentClient={currentClient}
                setClientSelected={setCurrentClient}
                cancelRemovingClient={cancelRemovingClient}
                thiseventid={thiseventid}
                dibsId={dibsId}
                startTime={startTime}
                setUpdateEvents={setUpdateEvents}
                eventType={eventType}
            />
        );
    if (!attendees?.length) return null;
    const handleChange = (event) => {
        const newAttendees = attendees.map((attendee) => {
            if (attendee.userid === event.userid) {
                attendee.emailIsChecked = !attendee.emailIsChecked;
                attendee.shouldSendEmail = !attendee.shouldSendEmail;
                if (!attendee.emailIsChecked) {
                    setLabelColor('#9e9e9e');
                } else {
                    setLabelColor('#616161');
                }
            }
            return attendee;
        });
        setAttendeeList(newAttendees);
    };
    const handleSmsChange = (event) => {
        const newAttendees = attendees.map((attendee) => {
            if (attendee.userid === event.userid) {
                attendee.smsIsChecked = !attendee.smsIsChecked;
                attendee.shouldSendSms = !attendee.shouldSendSms;
                if (!attendee.smsIsChecked) {
                    setLabelColor('#9e9e9e');
                } else {
                    setLabelColor('#616161');
                }
            }

            return attendee;
        });
        setAttendeeList(newAttendees);
    };
    const cancelationText = isUpdating ? 'update' : 'cancellation';

    return (
        <Grid container>
            <Grid item xs={10} sx={{ p: 0.5, m: 0, mt: 2 }}>
                <FormControl>
                    <FormGroup>
                        {attendees.map((attendee, i) => {
                            let label = `Sending ${cancelationText} email to ${attendee.firstname} ${attendee.lastname}`;
                            let smslabel = `Sending ${cancelationText} sms text to ${attendee.firstname} ${attendee.lastname}`;
                            if (!attendee.smsIsChecked) {
                                smslabel = `Will not send ${cancelationText} sms text to ${attendee.firstname} ${attendee.lastname}`;
                            }
                            if (!attendee.emailIsChecked) {
                                label = `Will not send ${cancelationText} email to ${attendee.firstname} ${attendee.lastname}`;
                            }
                            const { mobilephone } = attendee;
                            if (!mobilephone || mobilephone?.length < 8) {
                                console.log('mobile phone is not valid');
                                smslabel = `Cannot send an sms because ${attendee.firstname}'s mobile number is not saved to the profile`;
                                attendee.smsIsChecked = false;
                            }
                            return (
                                <React.Fragment key={`fragment-${i}`}>
                                    <ColoredFormControlLabel
                                        key={`affected-attendee-${i}-${attendee.userid}`}
                                        control={
                                            <GreenSwitch
                                                variant="secondary"
                                                checked={
                                                    attendee.emailIsChecked
                                                }
                                                onChange={() =>
                                                    handleChange(attendee)
                                                }
                                                key={`affected-attendee-${i}-${attendee.userid}-switch`}
                                                name={attendee.email}
                                            />
                                        }
                                        variant="off"
                                        label={label}
                                        slotProps={{
                                            typography: {
                                                // color: '#ff6900',
                                                // variant: 'filled',
                                                sx: {
                                                    color: '#ff6900',
                                                    fontSize: '.82rem',
                                                    // paddingRight: '0px',
                                                },
                                            },
                                            FormControlLabel: {
                                                '& .label': {
                                                    sx: {
                                                        color: '#ff6900',
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                    {attendee.smsIsAvailable && (
                                        <ColoredFormControlLabel
                                            key={`affected-attendee-sms-${i}-${attendee.userid}`}
                                            control={
                                                <GreenSwitch
                                                    variant="secondary"
                                                    checked={
                                                        attendee.smsIsChecked
                                                    }
                                                    onChange={() =>
                                                        handleSmsChange(
                                                            attendee
                                                        )
                                                    }
                                                    key={`sms-affected-attendee-${i}-${attendee.userid}-sms-switch`}
                                                    name={
                                                        attendee.mobilephone ||
                                                        'attendee-mobilephone'
                                                    }
                                                />
                                            }
                                            variant="off"
                                            label={smslabel}
                                            slotProps={{
                                                typography: {
                                                    // color: '#ff6900',
                                                    // variant: 'filled',
                                                    sx: {
                                                        // color: '#ff6900',
                                                        fontSize: '.82rem',
                                                        // paddingRight: '0px',
                                                    },
                                                },
                                                FormControlLabel: {
                                                    '& .label': {
                                                        sx: {
                                                            // color: '#ff6900',
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default AffectedClients;
