import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import Decimal from 'decimal.js';
import { getClient } from '../index';
import { getClientCartItems } from '../cart';
import {
    getUserStudioDateFormat,
    getUserStudioMainTZ,
    getUserStudioSource,
    getUserStudioDibsId,
} from '../../user';

/**
 * @param {Object} state in the Redux store
 * @returns {Array<Object>} client's active passes
 */
export function getAllClientPasses(state) {
    return getClient(state).passes || [];
}

/**
 * getClientEvents - prevent circular dependency
 * @param {Object} state in Redux store
 * @returns {Array<Object>} array of event objects
 */
function getClientEvents(state) {
    return getClient(state).events || [];
}

export const getClientPasses = createSelector(
    [getAllClientPasses, getUserStudioDibsId],
    (passes, dibsStudioId) =>
        passes.filter((p) => p.isValid && p.dibs_studio_id === dibsStudioId)
);

/**
 *
 * @param {Object} state  redux state
 * @returns {Array<Object>} list of feed events
 */
function getFeedEvents(state) {
    return state.feed.events || [];
}

export const getDetailedClientPasses = createSelector(
    [getClientPasses, getUserStudioDateFormat, getUserStudioMainTZ],
    (passes, dateFormat, mainTZ) =>
        passes.map((pass) => {
            const expiration = pass.expiresAt
                ? moment.utc(pass.expiresAt).tz(mainTZ)
                : null;
            let remainingUses = pass.totalUses - pass.usesCount;
            if (remainingUses <= 0) {
                remainingUses = 0;
            }
            return {
                ...pass,
                expiration,
                name:
                    pass.studioPackage.name ||
                    (pass.totalUses
                        ? `${pass.totalUses} Classes`
                        : 'Unlimited'),
                packageHasName: Boolean(pass.studioPackage.name),
                unlimited: !pass.totalUses,
                usesLeft: +remainingUses,
                formattedExpiration: pass.expiresAt
                    ? moment(expiration).format(dateFormat)
                    : 'Not yet determined',
                dailyUsageLimit: pass.studioPackage.dailyUsageLimit,
                notificationPeriod: pass.studioPackage.notification_period,
                seriesTypeId: pass.studioPackage.zf_series_type_id,
                packageIsAutopay: pass.studioPackage.autopay === 'FORCE',
            };
        })
);

export const getClientHasUnlmitedMembershipWithDailyUsageLimit = createSelector(
    getDetailedClientPasses,
    (passes) => passes.some((pass) => pass.unlimited && pass.dailyUsageLimit)
);

export const getClientPassesLeft = createSelector(
    [getDetailedClientPasses, getClientCartItems],
    (passes, cartItems) =>
        passes.filter((pass) => {
            const cartEventsWithPass = cartItems.filter(
                (item) => item.passid === pass.id
            );
            const quantityInCart = cartEventsWithPass.reduce(
                (acc, { quantity }) => acc + quantity,
                0
            );
            return (
                (quantityInCart + pass.usesCount < pass.totalUses ||
                    pass.unlimited ||
                    pass.autopay) &&
                (!pass.expiresAt || moment() < moment(pass.expiresAt))
            );
        })
);

export const getClientHasPasses = createSelector(
    getClientPassesLeft,
    (passes) => Boolean(passes?.length)
);

export const getClientNextPass = createSelector(
    [
        getClientPassesLeft,
        getClientCartItems,
        getFeedEvents,
        getUserStudioSource,
        getClientEvents,
    ],
    (passes, cartItems, events, source, upcomingEvents) => (eventid) =>
        passes.reduce((passAcc, pass) => {
            if (passAcc) return passAcc;
            const currentEvent = events.find((e) => e.id === eventid);
            if (
                !currentEvent ||
                !currentEvent.can_apply_pass ||
                !currentEvent.price
            )
                return null;
            let checkDateIsOk = true;
            if (pass.expiresAt < currentEvent.start_time) {
                checkDateIsOk = false;
            }
            if (!checkDateIsOk && !pass.autopay) return null;
            if (!pass) return null;

            // This is for the case where an autorenew pass user with 10 uses has used all 10
            // and is trying to book this month not the next month
            if (
                moment(currentEvent.start_time) < moment(pass.expiresAt) &&
                pass.autopay &&
                pass.usesLeft === 0 &&
                !pass.unlimited
            )
                return null;

            if (source === 'zf' && pass.studioPackage.zf_series_type_id) {
                const seriesTypeId = pass.studioPackage.zf_series_type_id;
                if (
                    currentEvent.zf_series_types &&
                    !currentEvent.zf_series_types[seriesTypeId]
                )
                    return null;
            }

            if (
                currentEvent.private === true &&
                pass.studioPackage.private === false
            )
                return null;
            if (
                currentEvent.private === false &&
                pass.studioPackage.private === true
            )
                return null;

            if (!pass.studioPackage.unlimited) return pass;

            // Make sure non-autopay passes cant be used passed their expiry
            if (
                moment.tz(currentEvent.start_time, currentEvent.mainTZ) >
                    moment(pass.expiresAt) &&
                !pass.autopay
            )
                return null;

            const cartItem = cartItems.find(
                (item) => item.eventid === eventid && item.passid === pass.id
            );
            if (cartItem) return null;

            const passInCart = cartItems.find(
                (item) => item.passid === pass.id
            );
            const cartDayUsage =
                pass.dailyUsageLimit &&
                cartItems.reduce(
                    (eventAcc, item) =>
                        eventAcc === pass.dailyUsageLimit && passInCart
                            ? eventAcc
                            : eventAcc +
                              Number(
                                  moment(
                                      currentEvent.start_time
                                  ).dayOfYear() ===
                                      moment(item.start_time).dayOfYear()
                              ),
                    0
                );
            const upcomingDayUsage =
                pass.dailyUsageLimit &&
                upcomingEvents.reduce(
                    (eventAcc, upcomingEvent) =>
                        eventAcc === pass.dailyUsageLimit
                            ? eventAcc
                            : eventAcc +
                              Number(
                                  moment(
                                      currentEvent.start_time
                                  ).dayOfYear() ===
                                      moment(
                                          upcomingEvent.start_time
                                      ).dayOfYear() &&
                                      Boolean(
                                          upcomingEvent.passes.find(
                                              (p) => p.id === pass.id
                                          )
                                      )
                              ),
                    0
                );
            if (cartDayUsage + upcomingDayUsage === pass.dailyUsageLimit)
                return null;

            return pass;
        }, null)
);

export const getClientNextPassId = createSelector(
    getClientNextPass,
    (getPass) => (eventid) => {
        const pass = getPass(eventid);
        return pass ? pass.id : null;
    }
);

export const getClientNextPassValue = createSelector(
    getClientNextPass,
    (getPass) => (eventid) => {
        const pass = getPass(eventid);
        return pass && pass.passValue && !pass.studioPackage.unlimited
            ? pass.passValue
            : 0;
    }
);

export const getClientFixedPrice = createSelector(getClientPasses, (passes) => {
    const fixedPricePasses = passes.filter(
        (p) =>
            p.studioPackage.member_class_fixed_price !== null &&
            (!p.expiresAt || moment(p.expiresAt).isAfter(moment()))
    );
    return fixedPricePasses?.length
        ? Math.min.apply(
              null,
              fixedPricePasses.map(
                  (p) => p.studioPackage.member_class_fixed_price
              )
          )
        : null;
});

export const getClientPassesInCart = createSelector(
    [getClientPasses, getClientCartItems],
    (passes, cartItems) =>
        passes
            .filter((pass) => cartItems.find((item) => item.passid === pass.id))
            .map((pass) => {
                const cartItemsWithThisPass = cartItems.filter(
                    (event) => event.passid === pass.id
                );
                const quantity = cartItemsWithThisPass.reduce(
                    (acc, { quantity: q }) => acc + q,
                    0
                );
                const eventPrices = cartItemsWithThisPass
                    .reduce((acc, { price }) => acc.plus(price), Decimal(0))
                    .toNumber();
                return {
                    ...pass,
                    quantity,
                    eventPrices,
                    displayPassValue:
                        !pass.source_serviceid && !pass.studioPackage.unlimited,
                };
            })
);
