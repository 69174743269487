import React from "react";
import PropTypes from "prop-types";
import { slide as SlideMenu } from "react-burger-menu";
import withRouter from "helpers/withRouter";

import WhiteArrowWithTail from "assets/images/white-arrow-with-tail.svg";
import RoseBurgerMenuIcon from "../../graphics/Icons/RoseBurgerMenuIcon";
import "./styles.scss";

/**
 * @class BurgerMenu
 * @extends {React.PureComponent}
 */
class BurgerMenu extends React.PureComponent {
  /**
   * @constructor
   * @param {Object} props component props
   */
  constructor(props) {
    super(props);
    this.state = { visible: false };
    this.onStateChange = this.onStateChange.bind(this);
    this.showBurgerMenu = this.onVisibleToggle.bind(this, true);
    this.hideBurgerMenu = this.onVisibleToggle.bind(this, false);
  }
  /**
   * @param {Object} _ props before update
   * @param {Object} state - state before update
   * @returns {undefined}
   */
  componentDidUpdate(_, state) {
    if (state.visible) {
      this.hideBurgerMenu();
    }
  }
  /**
   * @param {boolean} visible value to set the visible state
   * @returns {undefined}
   */
  onVisibleToggle(visible) {
    this.setState({ visible });
  }
  /**
   * Callback for ui state change
   * @param {Object} state on the react-burget-menu
   * @returns {undefined}
   */
  onStateChange(state) {
    return state.isOpen ? this.showBurgerMenu() : this.hideBurgerMenu();
  }
  /**
   * render
   * @returns {JSX.Element} HTML
   */
  render() {
    return [
      <SlideMenu
        key={0}
        left
        onStateChange={this.onStateChange}
        isOpen={this.state.visible}
        customCrossIcon={<img src={WhiteArrowWithTail} alt="close menu" />}
      >
        {this.props.children || null}
      </SlideMenu>,
      <button
        key={1}
        className="burger-menu-icon"
        onClick={this.showBurgerMenu}
      >
        <RoseBurgerMenuIcon />
      </button>,
    ];
  }
}

BurgerMenu.propTypes = {
  location: PropTypes.shape(),
  children: PropTypes.node,
};

export default withRouter(BurgerMenu);
