import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DEFAULT_AVATAR } from "../../constants/UserConstants";
import { addError, clearError } from "../../actions/ErrorActions";
import { refreshUser } from "../../actions/UserActions";
import DibsLoader from "./DibsLoader";
import withRouter from "helpers/withRouter";

import "../../styles/profile-picture.scss";

/**
 * ProfilePicture React Component
 * @extends {module:React.PureComponent}
 * @returns {JSX.Element} HTML
 */
class ProfilePicture extends React.PureComponent {
  /**
   * constructor
   * @constructs ProfilePicture
   * @param {Object} props Component props
   */
  constructor(props) {
    super(props);
    this.state = {
      isUploadingPicture: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
  }
  /**
   * handleClick clicks on hidden file input
   * @returns {undefined}
   */
  handleClick() {
    if (this.state.isUploadingPicture) return;
    if (this.fileinput) this.fileinput.click();
  }
  /**
   * handleImageChange updates studio employee's profile picture on the server
   * @param {Event} event the click event
   * @param {File} event.target.files the uploaded file
   * @returns {undefined}
   */
  handleImageChange(e) {
    if (this.state.isUploadingPicture) return;
    const formData = new FormData();
    const file = e.target.files[0];
    this.props.clearError();
    if (!file) return;
    if (this.state.isUploadingPicture) return;
    formData.append("pictureData", file);
    this.setState({ isUploadingPicture: true }, () => {
      //d1f9yoxjfza91b.cloudfront.net/dibs-user-placeholder2.png
      // $.ajax('/studios/api/account/picture', {
      //     method: 'PUT',
      //     data: formData,
      //     contentType: false,
      //     processData: false,
      // }).done(({ success, user, message }) => {
      //     if (success) this.props.refreshUser(user);
      //     else this.props.addError(message);
      //     this.setState({ isUploadingPicture: false });
      // });
    });
  }
  /**
   * render
   * @returns {JSX.Element} HTML
   */
  render() {
    return (
      <div className="profile-picture-wrapper">
        <button onClick={this.handleClick}>
          <div className="profile-picture-container">
            <img
              alt="profile avatar"
              src={DEFAULT_AVATAR}
              className={this.state.isUploadingPicture ? "is-updating" : ""}
            />
            {this.state.isUploadingPicture && <DibsLoader />}
          </div>
          <input
            onChange={this.handleImageChange}
            type="file"
            ref={(node) => {
              this.fileinput = node;
            }}
            accept="image/*"
            name="image-upload"
          />
        </button>
      </div>
    );
  }
}

ProfilePicture.propTypes = {
  user: PropTypes.shape({
    profile_picture: PropTypes.string,
  }),
  addError: PropTypes.func,
  clearError: PropTypes.func,
  refreshUser: PropTypes.func,
};

const SmartProfilePicture = connect(({ user }) => ({ user }), {
  clearError,
  addError,
  refreshUser,
})(ProfilePicture);

export default withRouter(SmartProfilePicture);
