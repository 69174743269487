import propTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function CancelButton(props) {
    const { id, valueString, onClick } = props;
    const theme = useTheme();
    return (
        <Grid item xs={12}>
            <Button
                id={id}
                onClick={onClick}
                sx={{
                    bgcolor: theme.palette.globalcolors.cancel,
                    '&:hover': {
                        backgroundColor: theme.palette.globalcolors.cancel,
                    },
                    height: '32px',
                    color: '#666',
                }}
            >
                {valueString}
            </Button>
        </Grid>
    );
}
CancelButton.propTypes = {
    id: propTypes.string,
    valueString: propTypes.string,
    onClick: propTypes.func,
};
