import { Grid, FormGroup, FormControlLabel, Fade, Switch } from '@mui/material';

const EmailAndSmsSwitches = ({
    sendEmailConfirmation,
    sendSMSConfirmation,
    setSMSLabel,
    smsLabel,
    clientSelected,
    setSendEmailConfirmation,
    setEmailLabel,
    emailLabel,
    setSendSMSConfirmation,
}) => {
    return (
        <Fade in={true} timeout={1000}>
            <Grid item xs={11} md={9} sx={{ p: 0, pt: 1, mb: 2 }}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={
                                    sendEmailConfirmation &&
                                    Boolean(clientSelected)
                                }
                                disabled={!clientSelected}
                                onChange={(event) => {
                                    setSendEmailConfirmation(
                                        event.target.checked
                                    );
                                    if (!sendEmailConfirmation) {
                                        setEmailLabel(
                                            'Send Email Confirmation'
                                        );
                                    } else {
                                        setEmailLabel(
                                            'Will Not Send Email Confirmation'
                                        );
                                    }
                                }}
                            />
                        }
                        label={emailLabel}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={
                                    sendSMSConfirmation &&
                                    Boolean(clientSelected)
                                }
                                disabled={!clientSelected}
                                onChange={(event) => {
                                    setSendSMSConfirmation(
                                        event.target.checked
                                    );
                                    if (!sendSMSConfirmation) {
                                        setSMSLabel(
                                            'Send SMS/Text Confirmation'
                                        );
                                    } else {
                                        setSMSLabel(
                                            'Will Not Send SMS/Text Confirmation'
                                        );
                                    }
                                }}
                            />
                        }
                        label={smsLabel}
                    />
                </FormGroup>
            </Grid>
        </Fade>
    );
};
export default EmailAndSmsSwitches;
