import { createSelector } from "reselect";

/**
 * @param {Object} state in Redux store
 * @returns {Object} location state
 */
export function getLocation(state) {
  return state.location;
}

/**
 * @param {Object} state in Redux store
 * @returns {number} selected location id
 */
export function getSelectedLocationId(state) {
  return getLocation(state).selectedLocationid || null;
}

/**
 * @param {Object} state in Redux store
 * @returns {Array<number>} array of locationids
 */
export function getLocationOptions(state) {
  return getLocation(state).options || [];
}

export const getLocationOptionsForFiltering = createSelector(
  getLocationOptions,
  (locations) =>
    locations.map((loc) => ({
      label: loc.name,
      value: loc.locationid,
    })),
);

export const getSelectedLocationForFilter = createSelector(
  [getSelectedLocationId, getLocationOptionsForFiltering],
  (locationId, locationsOptions) =>
    locationsOptions.find((loc) => loc.value === locationId) || "",
);

export const getLocationOptionsWithAllLocations = createSelector(
  getLocationOptions,
  (options) => [
    { label: "All Locations", value: "all" },
    ...options.map(({ name, locationid }) => ({
      label: name,
      value: locationid,
    })),
  ],
);

export const getLocationOptionsWithAllLocsAndPacks = createSelector(
  getLocationOptionsWithAllLocations,
  (options) => [
    options[0],
    { label: "Packages and Credits", value: "packs" },
    ...options.slice(1),
  ],
);

/**
 * @param {Object} state in Redux store
 * @returns {Array<number>} array of locationids for reporting
 */
export const getLocationOptionsForReporting = createSelector(
  getLocationOptions,
  (locations) =>
    locations.map((loc) => ({
      label: loc.name,
      value: loc.locationid,
    })),
);
