import axios from 'axios';

import { getApiUrl } from 'helpers';

// ==============================|| GET ACTIVE APPOINTMENTS FOR STUDIO ||============================== //

export const GetActiveAppointments = async (dibsStudioId) => {
    try {
        const url = getApiUrl('/get-appt-types');
        const response = await axios.post(url, {
            dibsStudioId,
        });

        const { data } = response;

        return {
            msg: 'success',
            data,
        };
    } catch (err) {
        console.log(
            `error getting appt types for dibsStudioId: ${dibsStudioId}\nerr is: ${err}`
        );
    }
    return 0;
};

export default GetActiveAppointments;
