import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
    setAllInstructorAvailability,
    setInstructorAvailabilityHasBeenUpdated,
} from 'store/slices/instructors';
import getStaffAvailability from 'actions/studios/employees/getStaffAvailability';
import { dispatch, useSelector } from 'store';

dayjs.extend(utc);

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0, border: 0 },
    border: 0,
    display: 'flex',
};

function getFormattedShortest(timeAsNumber) {
    const integerOfStartTime = Math.floor(timeAsNumber);

    const minutePart = timeAsNumber - integerOfStartTime;
    const minutePartInMinutes = minutePart * 60;
    let newDate = dayjs
        .utc()
        .hour(integerOfStartTime)
        .minute(minutePartInMinutes)
        .format('h:mma');
    if (minutePartInMinutes === 0) {
        newDate = dayjs
            .utc()
            .hour(integerOfStartTime)
            .minute(minutePartInMinutes)
            .format('ha');
    }
    return newDate;
}

const converttonum = (timetxt) => {
    const newtime = timetxt.split(':');
    const hours = newtime[0];
    const minutes = newtime[1];
    const hoursInt = parseInt(hours, 10);
    const minutesInt = parseInt(minutes, 10);
    const minutesAsDecimal = minutesInt / 60;
    const total = hoursInt + minutesAsDecimal;
    return total;
};

const getDefaultAvailabilityDataWithStartEnd = (
    availabilityToProcess,
    businessHours,
    isActual = false
) => {
    return availabilityToProcess.map((day) => {
        // find corresponding business hours for this day
        const businessHoursForThisDay = businessHours.find(
            (hours) => hours.dow === day.dow
        );
        const dbopentime = businessHoursForThisDay.openTime;
        const dbclosetime = businessHoursForThisDay.closeTime;
        let newtime;
        let newclosetime;
        try {
            newtime = dayjs.utc(dbopentime, 'H:mm:ss');
            newclosetime = dayjs.utc(dbclosetime, 'H:mm:ss');
        } catch (err) {
            console.log('error parsing date is', err);
            newtime = dayjs.utc(dbopentime, 'H:mm');
            newclosetime = dayjs.utc(dbclosetime, 'H:mm');
        }

        let newday = { ...day };

        const newNumber = dayjs.utc(newtime).format('H') / 1;
        const newCloseNumber = dayjs.utc(newclosetime).format('H') / 1;

        const minute = dayjs.utc(newtime).format('m') / 60;
        const newCloseMinute = dayjs.utc(newclosetime).format('m') / 60;

        const togethernumber = newNumber + minute;
        const newclosetimenumber = newCloseNumber + newCloseMinute;

        const newNumberlessOne = togethernumber - 0.5;
        const newClosePlusOne = newclosetimenumber + 0.5;

        const formattedopentime = dayjs.utc(newtime).format('h:mm A');
        const formattedclosetime = dayjs.utc(newclosetime).format('h:mm A');

        // HERE IS WHERE YOU ARE SETTING THE NEW VALUES //

        newday.locationOpenTime = `Opens ${formattedopentime}`;
        newday.locationCloseTime = `Closes ${formattedclosetime}`;
        newday.locationOpenNumber = togethernumber;
        newday.locationCloseNumber = newclosetimenumber;
        newday.startNumber = newNumberlessOne;
        newday.endNumber = newClosePlusOne;
        const newtimelessone = newtime.subtract(30, 'minute');
        const newtimeplusone = newclosetime.add(30, 'minute');
        newday.startTime = dayjs.utc(newtimelessone).format('HH:mm');
        newday.endTime = dayjs.utc(newtimeplusone).format('HH:mm');
        newday.formattedStart = getFormattedShortest(newNumberlessOne);
        newday.formattedEnd = getFormattedShortest(newClosePlusOne);
        newday.minNumber = newNumberlessOne;
        newday.maxNumber = newClosePlusOne;
        if (isActual) {
            // set the new startTime
            const dbstarttime = dayjs.utc(day.startTime, 'HH:mm:ss');
            const dbstart = dayjs.utc(dbstarttime).format('HH:mm');

            // SETTING START TIME HERE
            newday.startTime = dbstart;
            const firstOptionTimeDayJs = dayjs.utc(newtimelessone);
            const dbstarttimeDayJs = dayjs.utc(dbstarttime);
            if (dbstarttimeDayJs.isBefore(firstOptionTimeDayJs)) {
                newday.startTime = dayjs.utc(newtimelessone).format('HH:mm');
            } else {
                const dbstartnumber = converttonum(dbstart);
                newday.startNumber = dbstartnumber;
                // set start number
                // set formatted start
                newday.formattedStart = getFormattedShortest(dbstartnumber);
            }

            // set the new END time
            const dbendtime = dayjs.utc(day.endTime, 'HH:mm:ss');
            const dbend = dayjs.utc(dbendtime).format('HH:mm');

            // SETTING END TIME HERE
            newday.endTime = dbend;
            const lastOptionTimeDayJs = dayjs.utc(newtimeplusone);
            const dbendtimeDayJs = dayjs.utc(dbendtime);
            if (dbendtimeDayJs.isAfter(lastOptionTimeDayJs)) {
                newday.startTime = dayjs.utc(newtimeplusone).format('HH:mm');
            } else {
                const dbendnumber = converttonum(dbend);
                newday.endNumber = dbendnumber;
                // set end number
                // set formatted end
                newday.formattedEnd = getFormattedShortest(dbendnumber);
            }
        }

        return newday;
    });
};

// ==============================|| CALENDAR MAIN CARD ||============================== //

const MainCard = React.forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            borderColor,
            bgColor,
            variantType,
            secondary,
            shadow,
            sx = {},
            addBorder,
            title = '',
            borderThickness,
            idtouse,
            ...others
        },
        ref
    ) => {
        // const theme = useTheme();
        let thickness = borderThickness ? borderThickness : 1;
        if (!addBorder) thickness = 0;
        const responsiveStyles = {
            '@media (max-width: 600px)': {
                minWidth: '600px', // Ensures the minimum width is 410px
                overflowX: 'auto', // Allows horizontal scrolling on small screens
            },
        };
        const contentResponsiveSx = {
            '@media (max-width: 600px)': {
                p: '10px',
                ...contentSX,
            },
        };

        const bordertext = `${thickness}px solid`;
        let borderColorToUse = borderColor || '#dddddd';
        const { studioEmployeeId, isAlsoInstructor, instructorId } =
            useSelector((state) => state.studioemployeeuser);
        const { businessHours } = useSelector(
            (state) => state.dibsstudio.settings
        );
        const { instructorAvailabilityHasBeenUpdated } = useSelector(
            (state) => state.instructors
        );
        const { dibsStudioId } = useSelector(
            (state) => state.dibsstudio.config
        );
        const { openTime, closeTime } = useSelector(
            (state) => state.dibsstudio.studioConfig
        );

        React.useEffect(() => {
            const getAvailabilityInfo = async () => {
                dispatch(setInstructorAvailabilityHasBeenUpdated(false));
                const { availability } = await getStaffAvailability(
                    studioEmployeeId,
                    dibsStudioId,
                    openTime,
                    closeTime
                );

                let modifiedAvailability;
                if (businessHours?.length > 0) {
                    modifiedAvailability =
                        getDefaultAvailabilityDataWithStartEnd(
                            availability,
                            businessHours,
                            true
                        );
                } else {
                    modifiedAvailability = availability;
                }

                const sortedAvail = modifiedAvailability.sort((a, b) => {
                    function value(el) {
                        var x = parseInt(el.dow);
                        return x === 0 ? Infinity : x;
                    }
                    return value(a) - value(b);
                });

                dispatch(setAllInstructorAvailability(sortedAvail));
            };
            if (instructorAvailabilityHasBeenUpdated) {
                getAvailabilityInfo();
            }
        }, [
            studioEmployeeId,
            dibsStudioId,
            businessHours,
            openTime,
            closeTime,
            instructorAvailabilityHasBeenUpdated,
        ]);

        return (
            <Card
                id={idtouse}
                variant={variantType}
                ref={ref}
                {...others}
                sx={{
                    mb: 6,
                    p: 0,
                    mx: 0,
                    // border: border ? '1px solid' : 'none',
                    border: border ? bordertext : 'none',
                    borderColor: borderColorToUse,
                    // borderColor:
                    //     theme.palette.mode === 'dark'
                    //         ? theme.palette.background.default
                    //         : theme.palette.primary[200] + 75,
                    // ':hover': {
                    //     boxShadow: boxShadow
                    //         ? shadow ||
                    //           (theme.palette.mode === 'dark'
                    //               ? '0 2px 14px 0 rgb(33 150 243 / 10%)'
                    //               : '0 2px 14px 0 rgb(32 40 45 / 8%)')
                    //         : 'inherit',
                    // },
                    boxShadow: 'none',
                    ...sx,
                    ...responsiveStyles,
                }}
            >
                {/* card header and action */}
                <CardHeader
                    sx={headerSX}
                    title={<Typography variant="h3">{title}</Typography>}
                    action={secondary}
                />

                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.object,
    ]),
    shadow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sx: PropTypes.object,
    title: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.object,
    ]),
};

export default MainCard;
