import axios from 'axios';
import { getApiUrl } from 'helpers';
export default async function createCoupon(
    promoCodeInfo,
    dibsId,
    studioEmployeeId,
    timeZone
) {
    try {
        const url = getApiUrl('/stripe/create-coupon');
        const tosend = {
            dibsId,
            promoCodeInfo,
            studioEmployeeId,
            timeZone,
        };
        const couponCreated = await axios.post(url, tosend);
        const { data } = couponCreated;
        return data;
    } catch (err) {
        return {
            success: false,
            error: err.msg,
        };
    }
}
