import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function convertFromLocalToUTC(date) {
    const dateEntry = dayjs(date);
    const month = dateEntry.month() + 1;
    const day = dateEntry.date();
    const year = dateEntry.year();
    const dateString = `${year}-${month.toString()}-${day.toString()}`;
    const utcDateObject = dayjs.utc(dateString);
    return utcDateObject;
}
