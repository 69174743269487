export const SET_PROMO_CODES_DATA = "SET_PROMO_CODES_DATA";
export const SET_PROMO_CODES_LOADING_TRUE = "SET_PROMO_CODES_LOADING_TRUE";
export const SET_PROMO_CODES_LOADING_FALSE = "SET_PROMO_CODES_LOADING_FALSE";
export const SET_PROMO_CODES_QUERY = "SET_PROMO_CODES_QUERY";
export const FORGET_PROMO_CODES_QUERY = "FORGET_PROMO_CODES_QUERY";
export const SET_OPEN_PROMO_CODE = "SET_OPEN_PROMO_CODE";
export const FORGET_OPEN_PROMO_CODE = "FORGET_OPEN_PROMO_CODE";
export const PROMO_TYPE_FREE_CLASS = "FREE_CLASS";
export const PROMO_TYPE_PERCENT_OFF = "PERCENT_OFF";
export const PROMO_TYPE_CASH_OFF = "CASH_OFF";
export const PROMO_TYPE_GIFT_CARD = "GIFT_CARD";
export const PROMO_TYPE_ADD_CREDITS = "ADD_CREDITS";
export const PROMO_PRODUCT_PACKAGE = "PACKAGE";
export const PROMO_PRODUCT_CLASS = "CLASS";
export const PROMO_PRODUCT_RETAIL = "RETAIL";
export const PROMO_PRODUCT_UNIVERSAL = "UNIVERSAL";
export const PROMO_TYPE_FIXED = "FIXED_PRICE";

export const PROMO_CODE_PRODUCT_OPTIONS = [
  PROMO_PRODUCT_CLASS,
  PROMO_PRODUCT_RETAIL,
  PROMO_PRODUCT_PACKAGE,
  PROMO_PRODUCT_UNIVERSAL,
].map((item) => ({
  value: item,
  label: `${item[0]}${item.slice(1).toLowerCase()}`,
}));
