import axios from 'axios';
import { getApiUrl } from 'helpers';
import craftSMSMessage from 'actions/twilio/craftSMSMessage';

export default async function sendSmsConfirmation(datareceived) {
    const url = getApiUrl('/twilio/notify');
    let valuetoreturn;
    const msg = await craftSMSMessage(datareceived);
    const { appttocreate } = datareceived;
    const { apptToConfirm } = appttocreate;
    const { mobilephone, dibsStudioId, twilioCustomNumUS } = apptToConfirm;
    const twiliodata = {
        phoneNumberTo: mobilephone,
        customTwilioNumUS: twilioCustomNumUS,
        twilioMsg: msg,
        dibsId: Number(dibsStudioId),
    };
    await axios
        .post(url, twiliodata)
        .then((res) => {
            const { data } = res;

            valuetoreturn = data;
        })
        .catch((error) => {
            console.log(
                'there was an error - sending appt confirmaton sms',
                error
            );
        });
    return valuetoreturn;
}
