import { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useIntl } from 'react-intl';

// third-party
import FullCalendar from '@fullcalendar/react';
import itLocale from '@fullcalendar/core/locales/it';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';

// redux

dayjs.extend(utc);

// ==============================|| STUDIO CLASSES CALENDAR ||============================== //

const FullCalendarComponent = ({
    formattedEventsWithBackgroundEvents,
    calendarRef,
    startTime,
    date,
    handleEventDidMount,
    view,
    handleRangeSelect,
    handleEventUpdate,
    handleEventSelect,
    matchSm,
    resources,
    getCurrentDateView,
}) => {
    const intl = useIntl();
    const localetouse = intl.locale === 'en' ? 'en' : itLocale;
    return (
        <FullCalendar
            weekends
            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
            editable
            eventResourceEditable
            droppable
            timeZone="UTC"
            selectable
            selectMirror
            views={{
                day: {
                    titleFormat: {
                        month: 'numeric',
                        day: 'numeric',
                        weekday: 'short',
                    },
                },
            }}
            titleFormat={{ year: 'numeric', month: 'long' }}
            // eventContent={renderEventContent}
            displayEventEnd={false}
            events={formattedEventsWithBackgroundEvents}
            // eventsSet={(events) => handleSetEventsToUse(events)}
            ref={calendarRef}
            eventOverlap={true}
            rerenderDelay={10}
            slotMinTime={startTime}
            // eventMinHeight={45}
            initialDate={date}
            // initialEvents={events}
            slotEventOverlap={false}
            slotDuration="00:15:00"
            eventOrder="instructorId,start,-duration,title,allDay"
            allDaySlot={false}
            eventClick={(arg) => {
                if (arg.event.extendedProps.isOneTimeException) {
                    // Prevent default action and stop propagation
                    arg.jsEvent.preventDefault();
                    return false; // Prevents any default action for background events
                } else {
                    handleEventSelect(arg);
                }
            }}
            eventClassNames={(arg) => {
                if (arg.event.extendedProps.isbackground) {
                    return ['fc-event-is-background'];
                } else if (arg.event.extendedProps.isOneTimeException) {
                    return ['fc-event-is-one-time-exception'];
                } else {
                    return ['fc-event-is-regular-event'];
                }
            }}
            eventDidMount={handleEventDidMount}
            initialView={view}
            dayMaxEventRows={8}
            firstDay={1}
            eventDisplay="block"
            headerToolbar={false}
            allDayMaintainDuration
            eventResizableFromStart
            select={handleRangeSelect}
            datesSet={getCurrentDateView}
            eventDrop={handleEventUpdate}
            // eventClick={handleEventSelect}
            // dateClick={handleEventSelect}
            eventResize={handleEventUpdate}
            height={matchSm ? 'auto' : 720}
            eventColor="#cedae5"
            eventTextColor="#000"
            eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                meridiem: true,
            }}
            locale={localetouse}
            plugins={[
                listPlugin,
                dayGridPlugin,
                timelinePlugin,
                timeGridPlugin,
                interactionPlugin,
                resourceTimeGridPlugin,
                resourceDayGridPlugin,
            ]}
            datesAboveResources={true}
            // resourceGroupField="groupId"
            resourcesInitiallyExpanded={false}
            resources={resources}
            resourceLabelClassNames="fc-resource-label"
            dayHeaderClassNames="fc-col-header-label-info"
        />
    );
};

export default FullCalendarComponent;
