import { useState, useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import { useSelector } from 'store';
// material-ui
import { Button, Grid, TextField, Typography, Box, Fade } from '@mui/material';
import checkPackExists from 'actions/studios/memberships-packages/checkPackageExists';
import MembershipPackageQuestions from 'components/sub-components/FrontDesk/MembershipsPackages/questionsForMembershipsPackages';
import { capitalizeWords } from 'helpers/general';
import SubmitButton from 'components/button/submitButton';
import CancelButton from 'components/button/cancelButton';

import createNewMembershipOrPackage from 'actions/studios/memberships-packages/createNewMembershipOrPackage';
import setAlert from 'actions/status/setAlert';
import deactivatePackage from 'actions/studios/memberships-packages/deactivateMembershipsPackages';

import createNewStripeProduct from 'actions/stripe/plans/createNewStripeProduct';

import { useTheme, alpha } from '@mui/material/styles';

// ==============================|| PROMO CODES ||============================== //

const defaultValues = {
    packname: 'New Membership',
    classAmount: 10,
    price: 100,
    autopay: 'FORCE',
    priceAutopay: 100,
    autopayIncrement: 'month',
    validForInterval: 'month',
    autopayIncrementCount: 1,
    description: '',
    onlyFirstPurchase: false,
    passesValidFor: 1,
    unlimited: true,
    forPrivateSessionsOnly: false,
    pricePerClassOverride: 0,
    commitmentPeriod: 1,
    onDemandAccess: false,
    vodAccess: false,
    showPricePerClass: true,
    promoteAtCheckout: false,
    type: 'membership',
    newClientsOnly: false,
    isFree: false,
    freeTrial: false,
    adminAccessOnly: false,
    dailyUsageLimit: null,
    expiresAfterFirstBooking: false,
    appliesToSpecialCategory: false,
    multicriteriaStartingPoint: [],
};

const MembershipTextField = () => {
    const theme = useTheme();
    const [value, setValue] = useState('');
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const { promocodes } = useSelector((state) => state.datatables);
    const { needsRefresh } = promocodes;
    const [packAlreadyExists, setPackAlreadyExists] = useState(false);
    const [checkedExistence, setCheckedExistence] = useState(false);
    const [packageName, setPackageName] = useState('');
    const [fullPackageInfo, setFullPackageInfo] = useState(defaultValues);
    const [confirmationText, setConfirmationText] = useState('');
    const [showConfirmationText, setShowConfirmationText] = useState(true);
    // const [newCodeNow, setNewCodeNow] = useState(false);
    useEffect(() => {
        if (needsRefresh) {
            setPackAlreadyExists(false);
            setCheckedExistence(false);
        }
    }, [needsRefresh]);
    const clearPackName = () => {
        setValue('');
        setCheckedExistence(false);
        setPackAlreadyExists(false);
        setFullPackageInfo(defaultValues);
        setShowConfirmationText(false);
        setConfirmationText('');
    };
    const handleChange = (e) => {
        const newvalue = e.target.value;
        setValue(newvalue);
    };
    const setTrueFalseFields = (fieldname, value) => {
        setFullPackageInfo({
            ...fullPackageInfo,
            [fieldname]: value,
        });
    };
    // REFACTOR THIS FUNCTION INTO ITS OWN ACTION
    // better as a Switch statement
    const updateMembershipPackageInfo = (functiontype, newinfo) => {
        const { autopay } = fullPackageInfo;

        switch (functiontype) {
            case 'choosemembershiptype':
                let classAmountToUse = fullPackageInfo?.classAmount;
                let unlimitedToUse = fullPackageInfo?.unlimited;
                if (newinfo === 'singlesession') {
                    classAmountToUse = 1;
                    unlimitedToUse = false;
                }
                setFullPackageInfo({
                    ...fullPackageInfo,
                    classAmount: classAmountToUse,
                    type: newinfo,
                    autopay: newinfo === 'membership' ? 'FORCE' : 'NONE',
                    unlimited: unlimitedToUse,
                });
                break;
            case 'chooseunlimitedorfixed':
                setFullPackageInfo({
                    ...fullPackageInfo,
                    unlimited: newinfo === 'fixed' ? false : true,
                });
                break;
            case 'choosenumvisits':
                setFullPackageInfo({
                    ...fullPackageInfo,
                    classAmount: newinfo,
                });
                break;
            case 'chooseperiodtype':
                setFullPackageInfo({
                    ...fullPackageInfo,
                    autopayIncrement: newinfo,
                    validForInterval: newinfo,
                });
                break;
            case 'choosenumperiods':
                setFullPackageInfo({
                    ...fullPackageInfo,
                    autopayIncrementCount:
                        autopay === 'FORCE' || autopay === 'ALLOW'
                            ? newinfo
                            : fullPackageInfo.autopayIncrementCount,
                    passesValidFor: newinfo,
                });

                break;
            case 'chooseprice':
                setFullPackageInfo({
                    ...fullPackageInfo,
                    priceAutopay: newinfo,
                    price: newinfo,
                });
                break;
            case 'choosedescription':
                setFullPackageInfo({
                    ...fullPackageInfo,
                    description: newinfo,
                });
                break;
            // case 'chooserestrictions':
            //     setFullPackageInfo({
            //         ...fullPackageInfo,
            //         adminAccessOnly: newinfo,
            //     });
            //     break;

            default:
                console.error('Invalid functiontype');
                console.log('function type passed in was', functiontype);
        }
    };

    useEffect(() => {
        return () => {
            setValue('');
            setPackAlreadyExists(false);
            setCheckedExistence(false);
        };
    }, []);

    const handleFirstNext = async () => {
        setCheckedExistence(false);
        setPackAlreadyExists(false);
        setPackageName(capitalizeWords(value));
        const newpackinfo = {
            ...fullPackageInfo,
            packname: capitalizeWords(value),
        };
        setFullPackageInfo(newpackinfo);
        await checkPackExists(dibsStudioId, value).then((res) => {
            try {
                const { packNameAlreadyExists, msg } = res;
                if (packNameAlreadyExists) {
                    setPackAlreadyExists(true);
                } else {
                    setPackAlreadyExists(false);
                }
                if (msg === 'success') {
                    setCheckedExistence(true);
                    setShowConfirmationText(true);
                } else {
                    setCheckedExistence(false);
                    setShowConfirmationText(false);
                }
            } catch (err) {
                console.log(
                    `error checking membership name exists for studioid: ${dibsStudioId}\nerr is: ${err}`
                );
            }
        });
    };
    const addS = fullPackageInfo?.autopayIncrementCount === 1 ? '' : 's';
    const renewalText = `It will automatically renew every ${fullPackageInfo?.autopayIncrementCount === 1 ? '' : fullPackageInfo?.autopayIncrementCount} ${fullPackageInfo?.autopayIncrement}${addS} unless cancelled.`;
    const packageText = `It will expire after ${fullPackageInfo?.passesValidFor} ${fullPackageInfo?.validForInterval}.`;
    const costText = `It costs $${fullPackageInfo?.price}`;

    let packTypeToUse = fullPackageInfo?.type;
    if (fullPackageInfo?.type === 'singlesession') {
        packTypeToUse = 'single session';
    }
    const freeText = `This ${packTypeToUse} is free`;
    const newPackText = `You are creating a new ${packTypeToUse} called ${fullPackageInfo?.packname}. ${fullPackageInfo?.isFree ? freeText : costText} and ${fullPackageInfo?.unlimited ? 'includes unlimited' : 'includes ' + fullPackageInfo?.classAmount} visit${addS}. ${fullPackageInfo?.autopay === 'NONE' ? packageText : renewalText}`;
    const applicationText = fullPackageInfo?.forPrivateSessionsOnly
        ? `This ${packTypeToUse} is for private sessions only.`
        : '';
    let restrictionText = '';
    if (fullPackageInfo?.adminAccessOnly || fullPackageInfo?.newClientsOnly) {
        const useAnd =
            fullPackageInfo?.adminAccessOnly && fullPackageInfo?.newClientsOnly;
        restrictionText = `This ${packTypeToUse} is restricted to ${fullPackageInfo?.newClientsOnly ? 'new clients' : ''}${useAnd ? ' and ' : ''} ${fullPackageInfo?.adminAccessOnly ? 'admin access' : ''} only. ${fullPackageInfo?.adminAccessOnly ? 'It will not appear on your public facing widget.' : ''}`;
    } else {
        restrictionText = `This ${packTypeToUse} is not restricted and is available to all clients.`;
    }
    useEffect(() => {
        setConfirmationText(
            `${newPackText} ${restrictionText} ${applicationText}`
        );
    }, [newPackText, restrictionText, applicationText]);
    const handleCancel = () => {
        clearPackName();
    };
    const handleSubmit = async () => {
        try {
            const res = await createNewMembershipOrPackage(
                fullPackageInfo,
                dibsStudioId
            );
            if (res.success) {
                const { newPackageCreated } = res;
                const stripeRes = await createNewStripeProduct(
                    fullPackageInfo,
                    dibsStudioId,
                    newPackageCreated?.id
                );
                if (stripeRes?.success) {
                    setAlert({
                        msg: `${packageName} has been created!`,
                        success: true,
                        seconds: 7,
                    });
                    clearPackName();
                } else {
                    deactivatePackage(newPackageCreated?.id);
                    setAlert({
                        msg: `There was a problem adding ${packageName} to Stripe. Please refresh your screen and try again.`,
                        warning: true,
                        seconds: 7,
                    });
                }
            } else {
                setAlert({
                    msg: `There was a problem creating ${packageName}. Please refresh your screen and try again.`,
                    warning: true,
                    seconds: 7,
                });
            }
        } catch (error) {
            console.error(error);
            setAlert({
                msg: `An unexpected error occurred. Please refresh your screen and try again.`,
                warning: true,
                seconds: 7,
            });
        }
    };
    return (
        <Grid container spacing={gridSpacing}>
            {checkedExistence && packAlreadyExists && (
                <Grid item xs={12} id="membership-already-exists">
                    <Typography variant="h6" color="error" sx={{ mb: 2 }}>
                        A membership with this name already exists. Please
                        choose another text string.
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} sx={{ mb: 3 }} id="textfield-membership-name">
                <TextField
                    id="membership-field"
                    variant="outlined"
                    value={value}
                    onChange={(e) => handleChange(e)}
                    placeholder="e.g. UNLIMITED VISITS"
                    size="small"
                    sx={{ width: '400px', height: '40px', mr: 2 }}
                />
                {(!checkedExistence || packAlreadyExists) && (
                    <Button
                        id="next-button-membership"
                        variant="contained"
                        disableElevation
                        onClick={handleFirstNext}
                        sx={{ width: '30px', height: '35px', mt: 0.3 }}
                    >
                        Next
                    </Button>
                )}
            </Grid>
            {!packAlreadyExists && checkedExistence && (
                <>
                    <Grid item xs={9.5} sx={{ mt: 1.5, mb: 1.5 }}>
                        <Typography variant="h5">
                            Set the information below to choose the
                            specifications for the new membership or package (
                            {packageName}).
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={9.5}
                        sx={{ mt: 1 }}
                        id="membership-pack-questions"
                    >
                        <MembershipPackageQuestions
                            packageName={value}
                            clearPackName={clearPackName}
                            updateInfo={updateMembershipPackageInfo}
                            fullPackageInfo={fullPackageInfo}
                            setTrueFalseFields={setTrueFalseFields}
                        />
                    </Grid>
                    {showConfirmationText && (
                        <Fade in timeout={500}>
                            <Grid
                                item
                                xs={9.5}
                                sx={{ mt: 2, mb: 2 }}
                                id="grid-showing-confirmation-text-new-pack"
                            >
                                <Box
                                    border={2}
                                    borderRadius={1.2}
                                    borderColor={
                                        theme.palette.globalcolors.submit
                                    }
                                    sx={{ p: 2 }}
                                    backgroundColor={alpha(
                                        theme.palette.globalcolors.cancel,
                                        0.1
                                    )}
                                >
                                    {confirmationText}
                                    <Grid
                                        xs={10}
                                        item
                                        id="submit-cancel-buttons-membership"
                                        sx={{ mt: 2, pt: 1, pb: 1 }}
                                    >
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            spacing={2}
                                            border={0}
                                        >
                                            <Grid
                                                item
                                                // border={1}
                                                sx={{ mt: 0, mb: 0 }}
                                                id="cancel-membership-btn"
                                            >
                                                <CancelButton
                                                    id="cancel-button-membership"
                                                    valueString={`Cancel Creation of ${packTypeToUse}`}
                                                    onClick={handleCancel}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                // border={1}
                                                sx={{ mt: 0, mb: 0, ml: 1 }}
                                                id="submit-membership-btn"
                                            >
                                                <SubmitButton
                                                    id="submit-button-membership-textfield"
                                                    valueString={`Save New ${packTypeToUse}`}
                                                    onClick={handleSubmit}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Fade>
                    )}
                </>
            )}
        </Grid>
    );
};

export default MembershipTextField;
