import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

// material-ui
import { Dialog, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// redux
import { useDispatch, useSelector } from 'store';
import { getEvents, addEvent, updateEvent } from 'store/slices/calendar';

import { clearAllStatusMessageReducer } from 'store/slices/error';

import { capitalizeString } from 'helpers';
import { capitalizeFirstLetter } from 'helpers/general';
import updateExistingEvent from 'actions/schedule/updateExistingEvent';

import {
    setShowAvailabilityStatus,
    setPreferredView,
} from 'store/slices/calendar';
import { setCurrentDateShowing } from 'store/slices/schedule';
import setAlert from 'actions/status/setAlert';

// project imports
import MainCard from 'ui-component/cards/CalendarMainCard';
import SubCard from 'ui-component/cards/SubCard';
import CalendarStyled from './CalendarStyled';
import Toolbar from './Toolbar';
import AvailabilityOptions from './AvailabilityOptions';
import AddEventForm from 'ui-component/forms/AddEventForm';

import AddEventButton from './AddEventButton';
import getInstructors from 'actions/studios/instructors/getInstructors';
import getApptTypes from 'actions/appointments/getAllApptTypes';
import getAttendees from 'actions/schedule/getAttendees';
import getAllExceptions from 'actions/studios/employees/getAllExceptions';
import getAvailabilityBlockWithException from 'actions/studios/employees/getAvailabilityBlockWithExceptionNew';

import DeleteClassDialog from 'views/studio-admin/class-schedule/DeleteClassDialog';
import UpdateClassDialog from 'views/studio-admin/class-schedule/UpdateClassDialog';
import CalendarComponent from './calendarComponent';
import { getLightRandomHexColor } from 'helpers';
import convertFromLocalToUTC from 'helpers/dayjs/convertFromLocalToUTC';
import translate from 'helpers/translate';
// assets

import { setAppts } from 'store/slices/appointments';
import {
    setAllInstructorExceptions,
    setInstructorData,
    setInstructorAvailabilityHasBeenUpdated,
} from 'store/slices/instructors';
import { setApptBeingCreatedStartEnd } from 'store/slices/schedule';
import { useSearch } from 'routes/searchContext';

dayjs.extend(utc);

// ==============================|| STUDIO CLASSES CALENDAR ||============================== //

const Calendar = () => {
    const { setShowSearch } = useSearch();
    const theme = useTheme();
    const intl = useIntl();
    const dispatch = useDispatch();
    const calendarRef = useRef(null);
    const matchSm = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { config, studioConfig } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId, timeZone } = config;
    const { classAltName } = studioConfig;
    const { currentDateShowing } = useSelector((state) => state.schedule);
    let classNameUse = classAltName;
    if (!classNameUse) classNameUse = 'Class';
    if (classNameUse === 'undefined') classNameUse = 'Class';

    const [instructorsToPass, setInstructorsToPass] = useState([]);
    const [instructorsAsResources, setInstructorsAsResources] = useState([]);
    const [fullListInstructors, setFullListInstructors] = useState([]);
    const [initialStartDate, setInitialStartDate] = useState('');
    const [minCalendarViewDate, setMinCalendarViewDate] = useState(null);
    const [maxCalendarViewDate, setMaxCalendarViewDate] = useState(null);
    const [initialStartTime, setInitialStartTime] = useState('');
    const [initialEndTime, setInitialEndTime] = useState('');
    const [updateAllEvents, setUpdateAllEvents] = useState(false);
    const [notifyClientsModalOpen, setNotifyClientsModalOpen] = useState(false);
    const [attendeesAffected, setAttendeesAffected] = useState([]);
    const instructorColorsAssigned = useState([]);

    const [blockedTime, setBlockedTime] = useState([]);
    const [availabilityIsBeingDisplayed, setAvailabilityIsBeingDisplayed] =
        useState(false);
    const [showAlertWithEmailSms, setShowAlertWithEmailSms] = useState(false);
    const [
        instructorAvailabilityThatIsShowing,
        setInstructorAvailabilityThatIsShowing,
    ] = useState([]);
    const thisDate = dayjs.utc(currentDateShowing);

    const returnedDate = convertFromLocalToUTC(thisDate);
    const [date, setDate] = useState(new Date(returnedDate));
    const [showAvailability, setShowAvailability] = useState(false);
    const [preferredShowAvailability, setPreferredShowAvailability] =
        useState(false); // keep track in the case where we have month view and it is not available, go back to it on other view
    let showAvailabilityTu = translate({
        id: 'show-availability',
        msg: 'Show Availability',
    });
    const [showAvailabilityT, setShowAvailabilityT] =
        useState(showAvailabilityTu);
    let hideAvailabilityTu = translate({
        id: 'hide-availability',
        msg: 'Hide Availability',
    });
    const [hideAvailabilityT, setHideAvailabilityT] =
        useState(hideAvailabilityTu);
    const [showAvailabilityButtonText, setShowAvailabilityButtonText] =
        useState(showAvailabilityT);

    // const [view, setView] = useState(matchSm ? 'timeGridWeek' : 'dayGridMonth');

    const timegridweek = 'timeGridWeek';
    const timegridday = 'timeGridDay';
    const resourceTimeGridDay = 'resourceTimeGridDay';
    // const resourceDayGridDay = 'resourceDayGridDay';

    const resourceTimeGridWeek = 'resourceTimeGridWeek';
    // const [view, setView] = useState(resourceTimeGridWeek);
    const [view, setView] = useState(timegridweek);

    // fetch events data
    const [events, setEvents] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [eventTypeAdding, setEventTypeAdding] = useState('normal');
    const [eventIdEditing, setEventIdEditing] = useState(null);
    const [selectedRange, setSelectedRange] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [oneTimeBlockedEventsFromDB, setOneTimeBlockedEventsFromDB] =
        useState([]);
    const calendarState = useSelector((state) => state.calendar);
    const { showAvailabilityStatus, preferredView } = calendarState;
    const { instructorAvailability, instructorData } = useSelector(
        (state) => state.instructors
    );
    const { allInstructorAvailability, allInstructorExceptions } =
        instructorAvailability;
    const [
        formattedEventsWithBackgroundEvents,
        setFormattedEventsWithBackgroundEvents,
    ] = useState([]);
    useEffect(() => {
        setShowSearch(false);
    }, [setShowSearch]);

    useEffect(() => {
        dispatch(setInstructorAvailabilityHasBeenUpdated(true));
    }, [dispatch]);
    useEffect(() => {
        setShowAvailabilityT(showAvailabilityTu);
        setHideAvailabilityT(hideAvailabilityTu);
        setShowAvailabilityButtonText(
            showAvailabilityStatus ? hideAvailabilityT : showAvailabilityT
        );
    }, [
        showAvailabilityTu,
        hideAvailabilityTu,
        showAvailabilityStatus,
        hideAvailabilityT,
        showAvailabilityT,
    ]);

    useEffect(() => {
        if (showAvailabilityStatus !== showAvailability) {
            setShowAvailability(showAvailabilityStatus);
            setPreferredShowAvailability(showAvailabilityStatus);

            setShowAvailabilityButtonText(
                showAvailabilityStatus ? hideAvailabilityT : showAvailabilityT
            );

            const calendarEl = calendarRef.current;
            if (calendarEl) {
                const calendarApi = calendarEl.getApi();
                calendarApi.changeView(preferredView);
                setView(preferredView);
            }
        }
    }, [
        preferredView,
        showAvailability,
        showAvailabilityStatus,
        showAvailabilityT,
        hideAvailabilityT,
    ]);

    useEffect(() => {
        dispatch(getEvents(dibsStudioId, timeZone));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        return () => {
            setShowAvailability(false);
            setShowAvailabilityButtonText(showAvailabilityT);
            setPreferredShowAvailability(false);
            // setView(timegridweek);
        };
    }, [showAvailabilityT]);
    useEffect(() => {
        if (updateAllEvents) {
            dispatch(getEvents(dibsStudioId, timeZone));
            setUpdateAllEvents(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateAllEvents]);
    useEffect(() => {
        const exceptions = async () => {
            const exceptiondata = await getAllExceptions({
                dibsId: dibsStudioId,
            });
            const thisdata = exceptiondata.data;
            const { allExceptions } = thisdata;

            if (allExceptions) {
                dispatch(setAllInstructorExceptions(allExceptions));
            }
        };
        exceptions();
    }, [dibsStudioId, dispatch]);

    useEffect(() => {
        const getPeople = async () => {
            const { activeInstructors } = await getInstructors(dibsStudioId);
            setInstructorsToPass(activeInstructors);
            dispatch(setInstructorData(activeInstructors));
            const filteredForEnabledInstructors = activeInstructors.filter(
                (i) => i.enabled
            );

            const activeInstructorsAsResources =
                filteredForEnabledInstructors.map((instructor) => {
                    return {
                        id: instructor.id,
                        title: capitalizeFirstLetter(instructor.firstname),
                        groupId: 1,
                    };
                });
            setInstructorsAsResources(activeInstructorsAsResources);
            setFullListInstructors(activeInstructorsAsResources);
        };
        const getAppts = async () => {
            const appts = await getApptTypes(dibsStudioId);
            dispatch(setAppts(appts));
        };
        getPeople();
        getAppts();
    }, [dibsStudioId, dispatch]);

    useEffect(() => {
        const { events } = calendarState;
        const eventTimeFormat = {
            hour: '2-digit',
            minute: '2-digit',
            meridiem: 'short',
        };
        const formattedEvents = [];
        const blockedTimeEvents = [];
        for (const ev of events) {
            const { instructor } = ev;
            const { associated_color } = instructor;
            let bgColor = associated_color;
            // if (!bgColor) {
            //     const instrInfo = instructorColorsAssigned.find((ica) => {
            //         return ica.instructorId === instructor.id;
            //     });
            //     if (instrInfo) {
            //         bgColor = instrInfo.associated_color;
            //     } else {
            //         bgColor = getLightRandomHexColor();
            //         instructorColorsAssigned.push({
            //             instructorId: instructor.id,
            //             associated_color: bgColor,
            //         });
            //     }
            // }
            if (ev.is_blocked_time) {
                bgColor = '#F3686860';
            }
            const withresource = {
                ...ev,
                editable: true,
                eventResourceEditable: true,
                resourceId: ev.trainerid,
                isbackground: false,
                isOneTimeException: false,
                overlap: true,
                backgroundColor: bgColor,
            };

            if (ev.is_blocked_time) {
                blockedTimeEvents.push(withresource);
                if (showAvailability) {
                    formattedEvents.push(withresource);
                }
            } else {
                formattedEvents.push(withresource);
            }
        }

        let togethervalue = {
            events: formattedEvents,
            eventTimeFormat,
        };
        setEvents(togethervalue);
        setFormattedEventsWithBackgroundEvents(togethervalue);
        setBlockedTime(blockedTimeEvents);
        setAvailabilityIsBeingDisplayed(false);
    }, [
        allInstructorAvailability,
        calendarState,
        // instructorColorsAssigned,
        instructorData,
        showAvailability,
    ]);

    const formattedAvailability = useMemo(() => {
        let backgroundAvailability = [];
        const affectedAvailabilityArray = [];
        const unaffectedAvailabilityArray = [];

        if (instructorData?.length > 0) {
            for (const instruc of instructorData) {
                const newColorToUse = `${instruc.associated_color}30`;
                const thisInstructorAvailability =
                    allInstructorAvailability.filter((aia) => {
                        return aia.instructorId === instruc.id;
                    });

                const thisInstructorExceptions = allInstructorExceptions.filter(
                    (ex) => {
                        return ex.instructorId === instruc.id;
                    }
                );

                for (const exception of thisInstructorExceptions) {
                    const availabilityAffectedByExceptions =
                        thisInstructorAvailability.filter((tia) => {
                            return tia.dow === exception.dowAffected;
                        });
                    const onetimeExceptions = thisInstructorExceptions.filter(
                        (ot) => {
                            return ot.is_onetime;
                        }
                    );
                    if (onetimeExceptions?.length > 0) {
                        for (const ote of onetimeExceptions) {
                            // const newColorToUse = `${instruc.associated_color}30`;
                            const instructorName = instruc.firstname;
                            const naObj = {
                                resourceId: instruc.id,
                                instructorId: instruc.id,
                                backgroundColor: '#EBEBEB', // dee3df
                                availabilityId: null,
                                overlap: true,
                                start: ote.startdate_of_unavailability,
                                end: ote.enddate_of_unavailability,
                                isOneTimeException: true,
                                isbackground: false,
                                title: `Reserved Time for ${capitalizeString(instructorName)}`,
                                availabilityIdIsBasedOn: null,
                                exceptionIdApplied: ote.id,
                                disabled: false,
                                editable: false,
                                unavailable: true,
                                instructorName: instruc.firstname,
                                classNames: ['fc-event-is-one-time-exception'],
                            };
                            if (
                                !backgroundAvailability.some(
                                    (e) =>
                                        e.exceptionIdApplied ===
                                        naObj.exceptionIdApplied
                                )
                            ) {
                                backgroundAvailability.push(naObj);
                            }
                        }
                    }

                    if (availabilityAffectedByExceptions?.length > 0) {
                        if (affectedAvailabilityArray?.length > 0) {
                            const matchesDayForExistingArray = (element) => {
                                const affectedAvailabilityDow = element.dow;

                                const matches =
                                    affectedAvailabilityDow ===
                                    exception.dowAffected;
                                return matches;
                            };
                            const indexValue =
                                affectedAvailabilityArray.findIndex(
                                    matchesDayForExistingArray
                                );
                            if (indexValue < 0) {
                                affectedAvailabilityArray.push({
                                    availabilityAffected:
                                        availabilityAffectedByExceptions, // should be one day
                                    dow: exception.dowAffected,
                                    exceptionsThatAffects: [exception],
                                    instructorId: exception.instructorId,
                                    availabilityId:
                                        availabilityAffectedByExceptions[0]
                                            .availabilityId,
                                    exceptionIds: [exception.id],
                                    availabilityIdIsBasedOn:
                                        availabilityAffectedByExceptions[0]
                                            .availabilityId,
                                    backgroundColor: newColorToUse,
                                    daysOfWeek: [exception.dowAffected],
                                    resourceId: instruc.id,
                                    overlap: true,
                                    display: 'background',
                                    title: instruc.firstname,
                                    isbackground: true,
                                    isOneTimeException: false,
                                    instructorName: instruc.firstname,
                                });
                            } else {
                                affectedAvailabilityArray[
                                    indexValue
                                ].exceptionIds.push(exception.id);
                                affectedAvailabilityArray[
                                    indexValue
                                ].exceptionsThatAffects.push(exception);
                            }
                        } else {
                            affectedAvailabilityArray.push({
                                availabilityAffected:
                                    availabilityAffectedByExceptions, // should be one day
                                dow: exception.dowAffected,
                                daysOfWeek: [exception.dowAffected],
                                exceptionsThatAffects: [exception],
                                instructorId: exception.instructorId,
                                availabilityId:
                                    availabilityAffectedByExceptions[0]
                                        .availabilityId,
                                availabilityIdIsBasedOn:
                                    availabilityAffectedByExceptions[0]
                                        .availabilityId,
                                exceptionIds: [exception.id],
                                backgroundColor: newColorToUse,
                                resourceId: instruc.id,
                                overlap: true,
                                display: 'background',
                                title: instruc.firstname,
                                isbackground: true,
                                isOneTimeException: false,
                                instructorName: instruc.firstname,
                            });
                        }
                    }
                }

                for (const ti of thisInstructorAvailability) {
                    const isInOtherArray = (element) => {
                        const matches = affectedAvailabilityArray.some(
                            (aia) => {
                                return (
                                    aia.availabilityId ===
                                    element.availabilityId
                                );
                            }
                        );
                        return matches;
                    };
                    if (!isInOtherArray(ti)) {
                        // const newColorToUse = `${instruc.associated_color}30`;
                        const instructorName = instruc.firstname;

                        if (!ti.unavailable) {
                            unaffectedAvailabilityArray.push({
                                ...ti,
                                instructorName,
                                backgroundColor: newColorToUse,
                                resourceId: instruc.id,
                                overlap: true,
                                daysOfWeek: [ti.dow],
                                display: 'background',
                                isOneTimeException: false,
                                isbackground: true,
                                title: instructorName,
                                availabilityIdIsBasedOn: ti.availabilityId,
                            });
                        }
                    }
                }
                // check to place the remaining items in the array.
            }
        }

        for (const af of affectedAvailabilityArray) {
            const newAvailabilityPostException =
                getAvailabilityBlockWithException(af);

            const na = newAvailabilityPostException;
            const { newAvailability } = na;
            newAvailability.forEach((nape) => {
                const naObj = {
                    ...nape,
                    resourceId: af.resourceId,
                    overlap: true,
                    display: 'background',
                    isOneTimeException: false,
                    isbackground: true,
                    title: af.title,
                    availabilityIdIsBasedOn: af.availabilityId,
                    backgroundColor: na.backgroundColor,
                    daysOfWeek: na.daysOfWeek,
                };

                backgroundAvailability.push(naObj);
            });
        }
        for (const uaf of unaffectedAvailabilityArray) {
            backgroundAvailability.push(uaf);
        }

        return backgroundAvailability;
    }, [allInstructorAvailability, allInstructorExceptions, instructorData]);

    useEffect(() => {
        if (!availabilityIsBeingDisplayed) {
            if (events && showAvailability) {
                let tempEventsArray = [...events.events]; // create a new array
                let timeFormat = events.eventTimeFormat;
                if (formattedAvailability?.length > 0) {
                    // formattedAvailability.forEach((ev) => {
                    //     tempEventsArray.push(ev);
                    // });

                    formattedAvailability.forEach((fa) => {
                        tempEventsArray.push(fa);
                        // if (
                        //     !tempEventsArray.some(
                        //         (e) => e.availabilityId === fa.availabilityId
                        //     )
                        // ) {
                        //     tempEventsArray.push(fa);
                        // }
                        // tempEventsArray.push(ev);
                    });
                }

                blockedTime.forEach((bt) => {
                    const thiseventbeingconsidered = bt.eventid;
                    if (
                        !tempEventsArray.some(
                            (e) => e.eventid === thiseventbeingconsidered
                        )
                    ) {
                        tempEventsArray.push(bt);
                    }
                });
                oneTimeBlockedEventsFromDB.forEach((ev) => {
                    if (
                        !tempEventsArray.some((e) => e.eventid === ev.eventid)
                    ) {
                        tempEventsArray.push(ev);
                    }
                });

                setFormattedEventsWithBackgroundEvents({
                    eventTimeFormat: timeFormat,
                    events: tempEventsArray,
                }); // set new state
                setAvailabilityIsBeingDisplayed(true);
            }
        }
    }, [
        blockedTime,
        events,
        formattedAvailability,
        showAvailability,
        availabilityIsBeingDisplayed,
        oneTimeBlockedEventsFromDB,
    ]);

    // calendar toolbar events
    const handleDateToday = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.today();
            console.log('for today is', calendarApi.getDate());

            setDate(calendarApi.getDate());
        }
    };

    const changeInstructorAsResource = (instructorId, status) => {
        if (status) {
            const iar = [...instructorsAsResources];
            const instructorToAdd = fullListInstructors.find((i) => {
                return i.id === instructorId;
            });
            iar.push(instructorToAdd);
            setInstructorsAsResources(iar);
        } else {
            const iar = instructorsAsResources.filter((i) => {
                return i.id !== instructorId;
            });
            setInstructorsAsResources(iar);
        }
    };

    // availability is being added
    const addAvailabilityToEvents = () => {
        let tempEventsArray = [...events.events]; // create a new array
        let timeFormat = events.eventTimeFormat;

        formattedAvailability.forEach((fa) => {
            tempEventsArray.push(fa);
            // if (
            //     !tempEventsArray.some(
            //         (e) => e.availabilityId === fa.availabilityId
            //     )
            // ) {
            //     tempEventsArray.push(fa);
            // }
            // tempEventsArray.push(ev);
        });
        blockedTime.forEach((bt) => {
            if (!tempEventsArray.some((e) => e.eventid === bt.eventid)) {
                tempEventsArray.push(bt);
            }
        });
        oneTimeBlockedEventsFromDB.forEach((ev) => {
            if (!tempEventsArray.some((e) => e.eventid === ev.eventid)) {
                tempEventsArray.push(ev);
            }
        });

        setFormattedEventsWithBackgroundEvents({
            eventTimeFormat: timeFormat,
            events: tempEventsArray,
        }); // set new state
        setAvailabilityIsBeingDisplayed(true);
    };
    const hideAvailabilityFromEvents = async () => {
        let tempEventsArray = [...events.events]; // create a new array
        let timeFormat = events.eventTimeFormat;
        setFormattedEventsWithBackgroundEvents({
            eventTimeFormat: timeFormat,
            events: tempEventsArray,
        }); // set new state
        setAvailabilityIsBeingDisplayed(false);
        return;
    };
    const getCalViewToUse = () => {
        switch (view) {
            case resourceTimeGridWeek:
                return timegridweek;
            case timegridweek:
                return resourceTimeGridWeek;
            case resourceTimeGridDay:
                return timegridday;
            case timegridday:
                return resourceTimeGridDay;
            default:
                return timegridweek;
        }
    };
    const refreshCalendar = () => {
        console.log('Would refresh calendar - but not activated yet');

        // const calendarEl = calendarRef.current;
        // if (calendarEl) {
        //     const calendarApi = calendarEl.getApi();
        //     calendarApi.refetchEvents();
        // }
    };
    const handleShowAvailabilityClick = async () => {
        if (!showAvailability) {
            setShowAvailabilityButtonText(hideAvailabilityT);
            setShowAvailability(true);
            setPreferredShowAvailability(true);
            dispatch(setShowAvailabilityStatus(true));
            dispatch(setPreferredView(view));
            addAvailabilityToEvents();
            const calendarViewToUse = getCalViewToUse();
            const calendarEl = calendarRef.current;
            if (calendarEl) {
                const calendarApi = calendarEl.getApi();
                calendarApi.changeView(calendarViewToUse);
                setView(calendarViewToUse);
                dispatch(setPreferredView(calendarViewToUse));
            }
        } else {
            setShowAvailabilityButtonText(showAvailabilityT);
            dispatch(setPreferredView(view));
            setShowAvailability(false);
            setPreferredShowAvailability(false);
            dispatch(setShowAvailabilityStatus(false));

            const calendarViewToUse = getCalViewToUse();

            await hideAvailabilityFromEvents().then(() => {
                const calendarEl = calendarRef.current;
                if (calendarEl) {
                    const calendarApi = calendarEl.getApi();

                    calendarApi.changeView(calendarViewToUse);

                    setView(calendarViewToUse);
                    dispatch(setPreferredView(calendarViewToUse));
                }
            });
        }
    };

    const handleViewChange = (newView) => {
        let newviewtouse = newView;
        if (newView === 'dayGridMonth') {
            setShowAvailability(false);
            setShowAvailabilityButtonText('');
            hideAvailabilityFromEvents();
        } else {
            setShowAvailability(preferredShowAvailability);
            if (preferredShowAvailability) {
                setShowAvailabilityButtonText(hideAvailabilityT);
            } else {
                setShowAvailabilityButtonText(showAvailabilityT);
            }
            if (newView === 'timeGridWeek') {
                if (preferredShowAvailability) {
                    newviewtouse = resourceTimeGridWeek;
                    addAvailabilityToEvents();
                }
            } else if (newView === 'timeGridDay') {
                if (preferredShowAvailability)
                    newviewtouse = resourceTimeGridDay;
            }
        }
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.changeView(newviewtouse);
            dispatch(setPreferredView(newviewtouse));
            setView(newviewtouse);
        }
    };

    const handleDatePrev = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.prev();
            console.log(calendarApi.getDate());
            const newdate = calendarApi.getDate();
            dispatch(setCurrentDateShowing(newdate));
            setDate(newdate);
        }
    };

    const handleDateNext = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.next();
            const newdate = calendarApi.getDate();
            dispatch(setCurrentDateShowing(newdate));
            setDate(newdate);
        }
    };

    const clickedAddEventButton = () => {
        const today = dayjs.utc().format('M/D/YYYY');
        setInitialStartDate(today);
        const newstarttime = dayjs.utc().hour(9).minute(0);
        setInitialStartTime(newstarttime);
        const endtime = dayjs.utc().hour(9).minute(30);
        setInitialEndTime(endtime);
        setIsModalOpen(true);
        setEventTypeAdding('normal');
    };
    const clickedBlockTimeButton = () => {
        const today = dayjs.utc().format('M/D/YYYY');
        setInitialStartDate(today);
        const newstarttime = dayjs.utc().hour(9).minute(0);
        setInitialStartTime(newstarttime);
        const endtime = dayjs.utc().hour(9).minute(30);
        setInitialEndTime(endtime);
        setIsModalOpen(true);
        setEventTypeAdding('block-time');
    };

    const handleRangeSelect = (arg) => {
        dispatch(clearAllStatusMessageReducer());
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.unselect();
        }
        const newstartdate = dayjs(arg.start).format('M/D/YYYY');
        const newstarttime = dayjs.utc(arg.start);
        const endtimebeg = dayjs.utc(arg.start);
        const endtime = endtimebeg.add(30, 'minutes');
        const endtimeFormatted = endtime.format('YYYY-MM-DD HH:mm A');
        setInitialStartDate(newstartdate);
        setInitialStartTime(newstarttime);
        setInitialEndTime(endtime);
        dispatch(
            setApptBeingCreatedStartEnd({
                starttime: newstarttime,
                endtime: endtimeFormatted,
            })
        );

        setSelectedRange({
            start: arg.start,
            end: arg.end,
        });
        setIsModalOpen(true);
        setEventTypeAdding('normal');
    };

    const handleEventSelect = (arg) => {
        dispatch(clearAllStatusMessageReducer());
        const { event } = arg;
        const { _instance, _def } = event;
        const { extendedProps } = _def;
        const { range } = _instance;
        const { start, end } = range;
        const { is_blocked_time } = extendedProps;
        const newstart = dayjs.utc(start);
        const newstartdate = dayjs(start).format('M/D/YYYY');
        const newend = dayjs.utc(end);
        setInitialStartDate(newstartdate);
        setInitialStartTime(newstart);
        setInitialEndTime(newend);
        setSelectedEvent(extendedProps);
        setIsModalOpen(true);
        if (is_blocked_time) {
            setEventTypeAdding('block-time');
        } else {
            setEventTypeAdding('normal');
        }
    };

    const handleEventUpdate = async ({ event }) => {
        try {
            dispatch(
                updateEvent({
                    eventId: event.id,
                    update: {
                        allDay: event.allDay,
                        start: event.start,
                        end: event.end,
                    },
                })
            );
            const { _def } = event;
            const { extendedProps } = _def;
            const eventInfo = extendedProps;
            const { eventid } = eventInfo;
            setEventIdEditing(eventid);

            const eventToPass = {
                ...eventInfo,
                newstarttime: event.startStr,
                newendtime: event.endStr,
                itemsChanged: ['time'],
            };

            const sendEmailConfirmation = false;

            await updateExistingEvent(
                eventToPass,
                eventid,
                sendEmailConfirmation,
                'draggedEvent'
            )
                .then(async (res) => {
                    // check to see if there is a client in this class
                    setUpdateAllEvents(true);
                    const attendeeList = await getAttendees(eventid);

                    const { attendees } = attendeeList;
                    const attendeeListNew = attendees.map((a) => {
                        let smsAvailable = false;
                        const { mobilephone } = a;
                        if (mobilephone) {
                            if (mobilephone?.length > 2) {
                                smsAvailable = true;
                            }
                        }

                        return {
                            ...a,
                            emailIsChecked: true,
                            smsIsChecked: true,
                            shouldSendEmail: true,
                            shouldSendSms: true,
                            smsIsAvailable: smsAvailable,
                        };
                    });
                    setAttendeesAffected(attendeeListNew);

                    if (attendeeListNew?.length > 0) {
                        setShowAlertWithEmailSms(true);
                    } else {
                        setAlert({
                            msg: 'Successfully updated the appointment',
                            seconds: 5,
                            success: true,
                        });
                    }
                })
                .catch((error) => {
                    console.log('error updating the event', error);
                    setAlert({
                        msg: 'Could not update the appointment',
                        seconds: 5,
                        error: true,
                    });
                });
        } catch (err) {
            console.log('in handle event update');
            console.error(err);
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedEvent(null);
        setSelectedRange(null);
    };

    const handleEventCreate = async (data) => {
        dispatch(addEvent(data));
        handleModalClose();
    };

    const handleUpdateEvent = async (eventId, update) => {
        dispatch(updateEvent({ eventId, update }));
        handleModalClose();
    };

    const handleEventDelete = async (id) => {
        try {
            // dispatch(removeEvent(id));
            handleModalClose();
        } catch (err) {
            console.log('in handle event delete');
            console.error(err);
        }
    };

    const titleCalendar = `${capitalizeString(classNameUse)} Schedule`;
    let titleCalendarT = translate({
        id: 'appointment-schedule',
        msg: titleCalendar,
    });

    let startTime = '06:00:00';
    if (dibsStudioId === '226' || dibsStudioId === '260')
        startTime = '09:00:00';
    const handleResourceAreaHeaderDidMount = (info) => {
        // Access the DOM element of the event
        // let trackParentDiv = null;
        console.log(
            'anchorElement info is - handleResourceAreaHeaderDidMount',
            info
        );
    };
    const handleEventDidMount = (info) => {
        // Access the DOM element of the event
        // let trackParentDiv = null;
        let anchorElement = info.el;
        if (anchorElement.classList.contains('fc-event-is-background')) {
            let parentDiv = anchorElement.closest('.fc-timegrid-event-harness');
            if (parentDiv) {
                // console.log(
                //     '\n\n\nParent div style is Z INDEX',
                //     parentDiv.style.zIndex
                // );
                parentDiv.style.zIndex = '1';
                // trackParentDiv = parentDiv;
            }
        }
        if (anchorElement.classList.contains('fc-event-is-regular-event')) {
            // If it does, perform your desired operations
            // For example, changing the z-index of the parent <div>
            let parentDiv = anchorElement.closest('.fc-timegrid-event-harness');
            if (parentDiv) {
                // console.log('parentDiv.style is', parentDiv.style.inset);
                parentDiv.style.zIndex = '50';
                // trackParentDiv = parentDiv;
            }
        }
    };
    const widthofscreen = window.innerWidth;
    const getCurrentDateView = (arg) => {
        const mincalview = dayjs(arg.start);
        const maxcalview = dayjs(arg.end);
        setMinCalendarViewDate(mincalview);
        setMaxCalendarViewDate(maxcalview);
    };

    const updateAvailabilityAfterChipClick = (newArray) => {
        let tempFullEvents = [...events.events]; // create a new array
        let timeFormat = events.eventTimeFormat;
        formattedAvailability.forEach((ev) => {
            if (newArray.includes(ev.instructorId)) {
                tempFullEvents.push(ev);
            }
        });
        blockedTime.forEach((bt) => {
            if (newArray.includes(bt.trainerid)) {
                // tempFullEvents.push(bt);
                if (!tempFullEvents.some((e) => e.eventid === bt.eventid)) {
                    tempFullEvents.push(bt);
                }
            }
        });
        oneTimeBlockedEventsFromDB.forEach((ev) => {
            if (newArray.includes(ev.trainerid)) {
                if (!tempFullEvents.some((e) => e.eventid === ev.eventid)) {
                    tempFullEvents.push(ev);
                }
            }
        });
        setFormattedEventsWithBackgroundEvents({
            eventTimeFormat: timeFormat,
            events: tempFullEvents,
        });
        setAvailabilityIsBeingDisplayed(true);
    };
    const btnTextNew = `Add New ${classNameUse}`;
    const classnametouseT = translate({
        id: 'add-new-appt',
        msg: btnTextNew,
    });
    // console.log(
    //     'formattedEventsWithBackgroundEvents are',
    //     formattedEventsWithBackgroundEvents
    // );
    return (
        <MainCard
            idtouse="calendar-main-card-holder"
            title={titleCalendarT}
            secondary={
                <AddEventButton
                    text={classnametouseT}
                    clickAction={clickedAddEventButton}
                />
            }
            sx={{ borderRadius: 2 }}
        >
            <CalendarStyled theme={theme} widthofscreen={widthofscreen}>
                <Toolbar
                    date={date}
                    view={view}
                    onClickNext={handleDateNext}
                    onClickPrev={handleDatePrev}
                    onClickToday={handleDateToday}
                    handleShowAvailabilityClick={handleShowAvailabilityClick}
                    onChangeView={handleViewChange}
                    colorOfSelected={theme.palette.primary[400]}
                    defaultBgColor={theme.palette.primary.dark}
                    showAvailabilityButtonText={showAvailabilityButtonText}
                    clickedBlockTimeButton={clickedBlockTimeButton}
                    // availabilityButtonIsEnabled={availabilityButtonIsEnabled}
                />

                <AvailabilityOptions
                    showAvailability={showAvailability}
                    updateAvailabilityAfterChipClick={
                        updateAvailabilityAfterChipClick
                    }
                    instructorAvailabilityThatIsShowing={
                        instructorAvailabilityThatIsShowing
                    }
                    setInstructorAvailabilityThatIsShowing={
                        setInstructorAvailabilityThatIsShowing
                    }
                    changeInstructorAsResource={changeInstructorAsResource}
                />

                <SubCard>
                    <CalendarComponent
                        formattedEventsWithBackgroundEvents={
                            formattedEventsWithBackgroundEvents
                        }
                        calendarRef={calendarRef}
                        startTime={startTime}
                        date={date}
                        handleEventDidMount={handleEventDidMount}
                        handleResourceAreaHeaderDidMount={
                            handleResourceAreaHeaderDidMount
                        }
                        view={view}
                        handleRangeSelect={handleRangeSelect}
                        getCurrentDateView={getCurrentDateView}
                        handleEventUpdate={handleEventUpdate}
                        handleEventSelect={handleEventSelect}
                        matchSm={matchSm}
                        resources={instructorsAsResources}
                    />
                </SubCard>
            </CalendarStyled>
            <DeleteClassDialog
                isopen={notifyClientsModalOpen}
                setNotifyClientsModalOpen={setNotifyClientsModalOpen}
                attendees={attendeesAffected}
                dibsId={dibsStudioId}
                eventType={classNameUse}
            />
            {attendeesAffected?.length > 0 && (
                <UpdateClassDialog
                    isopen={showAlertWithEmailSms}
                    setShowAlertWithEmailSms={setShowAlertWithEmailSms}
                    attendees={attendeesAffected}
                    dibsId={dibsStudioId}
                    eventIdEditing={eventIdEditing}
                    eventType={classNameUse}
                />
            )}

            {/* Dialog renders its body even if not open */}
            <Dialog
                maxWidth="sm"
                fullWidth
                onClose={handleModalClose}
                open={isModalOpen}
                sx={{ '& .MuiDialog-paper': { p: 0 } }}
            >
                {isModalOpen && (
                    <AddEventForm
                        open={isModalOpen}
                        event={selectedEvent}
                        range={selectedRange}
                        onCancel={handleModalClose}
                        handleDelete={handleEventDelete}
                        handleCreate={handleEventCreate}
                        handleUpdate={handleUpdateEvent}
                        instructors={instructorsToPass}
                        initialStartDate={initialStartDate}
                        initialStartTime={initialStartTime}
                        initialEndTime={initialEndTime}
                        setUpdateAllEvents={setUpdateAllEvents}
                        setNotifyClientsModalOpen={setNotifyClientsModalOpen}
                        setAttendeesAffected={setAttendeesAffected}
                        typeOfEvent={eventTypeAdding}
                        refreshCalendar={refreshCalendar}
                        setShowAlertWithEmailSms={setShowAlertWithEmailSms}
                        setEventIdEditing={setEventIdEditing}
                    />
                )}
            </Dialog>
        </MainCard>
    );
};

export default Calendar;
