import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

// ==============================|| GET AVAILABILITY BLOCK WITH EXCEPTION ||============================== //

export const processAvailability = (av, exceptions) => {
    try {
        // combine the exceptions; if there are multiple exceptions for the same day, they will be combined into a single block
        const ogAv = av[0];
        let blocks = [
            {
                ...ogAv,
                start: dayjs.utc(ogAv.startTime, 'HH:mm'),
                end: dayjs.utc(ogAv.endTime, 'HH:mm'),
            },
        ];
        exceptions.forEach((ex) => {
            const exStart = dayjs.utc(ex.startTimeOfUnavailability, 'HH:mm:ss');
            const exEnd = dayjs.utc(ex.endTimeOfUnavailability, 'HH:mm:ss');

            blocks = blocks.reduce((newBlocks, block) => {
                if (exEnd.isBefore(block.start) || exStart.isAfter(block.end)) {
                    // No overlap, keep the block as is
                    newBlocks.push({ ...block, exceptionId: ex.id });
                } else {
                    // Adjust start and/or end times to account for the exception, potentially splitting the block
                    if (exStart.isAfter(block.start)) {
                        // Add the block before the exception
                        newBlocks.push({
                            ...block,
                            end: exStart,
                            exceptionId: ex.id,
                        });
                    }
                    if (exEnd.isBefore(block.end)) {
                        // Add the block after the exception
                        newBlocks.push({
                            ...block,
                            start: exEnd,
                            exceptionId: ex.id,
                        });
                    }
                }
                return newBlocks;
            }, []);
        });

        // Map blocks to the required format and filter out any blocks that ended up with no duration
        const result = blocks
            .map((block) => ({
                ...ogAv,
                availabilityIdNew:
                    ogAv.availabilityId * 2000 + parseInt(Math.random() * 1000),
                startTime: block.start.format('HH:mm'),
                endTime: block.end.format('HH:mm'),
                exceptionIdApplied: block.exceptionId,
            }))
            .filter((block) => block.startTime !== block.endTime);

        return result;
    } catch (err) {
        console.log(
            `error processing availabiliity for display in calendar. Error is: ${err}`
        );
    }
    // return 0;
};

export default processAvailability;
