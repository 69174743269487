import axios from 'axios';
import { getApiUrl } from 'helpers';
export default async function getPaymentInfoForUser(userid, dibsId) {
    const url = getApiUrl('/studio/get-payment-info-for-user');
    let datatosend = {};
    const getInfo = async () => {
        await axios
            .post(url, {
                userid,
                dibsId,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error getting payment info for a user: ${err}`);
                return err;
            });
    };
    await getInfo();
    return datatosend;
}
