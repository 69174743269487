import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { getApiUrl } from 'helpers';
import { getExceptionsInstructorOptions } from '../../selectors/exceptions';
import { requestStudioInstructors } from '../../actions/ExceptionsActions';
import { PACKAGE_PRODUCT_OPTIONS } from '../../constants/PackagesConstants';
import { VIDEOS_HISTORY_ROUTE } from '../../constants/RouteConstants';
import { addError, clearError } from '../../actions/ErrorActions';
import { addNotice, clearNotice } from '../../actions/NoticeActions';
import {
    setOpenStudioPackage,
    setStudioPackagesLoadingTrue,
} from '../../actions/AdminPackagesActions';
import DibsLoader from '../Layout/DibsLoader';
import InputField from 'shared/components/InputField';
import InputWithTip from 'shared/components/InputField/inputWithTip';
import SelectField from '../shared/SelectField';
import '../../styles/add-promo-code.scss';
import '../../containers/EditEvent/styles.scss';
import { constant } from 'lodash';

/**
 * AddVideo React Component
 * @extends {module:React.PureComponent}
 * @returns {JSX.Element} HTML
 */
class AddVideo extends React.PureComponent {
    /**
     * constructor
     * @constructs AddVideo
     * @param {Object} props Component props
     */
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            videoTitle: '',
            file: null,
            trainerid: 1,
            category: '',
            filenametosend: '',
            // thumbnail: '',
            description: '',
            equipment: "No addt'l equipment",
            dateRecorded: '',
            classLength: '',
            url: '',
            activeProduct: PACKAGE_PRODUCT_OPTIONS[0],
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleThumbnailChange = this.handleThumbnailChange.bind(this);
        this.handleInstructorChange = this.handleInstructorChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getInstructors = this.getInstructors.bind(this);
        this.resetState = this.resetState.bind(this);
        this.openHistory = this.openHistory.bind(this);
        this.submitNewVideo = this.submitNewVideo.bind(this);
        this.submitNewThumbnail = this.submitNewThumbnail.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }
    /**
     * componentDidMount
     * @returns {undefined}
     */
    async componentDidMount() {
        if (this.props.instructors?.length <= 1) {
            this.getInstructors().catch((err) => {
                console.log(err);
            });
        }
    }
    /**
     * getInstructors
     * @returns {Promise<undefined>} gets instructor names and saves them in state
     */
    getInstructors() {
        return new Promise((resolve, reject) => {
            this.props.requestStudioInstructors((err) => {
                if (err) return reject(err);
                return resolve();
            });
        });
    }
    /**
     * handleInstructorChange
     * @param {Object} option w label & value
     * @memberof ClassTableRow
     * @returns {undefined}
     */
    handleInstructorChange(option) {
        this.setState({
            trainerid: option.value,
            trainerName: option.label,
        });
    }
    /**
     * handleChange
     * @param {Event} event the event object
     * @param {string} event.target.name the name of the modified input
     * @param {string} event.target.value the value the user inputs
     * @returns {undefined}
     */
    handleChange({ target: { name, value: _value } }) {
        let value;
        switch (name) {
            case 'classLength':
                value = String(+_value.split(' mins').join('') || '');
                if (_value === this.state.classLength.split(' mins')[0])
                    value = value.slice(0, -5);
                if (!value) value = '';
                else value = `${value} mins`;
                break;
            default:
                value = _value;
        }
        // const value = _value;
        this.setState({ [name]: value });
    }
    /**
     * handleThumbnailChange
     * @param {e} e the file object
     * @param {string} event.target.name the name of the modified input
     * @param {string} event.target.value the value the user inputs
     * @returns {undefined}
     */
    handleThumbnailChange(e) {
        const fileState = e.target.files[0];
        this.setState({
            file: fileState,
        });
    }
    /**
     * openHistory
     * @returns {undefined}
     */
    openHistory() {
        this.context.router.push(VIDEOS_HISTORY_ROUTE);
    }
    /**
     *
     * @param {object} newValue new value to display
     * @returns {undefined}
     */
    handleSelectChange(newValue) {
        const stateChange = { activeProduct: newValue };
        this.setState(stateChange);
    }
    /**
     * handleSubmit validates form entries
     * @returns {undefined}
     */
    handleSubmit() {
        if (this.state.submitting) return;
        this.props.clearError();
        this.props.clearNotice();

        if (!this.state.videoTitle) {
            this.props.addError(
                "Don't forget to set the title for your new video."
            );
        } else {
            this.submitNewVideo();
        }
    }
    /**
     * resetState - clear state after submitting new video
     * @returns {undefined}
     */
    resetState() {
        this.setState({
            submitting: false,
            videoTitle: '',
            trainerid: 1,
            category: '',
            filenametosend: '',
            description: '',
            equipment: "No addt'l equipment",
            dateRecorded: '',
            // thumbnail: '',
            file: null,
            classLength: '',
            url: '',
            activeProduct: PACKAGE_PRODUCT_OPTIONS[0],
        });
    }
    /**
     * submitNewVideo to the server
     * @returns {undefined}
     */
    async submitNewVideo() {
        try {
            const reqBody = {
                ...this.state,
            };
            const { data } = await axios.post(getApiUrl('/studio/videos/new'), {
                reqBody,
            });
            if (data.success) {
                this.props.addNotice(
                    'Successfully added the new video. Now, you can add another video if you have one.'
                );
                this.setState({ isSubmitting: false });
                this.resetState();
            } else {
                this.props.addError(data.message);
            }
        } catch (err) {
            console.error(err);
            this.props.addError(
                'Something went wrong adding the new video. Pls try again or contact us at studios@ondibs.com'
            );
            this.setState({ isSubmitting: false });
        }
    }
    /**
     * submitNewThumbnail to the server
     * @param {e} e to be uploaded
     * @returns {undefined}
     */
    async submitNewThumbnail(e) {
        const file = this.state.file;
        const fd = new FormData();
        fd.append('image', file, file.name);
        try {
            const url = getApiUrl('/videos/thumbnail/new');
            const { data } = await axios.post(url, fd);
            if (data.success) {
                this.setState({
                    filenametosend: data.thumbnailName,
                });
                this.submitNewVideo();
            } else {
                this.props.addError(data.message);
            }
        } catch (err) {
            console.error(err);
            this.props.addError(
                'Something went wrong adding the new thumbnail. Pls try again or contact us at studios@ondibs.com'
            );
            this.setState({ isSubmitting: false });
        }
    }
    /**
     * render
     * @returns {JSX.Element} HTML
     */
    render() {
        return (
            <div className="dibs-section add-promo-code-container">
                <div className="dibs-section-heading">
                    Add A New On Demand Video
                </div>
                <div className="dibs-section-regular-text">
                    Video On Demand is here! Add your videos to your account by
                    following the prompts below.
                    <br />
                    Dibs VOD is currently integrated with Vimeo. If you have
                    another provider that you would like to use, please send us
                    an email at studios@ondibs.com.
                </div>
                <div className="dibs-input-fields first-input-container">
                    <InputField
                        label="Title"
                        name="videoTitle"
                        placeholder="e.g. STRENGTH"
                        onChange={this.handleChange}
                        value={this.state.videoTitle}
                    />
                    <div className="select-field-wrapper">
                        <SelectField
                            name="trainerid"
                            label="Instructor"
                            className="select-instructor"
                            value={this.state.trainerid}
                            onChange={this.handleInstructorChange}
                            options={this.props.instructors.filter(
                                (v) => v.value !== 0
                            )}
                            placeholder="Select an instructor"
                        />
                    </div>
                </div>
                <div className="first-input-container dibs-input-fields">
                    <InputWithTip
                        className="input-field-wider"
                        label="URL"
                        value={this.state.url}
                        tipid="urlid"
                        onChange={this.handleChange}
                        zoomtip="Where is your video hosted? Paste the direct link here."
                        name="url"
                        placeholder="e.g. https://vimeo.com/6sgaf32hh"
                    />
                    <InputWithTip
                        label="Category"
                        value={this.state.category}
                        tipid="categoryid"
                        onChange={this.handleChange}
                        zoomtip="Your clients will be able to filter your videos by category. Add the category here. Don't forget to check your spelling! We will follow the case that you use here (e.g. All upper case, all lower case, etc)"
                        name="category"
                        placeholder="e.g. Barre"
                    />
                </div>
                <div className="dibs-input-fields first-input-container">
                    <InputWithTip
                        className="input-field-wider"
                        label="Description"
                        value={this.state.description}
                        tipid="shortdescriptionid"
                        onChange={this.handleChange}
                        zoomtip="Enter a description for this video. Long descriptions are truncated to fit within the summary description, so remember to put the most important information first. The full description will be displayed on the video detail page."
                        name="description"
                        placeholder="Enter a description"
                    />
                    <InputWithTip
                        label="Length of class (in mins)"
                        value={this.state.classLength}
                        tipid="lengthclassid"
                        onChange={this.handleChange}
                        zoomtip="Enter the lenth of time for this class."
                        name="classLength"
                        placeholder="e.g. 45 mins"
                    />
                </div>
                <div className="dibs-input-fields first-input-container">
                    <InputWithTip
                        className="input-field-wider"
                        label="equipment needed"
                        value={this.state.equipment}
                        tipid="equipmentid"
                        onChange={this.handleChange}
                        zoomtip="Do your clients need to bring additional equipment? Let them know."
                        name="equipment"
                        placeholder="No addt'l equipment"
                    />
                    <InputWithTip
                        label="Date Recorded"
                        value={this.state.dateRecorded}
                        tipid="daterecordedid"
                        onChange={this.handleChange}
                        zoomtip="When was your video recorded? Your videos will be sorted by the date recorded."
                        name="dateRecorded"
                        placeholder="e.g. 4/26/21"
                    />
                </div>
                <div className="dibs-input-fields first-input-container">
                    <InputWithTip
                        className="input-field-wider"
                        label="Upload a thumbnail photo"
                        // value={this.state.thumbnail}
                        tipid="thumbnailid"
                        // onChange={this.handleThumbnailChange}
                        onChange={(e) => this.handleThumbnailChange(e)}
                        type="file"
                        zoomtip="Add a thumbnail for your video. This image will be displayed on both the summary and detail pages for your video. The size should be 1200px wide and 885px tall with resolution of 72. Questions? Contact us at studios@ondibs.com."
                        name="thumbnail"
                        accept="image/*"
                        // ref={node => this.fileinput = node}
                        placeholder="Add a thumbnail"
                    />
                </div>
                <div
                    className="dibs-submit-btn-container"
                    style={{ marginTop: 40 }}
                >
                    <button
                        className="dibs-button-special-case dibs-button-special-case-inverted"
                        onClick={this.openHistory}
                    >
                        VIEW ALL VIDEOS
                    </button>
                    <button
                        className="dibs-button"
                        onClick={(e) => this.submitNewThumbnail(e)}
                        disabled={this.state.submitting}
                    >
                        {this.state.submitting ? (
                            <DibsLoader />
                        ) : (
                            'SUBMIT VIDEO'
                        )}
                    </button>
                </div>
            </div>
        );
    }
}

AddVideo.contextTypes = {
    router: PropTypes.shape({
        push: PropTypes.func,
    }),
};

AddVideo.propTypes = {
    instructors: PropTypes.arrayOf(PropTypes.shape()),
    addError: PropTypes.func,
    clearError: PropTypes.func,
    // setOpenStudioPackage: PropTypes.func,
    // setStudioPackagesLoadingTrue: PropTypes.func,
    requestStudioInstructors: PropTypes.func,
    addNotice: PropTypes.func,
    clearNotice: PropTypes.func,
};

const mapStateToProps = (state) => ({
    // country: getUserStudioCountry(state),
    instructors: getExceptionsInstructorOptions(state),
});
const mapDispatchToProps = {
    addError,
    clearError,
    addNotice,
    clearNotice,
    setOpenStudioPackage,
    setStudioPackagesLoadingTrue,
    requestStudioInstructors,
};

const SmartAddVideo = connect(mapStateToProps, mapDispatchToProps)(AddVideo);

export default SmartAddVideo;
