import React from 'react';
import PropTypes from 'prop-types';
// import withRouter from 'helpers/withRouter';
import withRouter from 'helpers/withRouter';
// import Autosuggest from 'react-autosuggest';
import Downshift from 'downshift';
import { connect } from 'react-redux';

import { GET_CLIENT_PROFILE_ROUTE } from '../../../../constants/RouteConstants';
import {
    getRecentResults,
    getMatchingResults,
    getClientSearchTerm,
} from '../../../../selectors/client/search/index';
import {
    getSearchResults,
    clearSearchTerms,
    updateRecentSearches,
} from '../../../../actions/ClientSearchActions';

import './styles.scss';

/**
 * AutocompleteClientSearch
 * Meant to extend a searchbar, not be used on it's own
 */
class AutocompleteClientSearch extends React.PureComponent {
    /**
     * @static
     * @param {Object} section to get suggestions for
     * @returns {Array<Object>} suggestions
     */
    static getSectionSuggestions(section) {
        return section.suggestions;
    }
    /**
     * @static
     * @param {Object} section for heading
     * @returns {JSX} HTML
     */
    static renderSectionTitle(section) {
        return section.suggestions?.length > 0 ? (
            <strong className="autoselect-section-title">
                {section.title}
            </strong>
        ) : (
            <span />
        );
    }
    /**
     * @constructor
     * @param {object} props inherited props
     */
    constructor(props) {
        super(props);
        this.onSuggestionsFetchRequested =
            this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested =
            this.onSuggestionsClearRequested.bind(this);
        this.getSuggestionValue = this.getSuggestionValue.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
    }
    /**
     * Autosuggest Method - updates autosuggest each time input changes
     * @param {object} event autosuggest event
     * @param {string} event.value input value
     * @returns {undefined}
     */
    async onSuggestionsFetchRequested() {
        await this.props.getSearchResults();
    }
    /**
     * Autosuggest Method - clears suggestions
     * @returns {undefined}
     */
    onSuggestionsClearRequested() {
        this.props.clearSearchTerms();
    }
    /**
     * Autosuggest Method - When you click or hit enter on a suggestion
     * @param {object} event react event
     * @param {object} data suggestion data
     * @param {object} data.suggestion user data
     * @returns {undefined}
     */
    onSuggestionSelected(event, { suggestion }) {
        const id = suggestion.id;
        const { addUserToClass, createClient, onResultSelect } =
            this.props.customSubmitActions;

        switch (true) {
            case id === -1 && typeof createClient === 'function':
                createClient();
                break;
            case typeof addUserToClass === 'function':
                addUserToClass(id);
                break;
            case typeof onResultSelect === 'function':
                onResultSelect(id);
                break;
            default:
                this.props.router.push(GET_CLIENT_PROFILE_ROUTE(id));
        }

        this.props.clearSearchTerms();
        this.props.updateRecentSearches(id);
    }
    /**
     * @returns {Array<Object>} suggestions for search
     */
    getSuggestions() {
        const result = [];
        if (this.props.matches?.length) {
            result.push({
                title: 'All Matches',
                suggestions: this.props.matches,
            });
        }
        if (this.props.recents?.length) {
            result.push({
                title: 'Recent Searches',
                suggestions: this.props.recents,
            });
        }
        if (
            this.props.newUserEnabled &&
            this.props.searchValue &&
            this.props.searchValue?.length >= 3
        ) {
            result.push({
                title: 'New Client',
                suggestions: [
                    { id: -1, firstName: 'Add', lastName: 'new client' },
                ],
            });
        }
        return result;
    }
    /**
     * Autosuggest Method - Needed or everything breaks
     * @param {object} value data from autosuggest
     * @return {string} suggestion value
     */
    getSuggestionValue(value) {
        if (!value) return '';
        return `${value.firstName} ${value.lastName}`;
    }
    /**
     * Autosuggest Method - autosuggest lines
     * @param {object} suggestion value of suggestion in state
     * @returns {jsx} individual autocomplete lines
     */
    renderSuggestion(suggestion) {
        return (
            <div>
                {suggestion.firstName} {suggestion.lastName}
                <div className="autosuggest-custom-icon">{this.props.icon}</div>
                <div className="autosuggest-email-line">{suggestion.email}</div>
            </div>
        );
    }
    /**
     * Render Method
     * @returns {jsx} html
     */
    render() {
        return (
            <Downshift
                highlightFirstSuggestion
                suggestions={this.getSuggestions()}
                inputProps={{
                    placeholder: "Type a client's name or phone #",
                    value: String(this.props.value),
                    onChange: this.props.onChange,
                    onFocus: this.onSuggestionsFetchRequested,
                    onBlur: this.onSuggestionsClearRequested,
                }}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                multiSection
                renderSectionTitle={AutocompleteClientSearch.renderSectionTitle}
                getSectionSuggestions={
                    AutocompleteClientSearch.getSectionSuggestions
                }
                onSuggestionSelected={this.onSuggestionSelected}
                shouldRenderSuggestions={() => true}
            />
        );
    }
}

AutocompleteClientSearch.defaultProps = {
    icon: null,
    newUserEnabled: false,
    customSubmitActions: {},
};

AutocompleteClientSearch.propTypes = {
    router: PropTypes.shape({
        push: PropTypes.func,
    }),
    customSubmitActions: PropTypes.shape(),
    icon: PropTypes.element,
    value: PropTypes.string,
    onChange: PropTypes.func,
    matches: PropTypes.arrayOf(PropTypes.shape()),
    recents: PropTypes.arrayOf(PropTypes.shape()),
    getSearchResults: PropTypes.func,
    updateRecentSearches: PropTypes.func,
    clearSearchTerms: PropTypes.func,
    newUserEnabled: PropTypes.bool,
    searchValue: PropTypes.string,
};

const mapStateToProps = (state) => ({
    recents: getRecentResults(state),
    matches: getMatchingResults(state),
    term: getClientSearchTerm(state),
});

const mapDispatchToProps = {
    getSearchResults,
    updateRecentSearches,
    clearSearchTerms,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AutocompleteClientSearch)
);
