import React from 'react';
import { Grid, FormGroup } from '@mui/material';

import IndiCheckbox from 'ui-component/checkboxes/indiCheckbox';

const ShowMultiCheckboxes = ({
    idtouse,
    checkboxes,
    handleCheckboxChange,
    beingCreatedInfo,
    questionCriteria,
    setTrueFalseFields,
    setMultiCriteriaChosen,
    multiCriteriaChosen,
}) => {
    return (
        <Grid container id={`checkbox-container-${idtouse}`}>
            <Grid item xs={11} id="checkbox-multi-holder">
                <FormGroup>
                    <Grid container justifyContent="space-evenly">
                        {checkboxes.map((checkbox) => {
                            return (
                                <Grid
                                    item
                                    key={`checkbox-item-${checkbox?.id}-${checkbox?.codeValue}`}
                                    xs={checkbox?.needsOwnLine ? 12 : true}
                                    id={`checkbox-item${checkbox?.needsOwnLine ? '-ownline' : ''}-${checkbox?.id}-${checkbox?.codeValue}`}
                                    sx={{ mb: 2 }}
                                >
                                    <IndiCheckbox
                                        handleCheckboxChange={
                                            handleCheckboxChange
                                        }
                                        idtouse={idtouse}
                                        checkbox={checkbox}
                                        beingCreatedInfo={beingCreatedInfo}
                                        questionCriteria={questionCriteria}
                                        setTrueFalseFields={setTrueFalseFields}
                                        setMultiCriteriaChosen={
                                            setMultiCriteriaChosen
                                        }
                                        multiCriteriaChosen={
                                            multiCriteriaChosen
                                        }
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </FormGroup>
            </Grid>
        </Grid>
    );
};
export default ShowMultiCheckboxes;
