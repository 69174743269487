import axios from 'axios';
import { getApiUrl } from 'helpers';

// ==============================|| GET ALL EXCEPTIONS ||============================== //

export const getAllExceptions = async (info) => {
    try {
        const response = await axios.post(
            getApiUrl('/studio/calendar/get-all-exceptions'),
            info
        );

        return response;
    } catch (err) {
        console.log(`error adding exception to db. Error is: ${err}`);
    }
    return 0;
};

export default getAllExceptions;
