import { ADD_NOTICE, CLEAR_NOTICE } from "../constants/NoticeConstants";

/**
 * addNotice
 * @param {string} notice notice content
 * @returns {Object} action on the state
 */
export function addNotice(notice) {
  return { type: ADD_NOTICE, notice };
}

/**
 * clearNotice
 * @returns {Object} action on the state
 */
export function clearNotice() {
  return { type: CLEAR_NOTICE };
}
