import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import DibsLoader from "../DibsLoader";
import "../../styles/modal.scss";

/**
 * Modal React Pure Component
 * @extends {module:React.Component}
 * @returns {JSX.Element} HTML
 */
class Modal extends React.Component {
  /**
   * @static
   * @param {Event} ev click event
   * @returns {undefined}
   */
  static stopEventPropagation(ev) {
    return ev.stopPropagation();
  }
  /**
   * @constructor
   * @constructs Modal
   * @param {Object} props for component
   */
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }
  /**
   * @returns {undefined}
   */
  onClose() {
    if (!this.props.canCancel) return;
    if (this.props.handleClose) this.props.handleClose();
  }
  /**
   * render
   * @returns {JSX.Element} HTML
   */
  render() {
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <div
        className={this.props.open ? "modal open" : "modal"}
        onClick={this.onClose}
      >
        <div className="modal__content" onClick={Modal.stopEventPropagation}>
          <div className="modal__header">{this.props.header}</div>
          <div className="modal__body">
            {this.props.loading ? (
              <DibsLoader reverseColor />
            ) : (
              this.props.children
            )}
          </div>
          <div className="modal__divider" />
          <div className="modal__buttons">
            <button
              className={classNames(
                this.props.canSubmit ? "modal__submit" : "not-submittable",
              )}
              onClick={this.props.handleSubmit}
            >
              {this.props.okText || "OK"}
            </button>
            {this.props.canCancel && (
              <button className="modal__close" onClick={this.onClose}>
                {this.props.cancelText || "CANCEL"}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Modal.defaultProps = {
  canCancel: true,
  canSubmit: true,
};

Modal.propTypes = {
  canCancel: PropTypes.bool,
  open: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  header: PropTypes.string,
  loading: PropTypes.bool,
  canSubmit: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
};

export default Modal;
