import { useEffect } from 'react';
// material-ui
import { Grid, Paper, Box } from '@mui/material';
import FrontDeskTabs from 'components/entry-pages/front-desk';

import { dispatch } from 'store';
import { setShowClientSearchTop } from 'store/slices/menu';

// project imports

// ==============================|| FrontDesk Page ||============================== //

const FrontDesk = () => {
    console.log('front desk page is being loaded');

    useEffect(() => {
        console.log('front desk page');
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        // dispatch(setShowClientSearchTop(true));
    }, []);
    return (
        <Paper
            sx={{
                bgcolor: '#ffffff',
                borderRadius: 2,
                elevation: 0,
            }}
        >
            <Grid container>
                <Box
                    id="front-desk-tabs-box"
                    xs={12}
                    sx={{
                        marginTop: '20px',
                        marginLeft: '20px',
                        marginRight: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                    }}
                >
                    <Grid item xs={12} id="grid-front-desk-tabs-view">
                        <FrontDeskTabs />
                    </Grid>
                </Box>
            </Grid>
        </Paper>
    );
};

export default FrontDesk;
