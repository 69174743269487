// to do - check if this is even being run - still needed?
import { createTheme, alpha } from '@mui/material/styles';

export default function componentStyleOverrides(
    theme,
    borderRadius,
    outlinedFilled
) {
    const mode = theme.palette.mode;
    const bgColor =
        mode === 'dark' ? theme.palette.dark[800] : theme.palette.grey[50];
    const menuSelectedBack =
        mode === 'dark'
            ? theme.palette.secondary.main + 15
            : theme.palette.secondary.light;
    const menuSelected =
        mode === 'dark'
            ? theme.palette.secondary.main
            : theme.palette.secondary.dark;
    const buttonColor = theme.palette.primary.dark;
    const buttonHoverColor = theme.palette.primary[400];
    const borderColor = theme.palette.primary.slightlyDarker;
    // const buttonHoverColor = theme.palette.secondary.main;
    const checkboxcolor = theme.palette.secondary.notaslight;
    const checkboxChecked = theme.palette.secondary.main;
    let dialogMinWidth = '475px';
    if (window.screen.width < 500) {
        dialogMinWidth = `${window.screen.width - 10}px`;
    }
    return createTheme({
        ...theme,
        components: {
            ...theme.components,
            MuiButton: {
                styleOverrides: {
                    root: {
                        // fontWeight: 700,
                        borderRadius: '5px',
                        borderColor: borderColor,
                        color: '#fff',
                        // fontSize: '14px',
                        backgroundColor: buttonColor,
                        '&:hover': {
                            backgroundColor: buttonHoverColor,
                        },
                        height: '33px',
                        // minWidth: '150px',
                        paddingLeft: '14px',
                        paddingRight: '14px',
                        boxShadow: 'none',
                    },
                    buttonInverse: {
                        borderColor: buttonColor,
                        backgroundColor: '#fff',
                        color: buttonColor,
                        border: '2px solid',
                    },
                    iconSizeSmall: {
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        color: '#ffffff',
                        fontSize: '10px',
                        marginTop: '5px',
                        '&.Mui-disabled': {
                            color: '#fff000',
                        },
                    },
                    textButton: {
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        color: '#e7b2a5',
                        backgroundColor: 'transparent',
                        // color: '#e7b2a5',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: '#bd8d81',
                        },
                        // fontSize: '10px',
                        marginTop: '5px',
                        '&.Mui-disabled': {
                            color: '#fff000',
                        },
                    },
                    textButtonSecondary: {
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        color: '#616161',
                        backgroundColor: 'transparent',
                        // color: '#e7b2a5',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: '#999999',
                        },
                        // fontSize: '10px',
                        marginTop: '5px',
                        '&.Mui-disabled': {
                            color: '#fff000',
                        },
                    },
                    checkout: {
                        backgroundColor: theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.light,
                        },
                    },
                    checkoutinverse: {
                        backgroundColor: '#fff',
                        color: '#e7b2a5',
                        borderColor: '#e7b2a5',
                        border: '1px solid',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.light,
                        },
                    },
                    greeninverse: {
                        backgroundColor: '#fff',
                        color: theme.palette.globalcolors.hoverSubmit,
                        borderColor: theme.palette.globalcolors.hoverSubmit,
                        border: '1px solid',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.light,
                        },
                    },
                    cancel: {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.globalcolors.cancel,
                        '&:hover': {
                            backgroundColor: theme.palette.globalcolors.hover,
                        },
                    },
                    greenconfirm: {
                        backgroundColor: theme.palette.globalcolors.submit,
                        '&:hover': {
                            backgroundColor:
                                theme.palette.globalcolors.hoverSubmit,
                        },
                    },
                    tinygreenconfirm: {
                        backgroundColor: theme.palette.globalcolors.submit,
                        '&:hover': {
                            backgroundColor:
                                theme.palette.globalcolors.hoverSubmit,
                        },
                        margin: '5px',
                        minWidth: '25px',
                        paddingTop: '4px',
                        paddingBottom: '4px',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        height: '25px',
                        fontSize: '12px',
                    },
                    edit: {
                        backgroundColor: buttonColor,
                        '&:hover': {
                            backgroundColor: buttonHoverColor,
                        },
                    },
                    confirm: {
                        backgroundColor: theme.palette.globalcolors.submit,
                        '&:hover': {
                            backgroundColor:
                                theme.palette.globalcolors.hoverSubmit,
                        },
                    },
                    deleteEvent: {
                        backgroundColor: theme.palette.error.light,
                        lineHeight: 1,
                        height: '25px',
                        '&:hover': {
                            backgroundColor: theme.palette.error.light,
                        },
                    },
                    deleteEventCancel: {
                        backgroundColor: '#fff',
                        lineHeight: 1,
                        color: '#333333',
                        height: '25px',
                        border: 1,
                        borderColor: '#333333',
                        '&:hover': {
                            backgroundColor: theme.palette.globalcolors.cancel,
                        },
                    },
                },
            },
            MuiLoadingButton: {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.globalcolors.submit,
                        '&.Mui-disabled': {
                            backgroundColor: buttonHoverColor,
                        },
                        '&:hover': {
                            backgroundColor:
                                theme.palette.globalcolors.hoverSubmit,
                        },
                    },
                },
            },

            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        marginBottom: '5px',
                        marginRight: '10px',
                    },
                },
            },

            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        background: bgColor,
                        borderRadius: `${borderRadius}px`,
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor:
                                mode === 'dark'
                                    ? theme.palette.text.primary + 28
                                    : theme.palette.grey[400],
                        }, // this may be where border color is set - hint
                        '&:hover $notchedOutline': {
                            borderColor: theme.palette.primary.light,
                        },
                        '&.MuiInputBase-multiline': {
                            padding: 1,
                        },
                    },
                    input: {
                        fontWeight: 400,
                        background: bgColor,
                        padding: '12px 12px',
                        borderRadius: `${borderRadius}px`,
                        '&.MuiInputBase-inputSizeSmall': {
                            padding: '9px 9px',
                            '&.MuiInputBase-inputAdornedStart': {
                                paddingLeft: 0,
                            },
                        },
                    },
                    inputAdornedStart: {
                        paddingLeft: 4,
                    },
                    notchedOutline: {
                        borderRadius: `${borderRadius}px`,
                    },
                },
            },
            MuiDatePicker: {
                styleOverrides: {
                    root: {
                        padding: '0px',
                        '&.Mui-OutlinedInput-input': {
                            fontWeight: 100,
                            background: outlinedFilled
                                ? bgColor
                                : 'transparent',
                            padding: '0px 0px',
                            borderRadius: `${borderRadius}px`,
                            '&.MuiInputBase-inputSizeSmall': {
                                padding: '2px 2px',
                                '&.MuiInputBase-inputAdornedStart': {
                                    paddingLeft: 0,
                                },
                            },
                        },
                    },
                    input: {
                        padding: '0px 0px',
                        color: theme.palette.text.dark,
                        '&::placeholder': {
                            color: theme.palette.text.secondary,
                            fontSize: '0.875rem',
                        },
                        paddingTop: '0px',
                    },
                },
            },
            MuiPaper: {
                defaultProps: {
                    elevation: 0,
                },
                styleOverrides: {
                    root: {
                        backgroundImage: 'none',
                        '&.MuiDialog-paper': {
                            minWidth: dialogMinWidth,
                            borderRadius: `${borderRadius}px`,
                        },
                    },
                    rounded: {
                        borderRadius: `${borderRadius}px`,
                    },
                    productCards: {
                        color: theme.palette.grey[650],
                        backgroundColor: theme.palette.primary.light,
                    },
                    instructorCards: {
                        borderColor: theme.palette.primary.light,
                    },
                },
            },

            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        color: theme.palette.text.dark,
                        padding: '13px',
                        paddingTop: '24px',
                        paddingBottom: '24px',
                    },
                    title: {
                        fontSize: '1.125rem',
                    },
                },
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        padding: '10px',
                    },
                },
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        padding: '10px',
                    },
                },
            },
            MuiAlert: {
                styleOverrides: {
                    root: {
                        alignItems: 'center',
                    },
                    outlined: {
                        border: '1px dashed',
                    },
                },
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        color: theme.palette.text.primary,
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        '&.Mui-selected': {
                            color: menuSelected,
                            backgroundColor: menuSelectedBack,
                            '&:hover': {
                                backgroundColor: menuSelectedBack,
                            },
                            '& .MuiListItemIcon-root': {
                                color: menuSelected,
                            },
                        },
                        '&:hover': {
                            backgroundColor: menuSelectedBack,
                            color: menuSelected,
                            '& .MuiListItemIcon-root': {
                                color: menuSelected,
                            },
                        },
                    },
                },
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        color: theme.palette.text.primary,
                        minWidth: '30px',
                    },
                },
            },
            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        color: theme.palette.text.dark,
                    },
                },
            },

            MuiInputBase: {
                styleOverrides: {
                    input: {
                        color: theme.palette.text.dark,
                        '&::placeholder': {
                            color: theme.palette.text.secondary,
                            fontSize: '0.875rem',
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    label: {
                        color: theme.palette.text.dark,
                        '&.Mui-disabled': {
                            color: theme.palette.text.dark,
                            fontSize: '0.875rem',
                        },
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        top: '10px',
                    },
                    '&.MuiInputLabel': {
                        paddingTop: '10px',
                    },
                },
            },
            MuiSlider: {
                styleOverrides: {
                    root: {
                        '&.Mui-disabled': {
                            color:
                                mode === 'dark'
                                    ? theme.palette.text.primary + 50
                                    : theme.palette.grey[300],
                        },
                        color: theme.palette.primary.dark,
                    },
                    mark: {
                        backgroundColor: theme.palette.background.paper,
                        width: '4px',
                    },
                    valueLabel: {
                        color: theme.palette.primary.light,
                        backgroundColor: theme.palette.primary.dark,
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput': {
                            padding: '0px',
                        },
                        '& .MuiAutocomplete-tag': {
                            background:
                                mode === 'dark'
                                    ? theme.palette.text.primary + 20
                                    : theme.palette.secondary.light,
                            borderRadius: 4,
                            color: theme.palette.text.dark,
                            '.MuiChip-deleteIcon': {
                                color:
                                    mode === 'dark'
                                        ? theme.palette.text.primary + 80
                                        : theme.palette.secondary[200],
                            },
                        },
                    },
                    popper: {
                        borderRadius: `${borderRadius}px`,
                        boxShadow:
                            '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        borderColor: theme.palette.divider,
                        opacity: mode === 'dark' ? 0.2 : 1,
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        '&:focus': {
                            backgroundColor: 'transparent',
                            border: '2px',
                        },
                    },
                },
            },
            MuiRadio: {
                styleOverrides: {
                    root: {
                        size: 'medium',
                        color: checkboxcolor,
                        '&.Mui-checked': {
                            color: checkboxChecked,
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: 14,
                        },
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: checkboxcolor,
                        '&.Mui-checked': {
                            color: checkboxChecked,
                        },
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        color: theme.palette.primary.dark,
                        background: theme.palette.primary[200],
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        '&.MuiChip-deletable .MuiChip-deleteIcon': {
                            color: 'inherit',
                        },

                        background: theme.palette.primary.dark,
                        '&.MuiChip-clickable:hover': {
                            background: alpha(theme.palette.primary.dark, 0.75),
                        },
                    },
                    filled: {
                        color: '#fff',
                        background: theme.palette.primary.dark,
                        '&::hover': {
                            background: theme.palette.primary.dark,
                        },
                    },
                    outlined: {
                        background: '#fff',
                        borderColor: theme.palette.primary.dark,
                        '&.MuiChip-clickable:hover': {
                            background: theme.palette.primary.light,
                        },
                    },
                },
            },

            MuiTimelineContent: {
                styleOverrides: {
                    root: {
                        color: theme.palette.text.dark,
                        fontSize: '16px',
                    },
                },
            },
            MuiTreeItem: {
                styleOverrides: {
                    label: {
                        marginTop: 14,
                        marginBottom: 14,
                    },
                },
            },
            MuiTimelineDot: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    textColorInherit: {
                        fontWeight: 500,
                        color: theme.palette.grey[600],
                        '&.Mui-selected': {
                            fontWeight: 600,
                            opacity: 0.85,
                        },
                        '&:hover': {
                            fontWeight: 500,
                            color: theme.palette.grey[600],
                            opacity: 0.8,
                        },
                    },
                },
            },
            MuiInternalDateTimePickerTabs: {
                styleOverrides: {
                    tabs: {
                        backgroundColor:
                            mode === 'dark'
                                ? theme.palette.dark[900]
                                : theme.palette.primary.light,
                        '& .MuiTabs-flexContainer': {
                            borderColor:
                                mode === 'dark'
                                    ? theme.palette.text.primary + 20
                                    : theme.palette.primary[200],
                        },
                        '& .MuiTab-root': {
                            color:
                                mode === 'dark'
                                    ? theme.palette.text.secondary
                                    : theme.palette.grey[900],
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                        '& .Mui-selected': {
                            color: theme.palette.primary.dark,
                        },
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    flexContainer: {
                        borderBottom: '1px solid',
                        borderColor:
                            mode === 'dark'
                                ? theme.palette.text.primary + 20
                                : theme.palette.grey[200],
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        padding: '12px 0 12px 0',
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        borderColor:
                            mode === 'dark'
                                ? theme.palette.text.primary + 15
                                : theme.palette.grey[200],
                        fontSize: '0.775rem',
                        '&.MuiTableCell-head': {
                            fontSize: '0.775rem',
                            color: theme.palette.grey[600],
                            fontWeight: 500,
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderColor:
                            mode === 'dark'
                                ? theme.palette.text.primary + 15
                                : theme.palette.grey[200],
                        fontSize: '0.775rem',
                        '&.MuiTableCell-head': {
                            fontSize: '0.8rem',
                            // color: theme.palette.grey[600],
                            color: theme.palette.secondary.main,
                            fontWeight: 500,
                            background: theme.palette.grey[100],
                        },
                    },
                    exceptions: {
                        border: '0px solid',
                        fontWeight: 500,
                        padding: '8px 8px',
                        '&.MuiTableCell-head': {
                            fontSize: '0.8rem',
                            // color: theme.palette.grey[600],
                            color: theme.palette.secondary.main,
                            background: '#ff6900',
                            borderColor: '#ff6900',
                        },
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        background: theme.palette.grey[200],
                        color: theme.palette.text.primary,
                        fontSize: '0.775rem',
                    },
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        fontSize: '1.25rem',
                        paddingTop: '25px',
                    },
                },
            },
        },
    });
}
