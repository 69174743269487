import axios from 'axios';
import { getApiUrl } from 'helpers';
// import moment from 'moment-timezone';

// ==============================|| DEACTIVATE MEMBERSHIP OR PACKAGE FOR STUDIO ||============================== //

export const DeactivateMembershipsPackages = async (packageid) => {
    try {
        const response = await axios.post(
            getApiUrl('/studio/deactivate-package'),
            {
                packageid,
            }
        );
        if (response.data.msg === 'success') {
            return {
                msg: 'success',
            };
        }
        console.log(
            `Deactivate MEMBERSHIP OR PACKAGE Error: ${JSON.stringify(
                response.data
            )}`
        );
        return { msg: 'failure', error: response.data.error };
    } catch (err) {
        console.log(
            `error deactivating promo code for promoid: ${packageid}\nerr is: ${err}`
        );
    }
    return 0;
};

export default DeactivateMembershipsPackages;
