/**
 * routeReplacer - Description
 *
 * @param {type} str    Description
 * @param {type} mapObj Description
 *
 * @returns {type} Description
 */
function routeReplacer(str, mapObj) {
  const re = new RegExp(Object.keys(mapObj).join("|"), "gi");
  return str.replace(re, (matched) => mapObj[matched.toLowerCase()]);
}

export default routeReplacer;
