import Decimal from "decimal.js";
// import { format as formatCurrency } from 'currency';
import { createSelector } from "reselect";
import {
  getUserStudioCurrency,
  getPrimaryLocationRetailTaxes,
} from "../user/index";
import { getClientTotalCredits } from "../client";
import getModifiedCharge from "actions/stripe/getModifiedCharge";
import {
  PROMO_TYPE_PERCENT_OFF,
  PROMO_TYPE_CASH_OFF,
} from "../../constants/PromoCodesConstants";
import { getClientRetailAppliedPromo } from "../client/cart";

/**
 *
 * @param {object} state redux state
 * @returns {object} retail state
 */
export function getRetailState(state) {
  return state.retail || {};
}

/**
 *
 * @param {object} state  redux state
 * @returns {array} list of products
 */
export function getProducts(state) {
  return (getRetailState(state).all || []).filter(
    ({ deletedAt }) => !deletedAt,
  );
}

/**
 *
 * @param {object} state  redux state
 * @returns {array} list of products
 */
export function getDeletedProducts(state) {
  return (getRetailState(state).all || []).filter(
    ({ deletedAt }) => deletedAt !== null,
  );
}

export const getFormattedProducts = createSelector(
  [getProducts, getUserStudioCurrency],
  (products, currency) =>
    products.map((product) => ({
      ...product,
      price: formatCurrency(product.price, { code: currency }),
    })),
);

export const getFormattedProductsForRoster = createSelector(
  getFormattedProducts,
  (products) => products.filter((product) => product.display_on_roster),
);
const formatCurrency = (price, { code }) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: code,
  }).format(price);
};

/**
 *
 * @param {object} state  redux state
 * @returns {number} active product id
 */
export function getActiveProductId(state) {
  return getRetailState(state).confirmId || null;
}

export const getActiveRetailProduct = createSelector(
  [getActiveProductId, getProducts],
  (id, products) => products.find((product) => product.id === id) || {},
);

export const getActiveRetailProductName = createSelector(
  getActiveRetailProduct,
  (product) => product.name || "",
);

export const getActiveRetailProductId = createSelector(
  getActiveRetailProduct,
  (product) => product.id || null,
);

/**
 *
 * @param {object} state  redux state
 * @returns {boolean} confirming status
 */
export function getRetailProductIsConfirming(state) {
  return getRetailState(state).confirming;
}

/**
 *
 * @param {object} state  redux state
 * @returns {boolean} purchasing status
 */
export function getRetailProductIsPurchasing(state) {
  return getRetailState(state).purchasing;
}

export const getActiveProductPrice = createSelector(
  getActiveRetailProduct,
  (product) => product.price || 0,
);

export const getActiveProductIsTaxable = createSelector(
  getActiveRetailProduct,
  (product) => product.taxable,
);

export const getFormattedActiveProductBreakdown = createSelector(
  [
    getActiveProductPrice,
    getActiveProductIsTaxable,
    getUserStudioCurrency,
    getPrimaryLocationRetailTaxes,
    getClientTotalCredits,
    getClientRetailAppliedPromo,
  ],
  (price, taxable, currency, taxes, totalCredits, promoCode) => {
    let promoCodeAmount = 0;
    switch (promoCode.type) {
      case PROMO_TYPE_PERCENT_OFF:
        promoCodeAmount = Decimal(promoCode.amount)
          .dividedBy(100)
          .times(price)
          .toDecimalPlaces(2)
          .toNumber();
        break;
      case PROMO_TYPE_CASH_OFF:
        promoCodeAmount = Math.min(promoCode.amount, price);
        break;
      default:
        break;
    }
    const promofiedPrice = +getModifiedCharge(Decimal(price), promoCodeAmount);

    const taxAmount = taxable
      ? +Decimal(promofiedPrice).times(Decimal(taxes).dividedBy(100))
      : 0;

    const modifiedPrice = +getModifiedCharge(
      Decimal(promofiedPrice).plus(taxAmount),
      totalCredits,
    );
    const creditsSpent = +Decimal(promofiedPrice)
      .plus(taxAmount)
      .minus(modifiedPrice);

    return {
      price,
      promoCodeAmount,
      formattedPromoCodeAmount: formatCurrency(promoCodeAmount, {
        code: currency,
      }),
      formattedPrice: formatCurrency(price, { code: currency }),
      appliedPromoCode: promoCode.code,
      taxAmount,
      formattedTaxAmount: formatCurrency(taxAmount, { code: currency }),
      formattedPromofiedPrice: formatCurrency(promofiedPrice, {
        code: currency,
      }),
      formattedPromoAmount: formatCurrency(promoCodeAmount, {
        code: currency,
      }),
      formattedModifiedPrice: formatCurrency(modifiedPrice, {
        code: currency,
      }),
      totalCredits,
      promofiedPrice,
      creditsSpent,
      formattedCreditsSpent: formatCurrency(creditsSpent, {
        code: currency,
      }),
      modifiedPrice,
    };
  },
);
