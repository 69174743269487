// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
// import axios from 'axios';
// import { dispatch } from '../index';
// import { useDispatch } from 'react-redux';

// ----------------------------------------------------------------------

const initialState = {
    studioEmployeeId: 0,
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    accountType: 'unassociated-user',
    role: 'any-user',
    admin: false,
    instructor_only: false,
    permissions: [],
    financialAccess: false,
    settingsAccess: false,
    profilePicture: '',
    dibsStudioId: 0,
    managesFullSchedule: true,
    doneLoading: false,
};

const thisuser = createSlice({
    name: 'thisuser',
    initialState,
    reducers: {
        setThisUserEmail(state, action) {
            state.email = action.payload;
        },
        setUserQualifiesPromos(state, action) {
            return {
                ...state,
                userQualifiesPromos: action.payload,
            };
        },
        setThisUserRole(state, action) {
            state.role = action.payload;
        },
        setThisUserAdmin(state, action) {
            state.admin = action.payload;
        },
        setThisUserInstructorOnly(state, action) {
            state.instructor_only = action.payload;
        },
        setThisUserDoneLoading(state, action) {
            state.doneLoading = action.payload;
        },
        setThisUserPermissions(state, action) {
            state.permissions = action.payload;
        },
        setThisUserEmployeeId(state, action) {
            state.studioEmployeeId = action.payload;
        },
        setThisUserFirstName(state, action) {
            state.firstname = action.payload;
        },
        setThisUserDibsStudioId(state, action) {
            state.dibsStudioId = action.payload;
        },
        setThisUserLastName(state, action) {
            state.lastname = action.payload;
        },
        setThisUserPhone(state, action) {
            state.phone = action.payload;
        },
        setHasFinancialAccess(state, action) {
            state.financialAccess = action.payload;
        },
        setHasSettingsAccess(state, action) {
            state.settingsAccess = action.payload;
        },
        setAvailablePasses(state, action) {
            return {
                ...state,
                passes: action.payload,
            };
        },
        setIsNewUser(state, action) {
            return {
                ...state,
                isNewUser: action.payload,
            };
        },
        setManagesSchedule(state, action) {
            state.managesFullSchedule = action.payload;
        },
        setThisUserProfilePicture(state, action) {
            state.profilePicture = action.payload;
        },
        setThisUserAccountType(state, action) {
            state.accountType = action.payload;
        },
        clearThisUser(state, action) {
            state.studioEmployeeId = 0;
            state.email = '';
            state.firstname = '';
            state.lastname = '';
            state.phone = '';
            state.accountType = 'unassociated-user';
            state.role = 'any-user'; // 'admin', 'employee', 'instructor', 'any-user', 'dibs-super-admin'
            state.permissions = [];
            state.financialAccess = false;
            state.settingsAccess = false;
            state.profilePicture = '';
            state.dibsStudioId = 0;
            state.managesFullSchedule = true;
            state.doneLoading = false;
        },
        setAvailableCredit(state, action) {
            return {
                ...state,
                credit: action.payload,
            };
        },
        setUpcomingClasses(state, action) {
            return {
                ...state,
                upcomingClasses: action.payload,
            };
        },
    },
});

// Reducer
export default thisuser.reducer;
export const {
    setThisUserEmail,
    setThisUserFirstName,
    setThisUserLastName,
    setThisUserPhone,
    setUpcomingClasses,
    clearThisUser,
    setThisUserEmployeeId,
    setThisUserAdmin,
    setThisUserInstructorOnly,
    setThisUserRole,
    setThisUserPermissions,
    setHasFinancialAccess,
    setHasSettingsAccess,
    setThisUserDibsStudioId,
    setThisUserProfilePicture,
    setManagesSchedule,
    setThisUserAccountType,
    setThisUserDoneLoading,
    setUserQualifiesPromos,
    setAvailablePasses,
    setAvailableCredit,
    setIsNewUser,
} = thisuser.actions;

// ----------------------------------------------------------------------
