const moment = require("moment");

export default async function setPassesWithEventsCartView(
  itemsInCart,
  packageItemsInCart,
  passes,
  credit,
) {
  const sortingArray = [...passes];
  const sortedPasses = sortingArray
    .sort((a, b) => {
      if (!a.autopay && !b.autopay) return 0;
      if (a.autopay && !b.autopay) return -1;
      if (!a.autopay && b.autopay) return 1;
      return moment(a.expiresAt) - moment(b.expiresAt);
    })
    .sort((a, b) => {
      return moment(a.expiresAt) - moment(b.expiresAt);
    });

  const sortedPassesWithRemainingUses = sortedPasses.map((pass) => {
    let totalUsesRemaining = 0;
    if (pass.totalUses === null) {
      totalUsesRemaining = 9999;
    } else {
      totalUsesRemaining = pass.totalUses - pass.usesCount;
    }
    return { ...pass, totalUsesRemaining };
  });
  const eventsWithPassesAttached = itemsInCart.map((item) => {
    let quantityCovered = 0;
    let quantityNeededToCover = 1;
    let payMethod = {
      passid: 0,
      method: "creditcard",
      passname: null,
      quantity: item.quantity,
      amountToCharge: {
        subtotal: item.price,
        tax: item.itemtax,
        total: item.price + item.itemtax,
        discount: 0,
      },
    };
    sortedPassesWithRemainingUses.map((pass) => {
      quantityNeededToCover = item.quantity;
      if (
        pass.private_pass === item.private &&
        quantityCovered < quantityNeededToCover
      ) {
        if (pass.totalUsesRemaining >= item.quantity) {
          payMethod = {
            passid: pass.id,
            method: "pass",
            passname: pass.studioPackage.packageName,
            quantity: item.quantity,
            numItemsNotCovered: 0,
            amountToCharge: {
              subtotal: 0,
              tax: 0,
              total: 0,
              discount: 0,
            },
          };
          quantityCovered += item.quantity;
          pass.totalUsesRemaining -= item.quantity;
        } else {
          const numItemsNotCovered = item.quantity - pass.totalUsesRemaining;
          const subtotalRemaining = numItemsNotCovered * item.price;
          const taxRemaining = numItemsNotCovered * item.itemtax;
          const totalRemaining = subtotalRemaining + taxRemaining;
          payMethod = {
            passid: pass.id,
            method: "passAndCreditCard",
            passname: pass.studioPackage.packageName,
            quantity: pass.totalUsesRemaining,
            amountToCharge: {
              subtotal: subtotalRemaining,
              tax: taxRemaining,
              total: totalRemaining,
              discount: 0,
            },
            numItemsNotCovered: numItemsNotCovered,
            specialText: `You do not have enough passes to cover all of the appointments in your cart. You will be charged $${totalRemaining} for the remaining ${numItemsNotCovered} items.`,
          };
          quantityCovered += pass.totalUsesRemaining;
        }
      }
      return pass;
    });
    return { ...item, howtopay: payMethod };

    // return item;
  });
  return eventsWithPassesAttached;
}
