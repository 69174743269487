import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { Grid } from '@mui/material';

const FaceSleepingIcon = () => {
    return (
        <Grid item>
            <FontAwesomeIcon icon={faPlus} color="#e7b2a5" />
        </Grid>
    );
};
export default FaceSleepingIcon;
