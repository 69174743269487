import axios from 'axios';

import { getApiUrl } from 'helpers';

// ==============================|| GET ACTIVE MEMBERSHIPS PACKAGES FOR STUDIO ||============================== //

export const GetActiveMembershipsPackages = async (dibsStudioId) => {
    try {
        const url = getApiUrl('/studio/get-memberships-packages');
        const response = await axios.post(url, {
            dibsStudioId,
        });

        return {
            msg: 'success',
            memberships: response.data.memberships,
            packages: response.data.packages,
        };
    } catch (err) {
        console.log(
            `error getting active memberships and packages for dibsStudioId: ${dibsStudioId}\nerr is: ${err}`
        );
    }
    return 0;
};

export default GetActiveMembershipsPackages;
