export const SET_CLIENT = "SET_CLIENT";
export const SET_CLIENT_FLASH_CREDITS = "SET_CLIENT_FLASH_CREDITS";
export const SET_CLIENT_STUDIO_EVENTS = "SET_CLIENT_STUDIO_EVENTS";
export const SET_CLIENT_CREDIT_SPECIALS = "SET_CLIENT_CREDIT_SPECIALS";
export const FORGET_CLIENT = "FORGET_CLIENT";

export const SET_CLIENT_CC = "SET_CLIENT_CC";
export const SET_CLIENT_NO_CREDIT_CARD = "SET_CLIENT_NO_CREDIT_CARD";

export const SET_GETTING_CLIENT_TRUE = "SET_GETTING_CLIENT_TRUE";
export const SET_GETTING_CLIENT_FALSE = "SET_GETTING_CLIENT_FALSE";
export const SET_GETTING_CLIENT_CC_TRUE = "SET_GETTING_CLIENT_CC_TRUE";
export const SET_GETTING_CLIENT_CC_FALSE = "SET_GETTING_CLIENT_CC_FALSE";
export const SET_GETTING_CLIENT_EVENTS_TRUE = "SET_GETTING_CLIENT_EVENTS_TRUE";
export const SET_GETTING_CLIENT_VISITS_TRUE = "SET_GETTING_CLIENT_VISITS_TRUE";
export const SET_GETTING_CLIENT_VISITS_FALSE =
  "SET_GETTING_CLIENT_VISITS_FALSE";
export const SET_GETTING_CLIENT_EVENTS_FALSE =
  "SET_GETTING_CLIENT_EVENTS_FALSE";
export const SET_GETTING_CLIENT_CREDIT_SPECIALS_TRUE =
  "SET_GETTING_CLIENT_CREDIT_SPECIALS_TRUE";
export const SET_GETTING_CLIENT_CREDIT_SPECIALS_FALSE =
  "SET_GETTING_CLIENT_CREDIT_SPECIALS_FALSE";

export const SET_SYNCING_CLIENT_EVENTS = "SET_SYNCING_CLIENT_EVENTS";
export const SET_SYNCING_CLIENT_PASSES = "SET_SYNCING_CLIENT_PASSES";
export const SET_NUM_VISITS_HERE = "SET_NUM_VISITS_HERE";

export const SET_CANCELING_CLIENT_PLAN_FALSE =
  "SET_CANCELING_CLIENT_PLAN_FALSE";
export const SET_CANCELING_CLIENT_PLAN_TRUE = "SET_CANCELING_CLIENT_PLAN_TRUE";
export const SET_CLIENT_PASS_TO_AUTOPAY_FALSE =
  "SET_CLIENT_PASS_TO_AUTOPAY_FALSE";

export const SET_UPDATING_PACKAGE_EXPIRATION_TRUE =
  "SET_UPDATING_PACKAGE_EXPIRATION";
export const SET_UPDATING_PACKAGE_EXPIRATION_FALSE =
  "SET_UPDATING_PACKAGE_EXPIRATION_FALSE";

export const ADD_ITEM_TO_CLIENT_CART = "ADD_ITEM_TO_CLIENT_CART";
export const ADD_ITEM_TO_CLIENT_CART_FROM_WAITLIST =
  "ADD_ITEM_TO_CLIENT_CART_FROM_WAITLIST";
export const SET_CLIENT_CART = "SET_CLIENT_CART";
export const CLEAR_CLIENT_CART = "CLEAR_CLIENT_CART";
export const SET_CLIENT_CART_VISIBLE = "SET_CLIENT_CART_VISIBLE";
export const SET_CLIENT_CART_PROMO = "SET_CLIENT_CART_PROMO";
export const CLEAR_CLIENT_CART_PROMO = "CLEAR_CLIENT_CART_PROMO";
export const SET_CLIENT_EVENTS_CONFIRMED = "SET_CLIENT_EVENTS_CONFIRMED";

export const SET_CLIENT_PURCHASING_CLASSES = "SET_CLIENT_PURCHASING_CLASSES";

export const SET_CART_PURCHASE_BUTTON_STATE = "SET_CART_PURCHASE_BUTTON_STATE";

export const CLEAR_CLIENT_EVENTS_CONFIRMED = "CLEAR_CLIENT_EVENTS_CONFIRMED";
export const DROP_CLIENT_EVENT = "DROP_CLIENT_EVENT";

export const SET_CLIENT_TRANSACTIONS = "SET_CLIENT_TRANSACTIONS";
export const SET_CLIENT_TRANSACTIONS_LOADING =
  "SET_CLIENT_TRANSACTIONS_LOADING";
export const SET_CLIENT_TRANSACTIONS_SEARCH_QUERY =
  "SET_CLIENT_TRANSACTIONS_SEARCH_QUERY";
export const SET_REFUNDING_CLIENT_TRANSACTION =
  "SET_REFUNDING_CLIENT_TRANSACTION";
export const SET_DROPPING_CLIENT_TRANSACTION =
  "SET_EARLY_DROPPING_CLIENT_TRANSACTION";
export const SET_DROPPING_CLIENT_PAST_TRANSACTION =
  "SET_DROPPING_CLIENT_PAST_TRANSACTION";
export const SET_MARKING_CLIENT_TRANSACTION_EARLY_CANCEL =
  "SET_MARKING_CLIENT_TRANSACTION_EARLY_CANCEL";
export const SET_CLIENT_TRANSACTIONS_HEADING =
  "SET_CLIENT_TRANSACTIONS_HEADING";

export const SET_CLIENT_PACKAGE_PROMO = "SET_CLIENT_PACKAGE_PROMO";
export const CLEAR_CLIENT_PACKAGE_PROMO = "CLEAR_CLIENT_PACKAGE_PROMO";
export const SET_CLIENT_RETAIL_PROMO = "SET_CLIENT_RETAIL_PROMO";
export const CLEAR_CLIENT_RETAIL_PROMO = "CLEAR_CLIENT_RETAIL_PROMO";

// Refactor to using this
export const SET_CLIENT_IS_LOADING = "SET_CLIENT_IS_LOADING";
