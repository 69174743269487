import Decimal from "decimal.js";
export default async function getModifiedCharge(fullAmount, credit) {
  let modifiedAmount = fullAmount.minus(credit);
  if (modifiedAmount.lessThan(0)) {
    // need to make sure we stay within the proper type
    // Should only convert back to number at the END of the preparation steps in the purchase controller
    modifiedAmount = new Decimal(0);
  }
  return modifiedAmount;
}
