import axios from 'axios';
import { getApiUrl } from 'helpers';

// ==============================|| GET BUSINESS HOURS DATA ||============================== //

export const GetBusinessHours = async (dibsStudioId) => {
    try {
        const url = getApiUrl('/studio/get-business-hours');
        const response = await axios.post(url, {
            dibsStudioId,
        });
        if (response.data.msg === 'success') {
            return response.data.businessHoursData;
        }
        return { msg: 'failure', error: response.data.error };
    } catch (err) {
        console.log(
            `error GETTING GetBusinessHours DATA for studioid: ${dibsStudioId}\nerr is: ${err}`
        );
    }
    console.log('should not make it this far');
    return 0;
};

export default GetBusinessHours;
