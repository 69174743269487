import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

// material-ui
import {
    Button,
    ButtonGroup,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';

// // third-party
import { formatInTimeZone } from 'date-fns-tz';
import { format } from 'date-fns';
import { enUS, it } from 'date-fns/locale';

// assets
import {
    IconChevronLeft,
    IconChevronRight,
    IconLayoutGrid,
    IconTemplate,
    IconLayoutList,
    IconListNumbers,
} from '@tabler/icons-react';
import translate from 'helpers/translate';

// constant
const viewOptions = [
    {
        label: 'Month',
        value: 'dayGridMonth',
        icon: IconLayoutGrid,
    },
    {
        label: 'Week',
        value: 'timeGridWeek',
        icon: IconTemplate,
    },
    {
        label: 'Day',
        value: 'timeGridDay',
        icon: IconLayoutList,
    },
    {
        label: 'Agenda',
        value: 'listWeek',
        icon: IconListNumbers,
    },
];

// ==============================|| CALENDAR TOOLBAR ||============================== //

const Toolbar = ({
    date,
    view,
    onClickNext,
    colorOfSelected,
    defaultBgColor,
    onClickPrev,
    onClickToday,
    onChangeView,
    handleShowAvailabilityClick,
    showAvailabilityButtonText,
    clickedBlockTimeButton,
    ...others
}) => {
    const intl = useIntl();
    const [locale, setLocale] = useState('en');
    useEffect(() => {
        const intlL = intl.locale;
        setLocale(intlL);
    }, [intl.locale]);
    const [buttonTextShowAvailability, setButtonTextShowAvailability] =
        useState(showAvailabilityButtonText);
    useEffect(() => {
        setButtonTextShowAvailability(showAvailabilityButtonText);
    }, [showAvailabilityButtonText]);
    const todayT = translate({
        id: 'today',
        msg: 'Today',
    });
    const currentLocale = locale === 'en' ? enUS : it;
    const formatDate = (date, view) => {
        const zonedDateforRTGDOrTGD = formatInTimeZone(
            date,
            'UTC',
            'MMMM dd, yyyy HH:mm',
            { locale: currentLocale }
        );

        const zonedDateforOthers = formatInTimeZone(date, 'UTC', 'MMMM yyyy', {
            locale: currentLocale,
        });
        return view === 'timeGridDay' || view === 'resourceTimeGridDay'
            ? zonedDateforRTGDOrTGD
            : zonedDateforOthers;
    };
    return (
        <Grid
            alignItems="center"
            container
            // justifyContent="space-between"
            // spacing={3}
            {...others}
            sx={{ pb: 1, px: 0.5 }}
        >
            <Grid
                item
                id="div-today-show-availability-schedule"
                xs={4}
                border={0}
            >
                <Grid container justifyContent="flex-start" alignItems="center">
                    <Grid
                        item
                        id="div-today-button-schedule"
                        border={0}
                        sx={{ mr: 1 }}
                    >
                        <Button
                            id="total-button-calendar-today"
                            variant="outlined"
                            onClick={onClickToday}
                        >
                            {todayT}
                        </Button>
                    </Grid>
                    <Grid
                        item
                        id="div-show-availability-button-schedule"
                        border={0}
                    >
                        <Button
                            id="total-button-calendar"
                            variant="textButton"
                            onClick={handleShowAvailabilityClick}
                        >
                            {buttonTextShowAvailability}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} border={0} id="div-chevrons">
                <Grid container justifyContent="center">
                    <Stack direction="row" alignItems="center" spacing={3}>
                        <IconButton
                            onClick={onClickPrev}
                            size="large"
                            id="prev-date-button"
                        >
                            <IconChevronLeft />
                        </IconButton>
                        <Typography variant="h3" color="textPrimary">
                            {formatDate(date, view)}
                        </Typography>
                        <IconButton
                            onClick={onClickNext}
                            size="large"
                            id="next-date-button"
                        >
                            <IconChevronRight />
                        </IconButton>
                    </Stack>
                </Grid>
            </Grid>
            <Grid item xs={4} border={0} id="div-button-group-month-day-week">
                <Grid container justifyContent="flex-end" alignItems="center">
                    <IconButton onClick={clickedBlockTimeButton}>
                        <AlarmOffIcon color="secondary" sx={{ mr: 1 }} />
                    </IconButton>
                    <ButtonGroup
                        id="button-group-month-week-day"
                        variant="outlined"
                        aria-label="outlined button group"
                    >
                        {viewOptions.map((viewOption) => {
                            const Icon = viewOption.icon;
                            return (
                                <Tooltip
                                    title={viewOption.label}
                                    key={viewOption.value}
                                >
                                    <Button
                                        disableElevation
                                        sx={{
                                            backgroundColor:
                                                viewOption.value === view
                                                    ? colorOfSelected
                                                    : defaultBgColor,
                                        }}
                                        variant={
                                            viewOption.value === view
                                                ? 'outlined'
                                                : 'outlined'
                                        }
                                        onClick={() =>
                                            onChangeView(viewOption.value)
                                        }
                                    >
                                        <Icon stroke="2" size="1.3rem" />
                                    </Button>
                                </Tooltip>
                            );
                        })}
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Grid>
    );
};

Toolbar.propTypes = {
    date: PropTypes.object,
    view: PropTypes.string,
    onClickNext: PropTypes.func,
    onClickPrev: PropTypes.func,
    onClickToday: PropTypes.func,
    onChangeView: PropTypes.func,
};

export default Toolbar;
