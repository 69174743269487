import { useIntl } from 'react-intl';

const Translate = ({ id, msg }) => {
    const intl = useIntl();
    let newtext;
    if (intl.locale === 'en') {
        newtext = msg;
    } else {
        newtext = intl.formatMessage({
            id,
            defaultMessage: msg,
        });
    }
    return newtext;
};
export default Translate;
