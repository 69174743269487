import axios from 'axios';
import { getApiUrl } from 'helpers';
import { dispatch } from 'store';
import getInstructors from 'actions/studios/instructors/getInstructors';
import { setInstructorData } from 'store/slices/instructors';
import { getLightRandomHexColor } from 'helpers';

// ==============================|| GET STUDIO EMPLOYEE ACCOUNTS ||============================== //

export const AddColorsToInstructors = async (dibsStudioId) => {
    const baseURL = getApiUrl('/studio/instructors/update-instructor-color');
    try {
        let needToUpdateDb = false;
        const instructorstoupdate = [];
        await getInstructors(dibsStudioId).then(async (response) => {
            const { activeInstructors } = response;

            const instructorsWithColors = activeInstructors.map(
                (instructor, i) => {
                    const { associated_color } = instructor;

                    if (!associated_color) {
                        const newRandomColor = getLightRandomHexColor();
                        needToUpdateDb = true;
                        instructorstoupdate.push({
                            instructorId: instructor.id,
                            newColor: newRandomColor,
                        });
                        return {
                            ...instructor,
                            associated_color: newRandomColor,
                        };
                    } else {
                        return instructor;
                    }
                }
            );
            dispatch(setInstructorData(instructorsWithColors));
            if (needToUpdateDb) {
                await axios
                    .post(baseURL, {
                        instructorsToUpdate: instructorstoupdate,
                    })
                    .then((res) => {
                        console.log('response from updating colors is: ', res);
                    })
                    .catch((err) => {
                        console.log(
                            `error checking whether class exists: ${err}`
                        );
                        return err;
                    });
            }
        });
    } catch (err) {
        console.log(
            `error getting instructor COLORS for dibsStudioId: ${dibsStudioId}\nerr is: ${err}`
        );
    }
    return 0;
};

export default AddColorsToInstructors;
