import axios from 'axios';
import { getApiUrl } from 'helpers';

// ==============================|| CHECK PACKAGE NAME EXISTS FOR STUDIO ||============================== //

export const CheckPackageExists = async (dibsStudioId, packName) => {
    try {
        const url = getApiUrl('/studio/memberships-pack/check-pack-name');
        const response = await axios.post(url, {
            packName,
            dibsStudioId,
        });
        if (response.data.msg === 'success') {
            return {
                msg: 'success',
                packNameAlreadyExists: response.data.packNameExists,
            };
        } else {
            return { msg: 'failure', error: response.data.error };
        }
    } catch (err) {
        console.log(
            `error checking package name exist for dibsStudioId: ${dibsStudioId}\nerr is: ${err}`
        );
        return { msg: 'failure', error: err };
    }
};

export default CheckPackageExists;
