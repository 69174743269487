import { useEffect, useState } from 'react';
import {
    Grid,
    Divider,
    Button,
    Switch,
    FormGroup,
    FormControlLabel,
    Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { capitalizeString } from 'helpers';

import AutocompleteField from 'components/search/AutoCompleteClientSearchShort';
import AutocompleteFieldUI from 'ui-component/textfields/Autocomplete';
import DatePicker from 'ui-component/pickers/DatePicker';
import TimePicker from 'ui-component/pickers/TimePicker';
import BookedClients from 'components/schedule-sections/bookedClients';
import BookedClientsPotentially from 'components/schedule-sections/potentiallyAssignedClients';
import NewClientAccount from 'components/clients/createClient';

import createNewAppt from 'actions/appointments/createNewAppt';
import updateExistingEvent from 'actions/schedule/updateExistingEvent';
import addUserToAppt from 'actions/appointments/addUserToAppt';
import setAlert from 'actions/status/setAlert';
import sendConfirmationEmail from 'actions/emails/send-confirmation-appt';
import sendSmsConfirmation from 'actions/emails/send-sms-confirmation-appt';
import getAttendees from 'actions/schedule/getAttendees';
import { getEvents } from 'store/slices/calendar';

import { useSelector, dispatch } from 'store';

import { capitalizeFirstLetter } from 'helpers/general';

dayjs.extend(utc);

function compareByName(a, b) {
    return a.label.localeCompare(b.label);
}

const ApptFields = ({
    onCancel,
    initialStartDate,
    initialStartTime,
    initialEndTime,
    selectedEvent,
    actionTaking,
    setUpdateAllEvents,
    isBlockingTime,
    refreshCalendar,
    setAttendeesAffected,
    setShowAlertWithEmailSms,
    setEventIdEditing,
}) => {
    const theme = useTheme();
    const { apptTypes } = useSelector((state) => state.appointments);
    const { instructorData } = useSelector((state) => state.instructors);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const { instructorAltName, instructorPref } = useSelector(
        (state) => state.dibsstudio.studioConfig
    );
    const [instructorTitle, setInstructorTitle] = useState('Name');
    useEffect(() => {
        if (instructorAltName) {
            setInstructorTitle(`${capitalizeString(instructorAltName)} Name`);
        } else {
            setInstructorTitle('Name');
        }
    }, [instructorAltName]);
    const { studioEmployeeId } = useSelector(
        (state) => state.studioemployeeuser
    );
    const { timeZone, studioName } = useSelector(
        (state) => state.dibsstudio.config
    );
    const { customerServicePhone, twilioCustomNumUs } = useSelector(
        (state) => state.dibsstudio.customerService
    );
    const [selectionOptions, setSelectionOptions] = useState([]);
    const [instructorOptions, setInstructorOptions] = useState([]);
    const [apptTypeSelected, setApptTypeSelected] = useState(null);
    const [instructorSelected, setInstructorSelected] = useState(null);
    const [endTimeToPass, setEndTimeToPass] = useState(initialEndTime);
    const [buttonText, setButtonText] = useState('Create Appointment');
    const [isAddingNewClient, setIsAddingNewClient] = useState(false);
    const [hasInitialClientsInAppt, setHasInitialClientsInAppt] =
        useState(false);
    const [clientsNeedAddtlNotifPrompt, setClientsNeedAddtlNotifPrompt] =
        useState(false);

    const [showAutoCompleteClient, setShowAutoCompleteClient] = useState(true);

    const [startTimeToPass, setStartTimeToPass] = useState(initialStartTime);
    const [minutesToAdd, setMinutesToAdd] = useState(30);
    const [manuallySetEnd, setManuallySetEnd] = useState(false);
    const [clientSelected, setClientSelected] = useState(null);
    const [sendEmailConfirmation, setSendEmailConfirmation] = useState(true);
    const [sendSMSConfirmation, setSendSMSConfirmation] = useState(true);
    const [smsLabel, setSMSLabel] = useState('Send SMS/Text Confirmation');
    const [emailLabel, setEmailLabel] = useState('Send Email Confirmation');
    const [attendeeForThisEvent, setAttendeeForThisEvent] = useState([]);
    const [hideSaveButtons, setHideSaveButtons] = useState(false);
    const [eventidSelected, setEventidSelected] = useState(null);
    const [showBookedClients, setShowBookedClients] = useState(false);
    const [updateEvents, setUpdateEvents] = useState(false);
    const [itemsChanged, setItemsChanged] = useState([]);
    const [dateToPass, setDateToPass] = useState(initialStartDate);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        return () => {
            setIsSubmitting(false);
        };
    }, []);
    useEffect(() => {
        if (isBlockingTime) {
            setButtonText('Save Blocked Time');
            setManuallySetEnd(true);
        }
    }, [isBlockingTime]);
    useEffect(() => {
        if (!isBlockingTime) {
            if (actionTaking === 'editing') {
                setButtonText('Update Appointment');
            } else {
                setButtonText('Create Appointment');
            }
        }
    }, [actionTaking, isBlockingTime]);

    useEffect(() => {
        const getAttendeeInfo = async () => {
            await getAttendees(selectedEvent.eventid)
                .then((res) => {
                    const { attendees } = res;
                    if (attendees?.length > 0) {
                        setAttendeeForThisEvent(attendees);
                        setShowBookedClients(true);
                        setHasInitialClientsInAppt(true);
                    } else {
                        setAttendeeForThisEvent([]);
                        setShowBookedClients(false);
                        setHasInitialClientsInAppt(false);
                    }
                })
                .catch((error) => {
                    console.log('error receievd was', error);
                });
        };
        if (selectedEvent) {
            setApptTypeSelected({
                label: selectedEvent.apptTitle,
                apptdefaultprice: selectedEvent.price_dibs,
                eventid: selectedEvent.eventid,
            });
            const { instructor } = selectedEvent;
            if (instructor) {
                const lname = instructor.lastname;
                let fullname;
                if (lname?.length > 1) {
                    fullname = `${capitalizeFirstLetter(instructor.firstname)} ${lname}`;
                } else {
                    fullname = instructor.firstname;
                }
                const newinfo = {
                    value: instructor.id,
                    label: fullname,
                };
                setInstructorSelected(newinfo);
            }

            getAttendeeInfo();
            setEventidSelected(selectedEvent.eventid);
        }
        if (updateEvents) {
            getAttendeeInfo();
            setUpdateEvents(false);
        }
    }, [selectedEvent, updateEvents]);

    useEffect(() => {
        if (apptTypes?.length > 0) {
            const options = apptTypes.map((appt) => {
                return {
                    label: appt.appointment_type,
                    value: appt.id,
                    minutes: appt?.length_minutes,
                    apptdefaultprice: appt.default_price,
                };
            });
            const optionsSorted = options.sort(compareByName);
            setSelectionOptions(optionsSorted);
        }
    }, [apptTypes]);
    useEffect(() => {
        if (instructorData?.length > 0) {
            const filteredForEnabled = instructorData.filter((i) => i.enabled);
            const options = filteredForEnabled.map((i) => {
                const lname = i.lastname;
                let fullname;
                if (lname?.length > 1) {
                    const first = capitalizeString(i.firstname);
                    const last = capitalizeString(lname);
                    fullname = `${first} ${last}`;
                } else {
                    fullname = capitalizeString(i.firstname);
                }
                return {
                    label: fullname,
                    value: i.id,
                    email: i.email,
                    phone: i.mobilephone,
                    associated_color: i.associated_color,
                    imgUrl: i.image_url,
                };
            });
            const optionsSorted = options.sort(compareByName);
            setInstructorOptions(optionsSorted);
        }
    }, [instructorData]);
    useEffect(() => {
        if (!manuallySetEnd && !selectedEvent) {
            const baseForEndTime = dayjs.utc(startTimeToPass);
            const newEndTime = baseForEndTime.add(minutesToAdd, 'minutes');
            setEndTimeToPass(newEndTime);
        }
    }, [
        apptTypeSelected,
        manuallySetEnd,
        minutesToAdd,
        selectedEvent,
        startTimeToPass,
    ]);
    const handleRefreshAttendees = async (eventId) => {
        try {
            const newAttendees = await getAttendees(eventId);

            const { attendees } = newAttendees;
            if (attendees?.length > 0) {
                setAttendeeForThisEvent(attendees);
                setShowBookedClients(true);
                setHasInitialClientsInAppt(true);
            } else {
                setAttendeeForThisEvent([]);
                setShowBookedClients(false);
                setHasInitialClientsInAppt(false);
            }
        } catch (err) {
            console.log(
                'there was an issue refreshing the list of attendees',
                err
            );
        }
    };
    const handleCancel = () => {
        onCancel();
    };
    const handleApptSelection = (event, value) => {
        if (value) {
            setApptTypeSelected(value);
            setMinutesToAdd(value.minutes);
        }
        if (actionTaking === 'editing') {
            if (hasInitialClientsInAppt) {
                setClientsNeedAddtlNotifPrompt(true);
            }

            setItemsChanged([...itemsChanged, 'apptType']);
        }
    };
    const handleStartTimeChange = (event) => {
        setStartTimeToPass(event);
        setManuallySetEnd(false);
        if (actionTaking === 'editing') {
            if (hasInitialClientsInAppt) {
                setClientsNeedAddtlNotifPrompt(true);
            }
            setItemsChanged([...itemsChanged, 'time']);
        }
    };
    const handleDateChange = (event) => {
        const newdate = dayjs.utc(event);
        const newdateformatted = newdate.format('M/D/YYYY');
        setDateToPass(newdateformatted);
        const hourEnd = dayjs.utc(endTimeToPass).hour();
        const minuteEnd = dayjs.utc(endTimeToPass).minute();
        const newEndTime = dayjs.utc(event).hour(hourEnd).minute(minuteEnd);
        setEndTimeToPass(newEndTime);
        const hourStart = dayjs.utc(startTimeToPass).hour();
        const minuteStart = dayjs.utc(startTimeToPass).minute();
        const newStartTime = dayjs
            .utc(event)
            .hour(hourStart)
            .minute(minuteStart);
        setStartTimeToPass(newStartTime);
        if (actionTaking === 'editing') {
            if (hasInitialClientsInAppt) {
                setClientsNeedAddtlNotifPrompt(true);
            }
            setItemsChanged([...itemsChanged, 'date']);
        }
    };
    const handleEndTimeChange = (event) => {
        setManuallySetEnd(true);
        setEndTimeToPass(event);
        if (actionTaking === 'editing') {
            if (hasInitialClientsInAppt) {
                setClientsNeedAddtlNotifPrompt(true);
            }
            setItemsChanged([...itemsChanged, 'time']);
        }
    };

    const handleInstructorSelection = (event, value) => {
        setInstructorSelected(value);
        if (actionTaking === 'editing') {
            if (hasInitialClientsInAppt) {
                setClientsNeedAddtlNotifPrompt(true);
            }
            setItemsChanged([...itemsChanged, 'instructor']);
        }
    };
    const handleClientSelection = (event) => {
        if (event) setClientSelected(event);

        if (actionTaking === 'editing') {
            setItemsChanged([...itemsChanged, 'client']);
        }
    };
    const handleSubmit = async () => {
        // get the date updated to include the date
        if (!isBlockingTime) {
            if (!apptTypeSelected) {
                setAlert({
                    msg: 'Please select an appointment type before saving.',
                    seconds: 5,
                    warning: true,
                });
                return;
            }
        }

        if (!instructorSelected) {
            const msgtouse = `Please assign ${instructorPref} ${instructorAltName} to the ${isBlockingTime ? 'blocked time' : 'appointment'} before saving.`;
            setAlert({
                msg: msgtouse,
                seconds: 5,
                warning: true,
            });
            return;
        }
        const apptNametoUse = apptTypeSelected
            ? apptTypeSelected.label
            : `Blocked Time - ${instructorSelected.label}`;
        const apptdefaultpricetouse = apptTypeSelected
            ? apptTypeSelected.apptdefaultprice
            : 0;
        const apptIdtouse = apptTypeSelected ? apptTypeSelected.value : null;
        const apptminutestouse = apptTypeSelected
            ? apptTypeSelected.minutes
            : 10;
        // if (!apptNametoUse) apptNametoUse = 'Blocked Time';
        let apptToPass = {
            dibsStudioId,
            apptId: apptIdtouse,
            apptName: apptNametoUse,
            isBlockingTime,
            studioEmployeeId,
            instructorid: instructorSelected.value,
            instructorname: instructorSelected.label,
            apptLength: apptminutestouse,
            apptDefaultPrice: apptdefaultpricetouse,
            priceAppt: apptdefaultpricetouse,
            apptOverridePrice: apptdefaultpricetouse,
            originalStartTimeUnFormatted: startTimeToPass,
            mainTimezone: timeZone,
            studioName: studioName,
            startTimeToPass: dayjs
                .utc(startTimeToPass)
                .format('YYYY-MM-DD HH:mm:ss'),
            endTimeToPass: dayjs
                .utc(endTimeToPass)
                .format('YYYY-MM-DD HH:mm:ss'),
            clientid: clientSelected ? clientSelected.id : null,
            userid: clientSelected ? clientSelected.id : null,
            clientname: clientSelected ? clientSelected.label : null,
            clientemail: clientSelected ? clientSelected.email : null,
            clientfirstname: clientSelected ? clientSelected.firstName : null,
            clientlastname: clientSelected ? clientSelected.lastName : null,
            eventid: selectedEvent ? selectedEvent.eventid : null,
            mobilephone: clientSelected ? clientSelected.phone : null,
            itemsChanged,
            twilioCustomNumUS: twilioCustomNumUs ? twilioCustomNumUs : null,
            customerServicePhone: customerServicePhone
                ? customerServicePhone
                : null,
        };
        if (actionTaking === 'creating') {
            setIsSubmitting(true);
            await createNewAppt(apptToPass)
                .then(async (res) => {
                    const { success, instructorConflict, message } = res;
                    if (instructorConflict) {
                        if (
                            message ===
                            'Instructor is not available at this time'
                        ) {
                            setAlert({
                                msg: `${apptToPass.instructorname} is not available at this time. Please choose a different ${instructorAltName} or a different time. Click 'Show availability' to see the ${instructorAltName}'s schedule.`,
                                seconds: 10,
                                error: true,
                            });
                        } else {
                            setAlert({
                                msg: `There is a conflict with ${apptToPass.instructorname}'s schedule. Please choose a different ${instructorAltName} or a different time. Click 'Show availability' to see the ${instructorAltName}'s schedule.`,
                                seconds: 10,
                                error: true,
                            });
                        }

                        setIsSubmitting(false);
                        return;
                    }
                    if (success) {
                        // if there is a user - add the dibstransaction and user to attendees table
                        if (clientSelected) {
                            const { data } = res;
                            const { eventid } = data[0];
                            apptToPass = {
                                ...apptToPass,
                                eventid,
                            };
                            const email = clientSelected.email;

                            await addUserToAppt(apptToPass)
                                .then(async (res) => {
                                    // setUpdateAllEvents(true);
                                    const { status } = res;
                                    if (status === 1) {
                                        if (sendEmailConfirmation) {
                                            // send email confirmation
                                            const datatosend = {
                                                appttocreate: {
                                                    apptToConfirm: apptToPass,
                                                    email,
                                                    dibsId: dibsStudioId,
                                                },
                                                eventscreated: [
                                                    {
                                                        eventid,
                                                    },
                                                ],
                                            };
                                            await sendConfirmationEmail(
                                                datatosend
                                            ).catch((error) => {
                                                console.log(
                                                    'error sending email confirmation from appt fields',
                                                    error
                                                );
                                                setAlert({
                                                    msg: 'Error sending the confirmation email. Please refresh your screen and try again.',
                                                    seconds: 5,
                                                    error: true,
                                                });
                                            });
                                        }

                                        if (sendSMSConfirmation) {
                                            // send SMS confirmation
                                            const datatosend = {
                                                appttocreate: {
                                                    apptToConfirm: apptToPass,
                                                    dibsId: dibsStudioId,
                                                    eventtype: 'appt',
                                                },
                                                eventscreated: [
                                                    {
                                                        eventid,
                                                    },
                                                ],
                                            };
                                            await sendSmsConfirmation(
                                                datatosend
                                            ).catch((error) => {
                                                setAlert({
                                                    msg: 'Error sending the sms confirmation. Please refresh your screen and try again.',
                                                    seconds: 5,
                                                    error: true,
                                                });
                                                console.log(
                                                    'error sending sms from appt fields',
                                                    error
                                                );
                                            });
                                        }
                                        setIsSubmitting(false);
                                    }
                                })
                                .catch((error) => {
                                    console.log(
                                        'error adding user to appt is',
                                        error
                                    );
                                    setAlert({
                                        msg: 'Error adding the client to the appointment. Please refresh your screen and try again.',
                                        seconds: 5,
                                        error: true,
                                    });
                                    setIsSubmitting(false);
                                    return;
                                });
                        }
                        setAlert({
                            msg: `Successfully created the ${isBlockingTime ? 'blocked time' : 'appointment'}`,
                            seconds: 5,
                            success: true,
                        });
                        setIsSubmitting(false);
                        setUpdateEvents(true);
                        refreshCalendar();
                        dispatch(getEvents(dibsStudioId, timeZone));
                        onCancel(); // this just closes the modal. if we add more to this function, this needs to be changed. It comes from calendar. It is handleModalClose function.
                    } else {
                        setAlert({
                            msg: `Could not create the ${isBlockingTime ? 'blocked time' : 'appointment'}`,
                            seconds: 5,
                            error: true,
                        });
                        return;
                    }
                })
                .catch((error) => {
                    console.log('could not create the appt', error);
                });
        } else {
            if (apptToPass.itemsChanged?.length === 0) {
                onCancel();
                return;
            } else {
                await updateExistingEvent(
                    apptToPass,
                    eventidSelected,
                    sendEmailConfirmation,
                    'apptfields'
                )
                    .then(async () => {
                        setUpdateAllEvents(true);

                        const attendeeList =
                            await getAttendees(eventidSelected);

                        const { attendees } = attendeeList;
                        const attendeeListNew = attendees.map((a) => {
                            let smsAvailable = false;
                            const { mobilephone } = a;
                            if (mobilephone) {
                                if (mobilephone?.length > 2) {
                                    smsAvailable = true;
                                }
                            }

                            return {
                                ...a,
                                emailIsChecked: true,
                                smsIsChecked: true,
                                shouldSendEmail: true,
                                shouldSendSms: true,
                                smsIsAvailable: smsAvailable,
                            };
                        });
                        if (attendeeListNew?.length > 0) {
                            setAttendeeForThisEvent(attendeeListNew);
                            if (clientsNeedAddtlNotifPrompt) {
                                const attendeeListFiltered =
                                    attendeeListNew.filter(
                                        (a) => a.userid === clientSelected.id
                                    ); // removing current client selected bc they have just been added and do not need to be notified of the change
                                setAttendeesAffected(attendeeListFiltered);
                            } else {
                                setAttendeesAffected([]);
                                setAlert({
                                    msg: `Successfully updated the appointment`,
                                    seconds: 5,
                                    success: true,
                                });
                            }
                            setEventIdEditing(eventidSelected);
                            setShowAlertWithEmailSms(true);
                            const changedItems = apptToPass.itemsChanged;
                            if (changedItems.includes('client')) {
                                const email = clientSelected.email;
                                const datatosend = {
                                    appttocreate: {
                                        apptToConfirm: apptToPass,
                                        email,
                                        dibsId: dibsStudioId,
                                        eventtype: 'appt',
                                    },
                                    eventscreated: [
                                        {
                                            eventid: eventidSelected,
                                        },
                                    ],
                                };
                                if (sendSMSConfirmation) {
                                    sendSmsConfirmation(datatosend);
                                }
                                if (sendEmailConfirmation) {
                                    sendConfirmationEmail(datatosend);
                                }
                            }
                        } else {
                            setAlert({
                                msg: 'Successfully updated the appointment',
                                seconds: 5,
                                success: true,
                            });
                        }

                        setUpdateEvents(true);
                        setClientsNeedAddtlNotifPrompt(false);
                        setHasInitialClientsInAppt(false);
                        setItemsChanged([]);
                        onCancel(); // this just closes the modal. if we add more to this function, this needs to be changed. It comes from calendar. It is handleModalClose function.
                    })
                    .catch((error) => {
                        console.log('error updating the event', error);
                        setAlert({
                            msg: 'Could not update the appointment',
                            seconds: 5,
                            error: true,
                        });
                    });
            }
        }
    };
    const handleChangeWasMade = () => {
        setUpdateAllEvents(true);
    };

    return (
        <div>
            <Grid container justifyContent="center">
                {!isBlockingTime && (
                    <Grid
                        item
                        xs={11}
                        md={9}
                        id="appt-type-item-add-event-appt"
                        // border={2}
                        sx={{ p: 0, pt: 1, mb: 2 }}
                    >
                        <AutocompleteFieldUI
                            title="Appointment Type"
                            options={selectionOptions}
                            changeAction={handleApptSelection}
                            valueSelected={apptTypeSelected}
                        />
                    </Grid>
                )}

                <Grid
                    item
                    xs={11}
                    md={9}
                    id="instructor-name-item-add-event"
                    // border={2}
                    sx={{ p: 0, pt: 1, mb: 2 }}
                >
                    <AutocompleteFieldUI
                        title={instructorTitle}
                        options={instructorOptions}
                        changeAction={handleInstructorSelection}
                        valueSelected={instructorSelected}
                    />
                </Grid>
                <Grid item xs={11.5} md={9.5}>
                    <Divider sx={{ mt: 1, mb: 2 }} />
                </Grid>
                <Grid
                    item
                    xs={11}
                    md={9}
                    // border={2}
                    id="start-date-picker-appt"
                    sx={{ p: 0, m: 0, mb: 2 }}
                >
                    <DatePicker
                        initialStartDate={initialStartDate}
                        changeAction={handleDateChange}
                        valueSelected={dateToPass}
                    />
                </Grid>
                <Grid item xs={11.5} md={9.5}>
                    <Divider sx={{ mt: 1, mb: 2 }} />
                </Grid>
                <Grid
                    item
                    xs={11}
                    // border={2}
                    md={9}
                    id="start-date-time-picker-appt"
                    sx={{ p: 0, pt: 1, mb: 2 }}
                >
                    <Grid container>
                        <Grid item xs={5} sx={{ mr: 1 }}>
                            <Grid item xs={11} md={9} id="timepicker">
                                <TimePicker
                                    label="Start Time"
                                    value={startTimeToPass}
                                    defaultTimeValue={initialStartTime}
                                    changeAction={handleStartTimeChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid item xs={11} md={9}>
                                <TimePicker
                                    label="End Time"
                                    value={endTimeToPass}
                                    defaultTimeValue={initialEndTime}
                                    changeAction={handleEndTimeChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11.5} md={9.5}>
                    <Divider sx={{ mt: 1, mb: 2 }} />
                </Grid>
                {showBookedClients && (
                    <>
                        <Grid
                            item
                            xs={11}
                            md={9}
                            sx={{ p: 0, pt: 1, mb: 2 }}
                            id={`booked-clients-grid-item-appts-${eventidSelected}`}
                        >
                            <Typography>Booked Client(s)</Typography>
                            <BookedClients
                                attendees={attendeeForThisEvent}
                                setHideSaveButtons={setHideSaveButtons}
                                thiseventid={eventidSelected}
                                dibsId={dibsStudioId}
                                startTime={startTimeToPass}
                                setUpdateEvents={setUpdateEvents}
                                handleChangeWasMade={handleChangeWasMade}
                                setClientSelected={handleClientSelection}
                                handleClientSelection={handleClientSelection}
                                refreshAttendees={handleRefreshAttendees}
                            />
                        </Grid>
                    </>
                )}
                {!showAutoCompleteClient && (
                    <Grid item xs={11} md={9} sx={{ p: 0, pt: 1, mb: 2 }}>
                        <Typography>Selected Client</Typography>
                        <BookedClientsPotentially
                            attendees={attendeeForThisEvent}
                            setHideSaveButtons={setHideSaveButtons}
                            thiseventid={eventidSelected}
                            dibsId={dibsStudioId}
                            startTime={startTimeToPass}
                            setUpdateEvents={setUpdateEvents}
                            setAttendeeForThisEvent={setAttendeeForThisEvent}
                            setShowAutoCompleteClient={
                                setShowAutoCompleteClient
                            }
                            setClientSelected={setClientSelected}
                        />
                    </Grid>
                )}
                {isAddingNewClient && (
                    <>
                        <Grid
                            item
                            xs={11.5}
                            border={0}
                            md={9.5}
                            sx={{ px: 0.85 }}
                        >
                            <NewClientAccount
                                setHideSaveButtons={setHideSaveButtons}
                                setIsAddingNewClient={setIsAddingNewClient}
                                handleClientSelection={handleClientSelection}
                                setAttendeeForThisEvent={
                                    setAttendeeForThisEvent
                                }
                                setShowAutoCompleteClient={
                                    setShowAutoCompleteClient
                                }
                            />
                        </Grid>
                        <Grid item xs={11.5} md={9.5}>
                            <Divider sx={{ mt: 1, mb: 2 }} />
                        </Grid>
                    </>
                )}
                {!showBookedClients &&
                    !isBlockingTime &&
                    !isAddingNewClient &&
                    showAutoCompleteClient && (
                        <>
                            <Grid
                                item
                                xs={11}
                                md={9}
                                sx={{ p: 0, pt: 1, mb: 2 }}
                            >
                                <AutocompleteField
                                    title="Add A Client (Optional)"
                                    onChangeAction={handleClientSelection}
                                    setIsAddingNewClient={setIsAddingNewClient}
                                    clientSelected={clientSelected}
                                />
                            </Grid>
                        </>
                    )}
                <Grid item xs={11.5} md={9.5}>
                    <Divider sx={{ mt: 1, mb: 2 }} />
                </Grid>
                <Grid
                    item
                    xs={11}
                    md={9}
                    sx={{ p: 0, pt: 1, mb: 2 }}
                    id="holder-of-send-email-sms-confirmations"
                >
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={sendEmailConfirmation}
                                    disabled={!clientSelected}
                                    onChange={(event) => {
                                        setSendEmailConfirmation(
                                            event.target.checked
                                        );
                                        if (!sendEmailConfirmation) {
                                            setEmailLabel(
                                                'Send Email Confirmation'
                                            );
                                        } else {
                                            setEmailLabel(
                                                'Will Not Send Email Confirmation'
                                            );
                                        }
                                    }}
                                />
                            }
                            label={emailLabel}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={sendSMSConfirmation}
                                    disabled={!clientSelected}
                                    onChange={(event) => {
                                        setSendSMSConfirmation(
                                            event.target.checked
                                        );
                                        if (!sendSMSConfirmation) {
                                            setSMSLabel(
                                                'Send SMS/Text Confirmation'
                                            );
                                        } else {
                                            setSMSLabel(
                                                'Will Not Send SMS/Text Confirmation'
                                            );
                                        }
                                    }}
                                />
                            }
                            label={smsLabel}
                        />
                    </FormGroup>
                </Grid>
                {!hideSaveButtons && !isAddingNewClient && (
                    <Grid item xs={11.5} md={9.5}>
                        <Grid container justifyContent="flex-end">
                            <Grid item sx={{ mr: 2 }}>
                                <Button
                                    id="cancel-appt-button-fields"
                                    variant="cancel"
                                    sx={{ mt: 1, mb: 2 }}
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item sx={{ mr: 1 }}>
                                <LoadingButton
                                    disableElevation
                                    loading={isSubmitting}
                                    variant="contained"
                                    color="primary"
                                    id="save-appt-button-loading"
                                    onClick={handleSubmit}
                                    sx={{
                                        bgcolor:
                                            theme.palette.globalcolors.submit,
                                        '&:hover': {
                                            backgroundColor:
                                                theme.palette.globalcolors
                                                    .hoverSubmit,
                                        },
                                        mt: 1,
                                        mb: 2,
                                    }}
                                >
                                    {buttonText}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default ApptFields;
