import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function updatePackage(changelog) {
    const url = getApiUrl('/studio/update-package-membership');
    const response = await axios.post(url, {
        changelog,
    });
    const { data } = response;
    return data;
}
