import axios from "axios";
import { getApiUrl } from "helpers";
import {
  SET_RETAIL_PRODUCTS,
  SET_RETAIL_PRODUCT,
  CLEAR_RETAIL_PRODUCTS,
  CLEAR_RETAIL_PRODUCT,
  SET_RETAIL_ACTIVE_ITEM,
  CLEAR_RETAIL_ACTIVE_ITEM,
  CLEAR_RETAIL_PURCHASING,
  SET_RETAIL_PURCHASING,
  SET_RETAIL_CONFIRMING,
  CLEAR_RETAIL_CONFIRMING,
  CLEAR_ACTIVE_RETAIL_PURCHASE,
} from "../constants/RetailConstants";
import { addError } from "./ErrorActions";
import { getClientId, getClientHasNoCreditCard } from "../selectors/client";
import { getRetailProductIsPurchasing } from "../selectors/retail";
import { getClientRetailAppliedPromoCode } from "../selectors/client/cart/index";
import { addNotice } from "./NoticeActions";
import { requestClientData } from "./ClientActions";

/**
 *
 * @param {number} value retail product id
 * @returns {object} redux action
 */
export function setActiveProductForPurchase(value) {
  return { type: SET_RETAIL_ACTIVE_ITEM, value };
}

/**
 *
 * @returns {object} redux action
 */
export function clearActiveProduct() {
  return { type: CLEAR_RETAIL_ACTIVE_ITEM };
}

/**
 *
 * @returns {object} redux action
 */
export function setRetailPurchasing() {
  return { type: SET_RETAIL_PURCHASING };
}

/**
 *
 * @returns {object} redux action
 */
export function clearRetailPurchasing() {
  return { type: CLEAR_RETAIL_PURCHASING };
}

/**
 *
 * @returns {object} redux action
 */
export function setRetailConfirming() {
  return { type: SET_RETAIL_CONFIRMING };
}

/**
 *
 * @returns {object} redux action
 */
export function clearRetailConfirming() {
  return { type: CLEAR_RETAIL_CONFIRMING };
}

/**
 *
 * @returns {object} redux action
 */
export function clearActiveRetailPurchase() {
  return { type: CLEAR_ACTIVE_RETAIL_PURCHASE };
}
/**
 *
 * @param {array} value  array of retail products for sale
 * @returns {object} redux state update
 */
export function setRetailProducts(value) {
  return { type: SET_RETAIL_PRODUCTS, value };
}

/**
 *
 * @param {array} value  array of retail products for sale
 * @returns {object} redux state update
 */
export function setRetailProduct(value) {
  return { type: SET_RETAIL_PRODUCT, value };
}

/**
 *
 * @returns {object} redux state update
 */
export function clearRetailProducts() {
  return { type: CLEAR_RETAIL_PRODUCTS };
}

/**
 *
 * @returns {object} redux state update
 */
export function clearRetailProduct(value) {
  return { type: CLEAR_RETAIL_PRODUCT, value };
}

/**
 * Retrieves list of products from the server
 * @returns {function} inner thunk
 */
export function fetchRetailProducts(includeDeleted) {
  return function innerFetchRetailProducts(dispatch) {
    console.log("retail actions are here");
    // $.ajax(`/studios/api/retail/products/${includeDeleted ? `?includeDeleted=${includeDeleted}` : ''}`, {
    //   method: 'GET',
    //   success(data) {
    //     if (data.success) {
    //       dispatch(setRetailProducts(data.products));
    //     } else {
    //       dispatch(addError(data.message));
    //     }
    //   },
    //   failure(err) {
    //     dispatch(addError(err.message));
    //   },
    // });
  };
}

/**
 * Edits a product from the server
 * @returns {function} inner thunk
 */
export function editRetailProduct(
  productid,
  { name, description, price, displayOnRoster },
) {
  console.log("display!");
  console.log(displayOnRoster);
  return function innerEditRetailProduct(dispatch) {
    // $.ajax(`/studios/api/retail/products/${productid}`, {
    //     method: 'PUT',
    //     data: {
    //         name,
    //         description,
    //         price,
    //         displayOnRoster,
    //     },
    //     success(data) {
    //         if (data.success) {
    //             dispatch(setRetailProduct(data.product));
    //             dispatch(addNotice(data.message));
    //         } else {
    //             dispatch(addError(data.message));
    //         }
    //     },
    //     failure(err) {
    //         dispatch(addError(err.message));
    //     },
    // });
  };
}

/**
 * Edits a product from the server
 * @returns {function} inner thunk
 */
export function addRetailProduct({
  name,
  description,
  price,
  displayOnRoster,
}) {
  return function innerAddRetailProduct(dispatch) {
    // $.ajax('/studios/api/retail/products/', {
    //     method: 'POST',
    //     data: {
    //         name,
    //         description,
    //         price,
    //         displayOnRoster,
    //     },
    //     success(data) {
    //         if (data.success) {
    //             dispatch(setRetailProduct(data.product));
    //             dispatch(addNotice(data.message));
    //         } else {
    //             dispatch(addError(data.message));
    //         }
    //     },
    //     failure(err) {
    //         dispatch(addError(err.message));
    //     },
    // });
  };
}

/**
 * Removes a product from the quick select
 * @returns {function} inner thunk
 */
export function deactivateRetailProduct(productid) {
  return function innerDeactivateRetailProduct(dispatch) {
    // $.ajax(`/studios/api/retail/products/${productid}/deactivate`, {
    //     method: 'PUT',
    //     success(data) {
    //         if (data.success) {
    //             dispatch(setRetailProduct(data.product));
    //             dispatch(addNotice(data.message));
    //         } else {
    //             dispatch(addError(data.message));
    //         }
    //     },
    //     failure(err) {
    //         dispatch(addError(err.message));
    //     },
    // });
  };
}

/**
 * Activates a product from the quick select
 * @returns {function} inner thunk
 */
export function activateRetailProduct(productid) {
  return function innerActivateRetailProduct(dispatch) {
    // $.ajax(`/studios/api/retail/products/${productid}/activate`, {
    //     method: 'PUT',
    //     success(data) {
    //         if (data.success) {
    //             dispatch(setRetailProduct(data.product));
    //             dispatch(addNotice(data.message));
    //         } else {
    //             dispatch(addError(data.message));
    //         }
    //     },
    //     failure(err) {
    //         dispatch(addError(err.message));
    //     },
    // });
  };
}

/**
 * Removes a product from the server
 * @returns {function} inner thunk
 */
export function deleteRetailProduct(productid) {
  return function innerDeactivateRetailProduct(dispatch) {
    // $.ajax(`/studios/api/retail/products/${productid}`, {
    //     method: 'DELETE',
    //     success(data) {
    //         if (data.success) {
    //             dispatch(setRetailProduct(data.product));
    //             dispatch(addNotice(data.message));
    //         } else {
    //             dispatch(addError(data.message));
    //         }
    //     },
    //     failure(err) {
    //         dispatch(addError(err.message));
    //     },
    // });
  };
}

/**
 * Removes a product from the server
 * @returns {function} inner thunk
 */
export function restoreRetailProduct(productid) {
  return function innerActivateRetailProduct(dispatch) {
    // $.ajax(`/studios/api/retail/products/${productid}/restore`, {
    //     method: 'PUT',
    //     success(data) {
    //         if (data.success) {
    //             dispatch(setRetailProduct(data.product));
    //             dispatch(addNotice(data.message));
    //         } else {
    //             dispatch(addError(data.message));
    //         }
    //     },
    //     failure(err) {
    //         dispatch(addError(err.message));
    //     },
    // });
  };
}

/**
 *
 * @param {number} productid  database identifier for product being purchased
 * @param {number} clientid   user id to buy item for
 * @return {function} inner thunk
 */
export function purchaseRetailProductForClient(productid, clientid = null) {
  return async function innerPurchaseRetailProductForClient(
    dispatch,
    getState,
  ) {
    try {
      const state = getState();
      if (getClientHasNoCreditCard(state)) {
        dispatch(addError("Please update the client's payment information."));
        return;
      }
      if (getRetailProductIsPurchasing(state)) return;
      dispatch(setRetailPurchasing());
      if (!clientid) clientid = getClientId(state);
      const code = getClientRetailAppliedPromoCode(state);
      const { data } = await axios.post(
        `/studios/api/clients/${clientid}/retail/${productid}`,
        { code },
      );
      if (data.success) {
        dispatch(requestClientData(clientid));
        dispatch(addNotice(`Successfully purchased ${data.product.name}.`));
      } else {
        dispatch(addError(data.message));
      }
      dispatch(clearActiveRetailPurchase());
    } catch (err) {
      dispatch(addError("Oops! Something went wrong purchasing that product."));
      dispatch(clearActiveRetailPurchase());
    }
  };
}
