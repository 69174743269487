import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function getAttendees(eventid) {
    const url = getApiUrl('/get-all-attendees');
    let datatosend = {};
    const findAttendees = async () => {
        await axios
            .post(url, {
                eventid,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(
                    `error finding attendees for eventid - ${eventid}: \n${err}`
                );
                return err;
            });
    };
    await findAttendees();
    return datatosend;
}
