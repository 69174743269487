import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, toNumber } from "lodash";
import classNames from "classnames";

import Modal from "../Modal";
import "../../styles/retail-edit-modal.scss";
import InputField from "../InputField";

/**
 * RetailList Stateless Functional Component
 * @param {Object} props Component props
 * @returns {JSX.Element} HTML
 */
class RetailEditModal extends Component {
  constructor(props) {
    super(props);

    if (props.item) {
      this.state = {
        item: {
          id: props.item.id,
          name: props.item.name,
          price: props.item.price,
          description: props.item.description,
          displayOnRoster: props.item.display_on_roster,
        },
      };
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setSubmission = this.setSubmission.bind(this);
    this.isValid = this.isValid.bind(this);
    this.toggleDisplay = this.toggleDisplay.bind(this);
  }

  /**
   * handleChange
   * @param {Event} event the event object
   * @param {string} event.target.name the name of the modified input
   * @param {string} event.target.value the value the user inputs
   * @returns {undefined}
   */
  handleChange({ target: { name, value } }) {
    let newValue = value;
    switch (name) {
      case "displayOnRoster":
        newValue = !this.state.item.displayOnRoster;
        break;
      default:
        break;
    }
    this.setState({ item: { ...this.state.item, [name]: newValue } });
    return false;
  }

  /**
   * toggleRecurring
   * @returns {undefined}
   */
  toggleDisplay() {
    this.setState({
      item: {
        ...this.state.item,
        displayOnRoster: !this.state.item.displayOnRoster,
      },
    });

    return false;
  }

  state = {
    canSubmit: true,
    item: {
      id: undefined,
      name: "",
      description: "",
      price: 0.0,
      displayOnRoster: false,
    },
  };

  async handleSubmit() {
    const { handleSubmit, handleClose } = this.props;
    const { item } = this.state;

    if (this.isValid()) {
      // make sure price is a number
      item.price = toNumber(item.price);

      // turn off submission
      this.setSubmission(false);

      if (handleSubmit) await handleSubmit(item);
      if (handleClose) handleClose();
    }
  }

  isValid() {
    const {
      item: { name },
    } = this.state;

    return !isEmpty(name);
  }

  setSubmission(canSubmit) {
    this.setState({ canSubmit });
  }

  render() {
    const { open, handleClose, okText, header, currency } = this.props;
    const {
      item: { name, price, description, displayOnRoster },
      canSubmit,
    } = this.state;

    return (
      <Modal
        open={open}
        okText={okText}
        header={header}
        canSubmit={this.isValid() && canSubmit}
        handleClose={handleClose}
        handleSubmit={this.handleSubmit}
        className="retail-edit-modal"
      >
        <div className="retail-edit-modal-section">
          <div className="dibs-input-fields">
            <InputField
              label="Name"
              name="name"
              onChange={this.handleChange}
              value={name}
              autoComplete="none"
              invalid={!name}
              required
            />

            <InputField
              label="Price"
              name="price"
              onChange={this.handleChange}
              value={price}
              autoComplete="none"
              type="number"
              invalid={!price}
              required
              currency={currency}
            />

            <InputField
              label="Description"
              name="description"
              onChange={this.handleChange}
              value={description}
              autoComplete="none"
            />
            <div className="edit-toggle">
              <div className="checkbox__container">
                <button // eslint-disable-line
                  onClick={this.toggleDisplay}
                  name="displayOnRoster"
                  className={classNames(
                    "checkbox dibs-highlight-border",
                    displayOnRoster && "dibs-highlight-background",
                  )}
                />
                <p className="dibs-font">
                  Make available for Quick-buy on roster
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

RetailEditModal.propTypes = {
  open: PropTypes.bool,
  okText: PropTypes.string,
  header: PropTypes.string,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  currency: PropTypes.string,
  item: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      displayOnRoster: PropTypes.bool,
    }),
    PropTypes.number,
  ]),
};

export default RetailEditModal;
