import React from "react";
import PropTypes from "prop-types";
import Card from "../Card";
// import Currency from 'react-currency';
import { formatDollarAmount as FormatDollarAmount } from "helpers";

import "../../styles/retail-list-card.scss";

/**
 * RetailListCard React Component
 * @extends {module:React.Component}
 * @returns {JSX.Element} HTML
 */
class RetailListCard extends React.Component {
  /**
   * @constructor
   * @param {object} props inherited properties
   */
  constructor(props) {
    super(props);
    this.editClick = this.editClick.bind(this);
    this.activateClick = this.activateClick.bind(this);
    this.deactivateClick = this.deactivateClick.bind(this);
    this.deleteClick = this.deleteClick.bind(this);
  }

  /**
   * @returns {undefined}
   */
  editClick() {
    if (this.props.editClick) this.props.editClick(this.props.item);
  }

  /**
   * @returns {undefined}
   */
  deactivateClick() {
    if (this.props.deactivateClick) this.props.deactivateClick(this.props.item);
  }

  /**
   * @returns {undefined}
   */
  activateClick() {
    if (this.props.activateClick) this.props.activateClick(this.props.item);
  }

  /**
   * @returns {undefined}
   */
  deleteClick() {
    if (this.props.deleteClick) this.props.deleteClick(this.props.item);
  }

  /**
   * render
   * @returns {JSX.Element} HTML
   */
  render() {
    const { item, editClick, deleteClick, removeText = "REMOVE" } = this.props;

    return (
      <Card containerClassNames="" classNames="retail-list-card">
        <div className="retail-list-card-title retail-list-card-section">
          {" "}
          {item.name}{" "}
        </div>
        <FormatDollarAmount
          className="retail-list-card-price dibs-font retail-list-card-section"
          quantity={item.price}
        />
        {item.description && (
          <div className="retail-list-card-description retail-list-card-section">
            {" "}
            {item.description}
          </div>
        )}
        <div className="retail-list-card-buttons card-buttons">
          {editClick && (
            <button
              className="dibs-button card-button dibs-font dibs-highlight-background"
              onClick={this.editClick}
            >
              EDIT
            </button>
          )}
          {deleteClick && (
            <button
              className="dibs-button card-button dibs-font dibs-highlight-background"
              onClick={this.deleteClick}
            >
              {removeText}
            </button>
          )}
        </div>
      </Card>
    );
  }
}

RetailListCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  editClick: PropTypes.func,
  deactivateClick: PropTypes.func,
  activateClick: PropTypes.func,
  deleteClick: PropTypes.func,
};

export default RetailListCard;
