import React from 'react';
import {
    useNavigate,
    useParams,
    useSearchParams,
    // Link,
} from 'react-router-dom';
import {
    auth,
    sendEmailAuthLink,
    verifyUserClickedEmailLink,
} from 'firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
// import { lowercaseString } from 'helpers';
import setLastLogin from 'actions/users/setLastLogin';
import setPwdFirebaseUpdated from 'actions/users/setPwdFirebaseUpdated';
import { gridSpacing } from 'store/constant';
import validatePassword from 'actions/users/validatePassword';
import isUserNew from 'actions/isUserNew';

import { dispatch } from 'store';
import {
    setIsLoggedIn,
    // setIsNewUser,
    // setUserQualifiesPromos,
} from 'store/slices/studioemployeeuser';
import { setIsNewUser, setUserQualifiesPromos } from 'store/slices/thisuser';

import {
    registerWithEmailAndPassword,
    logInWithEmailAndPassword,
} from 'firebaseConfig';

// material-ui
import {
    Grid,
    Button,
    // Divider,
    // Grid,
    InputLabel,
    Typography,
    TextField,
    Link,
    Fade,
    // useMediaQuery,
} from '@mui/material';

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useSelector } from 'store';

// project imports
import LoginCard from 'ui-component/cards/AccountSubCardNew';
import ErrorMessage from 'views/feedback-messages/error/errorMessageNew';
import { setErrorMessageReducer, setHasErrorReducer } from 'store/slices/error';
import setFirebaseAuthenticationPwd from 'actions/setFirebaseAuthenticationPwd';

import setFirebaseAuthentication from 'actions/setFirebaseAuthentication';
import { addUserInfoAfterLogin } from 'actions/updateUserProfileInStore';

const validationSchema = yup.object().shape({
    password: yup
        .string()
        .required('Password is required')
        .min(
            7,
            'Your password must be at least 8 characters. Please try again.'
        ),
});

// ============================|| FIREBASE - LOGIN ||============================ //

const LoginAuthenticationCardPassword = ({ setCurrentStep }) => {
    const { dibsId } = useParams();
    const { encryptedpassword, hasSetPwdFirebase, email, userid } = useSelector(
        (state) => state.studioemployeeuser
    );
    const validatePasswordDb = async (password, encryptedpassword) => {
        return await validatePassword(password, encryptedpassword).then(
            (res) => {
                if (res) {
                    dispatch(setIsLoggedIn(true));
                    return true;
                } else {
                    console.log('password is BAD AND incorrect');
                    return false;
                }
            }
        );
    };

    const formik = useFormik({
        initialValues: {
            password: '',
        },
        validationSchema,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                console.log('entering password and validating it is being run');
                if (!hasSetPwdFirebase) {
                    await validatePasswordDb(
                        values.password,
                        encryptedpassword
                    ).then(async (res) => {
                        if (res) {
                            console.log(
                                'password is verified - sending user to success page AND sending pwd to firebase'
                            );
                            // firebase - register with email and password
                            await registerWithEmailAndPassword(
                                email,
                                values.password
                            ).then(async (response) => {
                                console.log(
                                    'response from register with email and password is:',
                                    response
                                );
                                if (response === 1) {
                                    setFirebaseAuthenticationPwd(userid);
                                    setCurrentStep('loggedin');
                                    dispatch(setIsLoggedIn(true));
                                    // isUserNew
                                    // isUserNew(email, dibsId);
                                    // updateUserProfile
                                    setFirebaseAuthentication(userid);
                                    addUserInfoAfterLogin(email);
                                } else if (response === 2) {
                                    console.log(
                                        'user already exists in firebase'
                                    );
                                    // await logInWithEmailAndPassword(
                                    //     email,
                                    //     values.password,
                                    // ).then((response) => {});
                                    // old one is above here
                                    await logInWithEmailAndPassword(
                                        email,
                                        values.password
                                    ).then((response) => {
                                        // if login is verified
                                        if (response) {
                                            const { uid } = response;
                                            if (uid) {
                                                setCurrentStep('loggedin');
                                                dispatch(setIsLoggedIn(true));
                                                setLastLogin(email);
                                                setPwdFirebaseUpdated(email);
                                                // isUserNew
                                                // isUserNew(email, dibsId);
                                                // updateUserProfile
                                                const setNew = async () => {
                                                    await isUserNew(
                                                        email,
                                                        dibsId
                                                    ).then((res) => {
                                                        console.log(
                                                            'response to is new user on enter password page is:',
                                                            res
                                                        );
                                                        if (res) {
                                                            const { isNew } =
                                                                res;
                                                            dispatch(
                                                                setIsNewUser(
                                                                    isNew
                                                                )
                                                            );
                                                            dispatch(
                                                                setUserQualifiesPromos(
                                                                    isNew
                                                                )
                                                            );
                                                        }
                                                    });
                                                };
                                                setNew();
                                                setFirebaseAuthentication(
                                                    userid
                                                );
                                                setFirebaseAuthenticationPwd(
                                                    userid
                                                );
                                                setCurrentStep('loggedin');
                                                dispatch(setIsLoggedIn(true));
                                                setFirebaseAuthenticationPwd(
                                                    userid
                                                );
                                                addUserInfoAfterLogin(email);
                                            }
                                        }
                                    });

                                    // isUserNew
                                    // isUserNew(email, dibsId);
                                    // updateUserProfile
                                    setFirebaseAuthentication(userid);
                                    addUserInfoAfterLogin(email);
                                }
                                await isUserNew(email, dibsId).then((res) => {
                                    console.log(
                                        'response to is new user is:',
                                        res
                                    );
                                    if (res) {
                                        const { isNew } = res;
                                        dispatch(setIsNewUser(isNew));
                                        dispatch(setUserQualifiesPromos(isNew));
                                    }
                                });
                            });
                        } else {
                            // say message that password is incorrect
                            dispatch(setHasErrorReducer(true));
                            dispatch(
                                setErrorMessageReducer(
                                    'Your password cannot be authenticated. Please try again or click the link below to reset your password.'
                                )
                            );
                        }
                    });
                } else {
                    // skip to validating password in firebase
                    console.log('sending pwd DIRECTLY to firebase');
                    await logInWithEmailAndPassword(
                        email,
                        values.password
                    ).then((response) => {
                        // if login is verified
                        if (response) {
                            const { uid } = response;
                            if (uid) {
                                setCurrentStep('loggedin');
                                dispatch(setIsLoggedIn(true));
                                // isUserNew
                                // isUserNew(email, dibsId);
                                // updateUserProfile
                                const setNew = async () => {
                                    await isUserNew(email, dibsId).then(
                                        (res) => {
                                            console.log(
                                                'response to is new user on enter password page is:',
                                                res
                                            );
                                            if (res) {
                                                const { isNew } = res;
                                                dispatch(setIsNewUser(isNew));
                                                dispatch(
                                                    setUserQualifiesPromos(
                                                        isNew
                                                    )
                                                );
                                            }
                                        }
                                    );
                                };
                                setNew();
                                setFirebaseAuthentication(userid);
                                addUserInfoAfterLogin(email);
                                setLastLogin(email);
                                setPwdFirebaseUpdated(email);
                            }
                        }
                    });
                }
            } catch (error) {
                console.log(`error was caught: ${JSON.stringify(error)}`);
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
            }
        },
    });
    const setForgotPassword = () => {
        setCurrentStep('forgotpassword');
    };
    const goBackToEmailStep = () => {
        setCurrentStep('email');
    };
    return (
        <Grid container justifyContent="center">
            <Grid item xs={11} md={5} sx={{ mb: 5 }}>
                <Fade in={true} timeout={500}>
                    <LoginCard title="Sign In">
                        <Grid
                            container
                            spacing={gridSpacing}
                            justifyContent="flex-start"
                        >
                            <ErrorMessage />
                            <Grid item xs={12} sx={{ ml: 1 }}>
                                <Typography variant="regularText">
                                    Enter your password to continue
                                </Typography>
                                <form
                                    className="form-profile"
                                    onSubmit={formik.handleSubmit}
                                >
                                    <Grid container justifyContent="flex-start">
                                        <Grid
                                            item
                                            xs={10}
                                            md={9}
                                            sx={{ mt: 2, ml: 0.5 }}
                                        >
                                            <InputLabel>Password</InputLabel>
                                            <TextField
                                                fullWidth
                                                name="password"
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={
                                                    formik.touched.password &&
                                                    Boolean(
                                                        formik.errors.password
                                                    )
                                                }
                                                type="password"
                                                helperText={
                                                    formik.touched.password &&
                                                    formik.errors.password
                                                }
                                                sx={{ mt: 1 }}
                                            >
                                                Password
                                            </TextField>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ mt: 4, mb: 2.5 }}
                                        >
                                            <Button
                                                variant="checkout"
                                                type="submit"
                                                // fullWidth
                                                disabled={formik.isSubmitting}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{ ml: 1, mt: 1 }}
                                        >
                                            <Grid
                                                container
                                                justifyContent="space-between"
                                            >
                                                <Grid item xs={12} md={7}>
                                                    <Link
                                                        onClick={() =>
                                                            setForgotPassword()
                                                        }
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="guidance"
                                                            sx={{
                                                                fontStyle:
                                                                    'italic',
                                                            }}
                                                        >
                                                            Forgot your
                                                            password? Reset it
                                                            now.
                                                        </Typography>
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Link
                                                        onClick={() =>
                                                            goBackToEmailStep()
                                                        }
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="guidance"
                                                            sx={{
                                                                fontStyle:
                                                                    'italic',
                                                            }}
                                                        >
                                                            Back to email
                                                        </Typography>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </LoginCard>
                </Fade>
            </Grid>
        </Grid>
    );
};

export default LoginAuthenticationCardPassword;
